import React,
{
  useEffect,
  useState,
} from 'react';

import {
  Helmet,
} from 'react-helmet-async';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  useLocation,
} from 'react-router-dom';

import {
  GetModeleProfissionalPanel,
} from '../../../apis/endpoints/modeles.endpoints';

import {
  ShowHideUserPicture,
} from '../../../apis/endpoints/users-medias.endpoint';

import AlertMessage from '../../../components/Alerts';

import {
  Container,
  Screen,
  Title,
} from '../../../components/Composh/web';

import {
  ButtonBack,
} from '../../../components/Controls';

import HeaderScreen from '../../../components/Headers/HeaderScreen';

import {
  IconMediaPhoto,
  IconMediaVideo,
  IconProfile,
} from '../../../components/Icons';

import TabBarStyled from '../../../components/TabBar';

import {
  RootState,
} from '../../../redux';

import {
  PROJECT_CONFIGS_MODULES_SET,
} from '../../../redux/reducers/app-configs.store';

import {
  APP_MODAL_LOADING_ACTION,
  APP_SNACKBAR_ACTION,
} from '../../../redux/reducers/app-modals.store';

import {
  USER_SET,
} from '../../../redux/reducers/user.store';

import {
  Colors,
} from '../../../shared/constants';

import EAppMediaTypeRelation from '../../../shared/enums/Picture/app-media-type-relation.enum';
import EResponseStatus from '../../../shared/enums/Response/response-status.enum';

import {
  TranslateConfig,
} from '../../../shared/translations';

import {
  treatErrorAndReturnColor,
  treatErrorAndReturnMessage,
} from '../../../shared/utils/errors.utils';

import RightImageInfo from './Infos/InfoImages';
import InfoPrincipal from './Infos/InfoPrincipal';
import RightVideoInfo from './Infos/InfoVideos';

import MediasAddModal from './Modals/MediasAdd';
import MediasInfoModal from './Modals/MediasInfo';

import TabAddImages from './Tabs/TabImages';
import TabPrincipal from './Tabs/TabPrincipal';
import TabAddVideos from './Tabs/TabVideos';



export interface IRouteLocation {
  type?: string;
}



const ModeleMediaAddScreen: React.FC = () => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();

  const routeLocation = useLocation();
  const paramsState = routeLocation?.state as IRouteLocation;
  const paramPost = paramsState?.type;


  const user = useSelector((state: RootState) => state.user.actualUser);
  const userToGet = user?.useralias;


  const accentGender = Colors.primary;

  const [showInfoModal, setShowInfoModal] = useState<'PRINCIPAL' | 'PHOTOS' | 'VIDEOS' | null>(null);

  const [stateIndex, setStateIndex] = useState(0);
  const [showTabPrincipal, setShowTabPrincipal] = useState(true);

  const [showImageModal, setShowImageModal] = useState(false);
  const [showCoverModal, setShowCoverModal] = useState(false);

  const [showPhotoModal, setShowPhotoModal] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [openShowHideModal, setOpenShowHideModal] = useState<string>('');



  function setPrincipalTabShow() {
    if (paramPost && paramPost === 'POST') {
      setShowTabPrincipal(false);
      setStateIndex(1);
      return;
    }

    setShowTabPrincipal(true);
  }


  const screenTabs = [
    {
      visible: showTabPrincipal,
      name: 'PRINCIPAL',
      title: 'Mídias do perfil',
      icon: (
        <IconProfile
          color={stateIndex === 0 ? accentGender : Colors.inputCount}
          size={25}
        />
      ),
      component: (
        <TabPrincipal
          onImagePress={() => {
            setShowImageModal(true);
          }}
          onCoverPress={() => {
            setShowCoverModal(true);
          }}
          onShowModal={() => {
            setShowInfoModal('PRINCIPAL');
          }}
        />
      ),
    },
    {
      visible: true,
      name: 'PHOTOS',
      title: 'Fotos',
      icon: (
        <IconMediaPhoto
          color={stateIndex === 1 ? accentGender : Colors.inputCount}
          size={25}
        />
      ),
      component: (
        <TabAddImages
          onOpenPhotoModal={() => {
            setShowPhotoModal(true);
          }}
          onShowModal={() => {
            setShowInfoModal('PHOTOS');
          }}
          tabEditPress={() => {
            // setShowInfoModal('PHOTOS');
          }}
          tabShowHidePress={(idMedia: string) => {
            console.log('id', idMedia);
            setOpenShowHideModal(idMedia);
          }}
        />
      ),
    },
    {
      visible: true,
      name: 'VIDEOS',
      title: 'Vídeos',
      icon: (
        <IconMediaVideo
          color={stateIndex === 2 ? accentGender : Colors.inputCount}
          size={25}
        />
      ),
      component: (
        <TabAddVideos
          onOpenVideoModal={() => {
            setShowVideoModal(true);
          }}
          onShowModal={() => {
            setShowInfoModal('VIDEOS');
          }}
          tabEditPress={() => {
            // setShowInfoModal('PHOTOS');
          }}
          tabShowHidePress={(idMedia: string) => {
            setOpenShowHideModal(idMedia);
          }}
        />
      ),
    },
  ];



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION,
      payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  function appProjectParamsValues(dataParams: any) {
    dispatch({ type: PROJECT_CONFIGS_MODULES_SET, payload: dataParams });
  }


  async function getActualModele() {
    dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

    try {
      const response = await GetModeleProfissionalPanel(userToGet);
      const responseModele = response?.data;

      if (!responseModele) {
        console.error('Error', response);
        showSnackBarProps(Colors.danger, 'Erro ao fazer o cadastro');
        return;
      }

      appProjectParamsValues(response?.data?.configs?.modules);

      dispatch({ type: USER_SET, payload: responseModele?.modele });
    }
    catch (error: any) {
      console.error(error);

      const getMessageError = treatErrorAndReturnMessage(error);
      const messageError = translate(getMessageError);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  }


  async function onShowHideMedia(idMedia: string) {
    setOpenShowHideModal(null);

    try {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

      const response = await ShowHideUserPicture(userToGet, idMedia);

      if (response?.status === EResponseStatus.ERROR) {
        console.error('Error', response);
        showSnackBarProps(Colors.danger, 'Erro ao editar mídia');
        return;
      }

      showSnackBarProps(Colors.accept, 'Mídia editada com sucesso');

      await getActualModele();
    }
    catch (error: any) {
      console.error(error);

      const getMessageError = treatErrorAndReturnMessage(error);
      const messageError = translate(getMessageError);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  }



  useEffect(() => {
    setPrincipalTabShow();
    getActualModele();
  }, []);



  return (

    <Screen
      backgroundColor={Colors.appBackground}>


      <Helmet prioritizeSeoTags>
        <title>
          {`${translate('Mídias')} | ${translate(TranslateConfig.NAME_APP)}`}
        </title>

        <meta name="robots" content="noindex,nofollow" />
        <meta name="googlebot" content="noindex,nofollow" />
      </Helmet>



      <Container>

        <HeaderScreen
          disableGender
          backgroundColor={Colors.toolbarBackground}
          subBackgroundColor={Colors.toolbarBackground}
          leftIcon={
            <ButtonBack
              color={Colors.white}
              size={27}
            />
          }
          centerContent={
            <Title
              color={Colors.white}>
              Mídias
            </Title>
          }
          noSubToolbarPadding>


          <TabBarStyled
            tabs={screenTabs}
            selected={stateIndex}
            color={accentGender}
            onPress={(tab) => {
              setStateIndex(tab?.index);
            }}
          />

        </HeaderScreen>



        {stateIndex === 0 && screenTabs[0].component}

        {stateIndex === 1 && screenTabs[1].component}

        {stateIndex === 2 && screenTabs[2].component}



        {/*
      <ol>
        <li><strong>Fotos fakes não serão aceitas</strong>, o anúncio será excluído sem aviso prévio caso seja constatado o envio de fotos falsas.</li>
        <li>Mulheres trans <strong>OPERADAS</strong> também são aceitas, porém é necessário que seja informado para que seja postado no anúncio a informação. Caso não seja informado a anunciante é banida do site.</li>
        <li>Lembrando que, <strong>abominamos anunciantes fakes</strong>, sendo assim, deixamos mais uma vez bem claro que, caso em algum momento, mesmo que a anunciante seja pagante <strong>(ANÚNCIO DESTAQUE) </strong>e tenha enviado <strong>DOCUMENTO COM FOTO + SELFIE COM DOCUMENTO</strong>, mas, posteriormente seja constatado o uso de fotos fakes, de outra anunciante, modelos, atrizes, ou qualquer outra pessoa que não seja a própria anunciante (você), a anunciante será <strong>EXCLUÍDA</strong> e <strong>BANIDA</strong> do nosso site.<br />
          Certo de sua compreensão e colaboração, daremos seguimento ao seu anúncio.</li>
      </ol>
      */}

      </Container>



      {/* Image Picture */}
      <MediasAddModal
        type={EAppMediaTypeRelation.USER_IMAGE}
        visible={showImageModal}
        title={'Adicionar imagem'}
        description={translate(TranslateConfig.PROFILE_PICTURE)}
        accept={'image/jpeg, image/png'}
        onClose={() => {
          setShowImageModal(false);
        }}
      />



      <MediasAddModal
        type={EAppMediaTypeRelation.USER_COVER}
        visible={showCoverModal}
        title={'Adicionar imagem'}
        description={'Imagem de fundo'}
        accept={'image/jpeg, image/png'}
        onClose={() => {
          setShowCoverModal(false);
        }}
      />



      <MediasAddModal
        type={EAppMediaTypeRelation.GALLERY_IMAGES}
        visible={showPhotoModal}
        title={'Adicionar imagem'}
        description={'Imagem para a galeria'}
        accept={'image/jpeg, image/png'} // '.jpg, .jpeg, .bmp, .png'
        onClose={() => {
          setShowPhotoModal(false);
        }}
      />



      <MediasAddModal
        type={EAppMediaTypeRelation.GALLERY_VIDEOS}
        visible={showVideoModal}
        title={'Adicionar vídeo'}
        description={'Vídeo para a galeria'}
        accept={'video/*'}
        onClose={() => {
          setShowVideoModal(false);
        }}
      />



      <AlertMessage
        visible={Boolean(openShowHideModal)}
        title={'Alterar visibilidade da mídia?'}
        cancelText={'Não'}
        cancelPress={() => {
          setOpenShowHideModal(null);
        }}
        okText={'Sim'}
        okPress={() => {
          onShowHideMedia(openShowHideModal);
        }}
      />



      <MediasInfoModal
        visible={showInfoModal !== null}
        onClose={() => {
          setShowInfoModal(null);
        }}>

        {showInfoModal === 'PRINCIPAL' && (
          <InfoPrincipal />
        )}

        {showInfoModal === 'PHOTOS' && (
          <RightImageInfo />
        )}

        {showInfoModal === 'VIDEOS' && (
          <RightVideoInfo />
        )}

      </MediasInfoModal>

    </Screen>

  );
};



export default ModeleMediaAddScreen;
