import React,
{
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  ILanguageCountry,
} from '../../../apis/interfaces';

import CardLanguage from '../../../components/Cards/CardLanguage';

import {
  Overlay,
} from '../../../components/Composh/web';

import EmptyContent from '../../../components/Empty';
import HeaderModal from '../../../components/Headers/HeaderModal';
import InputText from '../../../components/Inputs/InputText';

import {
  setCurrentLanguage,
} from '../../../config/language.config';

import {
  RootState,
} from '../../../redux';

import {
  USER_LANGUAGE,
} from '../../../redux/reducers/localities.store';

import {
  languagesOption,
} from '../../../shared/arrays/App/lang-country.array';

import {
  Colors,
  Images,
} from '../../../shared/constants';

import {
  TranslateConfig,
} from '../../../shared/translations';

import {
  BankModalView,
  FilteredInput,
  FilteredBanksContent,
  FilteredListSection,
} from './styled';



export interface IProps {
  visible?: boolean;
  onPress?: any;
  onOverlayPress?: any;
}



const LanguagesModal: React.FC<IProps> = (props: IProps) => {
  const visibleModal = props.visible;
  if (!visibleModal) {
    return null;
  }


  const dispatch = useDispatch();
  const { t: translate, i18n } = useTranslation();


  const languageSelected = useSelector((state: RootState) => state.localities.language);


  const widthLine = 320;
  const heightLine = 500;

  const [filterBank, setFilterBank] = useState('');

  const [filterArrayLanguages, setFilterArrayLanguages] = useState<Array<ILanguageCountry>>([]);



  function onLanguagePress(language: string) {
    i18n.changeLanguage(language);

    setCurrentLanguage(language);
    props.onOverlayPress();
  }


  function renderItem(item: ILanguageCountry, index: number) {
    return (

      <CardLanguage
        key={index}
        selected={item?.languageIso === languageSelected}
        title={translate(item?.label)}
        languageIso={item?.languageIso ? (item?.languageIso).replace('_', '-') : '-'}
        image={item?.flagImage}
        onPress={() => {
          dispatch({ type: USER_LANGUAGE, payload: item?.languageIso });
          onLanguagePress(item?.languageIso);
        }}
      />

    );
  }


  function returnListFiltered() {
    const getAllTofilterBankArray = languagesOption?.filter((bankFilter: ILanguageCountry) => (bankFilter.label.toLowerCase().includes(filterBank.toLowerCase())) || filterBank === '');
    setFilterArrayLanguages(getAllTofilterBankArray);
  }


  function listEmptyComponent() {
    return (

      <EmptyContent
        image={Images.noImage}
        title={translate(TranslateConfig.EMPTY_LANGUAGES)}
      />

    );
  }



  return (

    <Overlay
      visible={visibleModal}
      showBackground={true}
      overlayColor={Colors.menuOverlay}
      onOverlayPress={props.onOverlayPress}>

      <BankModalView
        width={widthLine}
        height={heightLine}>

        <HeaderModal
          title={translate(TranslateConfig.LANGUAGE)}
          onPress={props.onOverlayPress}
        />



        <FilteredInput>
          <InputText
            type={'TEXT'}
            labelText={translate(TranslateConfig.LANGUAGE)}
            placeholderText={translate(TranslateConfig.NAME)}
            value={filterBank}
            // helpText={errorMessage}
            // countLimit={Values.cpfCount}
            onChange={(rawText: string) => {
              setFilterBank(rawText);
              returnListFiltered();
            }}
          />
        </FilteredInput>



        <FilteredBanksContent>

          {(!languagesOption || languagesOption?.length === 0 || (filterBank !== '' && filterArrayLanguages.length === 0)) && (
            listEmptyComponent()
          )}



          {languagesOption?.length !== 0 && filterArrayLanguages.length !== 0 && filterBank !== '' && (
            <FilteredListSection>
              {filterArrayLanguages.map((itemFiltered: ILanguageCountry, index: number) => renderItem(itemFiltered, index))}
            </FilteredListSection>
          )}



          {languagesOption?.length !== 0 && filterBank === '' && (
            <FilteredListSection>
              {languagesOption.map((itemFiltered: ILanguageCountry, index: number) => (
                renderItem(itemFiltered, index)
              ))}
            </FilteredListSection>
          )}

        </FilteredBanksContent>

      </BankModalView>

    </Overlay>

  );
};



export default LanguagesModal;
