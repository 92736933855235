import React from 'react';

import {
  useSelector,
} from 'react-redux';

import {
  IInputSelect,
} from '../../apis/interfaces/App/input-select.interface';

import {
  Overlay,
} from '../../components/Composh/web';

import HeaderModal from '../../components/Headers/HeaderModal';

import {
  IconMapPin,
  IconPlane,
} from '../../components/Icons';

import {
  RootState,
} from '../../redux';

import {
  Colors,
} from '../../shared/constants';

import {
  returnCityArray,
  returnCountryArray,
  returnStateArray,
} from '../../shared/utils/address.utils';

import {
  FormatExtenseDate,
  daysUntilDate,
} from '../../shared/utils/date.utils';

import {
  TravelContent,
  TravelBodyContent,
  TravelCurrentLocationContent,
  TravelLocationContent,
  TravelCurrentView,
  TravelViewIcon,
  TravelCurrentInfoView,
  TravelCurrentInfoTitle,
  TravelCurrentInfoText,
  TravelCountTitle,
  TravelCurrentDateText,
} from './styled';



export interface IProps {
  visible?: boolean;
  onClose?: any;
}



const TravelScheduleModal: React.FC<IProps> = (props: IProps) => {
  const genderColorChoose = useSelector((state: RootState) => state.choose.genderColorApp);

  const travelsModal = useSelector((state: RootState) => state.appModals.travelsModal);


  // Verifica e Encontra a primeira viagem habilitada
  const enabledTravel = travelsModal?.travels?.find((travel) => travel.enabled);


  function renderCurrentLocationModele() {
    const getUserCountry = enabledTravel.country;
    const findCountry = returnCountryArray()?.find((item: IInputSelect) => item.value === getUserCountry);
    const countryUser = findCountry?.label;

    const getStateArray = returnStateArray(getUserCountry);
    const findState = getStateArray?.find((itemLocation: IInputSelect) => itemLocation.value === enabledTravel.state);
    const stateUser = findState?.label;

    const getCityArray = returnCityArray(getUserCountry, findState?.value);
    const findCity = getCityArray?.find((itemLocation: IInputSelect) => itemLocation.value === enabledTravel.city);
    const cityUser = findCity?.label;


    return (

      <TravelCurrentLocationContent>

        <TravelCurrentView>
          <TravelViewIcon>
            <IconMapPin
              color={genderColorChoose}
              size={30}
            />
          </TravelViewIcon>


          <TravelCurrentInfoView>
            <TravelCurrentInfoTitle>
              Localização atual
            </TravelCurrentInfoTitle>


            <TravelCurrentInfoText>
              {`${cityUser}, ${stateUser}, ${countryUser}`}
            </TravelCurrentInfoText>
          </TravelCurrentInfoView>

        </TravelCurrentView>

      </TravelCurrentLocationContent>

    );
  }



  function renderTravelModele(item: any, index: number) {
    const itemStartAt = item?.startAt;
    const itemEndAt = item?.endAt;

    const getUserCountry = item?.country;
    const findCountry = returnCountryArray()?.find((item: IInputSelect) => item.value === getUserCountry);
    const countryUser = findCountry?.label;

    const getStateArray = returnStateArray(getUserCountry);
    const findState = getStateArray?.find((itemLocation: IInputSelect) => itemLocation.value === item?.state);
    const stateUser = findState?.label;

    const getCityArray = returnCityArray(getUserCountry, findState?.value);
    const findCity = getCityArray?.find((itemLocation: IInputSelect) => itemLocation.value === item?.city);
    const cityUser = findCity?.label;


    return (

      <TravelLocationContent
        key={index}>

        <TravelCurrentView>
          <TravelViewIcon>
            <IconPlane
              color={Colors.white}
              size={30}
            />
          </TravelViewIcon>


          <TravelCurrentInfoView>
            <TravelCurrentInfoText>
              {`${cityUser}, ${stateUser}, ${countryUser}`}
            </TravelCurrentInfoText>


            <TravelCountTitle>
              {`Em ${daysUntilDate(itemStartAt)} dias`}
            </TravelCountTitle>
          </TravelCurrentInfoView>


        </TravelCurrentView>


        <TravelCurrentDateText>
          {FormatExtenseDate(itemStartAt)} a {FormatExtenseDate(itemEndAt)}
        </TravelCurrentDateText>

      </TravelLocationContent>

    );
  }



  return (

    <Overlay
      visible={props.visible}
      overlayColor={Colors.alertOverlay}
      onOverlayPress={props.onClose}>

      <TravelContent>

        <HeaderModal
          title={`Agenda de viagens de ${travelsModal?.alias}`}
          onPress={props.onClose}
        />



        <TravelBodyContent>

          {enabledTravel && renderCurrentLocationModele()}


          {travelsModal?.travels?.filter((travel) => !travel.enabled).map((travel, index) => (
            renderTravelModele(travel, index)
          ))}

        </TravelBodyContent>

      </TravelContent>

    </Overlay>

  );
};



export default TravelScheduleModal;
