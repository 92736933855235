import styled from 'styled-components';

import {
  ContentPadderHorizontal,
} from '../../../components/Contents/ContentPadder';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';



export const HeroContainer = styled(ContentPadderHorizontal)`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 30px;
  padding-bottom: 70px;
`;



export const HeroHeaderView = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const HeroHeaderInfo = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 500px;
`;

export const HeroHeaderTitle = styled.h1`
  color: ${Colors.white};
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
`;

export const HeroHeaderColor = styled.span`
  color: ${Colors.primary};
`;

export const HeroHeaderDescription = styled.p`
  margin-top: 7px;
  color: ${Colors.white};
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
`;

export const HeroImageLogo = styled.img`
  margin-top: -20px;
  width: 160px;
  height: 160px;
  object-fit: contain;
`;



export const HeroOptionsView = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-top: 45px;
`;

export const HeroOptionsButton = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 280px;
  height: 100px;
  margin-left: 5px;
  margin-right: 5px;
  padding-top: 16px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 16px;
  background-color: ${Colors.cardBackground};
  border-color: ${Colors.grayDark};
  border-style: solid;
  border-width: 1px;
  border-radius: ${Sizes.cardRadius}px;
  transition: all .15s ease;

  :hover {
    border-color: ${Colors.primary};

    svg {
      color: ${Colors.primary} !important;
      fill: ${Colors.primary} !important;
    }
  }
`;

export const HeroOptionsHeaderButton = styled.div`
  display: flex;
  flex-direction: row;
`;

export const HeroOptionsTitleButton = styled.p`
  margin-left: 10px;
  color: ${Colors.white};
  font-size: 20px;
  font-weight: bold;
  transition: all .15s ease;

  ${HeroOptionsButton}:hover & {
    color: ${Colors.primary};
  }
`;

export const HeroOptionsDescButton = styled.p`
  color: ${Colors.grayLight};
  font-size: 14px;
`;
