import styled from 'styled-components';

import {
  SubTitle,
} from '../../components/Composh/web';

import {
  Colors,
  Sizes,
} from '../../shared/constants';



export const CategoryChooseContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  background-color: ${Colors.appBackground};
  border-radius: ${Sizes.cardRadius}px;
  width: 90%;
  max-width: 700px;
  height: 90%;
  max-height: 550px;
`;



export const LocationSubtitle = styled(SubTitle)`
  margin-top: 1px;
  line-height: 17px;
`;



export const LocationCountryButton = styled.a`
  width: 36px;
  height: 36px;
`;

export const LocationCountryImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;



export const FilteredInput = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 15px;
`;

export const LocationClearButton = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
`;
