import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import {
  GetAllLiveHome,
} from '../../../../../apis/endpoints/lives.endpoints';

import ButtonAction from '../../../../../components/Buttons/ButtonAction';
import CardLiveModele from '../../../../../components/Cards/CardLiveModele';

import {
  Content,
  Switch,
} from '../../../../../components/Composh/web';

import EmptyContent from '../../../../../components/Empty';
import FooterApp from '../../../../../components/Footers/FooterApp';

import {
  IconGridSmall,
  IconGridMedium,
  IconGridLarge,
  IconHd,
  IconPhoneCell,
  IconFlagHome,
  IconFilter,
  IconStatusOnline,
  IconInformation,
} from '../../../../../components/Icons';

import LoadingScreen from '../../../../../components/Loadings/LoadingScreen';

import {
  SetEmptyGenderImage,
} from '../../../../../config/genre.config';

import NameRoutes from '../../../../../navigation/names';

import {
  RootState,
} from '../../../../../redux';

import {
  APP_SNACKBAR_ACTION,
} from '../../../../../redux/reducers/app-modals.store';

import {
  Colors,
} from '../../../../../shared/constants';

import {
  TranslateConfig,
} from '../../../../../shared/translations';

import {
  treatErrorAndReturnColor,
  treatErrorAndReturnMessage,
} from '../../../../../shared/utils/errors.utils';

import {
  LiveWrapperItemContent,
  RentViewContent,
  LiveCamContainer,
  LiveHeaderContent,
  LiveRoomUpdateText,
  MessageLiveHeader,
  FilterButtonLiveHeader,
  LiveCamHeaderOptionsView,
  LiveCamHeadingView,
  LiveCamHeadingTitle,
  LiveModeleSortable,
  LiveModelewrapSortable,
  LiveModeleskew,
  LiveSwitchToSeeButton,
  LiveSwitchToSeeText,
  LiveModelewrapSortableButton,
  LiveModeleSizewrapSort,
  CardLiveHighlightHigh,
  CardLiveItemNormal,
} from './styled';



const TabLivePage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t: translate } = useTranslation();


  const genderChoose = useSelector((state: RootState) => state.choose.genderChoose);

  const user = useSelector((state: RootState) => state.user.actualUser);
  const availableUser = user?.id;


  const colorSelected = Colors.primary;
  const colorUnselected = Colors.unselected;


  const [modeles, setModeles] = useState<Array<any>>([]);

  const [activeLiveRoom, setActiveLiveRoom] = useState<boolean>(false);
  const [showOnlineModels, setShowOnlineModels] = useState<boolean>(true);
  const [showMobileModels, setShowMobileModels] = useState<boolean>(false);
  const [showFlagsHome, setShowFlagsHome] = useState<boolean>(false);
  const [showHigherQuality, setShowHigherQuality] = useState<boolean>(false);

  const [gridArrange, setGridArrange] = useState<'SMALL' | 'MEDIUM' | 'LARGE'>('MEDIUM');

  const [loading, setLoading] = useState(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION,
      payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  const getLiveRooms = async () => {
    try {
      setLoading(true);

      const response = await GetAllLiveHome(genderChoose);

      if (response) {
        setModeles(response);
      }
      else {
        setModeles([]);
      }
    }
    catch (error: any) {
      console.error(error);
      setModeles([]);

      const getMessageError = treatErrorAndReturnMessage(error);
      const messageError = translate(getMessageError);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      setLoading(false);
    }
  };


  function renderItem(item: any, index: number) {
    return (

      <CardLiveModele
        key={index}
        status={item?.status}
        exclusive={item?.exclusive}
        verified={item?.verified}
        promoter={item?.promoter}
        showFlag={showFlagsHome}
        username={item?.useralias}
        name={item?.alias}
        image={item?.cover}
        onFavoritePress={() => {
          // TODO
        }}
        onProfilePress={() => {
          history.push(NameRoutes.ModeleDetailsScreen.replace(
            ':id',
            'username',
          ));
        }}
        onPress={() => {
          history.push({
            pathname: NameRoutes.LiveRoomCamScreen.replace(
              ':id',
              'username',
            ),
            state: {
              status: item?.status,
              promo: false,
            },
          });
        }}
      />

    );
  }


  function renderLoading() {
    if (!loading) {
      return null;
    }

    return (

      <LoadingScreen
        type={'SCREEN'}
        color={Colors.white}
      />

    );
  }


  function listEmptyComponent() {
    return (

      <EmptyContent
        image={SetEmptyGenderImage(genderChoose)}
        title={translate(TranslateConfig.EMPTY_ITEMS)}
      />

    );
  }


  function listHeaderComponent() {
    return (

      <LiveCamHeaderOptionsView>

        <LiveCamHeadingView>

          <LiveCamHeadingTitle
            title={`${translate(TranslateConfig.CAMS)}: ${'254'}`}
          />

        </LiveCamHeadingView>



        <LiveModeleSortable>

          <LiveModelewrapSortable>

            <LiveModeleskew
              backgroundColor={'#323541'}
              color={Colors.white}>
              {translate(TranslateConfig.ACTION_BE_PREMIUM)}
            </LiveModeleskew>


            {/* TODO: APENAS PREMIUM */}
            <LiveSwitchToSeeButton
              onClick={() => {
                setActiveLiveRoom(!activeLiveRoom);
              }}>
              <LiveSwitchToSeeText
                color={activeLiveRoom ? Colors.white : colorUnselected}>
                {activeLiveRoom
                  ? translate(TranslateConfig.LIVE_IMAGES)
                  : translate(TranslateConfig.LIVE_IMAGE_ROOM)
                }
              </LiveSwitchToSeeText>


              <Switch
                hideText
                selected={activeLiveRoom}
                color={colorUnselected}
                selectedColor={colorSelected}
              />
            </LiveSwitchToSeeButton>


            <LiveModelewrapSortableButton
              title={translate(TranslateConfig.LIVE_SHOW_MODELS_ONLINE)}
              onClick={() => {
                setShowOnlineModels(!showOnlineModels);
              }}>
              <IconStatusOnline
                color={showOnlineModels ? colorSelected : colorUnselected}
                size={26}
              />
            </LiveModelewrapSortableButton>


            <LiveModelewrapSortableButton
              title={translate(TranslateConfig.LIVE_SHOW_MODELS_PHONE)}
              onClick={() => {
                setShowMobileModels(!showMobileModels);
              }}>
              <IconPhoneCell
                color={showMobileModels ? colorSelected : colorUnselected}
                size={26}
              />
            </LiveModelewrapSortableButton>



            <LiveModelewrapSortableButton
              title={translate(TranslateConfig.LIVE_SHOW_MODELS_FLAG)}
              onClick={() => {
                setShowFlagsHome(!showFlagsHome);
              }}>
              <IconFlagHome
                color={showFlagsHome ? colorSelected : colorUnselected}
                size={26}
              />
            </LiveModelewrapSortableButton>



            <LiveModelewrapSortableButton
              title={translate(TranslateConfig.LIVE_SHOW_MODELS_HIGH_CAM)}
              onClick={() => {
                setShowHigherQuality(!showHigherQuality);
              }}>
              <IconHd
                color={showHigherQuality ? colorSelected : colorUnselected}
                size={26}
              />
            </LiveModelewrapSortableButton>

          </LiveModelewrapSortable>



          <LiveModeleSizewrapSort>

            <LiveModelewrapSortableButton
              title={translate(TranslateConfig.LIVE_GRID_SMALL)}
              onClick={() => {
                setGridArrange('SMALL');
              }}>
              <IconGridSmall
                color={gridArrange === 'SMALL' ? colorSelected : colorUnselected}
                size={24}
              />
            </LiveModelewrapSortableButton>



            <LiveModelewrapSortableButton
              title={translate(TranslateConfig.LIVE_GRID_MEDIUM)}
              onClick={() => {
                setGridArrange('MEDIUM');
              }}>
              <IconGridMedium
                color={gridArrange === 'MEDIUM' ? colorSelected : colorUnselected}
                size={24}
              />
            </LiveModelewrapSortableButton>



            <LiveModelewrapSortableButton
              title={translate(TranslateConfig.LIVE_GRID_LARGE)}
              onClick={() => {
                setGridArrange('LARGE');
              }}>
              <IconGridLarge
                color={gridArrange === 'LARGE' ? colorSelected : colorUnselected}
                size={24}
              />
            </LiveModelewrapSortableButton>

          </LiveModeleSizewrapSort>

        </LiveModeleSortable>

      </LiveCamHeaderOptionsView>

    );
  }



  useEffect(() => {
    getLiveRooms();
  }, []);


  useEffect(() => {
    getLiveRooms();
  }, [genderChoose]);



  return (

    <Content>

      {loading && (
        renderLoading()
      )}



      {!loading && (
        <LiveWrapperItemContent>

          <RentViewContent>

            <LiveHeaderContent>

              <LiveRoomUpdateText>
                {translate(TranslateConfig.LIVE_UPDATE_ROOM)}
              </LiveRoomUpdateText>


              <MessageLiveHeader>
                <ButtonAction
                  iconLeft={
                    <IconInformation
                      color={Colors.white}
                      size={20}
                    />
                  }
                  title={translate(TranslateConfig.UNDERSTAND_ROOMS)}
                  backgroundColor={Colors.cardBackground}
                  onPress={() => {
                    // TODO
                    // history.push(NameRoutes.RentHowAds);
                  }}
                />



                <FilterButtonLiveHeader>
                  <ButtonAction
                    iconLeft={
                      <IconFilter
                        color={Colors.white}
                        size={20}
                      />
                    }
                    title={translate(TranslateConfig.FILTERS)}
                    backgroundColor={Colors.cardBackground}
                    onPress={() => {
                      // 
                    }}
                  />
                </FilterButtonLiveHeader>
              </MessageLiveHeader>

            </LiveHeaderContent>



            {modeles?.length === 0 && (
              listEmptyComponent()
            )}



            {modeles?.length > 0 && (
              <LiveCamContainer>

                {availableUser && (
                  <LiveCamHeaderOptionsView>
                    <LiveCamHeadingTitle
                      title={`${translate(TranslateConfig.FAVORITES)}: ${'3'}`}
                    />
                  </LiveCamHeaderOptionsView>
                )}


                {availableUser && (
                  <CardLiveItemNormal>
                  </CardLiveItemNormal>
                )}


                {availableUser && (
                  <CardLiveItemNormal>
                  </CardLiveItemNormal>
                )}


                {availableUser && (
                  <CardLiveItemNormal>
                  </CardLiveItemNormal>
                )}



                {listHeaderComponent()}


                <CardLiveHighlightHigh>
                  {renderItem(modeles[0], 0)}
                </CardLiveHighlightHigh>


                <CardLiveHighlightHigh>
                  {renderItem(modeles[1], 1)}
                </CardLiveHighlightHigh>


                <CardLiveItemNormal>
                  {renderItem(modeles[2], 2)}
                </CardLiveItemNormal>


                <CardLiveItemNormal>
                  {renderItem(modeles[3], 3)}
                </CardLiveItemNormal>


                <CardLiveItemNormal>
                  {renderItem(modeles[4], 4)}
                </CardLiveItemNormal>


                <CardLiveItemNormal>
                  {renderItem(modeles[5], 5)}
                </CardLiveItemNormal>


                <CardLiveItemNormal>
                  {renderItem(modeles[6], 6)}
                </CardLiveItemNormal>


                <CardLiveItemNormal>
                  {renderItem(modeles[7], 7)}
                </CardLiveItemNormal>


                <CardLiveItemNormal>
                  {renderItem(modeles[8], 8)}
                </CardLiveItemNormal>


                <CardLiveItemNormal>
                  {renderItem(modeles[9], 9)}
                </CardLiveItemNormal>


                <CardLiveItemNormal>
                </CardLiveItemNormal>


                <CardLiveItemNormal>
                </CardLiveItemNormal>


                <CardLiveItemNormal>
                </CardLiveItemNormal>


                <CardLiveItemNormal>
                </CardLiveItemNormal>


                <CardLiveItemNormal>
                </CardLiveItemNormal>


                <CardLiveItemNormal>
                </CardLiveItemNormal>


                <CardLiveItemNormal>
                </CardLiveItemNormal>


                <CardLiveItemNormal>
                </CardLiveItemNormal>


                <CardLiveItemNormal>
                </CardLiveItemNormal>


                <CardLiveItemNormal>
                </CardLiveItemNormal>


                <CardLiveItemNormal>
                </CardLiveItemNormal>


                <CardLiveItemNormal>
                </CardLiveItemNormal>


                <CardLiveItemNormal>
                </CardLiveItemNormal>


                <CardLiveItemNormal>
                </CardLiveItemNormal>

              </LiveCamContainer>
            )}

          </RentViewContent>

        </LiveWrapperItemContent>
      )}



      <FooterApp />

    </Content>

  );
};



export default TabLivePage;
