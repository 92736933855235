import React,
{
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useSelector,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import CardSuggestionBig from '../../../components/Cards/CardSuggestions/CardSuggestionBig';
import CardSuggestionSmall from '../../../components/Cards/CardSuggestions/CardSuggestionSmall';
import Dots from '../../../components/Dots';
import EmptyContent from '../../../components/Empty';

import {
  IconArrowLightBack,
  IconArrowLightForward,
  IconRefresh,
} from '../../../components/Icons';

import NameRoutes from '../../../navigation/names';

import {
  RootState,
} from '../../../redux';

import {
  Colors,
  Images,
} from '../../../shared/constants';

import {
  TranslateConfig,
} from '../../../shared/translations';

import {
  HomeRightContent,
  SuggestionContent,
  SuggestionHeader,
  RightBoostLogoImage,
  SuggestionViewButton,
  SuggestionTitle,
  SuggestionText,
  SuggestionButton,
  SuggestionList,
  OnlineContent,
  OnlineList,
  AdsEmptyView,
} from './styled';



export interface IProps {
  dataSuggestionArray?: Array<any>;
  onProfilesSuggestionPress?: any;
}



const RightSidebarHome: React.FC<IProps> = (props: IProps) => {
  const history = useHistory();
  const { t: translate } = useTranslation();


  const projectParams = useSelector((state: RootState) => state.appConfigs.modules);


  const profilesPerPage = 3;
  const sponsorsPerPage = 6;

  const profilesSuggestion = props.dataSuggestionArray;
  const sponsorsSuggestion = [];

  const [profilesCurrentPage, setProfilesCurrentPage] = useState(0);
  const [sponsorsCurrentPage, setsponsorsCurrentPage] = useState(0);

  const [modeleBoost] = useState(null);



  function renderItemsDisplayed(data: Array<any>, currentPage: number, itemsPerPage: number) {
    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return data.slice(startIndex, endIndex);
  }


  function changePage(currentPage: number, setPage: Function, totalItems: number, itemsPerPage: number, direction: number) {
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const nextPage = currentPage + direction;
    if (nextPage >= 0 && nextPage < totalPages) {
      setPage(nextPage);
    }
  }


  function refreshProfiles() {
    if (props.onProfilesSuggestionPress) {
      props.onProfilesSuggestionPress();
    }
  };


  function refreshsponsors() {
    // TODO substitua por lógica real de busca no backend
  };


  function openDetailsModele(useralias: string) {
    history.push(NameRoutes.ModeleDetailsScreen.replace(
      ':id',
      useralias,
    ));
  }


  function renderBigCard(item: any, index: number) {
    return (

      <CardSuggestionBig
        key={index}
        online={item?.online}
        exclusive={item?.exclusive}
        plan={item?.plan?.planCurrent}
        image={item?.picture}
        backgroundImage={item?.pictureCover}
        username={item?.useralias}
        name={item?.alias}
        verified={item?.verified}
        promoter={item?.promoter}
        onPress={() => {
          openDetailsModele(item?.useralias);
        }}
      />

    );
  }


  function renderSmallCard(itemModele: any) {
    return (

      <CardSuggestionSmall
        key={itemModele?.aliasname}
        online={itemModele?.online}
        exclusive={itemModele?.exclusive}
        plan={itemModele?.plan?.planCurrent}
        image={itemModele?.image}
        username={itemModele?.aliasname}
        name={itemModele?.name}
        verified={itemModele?.verified}
        promoter={itemModele?.promoter}
        onPress={() => {
          history.push(NameRoutes.ModeleDetailsScreen.replace(
            ':id',
            'useralias',
          ));
        }}
      />

    );
  }



  return (

    <HomeRightContent>

      {profilesSuggestion?.length <= 0 &&
        sponsorsSuggestion?.length <= 0 && (
          <AdsEmptyView>
            <EmptyContent
              image={Images.noSuggestions}
              title={translate(TranslateConfig.EMPTY_SUGGESTIONS)}
            />
          </AdsEmptyView>
        )
      }



      {projectParams?.showRanking && (
        <SuggestionContent>

          {projectParams?.showRanking && (
            <SuggestionHeader>
              <SuggestionViewButton
                onClick={() => {
                  history.push(NameRoutes.RankingsScreen);
                }}>
                <SuggestionTitle>
                  {translate(TranslateConfig.RANKINGS)}
                </SuggestionTitle>

                <SuggestionText>
                  {translate(TranslateConfig.ACTION_OPEN_MORE)}
                </SuggestionText>
              </SuggestionViewButton>
            </SuggestionHeader>
          )}

        </SuggestionContent>
      )}



      {projectParams?.showAds && (
        <SuggestionContent>

          <SuggestionHeader>
            <RightBoostLogoImage
              alt={'boost'}
              src={Images.boostLogo}
            />
          </SuggestionHeader>


          <CardSuggestionBig
            key={modeleBoost?.aliasname}
            online={modeleBoost?.online}
            exclusive={modeleBoost?.exclusive}
            plan={modeleBoost?.plan?.planCurrent}
            image={modeleBoost?.image}
            backgroundImage={modeleBoost?.backgroundImage}
            username={modeleBoost?.aliasname}
            name={modeleBoost?.name}
            verified={modeleBoost?.verified}
            promoter={modeleBoost?.promoter}
            onPress={() => {
              history.push(NameRoutes.ModeleDetailsScreen.replace(
                ':id',
                'useralias',
              ));
            }}
          />

        </SuggestionContent>
      )}



      {profilesSuggestion?.length > 0 && (
        <SuggestionContent>

          <SuggestionHeader>
            <SuggestionViewButton
              onClick={() => {
                history.push(NameRoutes.SuggestionScreen);
              }}>
              <SuggestionTitle>
                {translate(TranslateConfig.SUGGESTIONS)}
              </SuggestionTitle>

              <SuggestionText>
                {translate(TranslateConfig.ACTION_OPEN_MORE)}
              </SuggestionText>
            </SuggestionViewButton>


            <SuggestionButton
              onClick={() => {
                refreshProfiles();
              }}>
              <IconRefresh
                color={Colors.white}
                size={22}
              />
            </SuggestionButton>


            <SuggestionButton
              onClick={() => {
                changePage(profilesCurrentPage, setProfilesCurrentPage, profilesSuggestion?.length, profilesPerPage, -1);
              }}>
              <IconArrowLightBack
                color={Colors.white}
                size={28}
              />
            </SuggestionButton>


            <SuggestionButton
              onClick={() => {
                changePage(profilesCurrentPage, setProfilesCurrentPage, profilesSuggestion?.length, profilesPerPage, 1);
              }}>
              <IconArrowLightForward
                color={Colors.white}
                size={28}
              />
            </SuggestionButton>
          </SuggestionHeader>



          <SuggestionList>
            {renderItemsDisplayed(profilesSuggestion, profilesCurrentPage, profilesPerPage).map((itemModele: any, index: number) => (
              renderBigCard(itemModele, index)
            ))}
          </SuggestionList>



          <Dots
            color={Colors.primary}
            currentPage={profilesCurrentPage}
            total={Math.ceil(profilesSuggestion?.length / profilesPerPage)}
            onPageChange={(pageIndex: number) => {
              setProfilesCurrentPage(pageIndex);
            }}
          />

        </SuggestionContent>
      )}



      {projectParams?.showAds && sponsorsSuggestion?.length > 0 && (
        <OnlineContent>

          <SuggestionHeader>
            <SuggestionViewButton
              onClick={() => {
                history.push(NameRoutes.SuggestionScreen);
              }}>
              <SuggestionTitle>
                {translate(TranslateConfig.SPONSORS)}
              </SuggestionTitle>

              <SuggestionText>
                {translate(TranslateConfig.ACTION_SUGGESTIONS_SEE_MORE)}
              </SuggestionText>
            </SuggestionViewButton>


            <SuggestionButton
              onClick={() => {
                refreshsponsors();
              }}>
              <IconRefresh
                color={Colors.white}
                size={22}
              />
            </SuggestionButton>


            <SuggestionButton
              onClick={() => {
                changePage(sponsorsCurrentPage, setsponsorsCurrentPage, sponsorsSuggestion?.length, sponsorsPerPage, -1);
              }}>
              <IconArrowLightBack
                color={Colors.white}
                size={28}
              />
            </SuggestionButton>


            <SuggestionButton
              onClick={() => {
                changePage(sponsorsCurrentPage, setsponsorsCurrentPage, sponsorsSuggestion?.length, sponsorsPerPage, 1);
              }}>
              <IconArrowLightForward
                color={Colors.white}
                size={28}
              />
            </SuggestionButton>
          </SuggestionHeader>



          <OnlineList>
            {renderItemsDisplayed(sponsorsSuggestion, sponsorsCurrentPage, sponsorsPerPage).map((itemModele) => (
              renderSmallCard(itemModele)
            ))}
          </OnlineList>



          <Dots
            color={Colors.primary}
            currentPage={sponsorsCurrentPage}
            total={Math.ceil(sponsorsSuggestion?.length / sponsorsPerPage)}
            onPageChange={(pageIndex: number) => {
              setsponsorsCurrentPage(pageIndex);
            }}
          />

        </OnlineContent>
      )}

    </HomeRightContent>

  );
};



export default RightSidebarHome;
