import React from 'react';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import NameRoutes from '../../navigation/names';

import {
  RootState,
} from '../../redux';

import {
  APP_DRAWER_ACTION,
} from '../../redux/reducers/app-modals.store';

import {
  Colors, Images,
} from '../../shared/constants';

import ButtonLocationApp from '../Buttons/ButtonLocationApp';
import AddCredit from '../Financial/AddCredit';

import {
  IconAdd,
  IconBack,
  IconBackSkip,
  IconChat,
  IconClose,
  IconDrawer,
  IconFilter,
  IconHelpCircle,
  IconMapPin,
  IconSearch,
} from '../Icons';

import {
  ButtonControl,
  PlaceButtonFull,
  PlaceButtonContainer,
  PlaceButtonControl,
  ControlCreditCoinImage,
} from './styled';



export interface IProps {
  disabled?: boolean;
  close?: boolean;
  expanded?: boolean;
  onPress?: any;

  color?: string;
  size?: number;
}



export const ButtonAdd: React.FC<IProps> = (props: any) => {
  const className = { ...props } as any;


  return (

    <ButtonControl
      className={className?.className}
      onClick={props.onPress}>

      <IconAdd
        color={props.color}
        size={props.size}
      />

    </ButtonControl>

  );
};



export const ButtonBack: React.FC<IProps> = (props) => {
  const history = useHistory();


  function chooseIcon() {
    if (props.close) {
      return (
        <IconClose
          color={props.color}
        />
      );
    }
    else {
      return (
        <IconBack
          color={props.color}
        />
      );
    }
  }


  function haveOnPress() {
    if (!props.onPress) {
      history.goBack();
    }
    else {
      props.onPress();
    }
  }


  return (

    <ButtonControl
      onClick={() => {
        haveOnPress();
      }}>

      {chooseIcon()}

    </ButtonControl>

  );
};



export const ButtonBackSkip: React.FC<IProps> = (props: IProps) => {
  const history = useHistory();


  function haveSkipPress() {
    if (!props.onPress) {
      history.goBack();
    }
    else {
      props.onPress();
    }
  }


  return (

    <ButtonControl
      onClick={() => {
        haveSkipPress();
      }}>

      <IconBackSkip
        color={props.color}
        size={props.size}
      />

    </ButtonControl>

  );
};



export const ButtonDrawer: React.FC<IProps> = (props: any) => {
  const history = useHistory();
  const dispatch = useDispatch();


  const user = useSelector((state: RootState) => state.user.actualUser);
  const availableUser = user?.id;


  function openMenuPage() {
    if (availableUser) {
      dispatch({ type: APP_DRAWER_ACTION, payload: true });
    }
    else {
      history.push(NameRoutes.AccessScreen);
    }
  }


  return (

    <ButtonControl
      onClick={() => {
        openMenuPage();
      }}>

      <IconDrawer
        color={availableUser
          ? props.color
          : Colors.gray
        }
      />

    </ButtonControl>

  );
};



export const ButtonHelp: React.FC<IProps> = (props: any) => {
  return (

    <ButtonControl
      onClick={props.onPress}>

      <IconHelpCircle
        color={props.color}
        size={props.size}
      />

    </ButtonControl>

  );
};



export const ButtonHelpContact: React.FC<IProps> = (props: any) => {
  return (

    <ButtonControl
      onClick={props.onPress}>

      <IconChat
        color={props.color}
        size={props.size}
      />

    </ButtonControl>

  );
};



export const ButtonFilter: React.FC<IProps> = (props: any) => {
  return (

    <ButtonControl
      onClick={props.onPress}>

      <IconFilter
        color={props.color}
        size={props.size}
      />

    </ButtonControl>

  );
};



export const ButtonPlace: React.FC<IProps> = (props: IProps) => {
  const genderColorChoose = useSelector((state: RootState) => state.choose.genderColorApp);



  return (

    <PlaceButtonFull>

      <PlaceButtonContainer
        color={genderColorChoose}>
        <ButtonLocationApp
          onPress={() => {
            if (props.onPress) {
              props.onPress();
            }
          }}
        />
      </PlaceButtonContainer>



      <PlaceButtonControl
        color={genderColorChoose}
        onClick={() => {
          if (props.onPress) {
            props.onPress();
          }
        }}>

        <IconMapPin
          color={props.color}
          size={props.size}
        />

      </PlaceButtonControl>

    </PlaceButtonFull>

  );
};



export const ButtonMoney: React.FC<IProps> = (props: IProps) => {
  return (

    <PlaceButtonFull>

      <PlaceButtonContainer>
        <AddCredit
          hideAddMoney={props.disabled}
        />
      </PlaceButtonContainer>


      <PlaceButtonControl>
        <ButtonControl
          onClick={props.onPress}>

          <ControlCreditCoinImage
            alt={'Coin'}
            size={props.size}
            src={Images.gencoin}
          />

        </ButtonControl>
      </PlaceButtonControl>

    </PlaceButtonFull>

  );
};



export const ButtonSearch: React.FC<IProps> = (props: any) => {
  return (

    <ButtonControl
      onClick={props.onPress}>

      <IconSearch
        color={props.color}
        size={props.size}
      />

    </ButtonControl>

  );
};
