import React from 'react';

import {
  IInputSelect,
} from '../../../apis/interfaces/App/input-select.interface';

import { countriesOption } from '../../../shared/arrays/App/countries.array';

import {
  Colors,
} from '../../../shared/constants';

import {
  returnCityArray,
  returnCountryArray,
  returnStateArray,
} from '../../../shared/utils/address.utils';

import {
  FormatIsoDateWithPattern,
} from '../../../shared/utils/date.utils';

import {
  stringRawDataText,
} from '../../../shared/utils/string.utils';

import {
  IconCalendar,
  IconDelete,
} from '../../Icons';

import {
  CardTravelContainer,
  TravelTabButton,
  TravelHeaderView,
  TravelTabHeaderView,
  TravelTabButtonText,
  TravelTabCurrentText,
  TravelDeleteButton,
  TravelDateView,
  TravelDateIcon,
  TravelDateText,
  TravelDateSpan,
  TravelLocationsFlag,
  TravelInfoView,
  TravelPlaceText,
  TravelPlaceObsContainer,
  TravelNotationText,
  TravelPlacePlaceBaseText,
} from './styled';



export interface IProps {
  canDelete?: boolean;
  current?: boolean;
  number?: number;
  dateStart?: string;
  dateEnd?: string;
  city?: string;
  state?: string;
  country?: string;
  observation?: string;
  showAdMyPlace?: boolean;
  onDeletePress?: any;
}



const CardTravel: React.FC<IProps> = (props: IProps) => {
  function returnCountry() {
    const getUserCountry = props.country;
    const findCountry = returnCountryArray()?.find((item: IInputSelect) => item.value === getUserCountry);
    const countryUser = findCountry?.label;

    const getStateArray = returnStateArray(getUserCountry);
    const findState = getStateArray?.find((itemLocation: IInputSelect) => itemLocation.value === props.state);
    const stateUser = findState?.label;

    const getCityArray = returnCityArray(getUserCountry, findState?.value);
    const findCity = getCityArray?.find((itemLocation: IInputSelect) => itemLocation.value === props.city);
    const cityUser = findCity?.label;

    const filterLangCountry = countriesOption.find((country) => stringRawDataText(country?.code) === stringRawDataText(getUserCountry));


    return (

      <TravelDateView>

        <TravelLocationsFlag
          src={filterLangCountry?.image}
        />


        <TravelInfoView>
          <TravelPlaceText>
            {cityUser}, {stateUser}
          </TravelPlaceText>


          <TravelPlaceText>
            {countryUser}
          </TravelPlaceText>
        </TravelInfoView>

      </TravelDateView>

    );
  }



  return (

    <CardTravelContainer>
      <TravelTabButton>

        <TravelHeaderView>

          <TravelTabHeaderView>
            <TravelTabButtonText>
              Viagem: {props.number}
            </TravelTabButtonText>


            {props.current && (
              <TravelTabCurrentText>
                Atual
              </TravelTabCurrentText>
            )}
          </TravelTabHeaderView>



          {props.canDelete && (
            <TravelDeleteButton
              onClick={() => {
                if (props.onDeletePress) {
                  props.onDeletePress();
                }
              }}>
              <IconDelete
                size={20}
                color={Colors.danger}
              />
            </TravelDeleteButton>
          )}

        </TravelHeaderView>



        <TravelDateView>

          <IconCalendar
            size={30}
            color={Colors.white}
          />


          <TravelDateIcon>
            <TravelDateText>
              Início:
              {' '}
              <TravelDateSpan>
                {FormatIsoDateWithPattern(props.dateStart, 'dd/MM/yyyy')}
              </TravelDateSpan>
            </TravelDateText>


            <TravelDateText>
              Final:
              {' '}
              <TravelDateSpan>
                {FormatIsoDateWithPattern(props.dateEnd, 'dd/MM/yyyy')}
              </TravelDateSpan>
            </TravelDateText>

          </TravelDateIcon>

        </TravelDateView>



        {returnCountry()}



        <TravelPlaceObsContainer>

          <TravelNotationText>
            Anotação: {props.observation || '-'}
          </TravelNotationText>


          <TravelPlacePlaceBaseText
            active={props.showAdMyPlace}>
            {props.showAdMyPlace
              ? 'Exibir anúncio na minha localização base'
              : 'Anúncio escondido na minha localização base'
            }
          </TravelPlacePlaceBaseText>

        </TravelPlaceObsContainer>

      </TravelTabButton>
    </CardTravelContainer>

  );
};



export default CardTravel;
