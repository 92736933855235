import React from 'react';

import {
  useSelector,
} from 'react-redux';

import {
  IGenderHighlight,
} from '../../../apis/interfaces/Genders/gender-highlight.interface';

import {
  RootState,
} from '../../../redux';

import {
  EUserType,
} from '../../../shared/enums';

import {
  MenuAdsButton,
  MenuAdsItemView,
} from './styled';



export interface IProps {
  type?: EUserType;
  onUserPress?: any;
  onModelePress?: any;
}



const CardUpgrade: React.FC<IProps> = (props: IProps) => {
  const className = { ...props } as any;


  const genderChoose = useSelector((state: RootState) => state.choose.genderChoose);

  const modelesStore = useSelector((state: RootState) => state.modeles.modelesHighlights);
  const findModeleStore = modelesStore?.find((itemState: IGenderHighlight) => itemState?.gender === genderChoose);



  return (

    <MenuAdsButton
      className={className?.className}

      onClick={() => {
        // history.push(NameRoutes.UpgradeUserShowScreen);
        // dispatch({ type: APP_DRAWER_ACTION, payload: false });
      }}>

      {props.type === EUserType.USER && (
        <MenuAdsButton
          onClick={() => {
            if (props.onUserPress) {
              props.onUserPress();
            }
          }}>
          <MenuAdsItemView
            alt={'PremiumAds'}
            src={findModeleStore?.imageUserUpgrade}
          />
        </MenuAdsButton>
      )}



      {props.type === EUserType.MODELE && (
        <MenuAdsButton
          onClick={() => {
            if (props.onModelePress) {
              props.onModelePress();
            }
          }}>
          <MenuAdsItemView
            alt={'PremiumAds'}
            src={findModeleStore?.imageModeleUpgrade}
          />
        </MenuAdsButton>
      )}

    </MenuAdsButton>

  );
};



export default CardUpgrade;
