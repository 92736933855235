import ApiWS from '../index';



export const AccessAppByContaGlobal = async (paylaod: any) => {
  const url = '/app/auth';

  const response = await ApiWS.post(url, paylaod);
  return response.data;
};



export const TryRevalidateSession = async (token: string | null) => {
  if (token) {
    const url = '/app/auth/revalidate';

    const response = await ApiWS.post(url, { token });
    return response.data;
  }
  else {
    return null;
  }
};
