import styled from 'styled-components';
import { Colors } from '../../../../shared/constants';



interface IProps {
  borderBottomWidth?: number;
}



export const LocationItemContainer = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 60px;
  margin-left: 20px;
  margin-right: 10px;
  padding-top: 15px;
  padding-left: 6px;
  padding-right: 8px;
  padding-bottom: 15px;
  border-bottom-color: ${Colors.menuSeparator};
  border-bottom-style: solid;
  border-bottom-width: ${(props: IProps) => props.borderBottomWidth}px;
`;

export const LocationItemText = styled.h3`
  color: #ffffff;
  font-size: 18px;
`;


export const LocationItemIcon = styled.div`
  margin-left: 14px;
`;
