import styled,
{
  css,
} from 'styled-components';

import {
  ContentPadder,
} from '../../../components/Contents/ContentPadder';

import {
  Colors,
} from '../../../shared/constants';

import {
  PlatformDevices,
} from '../../../shared/utils/platforms.utils';



export const BeModelContainer = styled.div`
  display: flex;
  flex-direction: column;
`;



export const BeModelHeroContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  min-height: 320px;
  max-height: 320px;
  background-color: ${Colors.black};

  @media ${PlatformDevices.maxTablet} {
    min-height: 220px;
    max-height: 220px;
  }
`;

export const BeModelHeroImageBackground = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
`;

export const BeModelHeroContent = styled(ContentPadder)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${Colors.cardOverlay};
  z-index: 1;
`;

export const BeModelHeroTitle = styled.p`
  color: ${Colors.white};
  font-size: 20px;
  font-weight: bold;
  line-height: 24px;
  text-align: center;
`;

export const BeModelHeroSubTitle = styled.p`
  margin-top: 10px;
  color: ${Colors.white};
  font-size: 12px;
  font-weight: normal;
  line-height: 16px;
  text-align: center;
`;



export const BeModeleButtonView = styled.div`
  display: none;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 30px;

  @media ${PlatformDevices.maxTablet} {
    display: flex;
  }
`;


export const BeModelOptionsFlexView = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;

  @media ${PlatformDevices.maxTablet} {
    flex-direction: column;
  }
`;


export const BeModelOptionsContStyle = css`
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 12px;

  @media ${PlatformDevices.maxTablet} {
    width: 100%;
  }
`;


export const BeModelOptionsLeftCont = styled.div`
  ${BeModelOptionsContStyle};

  padding-top: 15px;
  padding-right: 50px;

  @media ${PlatformDevices.maxTablet} {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
  }
`;

export const BeModelOptionsRightCont = styled.div`
  ${BeModelOptionsContStyle};

  padding-top: 15px;
  padding-left: 50px;
  border-left-color: ${Colors.toolbarLine};
  border-left-style: solid;
  border-left-width: 3px;

  @media ${PlatformDevices.maxTablet} {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    border-left: none;
  }
`;


export const BeModelOptionsTitle = styled.p`
  margin-top: 10px;
  margin-bottom: 10px;
  color: ${Colors.primary};
  font-size: 18px;
  font-weight: bold;
  line-height: 22px;
  text-align: center;

  @media ${PlatformDevices.maxTablet} {
    font-size: 16px;
    line-height: 20px;
  }
`;


export const BeModelOptionsLine = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 1px;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: ${Colors.toolbarLine};
`;


export const BeModelOptionsGrid = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-left: -12px;
  margin-right: -12px;
  width: calc(100% + 24px);

  @media ${PlatformDevices.maxTablet} {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
`;

export const BeModelOptionsItemGrid = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  width: 33.3%;
  padding: 8px;
`;

export const BeModelOptionsItemIcon = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;

  @media ${PlatformDevices.maxTablet} {
    margin-bottom: 8px;
  }
`;

export const BeModelOptionsItemText = styled.p`
  color: ${Colors.white};
  font-size: 14px;
  line-height: 18px;
  text-align: center;

  @media ${PlatformDevices.maxTablet} {
    font-size: 12px;
    line-height: 16px;
  }
`;



export const BeModelOptionsDescText = styled.p`
  margin-top: 15px;
  margin-bottom: 15px;
  color: ${Colors.white};
  font-size: 13px;
  text-align: center;
`;



export const BeModelButtonView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 20px;
`;

export const BeModelButtonOrText = styled.p`
  margin-top: 20px;
  margin-bottom: 20px;
  color: ${Colors.white};
  font-size: 15px;
  text-align: center;
`;

export const ProfileContactlButtonText = styled.p`
  margin-top: 25px;
  color: ${Colors.grayLight};
  font-size: 13px;
  text-align: center;
  transition: all .15s ease;
  cursor: pointer;

  :hover {
    color: ${Colors.primary};
  }
`;
