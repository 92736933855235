import React,
{
  useState,
} from 'react';

import {
  Helmet,
} from 'react-helmet-async';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import {
  DoUserBeModele,
} from '../../../apis/endpoints/users.endpoints';

import {
  IGenderHighlight,
} from '../../../apis/interfaces/Genders/gender-highlight.interface';

import AlertMessage from '../../../components/Alerts';
import ButtonAction from '../../../components/Buttons/ButtonAction';

import {
  Container,
  Content,
  Screen,
  Title,
} from '../../../components/Composh/web';

import {
  ContentPadder,
} from '../../../components/Contents/ContentPadder';

import {
  ButtonBack,
} from '../../../components/Controls';

import FooterApp from '../../../components/Footers/FooterApp';
import HeaderScreen from '../../../components/Headers/HeaderScreen';

import {
  IconChart,
  IconHelp,
  IconLocked,
  IconShare,
  IconTipGifts,
  IconVerifiedPromoter,
} from '../../../components/Icons';

import {
  openBeModeleWhatsApp,
  openSite,
  openWantMyProfileWhatsApp,
} from '../../../config/linking.config';

import NameRoutes from '../../../navigation/names';

import {
  RootState,
} from '../../../redux';

import {
  APP_MODAL_LOADING_ACTION,
  APP_SNACKBAR_ACTION,
} from '../../../redux/reducers/app-modals.store';

import {
  USER_SET,
} from '../../../redux/reducers/user.store';

import {
  Colors,
  Images,
  Sizes,
} from '../../../shared/constants';

import {
  EEnvsName,
  ETokenAsync,
} from '../../../shared/enums';

import EDrawerMenu from '../../../shared/enums/App/drawer-menu.enum';
import EResponseStatus from '../../../shared/enums/Response/response-status.enum';
import Vars from '../../../shared/environments/variables';

import Seo from '../../../shared/seo';

import {
  TranslateConfig,
} from '../../../shared/translations';

import {
  treatErrorAndReturnColor,
  treatErrorAndReturnMessage,
} from '../../../shared/utils/errors.utils';

import {
  getLocalStorageItem,
} from '../../../shared/utils/local-storage.utils';

import {
  BeModelContainer,
  BeModelHeroContainer,
  BeModelHeroImageBackground,
  BeModelHeroContent,
  BeModelHeroTitle,
  BeModelHeroSubTitle,
  BeModeleButtonView,
  BeModelOptionsFlexView,
  BeModelOptionsLeftCont,
  BeModelOptionsRightCont,
  BeModelOptionsTitle,
  BeModelOptionsLine,
  BeModelOptionsGrid,
  BeModelOptionsItemGrid,
  BeModelOptionsItemIcon,
  BeModelOptionsItemText,
  BeModelOptionsDescText,
  BeModelButtonView,
  BeModelButtonOrText,
  ProfileContactlButtonText,
} from './styled';



const BeModeleScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t: translate } = useTranslation();


  const genderChoose = useSelector((state: RootState) => state.choose.genderChoose);
  const genderColorChoose = useSelector((state: RootState) => state.choose.genderColorApp);

  const modelesStore = useSelector((state: RootState) => state.modeles.modelesHighlights);
  const findModeleStore = modelesStore?.find((itemState: IGenderHighlight) => itemState?.gender === genderChoose);

  const user = useSelector((state: RootState) => state.user.actualUser);
  const availableUser = user?.id;
  const userIdSearch = user?.useralias;
  const userHasIdentityValidate = true; // FIXME: user?.userGlobalAccount?.idCardValidate;


  const token = getLocalStorageItem(ETokenAsync.USER_TOKEN);

  const [openShowHideModal, setOpenShowHideModal] = useState(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION,
      payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  function openRegisterScreen() {
    history.push({
      pathname: NameRoutes.WebViewScreen,
      state: {
        title: translate(TranslateConfig.REGISTER),
        link: Vars().globalAccountRegister,
      },
    });
  }


  function openIdCardGlobalAccount() {
    setOpenShowHideModal(false);

    const link = Vars().globalAccountUser;
    const commonParams = `?token=${token}`;
    openSite(`${link}${commonParams}`);
  }


  async function userBeModelFunction() {
    try {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

      const response = await DoUserBeModele(userIdSearch);

      if (response?.status === EResponseStatus.ERROR) {
        console.error('Error', response);
        showSnackBarProps(Colors.danger, translate(TranslateConfig.ERROR_CHANGE_PROFILE));
        return;
      }

      dispatch({ type: USER_SET, payload: response?.data });
      showSnackBarProps(Colors.accept, translate(TranslateConfig.SUCCESS_CHANGE_PROFILE));

      history.replace(NameRoutes.PanelModeleScreen);
    }
    catch (error: any) {
      console.error(error);

      const getMessageError = treatErrorAndReturnMessage(error);
      const messageError = translate(getMessageError);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  }


  function renderBeModeleButton() {
    return (

      <ButtonAction
        title={translate(TranslateConfig.REGISTER)}
        width={260}
        height={Sizes.buttonHeight}
        backgroundColor={genderColorChoose}
        onPress={() => {
          if (!availableUser) {
            openRegisterScreen();
            return;
          }

          if (!userHasIdentityValidate) {
            setOpenShowHideModal(true);
            return;
          }

          userBeModelFunction();
        }}
      />

    );
  }


  const urlJsonLd = `${Vars(EEnvsName.PROD).secretioApp}${NameRoutes.BeModeleScreen}`;

  const jsonLd = {
    '@context': 'http://schema.org',
    '@type': 'WebSite',
    '@id': urlJsonLd,
    'url': urlJsonLd,
    'name': `${translate(TranslateConfig.BE_MODELE)} | ${translate(TranslateConfig.NAME_APP)}`,
    'image': Images.bannerAppLink,
    'description': Seo.descriptionBeModele,
    'isPartOf': {
      '@type': 'WebSite',
      'url': Vars(EEnvsName.PROD).secretioApp,
      'name': translate(TranslateConfig.NAME_APP),
    },
  };



  return (

    <Screen
      backgroundColor={Colors.appBackground}>


      <Helmet prioritizeSeoTags>
        <title>
          {`${translate(TranslateConfig.BE_MODELE)} | ${translate(TranslateConfig.NAME_APP)}`}
        </title>

        <meta name="robots" content="index,follow" />
        <meta name="googlebot" content="index,follow" />
        <meta name="description" content={Seo.descriptionBeModele} />

        <meta name="keywords" content={Seo.keywords} />

        <meta property="og:site_name" content={translate(TranslateConfig.NAME_APP)} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={Images.logo512} />
        <meta property="og:url" content={urlJsonLd} />
        <meta property="og:title" content={`${translate(TranslateConfig.BE_MODELE)} | ${translate(TranslateConfig.NAME_APP)}`} />
        <meta property="og:description" content={Seo.descriptionBeModele} />

        <link rel="canonical" href={urlJsonLd} />

        <script type="application/ld+json">
          {JSON.stringify(jsonLd)}
        </script>
      </Helmet>



      <Container>

        <HeaderScreen
          screenKey={EDrawerMenu.BE_MODELE}
          disableGender
          backgroundColor={Colors.toolbarBackground}
          subBackgroundColor={Colors.toolbarBackground}
          leftIcon={
            <ButtonBack
              color={Colors.white}
              size={27}
            />
          }
          centerContent={
            <Title>
              {translate(TranslateConfig.BE_MODELE)}
            </Title>
          }
        />



        <Content>

          <BeModelContainer>

            <BeModelHeroContainer>

              <BeModelHeroImageBackground
                src={findModeleStore?.imageBeModele}
              />


              <BeModelHeroContent>
                <BeModelHeroTitle>
                  {translate(TranslateConfig.SUBTITLES_BE_MODELE_1).toUpperCase()}
                </BeModelHeroTitle>

                <BeModelHeroSubTitle>
                  {translate(TranslateConfig.SUBTITLES_BE_MODELE_2).toUpperCase()}
                </BeModelHeroSubTitle>
              </BeModelHeroContent>

            </BeModelHeroContainer>



            <ContentPadder>

              <BeModeleButtonView>
                {renderBeModeleButton()}
              </BeModeleButtonView>



              <BeModelOptionsFlexView>

                <BeModelOptionsLeftCont>

                  <BeModelOptionsTitle>
                    {translate(TranslateConfig.SUBTITLES_BE_MODELE_3).toUpperCase()}
                  </BeModelOptionsTitle>


                  <BeModelOptionsLine />


                  <BeModelOptionsGrid>

                    <BeModelOptionsItemGrid>
                      <BeModelOptionsItemIcon>
                        <IconVerifiedPromoter
                          size={36}
                        />
                      </BeModelOptionsItemIcon>


                      <BeModelOptionsItemText>
                        {translate(TranslateConfig.BE_MODELE_ITEM_1)}
                      </BeModelOptionsItemText>
                    </BeModelOptionsItemGrid>


                    <BeModelOptionsItemGrid>
                      <BeModelOptionsItemIcon>
                        <IconShare
                          color={Colors.primary}
                          size={36}
                        />
                      </BeModelOptionsItemIcon>


                      <BeModelOptionsItemText>
                        {translate(TranslateConfig.BE_MODELE_ITEM_2)}
                      </BeModelOptionsItemText>
                    </BeModelOptionsItemGrid>


                    <BeModelOptionsItemGrid>
                      <BeModelOptionsItemIcon>
                        <IconLocked
                          color={Colors.primary}
                          size={36}
                        />
                      </BeModelOptionsItemIcon>


                      <BeModelOptionsItemText>
                        {translate(TranslateConfig.BE_MODELE_ITEM_3)}
                      </BeModelOptionsItemText>
                    </BeModelOptionsItemGrid>


                    <BeModelOptionsItemGrid>
                      <BeModelOptionsItemIcon>
                        <IconHelp
                          color={Colors.primary}
                          size={36}
                        />
                      </BeModelOptionsItemIcon>


                      <BeModelOptionsItemText>
                        {translate(TranslateConfig.BE_MODELE_ITEM_4)}
                      </BeModelOptionsItemText>
                    </BeModelOptionsItemGrid>


                    <BeModelOptionsItemGrid>
                      <BeModelOptionsItemIcon>
                        <IconChart
                          color={Colors.primary}
                          size={36}
                        />
                      </BeModelOptionsItemIcon>


                      <BeModelOptionsItemText>
                        {translate(TranslateConfig.BE_MODELE_ITEM_5)}
                      </BeModelOptionsItemText>
                    </BeModelOptionsItemGrid>


                    <BeModelOptionsItemGrid>
                      <BeModelOptionsItemIcon>
                        <IconTipGifts
                          color={Colors.primary}
                          size={36}
                        />
                      </BeModelOptionsItemIcon>


                      <BeModelOptionsItemText>
                        {translate(TranslateConfig.BE_MODELE_ITEM_6)}
                      </BeModelOptionsItemText>
                    </BeModelOptionsItemGrid>

                  </BeModelOptionsGrid>

                </BeModelOptionsLeftCont>



                <BeModelOptionsRightCont>


                  <BeModelOptionsTitle>
                    {translate(TranslateConfig.SUBTITLES_BE_MODELE_4).toUpperCase()}
                  </BeModelOptionsTitle>


                  <BeModelOptionsLine />


                  <BeModelOptionsDescText>
                    {translate(TranslateConfig.SUBTITLES_BE_MODELE_5)}
                  </BeModelOptionsDescText>


                  <div className="tbl-responsive">

                  </div>

                </BeModelOptionsRightCont>

              </BeModelOptionsFlexView>



              <BeModelButtonView>

                {renderBeModeleButton()}


                <BeModelButtonOrText>
                  {translate(TranslateConfig.OR).toUpperCase()}
                </BeModelButtonOrText>


                <ButtonAction
                  title={translate(TranslateConfig.CONTACT_US)}
                  width={260}
                  height={Sizes.buttonHeight}
                  backgroundColor={Colors.primaryDark}
                  onPress={() => {
                    openBeModeleWhatsApp();
                  }}
                />


                <ProfileContactlButtonText
                  onClick={() => {
                    openWantMyProfileWhatsApp();
                  }}>
                  {translate(TranslateConfig.CONTACT_US_PROFILE_PUBLISH)}
                </ProfileContactlButtonText>

              </BeModelButtonView>

            </ContentPadder>

          </BeModelContainer>



          <FooterApp />

        </Content>

      </Container>



      <AlertMessage
        visible={Boolean(openShowHideModal)}
        title={translate(TranslateConfig.DONT_CONFIRM_PROFILE_TITLE)}
        description={translate(TranslateConfig.DONT_CONFIRM_PROFILE_TEXT)}
        cancelText={translate(TranslateConfig.NO)}
        cancelPress={() => {
          setOpenShowHideModal(null);
        }}
        okText={translate(TranslateConfig.YES)}
        okPress={() => {
          openIdCardGlobalAccount();
        }}
      />

    </Screen>

  );
};



export default BeModeleScreen;
