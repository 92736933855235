import React,
{
  useEffect,
  useState,
} from 'react';

import {
  Helmet,
} from 'react-helmet-async';

import {
  useTranslation,
} from 'react-i18next';

import {
  useSelector,
  useDispatch,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import {
  GetAllModeles,
} from '../../../apis/endpoints/modeles.endpoints';

import {
  IInputSelect,
} from '../../../apis/interfaces/App/input-select.interface';

import CardAdsSuggestion from '../../../components/Ads';
import CardModeles from '../../../components/Cards/CardModeles';

import {
  Body,
  Content,
  Screen,
  SubTitle,
  Title,
} from '../../../components/Composh/web';

import {
  ButtonDrawer,
} from '../../../components/Controls';

import EmptyContent from '../../../components/Empty';
import FooterApp from '../../../components/Footers/FooterApp';
import HeaderScreen from '../../../components/Headers/HeaderScreen';

import {
  IconBed,
  IconFilter,
  IconFlagHome,
  IconPenis,
} from '../../../components/Icons';

import LoadingScreen from '../../../components/Loadings/LoadingScreen';

import {
  SetEmptyGenderImage,
  SetLightGenreText,
} from '../../../config/genre.config';

import NameRoutes from '../../../navigation/names';

import {
  RootState,
} from '../../../redux';

import {
  APP_MENU_SELECTED_ACTION,
  APP_SNACKBAR_ACTION,
} from '../../../redux/reducers/app-modals.store';

import {
  Colors,
} from '../../../shared/constants';

import {
  EGenderUser,
} from '../../../shared/enums';

import EDrawerMenu from '../../../shared/enums/App/drawer-menu.enum';

import {
  TranslateConfig,
} from '../../../shared/translations';

import {
  returnCityArray,
  returnStateArray,
} from '../../../shared/utils/address.utils';

import {
  treatErrorAndReturnColor,
  treatErrorAndReturnMessage,
} from '../../../shared/utils/errors.utils';

import {
  AllModelesContentPadder,
  RentScreenHeader,
  SectionTitleStyled,
  MessageRentHeader,
  RentWrapperActionsView,
  RentWrapSortableButton,
  HowRentButtonAction,
  HowRentButtonText,
  ModeleItemGrid,
} from './styled';



const AllModeleScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t: translate } = useTranslation();


  const genderChoose = useSelector((state: RootState) => state.choose.genderChoose);


  const colorSelected = Colors.primary;
  const colorUnselected = Colors.unselected;

  const [showCock, setShowCock] = useState<boolean>(false);
  const [showPosition, setShowPosition] = useState<boolean>(false);
  const [showFlagsHome, setShowFlagsHome] = useState<boolean>(true);

  const [modeles, setModeles] = useState<Array<any>>([]);

  const [loading, setLoading] = useState(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION,
      payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  const getAllFullModeles = async () => {
    try {
      setLoading(true);

      const response = await GetAllModeles(genderChoose);

      if (!response?.data || !Array.isArray(response?.data)) {
        setModeles([]);
        return;
      }

      setModeles(response?.data);
    }
    catch (error: any) {
      console.error(error);
      setModeles([]);

      const getMessageError = treatErrorAndReturnMessage(error);
      const messageError = translate(getMessageError);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      setLoading(false);
    }
  };


  function listHeaderComponent() {
    return (

      <RentScreenHeader>

        <SectionTitleStyled
          title={modeles?.length === 1
            ? `1 ${translate(TranslateConfig.MODELE)}`
            : `${modeles.length} ${translate(TranslateConfig.MODELES)}`
          }
        />



        <MessageRentHeader>

          <RentWrapperActionsView>

            {(genderChoose === EGenderUser.MAN || genderChoose === EGenderUser.TRANS_WOMAN) && (
              <RentWrapSortableButton
                title={translate(TranslateConfig.FILTER_COCK)}
                onClick={() => {
                  setShowCock(!showCock);
                }}>
                <IconPenis
                  color={showCock ? colorSelected : colorUnselected}
                  size={26}
                />
              </RentWrapSortableButton>
            )}



            {(genderChoose === EGenderUser.MAN || genderChoose === EGenderUser.TRANS_WOMAN) && (
              <RentWrapSortableButton
                title={translate(TranslateConfig.FILTER_POSITION)}
                onClick={() => {
                  setShowPosition(!showPosition);
                }}>
                <IconBed
                  color={showPosition ? colorSelected : colorUnselected}
                  size={26}
                />
              </RentWrapSortableButton>
            )}



            <RentWrapSortableButton
              title={translate(TranslateConfig.FILTER_LOCATION)}
              onClick={() => {
                setShowFlagsHome(!showFlagsHome);
              }}>
              <IconFlagHome
                color={showFlagsHome ? colorSelected : colorUnselected}
                size={26}
              />
            </RentWrapSortableButton>

          </RentWrapperActionsView>



          {false && (
            // TODO
            <HowRentButtonAction
              onClick={() => {
                // TODO
              }}>
              <IconFilter
                color={Colors.white}
                size={20}
              />

              <HowRentButtonText>
                {translate(TranslateConfig.FILTERS)}
              </HowRentButtonText>
            </HowRentButtonAction>
          )}

        </MessageRentHeader>

      </RentScreenHeader>

    );
  }


  function openDetailsModele(useralias: string) {
    history.push(NameRoutes.ModeleDetailsScreen.replace(
      ':id',
      useralias,
    ));
  }


  function renderItem(item: any, index: number) {
    let hasLocation = false;

    let cityUser = '';
    let stateUser = '';
    let countryUser = '';

    if (item?.appTravels?.length > 0) {
      const travel = item?.appTravels[0];

      hasLocation = travel
        && travel?.country
        && travel?.state
        && travel?.city;

      countryUser = travel?.country;

      const getStateArray = returnStateArray(countryUser);
      const findState = getStateArray?.find((itemTravel: IInputSelect) => itemTravel.value === travel?.state);
      stateUser = findState?.label;

      const getCityArray = returnCityArray(countryUser, findState?.value);
      const findCity = getCityArray?.find((itemTravel: IInputSelect) => itemTravel.value === travel?.city);
      cityUser = findCity?.label;
    }
    else {
      hasLocation = item?.location
        && item?.location?.country
        && item?.location?.state
        && item?.location?.city;

      countryUser = item?.location?.country;

      const getStateArray = returnStateArray(countryUser);
      const findState = getStateArray?.find((itemLocation: IInputSelect) => itemLocation.value === item?.location?.state);
      stateUser = findState?.label;

      const getCityArray = returnCityArray(countryUser, findState?.value);
      const findCity = getCityArray?.find((itemLocation: IInputSelect) => itemLocation.value === item?.location?.city);
      cityUser = findCity?.label;
    }


    return (

      <CardModeles
        key={index}
        disabled={loading}
        comeNow={false}
        isOnTravel={false}
        showFlag={showFlagsHome}
        showCock={showCock}
        cock={item?.characters?.cockSize}
        showPosition={showPosition}
        position={item?.characters?.position}
        image={item?.picture}
        plan={item?.plan?.planCurrent}
        name={item?.alias}
        username={item?.useralias}
        online={item?.online}
        verified={item?.verified}
        brandPromoter={item?.brandPromoter}
        all24h={item?.contacts?.phoneNumber24h}
        labelPlace={translate(TranslateConfig.IN)}
        country={hasLocation ? countryUser : ''}
        place={hasLocation
          ? `${cityUser}, ${stateUser}`
          : translate(TranslateConfig.EMPTY_LOCATION)
        }
        onPress={() => {
          openDetailsModele(item?.useralias);
        }}
      />

    );
  }


  function renderLoading() {
    if (!loading) {
      return null;
    }

    return (

      <LoadingScreen
        type={'SCREEN'}
        color={Colors.white}
      />

    );
  }


  function renderFooter() {
    if (!loading) {
      return null;
    }

    return (

      <LoadingScreen
        type={'FOOTER'}
        color={Colors.white}
      />

    );
  }


  function listEmptyComponent() {
    return (

      <EmptyContent
        image={SetEmptyGenderImage(genderChoose)}
        title={translate(TranslateConfig.EMPTY_HOME)}
      />

    );
  }



  useEffect(() => {
    if (genderChoose === EGenderUser.MAN || genderChoose === EGenderUser.TRANS_WOMAN) {
      setShowCock(false);
      setShowPosition(false);
    }

    getAllFullModeles();
  }, [genderChoose]);


  useEffect(() => {
    dispatch({ type: APP_MENU_SELECTED_ACTION, payload: EDrawerMenu.ALL_MODELES });
  }, []);



  return (

    <Screen
      backgroundColor={Colors.appBackground}>


      <Helmet prioritizeSeoTags>
        <title>
          {`${translate(TranslateConfig.ALL_MODELES)} | ${translate(TranslateConfig.NAME_APP)}`}
        </title>

        <meta name="robots" content="noindex,nofollow" />
        <meta name="googlebot" content="noindex,nofollow" />
      </Helmet>



      <HeaderScreen
        showBalance
        backgroundColor={Colors.toolbarBackground}
        subBackgroundColor={Colors.toolbarBackground}
        leftIcon={
          <ButtonDrawer
            color={Colors.white}
            size={27}
          />
        }
        centerContent={
          <Body>
            <Title
              color={Colors.white}>
              {translate(TranslateConfig.ALL_MODELES)}
            </Title>

            <SubTitle>
              {translate(SetLightGenreText(genderChoose))}
            </SubTitle>
          </Body>
        }
      />



      <Content>

        {loading && (
          renderLoading()
        )}



        {!loading && (
          <AllModelesContentPadder>
            <ModeleItemGrid
              noScroll
              data={modeles}
              renderItem={renderItem}
              listHeaderComponent={listHeaderComponent()}
              listFooterComponent={renderFooter()}
              listEmptyComponent={listEmptyComponent()}
            />



            <CardAdsSuggestion />

          </AllModelesContentPadder>
        )}



        <FooterApp />

      </Content>

    </Screen>

  );
};



export default AllModeleScreen;
