import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useHistory,
} from 'react-router-dom';

import NameRoutes from '../../../navigation/names';

import {
  Colors,
} from '../../../shared/constants';

import Vars from '../../../shared/environments/variables';

import {
  TranslateConfig,
} from '../../../shared/translations';

import {
  IconLogoSecretio,
} from '../../Icons';

import {
  FooterAppContainer,
  FooterListInline,
  FooterListButton,
  FooterListTextButton,
  FooterLogoInserted,
  FooterTextYear,
  FooterLogoImageButton,
} from './styled';



export interface IProps {
  onlyWebView?: boolean;
}



const FooterApp: React.FC<IProps> = (props: IProps) => {
  const history = useHistory();
  const { t: translate } = useTranslation();


  const colorAccent = Colors.primary;



  return (

    <FooterAppContainer>

      <FooterListInline>

        <FooterListButton
          onClick={() => {
            history.push({
              pathname: NameRoutes.WebViewScreen,
              state: {
                title: translate(TranslateConfig.ABOUT),
                link: Vars().secretioAbout,
              },
            });
          }}>
          <FooterListTextButton
            color={colorAccent}>
            {translate(TranslateConfig.ABOUT)}
          </FooterListTextButton>
        </FooterListButton>



        {!props.onlyWebView && (
          <FooterListButton
            onClick={() => {
              history.push(NameRoutes.TermsPolicyScreen);
            }}>
            <FooterListTextButton
              color={colorAccent}>
              {translate(TranslateConfig.TERMS_POLICY)}
            </FooterListTextButton>
          </FooterListButton>
        )}



        <FooterListButton
          onClick={() => {
            history.push({
              pathname: NameRoutes.WebViewScreen,
              state: {
                title: translate(TranslateConfig.CONTACT),
                link: Vars().secretioContact,
              },
            });
          }}>
          <FooterListTextButton
            color={colorAccent}>
            {translate(TranslateConfig.CONTACT)}
          </FooterListTextButton>
        </FooterListButton>



        {/* <a
          href="http://www.parentalcontrolbar.org/"
          target="_blank" rel="noopener noreferrer">
          Controle Parental
        </a> */}

      </FooterListInline>



      <FooterLogoInserted>

        <FooterTextYear>
          {`${new Date().getFullYear()} © ${translate(TranslateConfig.NAME_APP)}`}
        </FooterTextYear>


        <FooterLogoImageButton
          onClick={() => {
            // TODO: Fazer um easter egg
          }}>
          <IconLogoSecretio
            size={30}
          />
        </FooterLogoImageButton>

      </FooterLogoInserted>

    </FooterAppContainer >

  );
};



export default FooterApp;
