import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useSelector,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import {
  openSite,
} from '../../config/linking.config';

import NameRoutes from '../../navigation/names';

import {
  RootState,
} from '../../redux';

import {
  Images,
} from '../../shared/constants';

import Vars from '../../shared/environments/variables';

import {
  TranslateConfig,
} from '../../shared/translations';

import CardUpgrade from '../Cards/CardUpgrade';
import SectionTitle from '../Titles/SectionTitle';

import {
  AdsSuggestionContainer,
  AdsWrapperContentItem,
  AdsSuggestionHeaderContent,
  AdsBannerImage,
  AdsStreamAppsButton,
  AdsAppsImage,
  LastDetailsAdsList,
} from './styled';



export interface IProps {
  color?: any;
}



const CardAdsSuggestion: React.FC<IProps> = (props: IProps) => {
  const history = useHistory();
  const { t: translate } = useTranslation();


  const user = useSelector((state: RootState) => state.user.actualUser);
  const availableUser = user?.id;

  const projectParams = useSelector((state: RootState) => state.appConfigs.modules);



  return (

    <AdsSuggestionContainer>

      {projectParams?.canAccess && projectParams?.showAds && availableUser && (
        <AdsWrapperContentItem>
          <CardUpgrade
            type={user?.type}
            onUserPress={() => {
              history.push(NameRoutes.UpgradeUserShowScreen);
            }}
            onModelePress={() => {
              history.push(NameRoutes.UpgradeModeleShowScreen);
            }}
          />
        </AdsWrapperContentItem>
      )}



      <AdsWrapperContentItem>
        <AdsSuggestionHeaderContent>
          <SectionTitle
            title={translate(TranslateConfig.AD)}
            color={props.color}
          />
        </AdsSuggestionHeaderContent>


        <AdsBannerImage
          alt={'ads'}
          src={Images.adBannerLogo}
        />
      </AdsWrapperContentItem>



      {projectParams?.showStream && (
        <AdsWrapperContentItem>
          <AdsSuggestionHeaderContent>
            <SectionTitle
              title={translate(TranslateConfig.APP_STREAM)}
              color={props.color}
            />
          </AdsSuggestionHeaderContent>


          <AdsStreamAppsButton
            onClick={() => {
              openSite(Vars().secretioStreamApp);
            }}>

            <AdsAppsImage
              alt={'ads'}
              src={Images.adBannerStream}
            />
          </AdsStreamAppsButton>
        </AdsWrapperContentItem>
      )}



      {projectParams?.showShop && (
        <LastDetailsAdsList>
          <AdsSuggestionHeaderContent>
            <SectionTitle
              title={translate(TranslateConfig.APP_SHOP)}
              color={props.color}
            />
          </AdsSuggestionHeaderContent>


          <AdsAppsImage
            alt={'ads'}
            src={Images.adBannerShop}
          />
        </LastDetailsAdsList>
      )}

    </AdsSuggestionContainer>

  );
};



export default CardAdsSuggestion;
