import ApiWS from '../index';



export const SubscribePro = async (companyId: string, payload: any) => {
  const url = `/app/modele/plans/pay/${companyId}`;

  const response = await ApiWS.put(url, payload);
  return response.data;
};
