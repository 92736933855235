import ApiWS from '../index';



export const EditCharUser = async (useralias: string, payload: any) => {
  const url = `/app/user/${useralias}`;

  const response = await ApiWS.put(url, payload);
  return response.data;
};



export const GetUserByUseralias = async (clientNumber: string) => {
  const url = `/app/user/${clientNumber}`;

  const response = await ApiWS.get(url);
  return response.data;
};



export const EditUserMainProfile = async (useralias: string, payload: any) => {
  const url = `/app/user/main/${useralias}`;

  const response = await ApiWS.put(url, payload);
  return response.data;
};



export const DoUserBeModele = async (useralias: string) => {
  const url = `/app/user/be-modele/${useralias}`;

  const response = await ApiWS.put(url);
  return response.data;
};
