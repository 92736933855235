import React from 'react';

import {
  useHistory,
} from 'react-router-dom';

import {
  Content,
  Screen,
  Title,
} from '../../../components/Composh/web';

import {
  ContentPadder,
} from '../../../components/Contents/ContentPadder';
import {
  ButtonBack,
} from '../../../components/Controls';

import FooterApp from '../../../components/Footers/FooterApp';
import HeaderScreen from '../../../components/Headers/HeaderScreen';

import NameRoutes from '../../../navigation/names';

import {
  Colors,
} from '../../../shared/constants';



const ModeleLiveOptionsScreen: React.FC = () => {
  const history = useHistory();



  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <HeaderScreen
        disableGender
        backgroundColor={Colors.toolbarBackground}
        subBackgroundColor={Colors.toolbarBackground}
        leftIcon={
          <ButtonBack
            color={Colors.white}
            size={27}
          />
        }
        centerContent={
          <Title>
            Opções de live
          </Title>
        }
      />



      <Content>

        <ContentPadder>

          <p>
            ProfileSeeScreen
          </p>

          <button
            onClick={() => {
              history.push(NameRoutes.ModeleLiveOpenScreen);
            }}>
            Abrir sala
          </button>

        </ContentPadder>



        <FooterApp />

      </Content>

    </Screen>

  );
};



export default ModeleLiveOptionsScreen;
