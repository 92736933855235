import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import NameRoutes from '../../../navigation/names';

import {
  RootState,
} from '../../../redux';

import {
  APP_DRAWER_OFF_ACTION,
} from '../../../redux/reducers/app-modals.store';

import {
  USER_LOGOUT_STATE,
} from '../../../redux/reducers/user.store';

import {
  Colors,
} from '../../../shared/constants';

import {
  EUserType,
} from '../../../shared/enums';

import EDrawerMenu from '../../../shared/enums/App/drawer-menu.enum';

import {
  TranslateConfig,
} from '../../../shared/translations';

import ButtonLanguages from '../../Buttons/ButtonLanguages';

import {
  CpHeaderTopContent,
  CpHeaderMobileContent,
  HeaderMobileMenuText,
  CpHeaderDesktopContent,
  CpLinksLeftContent,
  HeaderHomeButton,
  HeaderActionsButton,
  HeaderActionsText,
  BeModelActionsText,
  HeaderActionsContent,
} from './styled';



export interface IProps {
  onlyLanguage?: boolean;
  screenKey?: EDrawerMenu;
  backgroundColor?: string;
}



const HeaderFixed: React.FC<IProps> = (props: IProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t: translate } = useTranslation();


  const user = useSelector((state: RootState) => state.user.actualUser);
  const availableUser = user?.id;

  const projectParams = useSelector((state: RootState) => state.appConfigs.modules);



  function onHomePress() {
    history.push(NameRoutes.HomeScreen);
  };


  function onClearAndReplaceHome() {
    history.push(NameRoutes.MyPriveScreen);
  };



  return (

    <CpHeaderTopContent
      alignRight={props.onlyLanguage}
      backgroundColor={props.backgroundColor || Colors.toolbarFixedBackground}>

      {!props.onlyLanguage && (
        <CpHeaderMobileContent
          style={{
            paddingLeft: 14,
          }}
          onClick={() => {
            dispatch({ type: APP_DRAWER_OFF_ACTION, payload: true });
          }}>

          <HeaderMobileMenuText>
            {translate(TranslateConfig.ACTION_OPEN_OTHER_MORE)}
          </HeaderMobileMenuText>

        </CpHeaderMobileContent>
      )}



      {!props.onlyLanguage && (
        <CpHeaderDesktopContent
          style={{
            paddingLeft: projectParams?.canAccess ? 16 : 8,
          }}>

          <CpLinksLeftContent>

            <HeaderHomeButton
              onClick={() => {
                if (props.screenKey !== EDrawerMenu.HOME) {
                  onHomePress();
                }
              }}>
              <HeaderActionsText
                active={props.screenKey === EDrawerMenu.HOME}>
                {translate(TranslateConfig.HOME)}
              </HeaderActionsText>
            </HeaderHomeButton>



            {availableUser && (
              <HeaderActionsButton
                onClick={() => {
                  if (props.screenKey !== EDrawerMenu.MY_PRIVE) {
                    onClearAndReplaceHome();
                  }
                }}>
                <HeaderActionsText
                  active={props.screenKey === EDrawerMenu.MY_PRIVE}>
                  {translate(TranslateConfig.MY_PRIVE)}
                </HeaderActionsText>
              </HeaderActionsButton>
            )}



            {projectParams?.showRent && (
              <HeaderActionsButton
                onClick={() => {
                  if (props.screenKey !== EDrawerMenu.RENT) {
                    history.push(NameRoutes.RentHomeScreen);
                  }
                }}>
                <HeaderActionsText
                  active={props.screenKey === EDrawerMenu.RENT}>
                  {translate(TranslateConfig.ESCORTS)}
                </HeaderActionsText>
              </HeaderActionsButton>
            )}



            {projectParams?.showLive && (
              <HeaderActionsButton
                onClick={() => {
                  if (props.screenKey !== EDrawerMenu.LIVE) {
                    history.push(NameRoutes.LiveHomeScreen);
                  }
                }}>
                <HeaderActionsText
                  active={props.screenKey === EDrawerMenu.LIVE}>
                  {translate(TranslateConfig.LIVE_ROOMS_ONDEMAND)}
                </HeaderActionsText>
              </HeaderActionsButton>
            )}



            {projectParams?.canAccess && availableUser && (
              <HeaderActionsButton
                onClick={() => {
                  if (props.screenKey !== EDrawerMenu.SUGGESTIONS) {
                    history.push(NameRoutes.SuggestionScreen);
                  }
                }}>
                <HeaderActionsText
                  active={props.screenKey === EDrawerMenu.SUGGESTIONS}>
                  {translate(TranslateConfig.SUGGESTIONS)}
                </HeaderActionsText>
              </HeaderActionsButton>
            )}



            {projectParams?.showShop && (
              <HeaderActionsButton
                onClick={() => {
                  // history.push(NameRoutes.ShopScreen);
                }}>
                <HeaderActionsText>
                  {translate(TranslateConfig.SHOP_APP)}
                </HeaderActionsText>
              </HeaderActionsButton>
            )}

          </CpLinksLeftContent>



          <HeaderActionsContent>

            {projectParams?.canAccess && !availableUser && (
              <HeaderActionsButton
                onClick={() => {
                  history.push(NameRoutes.AccessScreen);
                }}>
                <HeaderActionsText>
                  {translate(TranslateConfig.ACCESS)}
                </HeaderActionsText>
              </HeaderActionsButton>
            )}



            {projectParams?.canAccess && !availableUser && (
              <HeaderActionsButton
                onClick={() => {
                  history.push(NameRoutes.AccessScreen);
                }}>
                <HeaderActionsText>
                  {translate(TranslateConfig.REGISTER_FREE)}
                </HeaderActionsText>
              </HeaderActionsButton>
            )}



            {user?.type !== EUserType.MODELE && (
              <HeaderActionsButton
                onClick={() => {
                  if (props.screenKey !== EDrawerMenu.BE_MODELE) {
                    history.push(NameRoutes.BeModeleScreen);
                  }
                }}>
                <BeModelActionsText
                  active={props.screenKey === EDrawerMenu.BE_MODELE}>
                  {translate(TranslateConfig.BE_MODELE)}
                </BeModelActionsText>
              </HeaderActionsButton>
            )}



            {(projectParams?.showRent || projectParams?.showLive) && (
              <HeaderActionsButton
                onClick={() => {
                  if (props.screenKey !== EDrawerMenu.SETTINGS) {
                    history.push(NameRoutes.SettingsAppScreen);
                  }
                }}>
                <HeaderActionsText
                  active={props.screenKey === EDrawerMenu.SETTINGS}>
                  {availableUser
                    ? translate(TranslateConfig.SETTINGS)
                    : translate(TranslateConfig.MORE_OPTIONS)
                  }
                </HeaderActionsText>
              </HeaderActionsButton>
            )}



            {projectParams?.canAccess && availableUser && (
              <HeaderActionsButton
                onClick={() => {
                  dispatch({ type: USER_LOGOUT_STATE, payload: true });
                }}>
                <HeaderActionsText>
                  {translate(TranslateConfig.EXIT)}
                </HeaderActionsText>
              </HeaderActionsButton>
            )}

          </HeaderActionsContent>

        </CpHeaderDesktopContent>
      )}



      <ButtonLanguages />

    </CpHeaderTopContent>

  );
};



export default HeaderFixed;
