import React,
{
  useEffect,
  useState,
} from 'react';

import {
  Helmet,
} from 'react-helmet-async';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import {
  Formik,
} from 'formik';

import {
  AccessAppByContaGlobal,
} from '../../apis/endpoints/app-global-auth.endpoints';

import LoginModel from '../../apis/models/Access/login.model';

import ButtonAction from '../../components/Buttons/ButtonAction';

import {
  Screen,
  Title,
} from '../../components/Composh/web';

import LoginContainer from '../../components/Containers/LoginContainer';

import {
  ButtonBack,
} from '../../components/Controls';

import EmptyContent from '../../components/Empty';
import Header from '../../components/Headers/Header';
import InputText from '../../components/Inputs/InputText';
import NameRoutes from '../../navigation/names';

import {
  RootState,
} from '../../redux';

import {
  PROJECT_CONFIGS_MODULES_SET,
} from '../../redux/reducers/app-configs.store';

import {
  APP_SNACKBAR_ACTION,
} from '../../redux/reducers/app-modals.store';

import {
  FAVORITES_SET,
} from '../../redux/reducers/favorites.store';

import {
  LIKES_SET,
} from '../../redux/reducers/likes.store';

import {
  USER_LOGGED,
  USER_SET,
} from '../../redux/reducers/user.store';

import {
  Colors,
  Images,
  Values,
} from '../../shared/constants';

import {
  EEnvsName,
  ETokenAsync,
} from '../../shared/enums';

import EResponseStatus from '../../shared/enums/Response/response-status.enum';

import Vars from '../../shared/environments/variables';

import Seo from '../../shared/seo';

import {
  TranslateConfig,
} from '../../shared/translations';

import {
  treatErrorAndReturnColor,
  treatErrorAndReturnMessage,
} from '../../shared/utils/errors.utils';

import {
  removeLocalStorageItem,
  setLocalStorageItem,
} from '../../shared/utils/local-storage.utils';

import {
  UserHasAuthorization,
} from '../../shared/utils/user.utils';

import LoginValidationSchema from '../../shared/validations/Login';

import {
  AccessLoginContainer,
  LogoContent,
  LogoInfoAppContent,
  LogoName,
  LogoApplication,
  LogoImageView,
  LogoImage,
  InputsView,
  InputMarginView,
  ButtonsMarginView,
  FooterView,
  LoginWarningText,
  FooterTermsText,
} from './styled';



const AccessScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t: translate } = useTranslation();


  const loggedIn = useSelector((state: RootState) => state.user.loggedIn);

  const projectParams = useSelector((state: RootState) => state.appConfigs.modules);


  const [loading, setLoading] = useState(false);
  // const [loaded, setLoaded] = useState(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION, payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  function appSetValues(idUser: string, newToken: string, clientNumber: string, userData: any) {
    try {
      setLocalStorageItem(ETokenAsync.USER_ID, idUser);
      setLocalStorageItem(ETokenAsync.USER_TOKEN, newToken);
      setLocalStorageItem(ETokenAsync.USER_CLIENT_NUMBER, clientNumber);
      setLocalStorageItem(ETokenAsync.USER_DATA, userData);

      dispatch({ type: USER_LOGGED, payload: true });
      dispatch({ type: USER_SET, payload: userData });

      dispatch({ type: LIKES_SET, payload: userData?.appLikes || [] });

      dispatch({ type: FAVORITES_SET, payload: userData?.appFavorites || [] });
    }
    catch (error: any) {
      console.error(error);
    }
  }


  function appProjectParamsValues(dataParams: any) {
    dispatch({ type: PROJECT_CONFIGS_MODULES_SET, payload: dataParams });
  }


  function appClearValues() {
    try {
      dispatch({ type: USER_SET, payload: {} });
      removeLocalStorageItem(USER_SET);

      dispatch({ type: USER_LOGGED, payload: false });
      removeLocalStorageItem(USER_LOGGED);
    }
    catch (error: any) {
      console.error(error);
    }
  }


  async function sendLoginRequest(values: typeof LoginModel) {
    const loginForm: typeof LoginModel = {
      email: values.email,
      password: values.password,
    };

    try {
      setLoading(true);

      const response = await AccessAppByContaGlobal(loginForm);
      const responseData = response.data;

      if (!responseData) {
        appClearValues();

        const getMessageError = TranslateConfig.ERROR_ACCESS;
        const messageError = translate(getMessageError);
        const colorError = treatErrorAndReturnColor(EResponseStatus.ERROR);
        showSnackBarProps(colorError, messageError);
        return;
      }

      const newToken = responseData?.token;
      const userData = responseData?.userGlobalAccount;
      const getUser = responseData?.userApp;

      if (UserHasAuthorization(userData)) {
        const newDataUser = {
          ...getUser,
          userGlobalAccount: userData,
        };

        const setIdUser = getUser?.id;
        const setClientNumber = userData?.clientNumber;
        const setParamsReceived = responseData?.configs?.modules;

        appSetValues(setIdUser, newToken, setClientNumber, newDataUser);
        appProjectParamsValues(setParamsReceived);
      }
      else {
        const getMessageError = TranslateConfig.ACCESS_DENIED;
        const messageError = translate(getMessageError);
        const colorError = treatErrorAndReturnColor(EResponseStatus.ERROR);
        showSnackBarProps(colorError, messageError);
      }
    }
    catch (error: any) {
      console.error(error);
      appClearValues();

      const getMessageError = treatErrorAndReturnMessage(error);
      const messageError = translate(getMessageError);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      setTimeout(() => {
        setLoading(false);
      }, Values.reRenderTime);
    }
  }


  function onOpenHomeScreen() {
    history.replace(NameRoutes.MyPriveScreen);
  }


  function openRegisterScreen() {
    history.push({
      pathname: NameRoutes.WebViewScreen,
      state: {
        title: translate(TranslateConfig.REGISTER),
        link: Vars().globalAccountRegister,
      },
    });
  }


  function listEmptyComponent() {
    return (

      <EmptyContent
        image={Images.noLogin}
        title={translate(TranslateConfig.ACTION_BACK)}
        buttonText={translate(TranslateConfig.ACTION_BACK)}
        onPress={() => {
          if (projectParams?.showRent) {
            history.replace(NameRoutes.RentHomeScreen);
            return;
          }

          if (projectParams?.showLive) {
            history.replace(NameRoutes.LiveHomeScreen);
            return;
          }

          history.replace(NameRoutes.HomeScreen);
        }}
      />

    );
  }


  async function redirectToHome() {
    if (loggedIn) {
      onOpenHomeScreen();
    }
  }



  useEffect(() => {
    redirectToHome();
  }, [loggedIn]);

  useEffect(() => {
    redirectToHome();
  }, []);


  const urlJsonLd = `${Vars(EEnvsName.PROD).secretioApp}${NameRoutes.AccessScreen}`;

  const jsonLd = {
    '@context': 'http://schema.org',
    '@type': 'WebSite',
    '@id': urlJsonLd,
    'url': urlJsonLd,
    'name': `${translate(TranslateConfig.ACCESS)} | ${translate(TranslateConfig.NAME_APP)}`,
    'image': Images.bannerAppLink,
    'description': Seo.descriptionGlobal,
    'isPartOf': {
      '@type': 'WebSite',
      'url': Vars(EEnvsName.PROD).secretioApp,
      'name': translate(TranslateConfig.NAME_APP),
    },
  };



  return (

    <Formik
      validateOnMount
      initialValues={LoginModel}
      validationSchema={LoginValidationSchema}
      onSubmit={(values) => {
        // KeyboardModal.dismiss();
        sendLoginRequest(values);
      }}>
      {({
        handleChange,
        handleBlur,
        handleSubmit,
        values,
        errors,
      }) => (

        <Screen
          backgroundColor={Colors.appBackground}>


          <Helmet prioritizeSeoTags>
            <title>
              {`${translate(TranslateConfig.ACCESS)} | ${translate(TranslateConfig.NAME_APP)}`}
            </title>

            <meta name="robots" content="index,follow" />
            <meta name="googlebot" content="index,follow" />
            <meta name="description" content={Seo.descriptionGlobal} />

            <meta name="keywords" content={Seo.keywords} />

            <meta property="og:site_name" content={translate(TranslateConfig.NAME_APP)} />
            <meta property="og:type" content="website" />
            <meta property="og:image" content={Images.logo512} />
            <meta property="og:url" content={urlJsonLd} />
            <meta property="og:title" content={`${translate(TranslateConfig.ACCESS)} | ${translate(TranslateConfig.NAME_APP)}`} />
            <meta property="og:description" content={Seo.descriptionGlobal} />

            <link rel="canonical" href={urlJsonLd} />

            <script type="application/ld+json">
              {JSON.stringify(jsonLd)}
            </script>
          </Helmet>



          <LoginContainer
            backgroundImage={Images.backgroundLogin}>

            <Header
              transparent
              leftIcon={
                <ButtonBack
                  color={!loading ? Colors.white : Colors.disabled}
                  onPress={() => {
                    if (!loading) {
                      history.goBack();
                    }
                  }}
                />
              }
              centerContent={
                <Title>
                  {translate(TranslateConfig.ACCESS)}
                </Title>
              }
            />


            {projectParams?.canAccess && (
              <AccessLoginContainer>

                <LogoContent>

                  <LogoInfoAppContent>
                    <LogoName>
                      {translate(TranslateConfig.NAME_APP)}
                    </LogoName>


                    <LogoApplication>
                      {translate(TranslateConfig.ACTION_ACCESS_TO_CONTINUE)}
                    </LogoApplication>
                  </LogoInfoAppContent>



                  <LogoImageView>
                    <LogoImage
                      src={Images.logoIconApp}
                    />
                  </LogoImageView>

                </LogoContent>



                <InputsView>

                  <InputMarginView>

                    <InputText
                      disabled={loading}
                      autoCorrect={false}
                      type={'EMAIL'}
                      labelText={translate(TranslateConfig.EMAIL)}
                      placeholderText={translate(TranslateConfig.EMAIL)}
                      errorText={translate(errors.email || '')}
                      countLimit={Values.emailMaxCount}
                      value={values.email}
                      onChange={handleChange('email')}
                      onBlur={handleBlur('email')}
                    />


                    <InputText
                      password
                      disabled={loading}
                      type={'PASSWORD'}
                      labelText={translate(TranslateConfig.PASSWORD)}
                      placeholderText={translate(TranslateConfig.PASSWORD)}
                      errorText={translate(errors.password || '')}
                      countLimit={Values.passwordMaxCount}
                      value={values.password}
                      onChange={handleChange('password')}
                      onBlur={handleBlur('password')}
                    />

                  </InputMarginView>



                  <ButtonsMarginView>

                    <ButtonAction
                      bold
                      disabled={loading}
                      title={translate(TranslateConfig.ACTION_ACCESS)}
                      backgroundColor={Colors.primary}
                      color={Colors.black}
                      // iconLeft={
                      // <IconServer
                      //   color={'white'}
                      //   size={20}
                      // />
                      // }
                      onPress={() => {
                        handleSubmit();
                      }}
                    />


                    <ButtonAction
                      bold
                      disabled={loading}
                      title={translate(TranslateConfig.ACTION_REGISTER)}
                      backgroundColor={Colors.transparent}
                      color={Colors.primary}
                      borderColor={Colors.primaryDark}
                      // iconLeft={
                      // <IconServer
                      //   color={'white'}
                      //   size={20}
                      // />
                      // }
                      onPress={() => {
                        openRegisterScreen();
                      }}
                    />
                  </ButtonsMarginView>

                </InputsView>



                <FooterView>

                  {/*
                  <LoginWarningText>
                    O acesso é feito utilizando um login anônimo e os dados dos usuários depois de usados são apagados.
                  </LoginWarningText>
                  */}


                  <LoginWarningText>
                    {translate(TranslateConfig.TUTORIAL_AGREE)}
                    {' '}
                    <FooterTermsText
                      onClick={() => {
                        history.push({
                          pathname: NameRoutes.WebViewScreen,
                          state: {
                            title: translate(TranslateConfig.ACCESS_USER_TERMS_USE),
                            link: Vars().termsUseLink,
                          },
                        });
                      }}>
                      {translate(TranslateConfig.ACCESS_USER_TERMS_USE)}
                    </FooterTermsText>,
                    {' '}
                    <FooterTermsText
                      onClick={() => {
                        history.push({
                          pathname: NameRoutes.WebViewScreen,
                          state: {
                            title: translate(TranslateConfig.ACCESS_USER_TERMS_PRIVACY),
                            link: Vars().privacityPolicyLink,
                          },
                        });
                      }}>
                      {translate(TranslateConfig.ACCESS_USER_TERMS_PRIVACY)}
                    </FooterTermsText>
                    {' '}
                    {translate(TranslateConfig.AND)}
                    {' '}
                    <FooterTermsText
                      onClick={() => {
                        history.push({
                          pathname: NameRoutes.WebViewScreen,
                          state: {
                            title: translate(TranslateConfig.ACCESS_USER_TERMS_COOKIES),
                            link: Vars().termCookiesLink,
                          },
                        });
                      }}>
                      {translate(TranslateConfig.ACCESS_USER_TERMS_COOKIES)}
                    </FooterTermsText>
                    .
                  </LoginWarningText>

                </FooterView>

              </AccessLoginContainer>
            )}



            {!projectParams?.canAccess && (
              <AccessLoginContainer>

                {listEmptyComponent()}

              </AccessLoginContainer>
            )}

          </LoginContainer>

        </Screen>

      )}
    </Formik>

  );
};



export default AccessScreen;
