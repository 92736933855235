import React,
{
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  Helmet,
} from 'react-helmet-async';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  Formik,
  FormikProps,
} from 'formik';

import {
  GetLocationModele,
  UpdateLocationModele,
} from '../../../apis/endpoints/modeles-location.endpoints';

import {
  IInputSelect,
} from '../../../apis/interfaces/App/input-select.interface';

import ButtonAction from '../../../components/Buttons/ButtonAction';

import {
  Container,
  Content,
  Title,
  Screen,
} from '../../../components/Composh/web';

import {
  ContentPadder,
} from '../../../components/Contents/ContentPadder';

import {
  ButtonBack,
} from '../../../components/Controls';

import FooterApp from '../../../components/Footers/FooterApp';
import HeaderScreen from '../../../components/Headers/HeaderScreen';
import LoadingScreen from '../../../components/Loadings/LoadingScreen';
import SectionTitle from '../../../components/Titles/SectionTitle';

import DataSelectModal from '../../../modals/DataSelect';

import {
  RootState,
} from '../../../redux';

import {
  APP_MODAL_LOADING_ACTION,
  APP_SNACKBAR_ACTION,
} from '../../../redux/reducers/app-modals.store';

import {
  USER_SET,
} from '../../../redux/reducers/user.store';

import {
  Colors,
  Sizes,
  Values,
} from '../../../shared/constants';

import {
  TranslateConfig,
} from '../../../shared/translations';

import {
  returnCityArray,
  returnCountryArray,
  returnStateArray,
} from '../../../shared/utils/address.utils';

import {
  treatErrorAndReturnColor,
  treatErrorAndReturnMessage,
} from '../../../shared/utils/errors.utils';

import {
  TravelButtonsContainer,
  TravelColumnPlaceView,
  InputSelectStyled,
} from './styled';



export interface IRouteParams {
  useralias: string;
}



const ModeleLocationFormSection: React.FC = () => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();


  const user = useSelector((state: RootState) => state.user.actualUser);
  const userIdSearch = user?.useralias;


  const formik = useRef<FormikProps<any>>(null);

  const [selectedCountry, setSelectedCountry] = useState<IInputSelect | null>(null);
  const [modalCountry, setModalCountry] = useState<boolean>(false);

  const [selectedState, setSelectedState] = useState<IInputSelect | null>(null);
  const [modalState, setModalState] = useState<boolean>(false);

  const [selectedCity, setSelectedCity] = useState<IInputSelect | null>(null);
  const [modalCity, setModalCity] = useState<boolean>(false);

  const [mounted, setMounted] = useState<boolean>(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION, payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  async function getActualModele() {
    try {
      const response = await GetLocationModele(userIdSearch);

      const responseModele = response?.data;

      if (!responseModele) {
        console.error('Error', response);
        showSnackBarProps(Colors.danger, 'Erro ao fazer o cadastro');
        return;
      }

      return response?.data;
    }
    catch (error: any) {
      console.error(error);

      const getMessageError = treatErrorAndReturnMessage(error);
      const messageError = translate(getMessageError);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);

      return null;
    }
    finally {
      setMounted(true);
    }
  }


  async function updateForm() {
    const localityReceived = await getActualModele();

    if (localityReceived && formik.current) {
      const findCountry = returnCountryArray()?.find((item) => item.value === localityReceived?.country);
      formik.current?.setFieldValue('country', findCountry?.value);
      setSelectedCountry(findCountry || null);

      const getStateArray = returnStateArray(findCountry?.value);
      const findState = getStateArray?.find((item) => item.value === localityReceived?.state);
      formik.current?.setFieldValue('state', findState?.value);
      setSelectedState(findState || null);

      const getCityArray = returnCityArray(findCountry?.value, findState?.value);
      const findCity = getCityArray?.find((item) => item.value === localityReceived?.city);
      formik.current?.setFieldValue('city', findCity?.value);
      setSelectedCity(findCity || null);

      formik.current?.validateForm();
    }

    setTimeout(() => {
      setMounted(true);
    }, Values.mountTime);
  }


  function mountPayload() {
    const values = formik?.current?.values;

    const payload: any = {
      country: values.country,
      state: values.state,
      city: values.city,
    };

    return payload;
  }


  async function saveItemGroup() {
    dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

    const payload = mountPayload();

    try {
      const response = await UpdateLocationModele(userIdSearch, payload);

      if (!response?.data) {
        console.error('Error', response);
        showSnackBarProps(Colors.danger, 'Erro ao fazer o cadastro');
        return;
      }

      dispatch({ type: USER_SET, payload: response?.data });
      showSnackBarProps(Colors.accept, 'Cadastro realizado com sucesso');
    }
    catch (error: any) {
      console.error(error);

      const getMessageError = treatErrorAndReturnMessage(error);
      const messageError = translate(getMessageError);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  };


  function renderLoading() {
    return (

      <LoadingScreen
        type={'SCREEN'}
        color={Colors.white}
      />

    );
  }



  useEffect(() => {
    updateForm();
  }, [formik]);



  return (

    <Formik
      enableReinitialize
      validateOnMount={false}
      innerRef={formik}
      initialValues={{}}
      // validationSchema={CharactersProfileValidationSchema}
      onSubmit={() => {
        // KeyboardModal.dismiss();
        saveItemGroup();
      }}>
      {({
        // dirty,
        // isValid,
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        setFieldValue,
      }) => (

        <Screen
          backgroundColor={Colors.appBackground}>


          <Helmet prioritizeSeoTags>
            <title>
              {`${translate('Localização')} | ${translate(TranslateConfig.NAME_APP)}`}
            </title>

            <meta name="robots" content="noindex,nofollow" />
            <meta name="googlebot" content="noindex,nofollow" />
          </Helmet>



          <Container>

            <HeaderScreen
              disableGender
              backgroundColor={Colors.toolbarBackground}
              subBackgroundColor={Colors.toolbarBackground}
              leftIcon={
                <ButtonBack
                  color={Colors.white}
                  size={27}
                />
              }
              centerContent={
                <Title
                  color={Colors.white}>
                  Localização
                </Title>
              }
            />



            <Content>

              {!mounted && (
                renderLoading()
              )}



              {mounted && (
                <ContentPadder>

                  <SectionTitle
                    title={'Localização'}
                    subtitle={'Essa é a localização que o perfil será mostrado por padrão.'}
                  />



                  <TravelColumnPlaceView
                    backgroundColor={Colors.formBackground}
                    borderRadius={Sizes.cardRadius}>

                    <InputSelectStyled
                      labelText={'País'}
                      placeholderText={selectedCountry?.label}
                      onPress={() => {
                        setModalCountry(true);
                      }}
                    />


                    <InputSelectStyled
                      disabled={!selectedCountry}
                      labelText={'Estado / Região'}
                      placeholderText={selectedState?.label}
                      onPress={() => {
                        setModalState(true);
                      }}
                    />


                    <InputSelectStyled
                      disabled={!selectedState}
                      labelText={'Cidade'}
                      placeholderText={selectedCity?.label}
                      onPress={() => {
                        setModalCity(true);
                      }}
                    />


                    <TravelButtonsContainer>
                      <ButtonAction
                        title={'Salvar'}
                        width={'100%'}
                        onPress={() => {
                          handleSubmit();
                        }}
                      />
                    </TravelButtonsContainer>

                  </TravelColumnPlaceView>

                </ContentPadder>
              )}



              <FooterApp />

            </Content>

          </Container>



          <DataSelectModal
            title={'País'}
            visible={modalCountry}
            data={returnCountryArray()}
            selectedValue={selectedCountry?.value}
            setData={(typeReturn: IInputSelect) => {
              setSelectedCountry(typeReturn);
              setFieldValue('country', typeReturn?.value);

              setSelectedState(null);
              setFieldValue('state', '');

              setSelectedCity(null);
              setFieldValue('city', '');
            }}
            onClose={() => {
              setModalCountry(false);
            }}
          />


          <DataSelectModal
            title={'Estado'}
            visible={modalState}
            data={returnStateArray(selectedCountry?.value)}
            selectedValue={selectedState?.value}
            setData={(typeReturn: IInputSelect) => {
              setSelectedState(typeReturn);
              setFieldValue('state', typeReturn?.value);

              setSelectedCity(null);
              setFieldValue('city', '');
            }}
            onClose={() => {
              setModalState(false);
            }}
          />


          <DataSelectModal
            title={'Cidade'}
            visible={modalCity}
            data={returnCityArray(selectedCountry?.value, selectedState?.value)}
            selectedValue={selectedCity?.value}
            setData={(typeReturn: IInputSelect) => {
              setSelectedCity(typeReturn);
              setFieldValue('city', typeReturn?.value);
            }}
            onClose={() => {
              setModalCity(false);
            }}
          />

        </Screen>

      )}
    </Formik>

  );
};



export default ModeleLocationFormSection;
