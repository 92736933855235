import React from 'react';
import ReactDOM from 'react-dom/client';

import {
  HelmetProvider,
} from 'react-helmet-async';

import {
  CssBaseline,
} from '@material-ui/core';

import AppEnvironments from './shared/environments/application';

import './shared/translations';

import {
  getAnalytics,
} from 'firebase/analytics';

import {
  initializeApp,
} from 'firebase/app';

import App from './App';


const app = initializeApp(AppEnvironments().firebaseWebConfig);
getAnalytics(app);



const AppRender = (
  <React.StrictMode>
    <HelmetProvider>
      <CssBaseline />
      <App />
    </HelmetProvider>
  </React.StrictMode>
);



const rootElement = document.getElementById('root');

// if (rootElement && rootElement.hasChildNodes()) {
//   // Use hydrateRoot para aplicações server-side renderizadas
//   ReactDOM.hydrateRoot(rootElement, AppRender);
// }
// else if (rootElement) {
//   // Use createRoot para novas renderizações
//   const root = ReactDOM.createRoot(rootElement);
//   root.render(AppRender);
// }


// Hot Reload development
const root = ReactDOM.createRoot(rootElement);
root.render(AppRender);
