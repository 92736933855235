import styled,
{
  css,
  keyframes,
} from 'styled-components';

import {
  Colors,
} from '../../../shared/constants';

import {
  PlatformDevices,
} from '../../../shared/utils/platforms.utils';



export interface IProps {
  alignRight?: boolean;
  active?: boolean;
  backgroundColor?: string;
}



const Wiggle = keyframes`
  0%, 7% {
    transform: rotateZ(0);
  }
  15% {
    transform: rotateZ(-13deg);
  }
  20% {
    transform: rotateZ(9deg);
  }
  25% {
    transform: rotateZ(-10deg);
  }
  30% {
    transform: rotateZ(7deg);
  }
  35% {
    transform: rotateZ(-2deg);
  }
  40%, 100% {
    transform: rotateZ(0);
  }
`;



export const alignRightItems = css`
  justify-content: flex-end;
`;

export const CpHeaderTopContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  padding-top: 2px;
  padding-right: 16px;
  padding-bottom: 2px;
  background-color: ${(props: IProps) => props.backgroundColor};

  ${(props: IProps) => props.alignRight && alignRightItems};
`;



export const CpHeaderMobileContent = styled.a`
  display: none;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 27px;
  margin-right: 8px;

  @media ${PlatformDevices.maxTabletL} {
    display: flex;
  }
`;

export const HeaderMobileMenuText = styled.p`
  color: ${Colors.white};
  font-size: 12px;
  font-weight: 300;

  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  word-break: break-word;
`;



export const CpHeaderDesktopContent = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 35px;

  @media ${PlatformDevices.maxTabletL} {
    display: none;
  }
`;


export const CpLinksLeftContent = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  flex-wrap: wrap;
`;

export const HeaderHomeButton = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 10px;
`;

export const HeaderActionsButton = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  margin-right: 10px;
`;

export const HeaderActionsText = styled.p`
  color: ${(props: IProps) => props.active ? Colors.tertiaryLight : Colors.white};
  font-size: 12px;
  font-weight: 300;
`;

export const AnimationWiggleOn = css`
  animation: ${Wiggle} 1.5s ease-in infinite;
`;

export const BeModelActionsText = styled.p`
  color: ${(props: IProps) => props.active ? Colors.tertiaryLight : Colors.primary};
  font-size: 12px;
  font-weight: 300;
  ${(props: IProps) => !props.active && AnimationWiggleOn};
`;



export const HeaderActionsContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  flex-wrap: wrap;
  margin-right: 10px;
`;
