import React,
{
  useEffect,
} from 'react';

import {
  Helmet,
} from 'react-helmet-async';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import * as PackageInfo from '../../../../package.json';

import {
  ILanguageCountry,
} from '../../../apis/interfaces';

import {
  Container,
  Content,
  Epigraph,
  Screen,
  Title,
} from '../../../components/Composh/web';

import {
  ContentPadder,
} from '../../../components/Contents/ContentPadder';

import UserAvatar from '../../../components/Contents/UserAvatar';

import {
  ButtonDrawer,
} from '../../../components/Controls';

import FooterApp from '../../../components/Footers/FooterApp';
import HeaderScreen from '../../../components/Headers/HeaderScreen';

import {
  IconCoin,
  IconCreditCard,
  IconLanguages,
  IconLogoSecretio,
  IconPerson,
  IconTermUse,
  IconVerified,
} from '../../../components/Icons';

import ItemsList from '../../../components/Items/ItemsList';

import {
  openSite,
} from '../../../config/linking.config';

import NameRoutes from '../../../navigation/names';

import {
  RootState,
} from '../../../redux';

import {
  APP_MENU_SELECTED_ACTION,
} from '../../../redux/reducers/app-modals.store';

import {
  USER_LANGUAGE_MODAL,
} from '../../../redux/reducers/localities.store';

import {
  languagesOption,
} from '../../../shared/arrays/App/lang-country.array';

import {
  Colors,
  Images,
  Sizes,
} from '../../../shared/constants';

import {
  ETokenAsync,
} from '../../../shared/enums';

import EDrawerMenu from '../../../shared/enums/App/drawer-menu.enum';

import Vars from '../../../shared/environments/variables';

import {
  TranslateConfig,
} from '../../../shared/translations';

import {
  getLocalStorageItem,
} from '../../../shared/utils/local-storage.utils';

import {
  TouchableEditUser,
  SettingsUserView,
  SettingImagesView,
  UserVerifiedContent,
  TextUser,
  IconVerifiedStyled,
  TextName,
  TextEditProfile,
  FooterText,
  DetailsSectionView,
  UserSettingsImage,
} from './styled';



const SettingsAppScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t: translate } = useTranslation();


  const user = useSelector((state: RootState) => state.user.actualUser);
  const availableUser = user?.id;

  const projectParams = useSelector((state: RootState) => state.appConfigs.modules);

  const languageSelected = useSelector((state: RootState) => state.localities.language);
  const languageFiltered = languagesOption?.find((item: ILanguageCountry) => item?.languageIso === languageSelected);


  const token = getLocalStorageItem(ETokenAsync.USER_TOKEN);


  const projectPackage = PackageInfo;
  const infoVersion = projectPackage.version;



  function openWebScreen(title: string, link: string, action?: string | null) {
    history.push({
      pathname: NameRoutes.WebViewScreen,
      state: {
        title,
        link,
        action,
      },
    });
  }


  function openEditProfile() {
    if (availableUser) {
      openWebScreen(
        translate(TranslateConfig.PROFILE_DETAILS),
        Vars().globalAccountProfile,
      );
    }
    else {
      history.push(NameRoutes.AccessScreen);
    }
  }


  function openExternalSite(link: string) {
    const commonParams = `?token=${token}`;
    openSite(`${link}${commonParams}`);
  }


  function renderItemList(
    icon: any,
    title: string,
    subtitle: string | null,
    onPress: any,
    disabled = false,
  ) {
    return (

      <ItemsList
        disabled={disabled}
        onPress={onPress}
        iconLeft={icon}
        title={title}
        titleColor={Colors.white}
        subtitle={subtitle}
        subtitleColor={Colors.textApp}
      />

    );
  }



  useEffect(() => {
    dispatch({ type: APP_MENU_SELECTED_ACTION, payload: EDrawerMenu.SETTINGS });
  }, []);



  return (

    <Screen
      backgroundColor={Colors.appBackground}>


      <Helmet prioritizeSeoTags>
        <title>
          {`${translate(TranslateConfig.SETTINGS)} | ${translate(TranslateConfig.NAME_APP)}`}
        </title>

        <meta name="robots" content="noindex,nofollow" />
        <meta name="googlebot" content="noindex,nofollow" />
      </Helmet>



      <Container>

        <HeaderScreen
          screenKey={EDrawerMenu.SETTINGS}
          disableGender
          showBalance
          backgroundColor={Colors.toolbarBackground}
          subBackgroundColor={Colors.toolbarBackground}
          leftIcon={projectParams?.canAccess
            ? (
              <ButtonDrawer
                color={Colors.white}
                size={27}
              />
            )
            : null
          }
          centerContent={
            <Title
              color={Colors.white}>
              {availableUser
                ? translate(TranslateConfig.SETTINGS)
                : translate(TranslateConfig.MORE_OPTIONS)
              }
            </Title>
          }
        />



        {/* <ConfigContainerStyled>

          <SidebarLeftConfigView>
            {[
              {
                index: 'GENERAL',
                title: 'Geral',
                onPress: () => {
                  setTabConfig('GENERAL');
                },
              },
              {
                index: 'PROFILE',
                title: 'Perfil',
                onPress: () => {
                  setTabConfig('PROFILE');
                },
              },
              {
                index: 'PRIVACITY',
                title: 'Privacidade',
                onPress: () => {
                  setTabConfig('PRIVACITY');
                },
              },
              {
                index: 'LOCATION',
                title: 'Localização',
                onPress: () => {
                  setTabConfig('LOCATION');
                },
              },
              {
                index: 'NOTIFICATION',
                title: 'Notificações',
                onPress: () => {
                  setTabConfig('NOTIFICATION');
                },
              },
              {
                index: 'PAYMENT',
                title: 'Pagamentos',
                icon: (
                  <IconCreditCard
                    color={Colors.white}
                    size={22}
                  />
                ),
                onPress: () => {
                  setTabConfig('PAYMENT');
                },
              },
              {
                index: 'EXTRACT',
                title: 'Extrato',
                onPress: () => {
                  setTabConfig('EXTRACT');
                },
              },
              {
                index: 'PAY_HISTORY',
                title: 'Histórico de recargas',
                onPress: () => {
                  setTabConfig('PAY_HISTORY');
                },
              },
              {
                index: 'PAY_SIGNATURE',
                title: 'Histórico de assinaturas',
                onPress: () => {
                  setTabConfig('PAY_SIGNATURE');
                },
              },
              {
                index: 'DISABLE_ACCOUNT',
                title: 'Desativar Conta',
                onPress: () => {
                  setTabConfig('DISABLE_ACCOUNT');
                },
              },
            ].map((itemConfig: any) =>
              renderItem(itemConfig, itemConfig.index),
            )}
          </SidebarLeftConfigView>



          <ConfigTabRightView>



          </ConfigTabRightView>

        </ConfigContainerStyled> */}



        <Content>
          <ContentPadder>

            <DetailsSectionView>
              <TouchableEditUser
                style={{
                  opacity: availableUser ? 0.95 : Sizes.opacityOff,
                }}
                onClick={() => {
                  if (projectParams?.canAccess) {
                    openEditProfile();
                  }
                }}>

                <SettingsUserView>
                  <SettingImagesView
                    style={{
                      opacity: availableUser ? 1 : 0.75,
                    }}>

                    {projectParams?.canAccess && availableUser && (
                      <UserAvatar
                        plan={user?.plan?.planCurrent}
                        image={user?.picture}
                        width={100}
                      />
                    )}


                    {(!projectParams?.canAccess || !availableUser) && (
                      <UserSettingsImage
                        src={projectParams?.canAccess && availableUser
                          ? user.picture === '' || user.picture === null || user.picture === undefined
                            ? Images.avatarUserImage
                            : user.picture
                          : Images.logoIconApp
                        }
                      />
                    )}

                  </SettingImagesView>



                  {projectParams?.canAccess && availableUser && (
                    <UserVerifiedContent>
                      <TextUser>
                        @{user?.useralias}
                      </TextUser>


                      {user.userInfo?.verified && (
                        <IconVerifiedStyled>
                          <IconVerified
                            color={Colors.accent}
                            size={14}
                          />
                        </IconVerifiedStyled>
                      )}
                    </UserVerifiedContent>
                  )}


                  {projectParams?.canAccess && (
                    <TextName>
                      {availableUser
                        ? user?.name
                        : translate(TranslateConfig.HELP_LOGIN_TO_SEE)
                      }
                    </TextName>
                  )}


                  {projectParams?.canAccess && availableUser && (
                    <TextEditProfile>
                      {translate(TranslateConfig.ACTION_TO_SEE_PROFILE)}
                    </TextEditProfile>
                  )}
                </SettingsUserView>

              </TouchableEditUser>
            </DetailsSectionView>



            {projectParams?.canAccess && availableUser && (
              <DetailsSectionView>

                <Epigraph.Section
                  text={translate(TranslateConfig.MY_ACCOUNT)}
                  textColor={Colors.primary}
                  borderBottomColor={Colors.primary}
                />


                {renderItemList(
                  <IconPerson
                    size={Sizes.iconAndroidSize}
                    color={Colors.white}
                  />,
                  translate(TranslateConfig.ACCOUNT_SEE),
                  null,
                  () => {
                    openExternalSite(Vars().globalAccountUser);
                  },
                )}


                {/* {renderItemList(
                  <IconAddress
                    size={Sizes.iconAndroidSize}
                    color={Colors.white}
                  />,
                  translate(TranslateConfig.ADDRESSES),
                  translate(TranslateConfig.ACTION_ADDRESS_SEE_YOURS),
                  () => {
                    openWebScreen(
                      translate(TranslateConfig.ADDRESSES),
                      Vars().globalAccountAddress,
                      translate(TranslateConfig.ACTION_ADDRESS_SEE),
                    );
                  },
                )} */}

              </DetailsSectionView>
            )}



            {projectParams?.canAccess && availableUser && (
              <DetailsSectionView>

                <Epigraph.Section
                  text={translate(TranslateConfig.ACCOUNT_ACTIONS)}
                  textColor={Colors.primary}
                  borderBottomColor={Colors.primary}
                />


                {renderItemList(
                  <IconCoin
                    color={Colors.white}
                  />,
                  'Moeda',
                  'BRL - Real (R$)',
                  null,
                  null,
                )}


                {renderItemList(
                  <IconCreditCard
                    size={Sizes.iconAndroidSize}
                    color={Colors.white}
                  />,
                  translate(TranslateConfig.PAYMENTS_FORMS),
                  null,
                  () => {
                    openWebScreen(
                      translate(TranslateConfig.PAYMENTS_FORMS),
                      Vars().globalAccountCreditCard,
                      translate(TranslateConfig.ACTION_PAY_FORMS_SEE),
                    );
                  },
                )}


                {/* TODO: Fazer os descontos
                {renderItemList(
                  <Icon
                    type={'material-community'}
                    name={'ticket-percent'}
                    size={Platform.select({ android: Sizes.iconAndroidSize, ios: Sizes.iconIosSize })}
                    color="white"
                  />,
                  'Cupons de Descontos',
                  null,
                  () => {
                    navigation.dispatch(StackActions.push('CouponsManagement'));
                  },
                )}
                */}

              </DetailsSectionView>
            )}



            <DetailsSectionView>

              <Epigraph.Section
                text={translate(TranslateConfig.PREFERENCES)}
                textColor={Colors.primary}
                borderBottomColor={Colors.primary}
              />


              {renderItemList(
                <IconLanguages
                  size={Sizes.iconAndroidSize}
                  color={Colors.white}
                />,
                translate(TranslateConfig.LANGUAGE),
                translate(languageFiltered?.label || '') || '-', // FIXME: Integrar com a conta global
                () => {
                  dispatch({ type: USER_LANGUAGE_MODAL, payload: true });
                },
              )}

            </DetailsSectionView>



            <DetailsSectionView>

              <Epigraph.Section
                text={translate(TranslateConfig.OTHER_INFORMATIONS)}
                textColor={Colors.primary}
                borderBottomColor={Colors.primary}
              />


              {renderItemList(
                <IconLogoSecretio
                  size={Sizes.iconAndroidSize}
                />,
                translate(TranslateConfig.ABOUT_APP),
                null,
                () => {
                  // history.push(NameRoutes.AboutScreen);
                },
              )}


              {renderItemList(
                <IconTermUse
                  size={Sizes.iconAndroidSize}
                  color={Colors.white}
                />,
                translate(TranslateConfig.TERMS_POLICY),
                null,
                () => {
                  history.push(NameRoutes.TermsPolicyScreen);
                },
              )}

            </DetailsSectionView>



            <FooterText>
              {translate(TranslateConfig.VERSION)}: {infoVersion}
            </FooterText>

          </ContentPadder>



          <FooterApp />

        </Content>

      </Container>

    </Screen>

  );
};



export default SettingsAppScreen;
