import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useHistory,
} from 'react-router-dom';

import CardAdsSuggestion from '../../../../components/Ads';
import CardModeleRankingYear from '../../../../components/Cards/CardModeleRankingYear';

import Content from '../../../../components/Composh/web/Content';

import FooterApp from '../../../../components/Footers/FooterApp';
import SectionTitle from '../../../../components/Titles/SectionTitle';
import YearSelect from '../../../../components/Titles/YearSelect';
import {
  Images,
  Videos,
} from '../../../../shared/constants';
import {
  ContentCenterFlex,
  ContentWrapperRowFull,
} from '../../../../styles/styled.layout';

import {
  YearlyContainer,
  FotyCountdownImage,
  FotyDividerImage,
  FotyCountdownSection,
  FotyCountdownBackground,
  FotyCountdownContent,
  FotyCountdownVideo,
  FotyCountdownInfoView,
  FotyCountInfoTitle,
  FotyCountInfoText,
  YearModeleFromWinView,
  YearWinContestView,
  YearWinContestBadgeView,
  YearWinContestTextView,
  YearWinContestBadgeImage,
  YearWinContestTitle,
  YearWinContestText,
  YearWinPrizeAmountTitle,
  YearWinPrizeAmountText,
  YearWinContestGridView,
  YearCreditLevelView,
  YearCreditRelativeView,
  YearCreditRelativeTitle,
  YearCreditRelativeText,
  YearCreditRelativeImage,
  YearCreditAutoView,
  YearCreditBadgeView,
  YearCreditWorldView,
  YearCreditPersonView,
  YearMediaTypeContent,
} from './styled';



const TabYearlyCrush: React.FC = () => {
  const history = useHistory();


  const [loading, setLoading] = useState(false);

  const [modeles, setModeles] = useState(null);


  const userWinYear = [
    {
      rank: 'Janeiro',
      name: 'Ian Hunk',
      username: 'username',
      image: Images.avatarModeleImage,
    },
    {
      rank: 'Fevereiro',
      name: 'Kieran Benning',
      username: 'username',
      image: Images.avatarModeleImage,
    },
    {
      rank: 'Março',
      name: 'Frank Dwayne',
      username: 'username',
      image: Images.avatarModeleImage,
    },
    {
      rank: 'Abril',
      name: 'Killian Crew',
      username: 'username',
      image: Images.avatarModeleImage,
    },
    {
      rank: 'Maio',
      name: 'Maveryk Stone',
      username: 'username',
      image: Images.avatarModeleImage,
    },
    {
      rank: 'Junho',
      name: 'Joshua Reid',
      username: 'username',
      image: Images.avatarModeleImage,
    },
    {
      rank: 'Julho',
      name: 'Izzi',
      username: 'username',
      image: Images.avatarModeleImage,
    },
    {
      rank: 'Agosto',
      name: 'Fede Muller',
      username: 'username',
      image: Images.avatarModeleImage,
    },
    {
      rank: 'Setembro',
      name: 'Donny Harrington',
      username: 'username',
      image: Images.avatarModeleImage,
    },
    {
      rank: 'Outubro',
      name: 'Indy Harrison',
      username: 'username',
      image: Images.avatarModeleImage,
    },
    {
      rank: 'Novembro',
      name: '',
      username: 'username',
      image: Images.avatarModeleImage,
    },
    {
      rank: 'Dezembro',
      name: '',
      username: 'username',
      image: Images.avatarModeleImage,
    },
  ];


  // async function loadHome() {
  //   // const url = '/modeles';
  //   const url = 'https://api.npoint.io/4a91d567c122eb0be824';

  //   try {
  //     setLoading(true);

  //     const response = await api.get(url, {
  //       cancelToken: source.token,
  //     });

  //     setModeles(response.data);
  //   }
  //   catch (err) {
  //     console.error(err);
  //   }
  //   finally {
  //     setLoading(false);
  //   }
  // };



  useEffect(() => {
    // loadHome();
  }, []);



  return (

    <Content>

      <ContentWrapperRowFull>

        <ContentCenterFlex>

          <YearMediaTypeContent>
            <SectionTitle
              title={'Crushs do ano'}
            />

            <YearSelect
            />
          </YearMediaTypeContent>


          <YearlyContainer>

            <FotyCountdownImage
              alt={'crushsYearlyGuys'}
              src={Images.crushsYearlyGuys}
            />

          </YearlyContainer>



          <FotyDividerImage
            src={Images.divisorRankingSilver}
          />


          <FotyCountdownSection>

            <FotyCountdownBackground
              alt={'backgroundRankingYear'}
              src={Images.backgroundRankingYear}
            />

            <FotyCountdownContent>
              <FotyCountdownVideo id={'YearIntroVideo'} controls>
                <source
                  type={'video/mp4'}
                  src={Videos.crushsMontlyMan}
                />
              </FotyCountdownVideo>


              <FotyCountdownInfoView>
                <FotyCountInfoTitle>
                  {('Crushs do Ano 2021 - A batalha pelo melhor ao vivo').toUpperCase()}
                </FotyCountInfoTitle>

                <FotyCountInfoText>
                  Prepare-se para a maior competição em caming! A corrida pela fortuna e glória do Flirt of the Year começa à meia-noite de 1º de outubro de 2021.
                </FotyCountInfoText>

                <FotyCountInfoText>
                  Cada dica, cada promessa, cada show privado e cada crédito que você gasta no seu camguy favorito pode torná-lo um campeão!
                </FotyCountInfoText>

                <FotyCountInfoText>
                  Os modelos qualificados serão classificados com base no total de créditos ganhos em 2021, no total de créditos ganhos de 1º de outubro a 31 de dezembro de 2021, no total de créditos mensais ou no total de votos e supervotos do Viewers Choice, dependendo do concurso.
                </FotyCountInfoText>
              </FotyCountdownInfoView>
            </FotyCountdownContent>

          </FotyCountdownSection>



          <YearModeleFromWinView>

            <YearWinContestView>
              <YearWinContestBadgeView>
                <YearWinContestBadgeImage
                  alt=""
                  src={Images.emblemCrushYearImage}
                />
              </YearWinContestBadgeView>

              <YearWinContestTextView>
                <YearWinContestTitle>
                  Overall:
                </YearWinContestTitle>

                <YearWinContestText>
                  The race for Mister Flirt of the Year 2021 tests a model_s wit, sensuality, dedication, and endurance all year long.
                </YearWinContestText>
              </YearWinContestTextView>

              <YearWinContestBadgeView>
                <YearWinPrizeAmountTitle>
                  Total Prize Money:
                </YearWinPrizeAmountTitle>

                <YearWinPrizeAmountText>
                  $11,650
                </YearWinPrizeAmountText>
              </YearWinContestBadgeView>
            </YearWinContestView>


            <YearWinContestGridView>
              {userWinYear.map((modele, index) => (
                <CardModeleRankingYear
                  key={index}
                  rank={modele.rank}
                  name={modele.name}
                  username={modele.username}
                  image={modele.image}
                />
              ))}
            </YearWinContestGridView>



            <div>
              <p style={{ color: 'white', textAlign: 'center', marginTop: 20 }}>
                Vencedor 2021
              </p>

              <p style={{ color: 'white', textAlign: 'center', marginTop: 20 }}>
                -
              </p>
            </div>

          </YearModeleFromWinView>



          <YearCreditLevelView>
            <YearCreditAutoView>

              <YearCreditRelativeView>
                <YearCreditRelativeTitle>
                  Credit Level
                </YearCreditRelativeTitle>

                <YearCreditRelativeText>
                  A classificação de nível de crédito de um modelo é baseada em seus ganhos vitalícios em nosso site! Durante o FOTY 2021, eles competirão por prêmios em dinheiro e reconhecimento como o top model em sua respectiva faixa, da First Base ao Superstar e até o Webcam Legend!
                </YearCreditRelativeText>
              </YearCreditRelativeView>


              <YearCreditRelativeImage
                src={Images.yearlyGuys1}
              />

            </YearCreditAutoView>
          </YearCreditLevelView>



          <YearCreditBadgeView>
            <YearCreditAutoView>

              <YearCreditRelativeImage
                src={Images.yearlyGuys2}
              />

              <YearCreditRelativeView>
                <YearCreditRelativeTitle>
                  Categories
                </YearCreditRelativeTitle>

                <YearCreditRelativeText>
                  VOD kings, certified kink specialists, top rookies, and explosive duos. Here our models compete in the categories that suit their strengths.
                </YearCreditRelativeText>
              </YearCreditRelativeView>

            </YearCreditAutoView>
          </YearCreditBadgeView>



          <YearCreditWorldView>
            <YearCreditAutoView>

              <YearCreditRelativeView>
                <YearCreditRelativeTitle>
                  Regional
                </YearCreditRelativeTitle>

                <YearCreditRelativeText>
                  The FOTY Regional competition is a 3-month showdown to crown the most dominant performers from North America, South America, Europe, Russia, and Romania! Multiplier Days Apply!
                </YearCreditRelativeText>
              </YearCreditRelativeView>


              <YearCreditRelativeImage
                src={Images.yearlyGuys3}
              />

            </YearCreditAutoView>
          </YearCreditWorldView>



          <YearCreditPersonView>
            <YearCreditAutoView>

              <YearCreditRelativeImage
                src={Images.yearlyGuys4}
              />

              <YearCreditRelativeView>
                <YearCreditRelativeTitle>
                  Monthly
                </YearCreditRelativeTitle>

                <YearCreditRelativeText>
                  On his way to a massive Flirt of the Year win, your #1 contender can win huge cash prizes during all 3 contest months. Multiplier Days Apply!
                </YearCreditRelativeText>
              </YearCreditRelativeView>

            </YearCreditAutoView>
          </YearCreditPersonView>



          {/* TODO: Colocar botão de "Ver Regras e Regulamento" */}

        </ContentCenterFlex>



        <CardAdsSuggestion />

      </ContentWrapperRowFull>



      <FooterApp />

    </Content>

  );
};



export default TabYearlyCrush;
