import React from 'react';

import {
  useSelector,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import NameRoutes from '../../../../navigation/names';

import {
  RootState,
} from '../../../../redux';

import {
  Colors,
  Images,
} from '../../../../shared/constants';

import {
  validateString,
} from '../../../../shared/utils/string.utils';

import UserAvatar from '../../../Contents/UserAvatar';

import {
  IconMessages,
} from '../../../Icons';

import {
  PostCreateContainer,
  PostCreateAvatarView,
  PostCreateInputView,
  PostCreateInputText,
} from './styled';



const CardPostCreate: React.FC = () => {
  const history = useHistory();


  const user = useSelector((state: RootState) => state.user.actualUser);

  const userImage = validateString(user?.picture) || Images.avatarUserImage;



  return (

    <PostCreateContainer
      onClick={() => {
        history.push({
          pathname: NameRoutes.ModeleMediaAddScreen,
          state: {
            type: 'POST',
          },
        });
      }}>

      <PostCreateAvatarView>
        <UserAvatar
          width={50}
          image={userImage}
          plan={user?.plan?.planCurrent}
        />
      </PostCreateAvatarView>



      <PostCreateInputView>

        <IconMessages
          color={Colors.gray}
          size={18}
        />


        <PostCreateInputText>
          Adicionar fotos ou vídeos
        </PostCreateInputText>

      </PostCreateInputView>

    </PostCreateContainer>

  );
};



export default CardPostCreate;
