import styled from 'styled-components';

import {
  ContentPadderHorizontal,
} from '../../../components/Contents/ContentPadder';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';



export const HeroDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${Colors.menuBackground};
`;



export const HeroDetailsButtonsView = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: -30px;
  z-index: 5px;
`;

export const HeroDetailsButton = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 290px;
  height: 55px;
  margin-left: 5px;
  margin-right: 5px;
  padding-top: 9px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 9px;
  background-color: ${Colors.cardBackground};
  border-color: ${Colors.grayDark};
  border-style: solid;
  border-width: 1px;
  border-radius: ${Sizes.cardRadius}px;
  transition: all .15s ease;

  :hover {
    border-color: ${Colors.primary};
    background-color: ${Colors.primary};

    svg {
      color: ${Colors.black} !important;
      fill: ${Colors.black} !important;
    }
  }
`;

export const HeroDetailsTextButton = styled.p`
  margin-left: 10px;
  color: ${Colors.white};
  font-size: 15px;
  font-weight: bold;
  line-height: 21px;
  transition: all .15s ease;

  ${HeroDetailsButton}:hover & {
    color: ${Colors.black};
  }
`;



export const HeroDetailsInfoContainer = styled(ContentPadderHorizontal)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
`;

export const HeroDetailsHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const HeroDetailsTitle = styled.h2`
  color: ${Colors.white};
  font-size: 32px;
  text-align: center;
`;

export const HeroDetailsDescription = styled.p`
  margin-top: 5px;
  color: ${Colors.white};
  font-size: 17px;
  text-align: center;
`;



export const HeroDetailsItemsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 25px;
  margin-bottom: 25px;
`;

export const HeroDetailsItemsView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 280px;
  height: 150px;
  margin-left: 5px;
  margin-right: 5px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: ${Colors.black};
  border-radius: ${Sizes.cardRadius}px;
`;

export const HeroDetailsItemsIcon = styled.img`
  width: 55px;
  height: 55px;
  margin-bottom: 13px;
  object-fit: contain;
`;

export const HeroDetailsItemsText = styled.p`
  color: ${Colors.white};
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
`;



export const HeroDetailsFooterDescription = styled.p`
  color: ${Colors.gray};
  font-size: 16px;
  text-align: center;
`;

export const HeroDetailsFooterKnowMore = styled.a`
  color: ${Colors.primary};
  text-decoration-line: underline;
`;
