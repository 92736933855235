import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useSelector,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import {
  Body,
  Container,
  Overlay,
  Title,
} from '../../components/Composh/web';

import {
  ButtonBack,
  ButtonBackSkip,
} from '../../components/Controls';

import FooterChoose from '../../components/Footers/FooterChoose';
import Header from '../../components/Headers/Header';

import {
  IconClose,
} from '../../components/Icons';

import InputText from '../../components/Inputs/InputText';

import {
  RootState,
} from '../../redux';

import {
  ArrayColors,
} from '../../shared/arrays';

import {
  countriesOption,
} from '../../shared/arrays/App/countries.array';

import {
  Colors,
  Images,
} from '../../shared/constants';

import {
  TranslateConfig,
} from '../../shared/translations';

import {
  stringRawDataText,
  stringUpperCase,
} from '../../shared/utils/string.utils';

import {
  ButtonText,
} from '../../styles/styled.layout';

import LocationCitiesScreen from './Cities';
import LocationCountriesScreen from './Countries';

import {
  CategoryChooseContainer,
  LocationSubtitle,
  LocationCountryButton,
  LocationCountryImage,
  FilteredInput,
  LocationClearButton,
} from './styled';



export interface IProps {
  visible?: boolean;
  action: 'RETURN' | 'DISPATCH';
  currentCity?: string;
  onClose?: any;
  onPress?: any;
}



const LocationsScreen: React.FC<IProps> = (props: IProps) => {
  const visibleModal = props.visible;
  if (!visibleModal) {
    return null;
  }


  const history = useHistory();
  const { t: translate } = useTranslation();


  const genderChoose = useSelector((state: RootState) => state.choose.genderChoose);
  const allStoreLocalities = useSelector((state: RootState) => state.localities.allLocalities);
  const localities = useSelector((state: RootState) => state.localities.selected);

  const appActualCityName = props.currentCity || localities.city;
  const [appCountryUser, setAppCountryUser] = useState(localities.country);

  const filterLangCountry = countriesOption.find((country) => stringRawDataText(country?.code) === stringRawDataText(appCountryUser));

  const [filterLocation, setFilterLocation] = useState<string>('');
  const [showCountries, setShowCountries] = useState<boolean>(false);

  const [data, setData] = useState<Array<any>>([]);

  const [countriesApp, setCountriesApp] = useState<Array<any>>([]);
  const [locationsApp, setLocationsApp] = useState<Array<any>>([]);



  function onCloseModal() {
    if (props.onClose) {
      props.onClose();
    }
  }


  // Função para formatar e filtrar os países e estados
  function returnCountryListUser(response: any) {
    const countryArray: Array<any> = [];

    if (response?.length > 0) {
      response.forEach((itemCountry: any) => {
        countryArray.push({
          title: itemCountry?.countryName,
        });
      });

      setCountriesApp(countryArray);

      const countryShowList = response?.find((country: any) => {
        const itemA = stringRawDataText(stringUpperCase(country?.countryName));
        const itemB = stringRawDataText(stringUpperCase(appCountryUser));
        return itemA === itemB;
      });

      const arrayStates = countryShowList?.states?.map((stateItem: any) => {
        if (stateItem?.stateName && stateItem?.cities) {
          return {
            title: stateItem?.stateName,
            data: stateItem?.cities,
          };
        }
      })?.filter((itemFilter: any) => !!itemFilter);

      if (!arrayStates || !arrayStates[0]) {
        setLocationsApp([]);
        return;
      }

      setLocationsApp(arrayStates);
    }
    else {
      setCountriesApp([]);
      setLocationsApp([]);
    }
  }


  // Função de filtro de cidades
  const filterCitiesByInput = (input: string) => {
    if (!input || !input.trim()) {
      // Se o input estiver vazio, restaure a lista original.
      returnCountryListUser(data);
      return;
    }

    // Normaliza o texto de entrada para comparações.
    const normalizedInput = stringRawDataText(input).toUpperCase();

    // Filtra os estados e as cidades com base no input normalizado.
    const filteredStates = data.reduce((acc: Array<any>, country: any) => {
      const statesWithFilteredCities = country.states
        ?.map((state: any) => {
          const filteredCities = state.cities?.filter((city: any) => {
            // Normaliza o nome da cidade para comparação, considerando o nome original (SAO_PAULO) e o input do usuário.
            const normalizedCityName = stringRawDataText(city.label).toUpperCase();  // Alterado de city para city.label
            return normalizedCityName.includes(normalizedInput);
          });

          // Adiciona o estado se houver cidades correspondentes.
          if (filteredCities?.length) {
            return {
              title: state.stateName, // Nome do estado.
              data: filteredCities,   // Cidades filtradas.
            };
          }
          return null;
        })
        .filter(Boolean); // Remove estados nulos.

      // Adiciona os estados filtrados ao acumulador.
      if (statesWithFilteredCities?.length) {
        acc.push(...statesWithFilteredCities);
      }

      return acc;
    }, []);

    // Atualiza a lista de locais.
    setLocationsApp(filteredStates);
  };


  const handleFilterChange = (rawText: string) => {
    setFilterLocation(rawText);
    filterCitiesByInput(rawText);
  };


  const getApiLocalities = () => {
    setData(allStoreLocalities);
    returnCountryListUser(allStoreLocalities);
  };


  // Função de retorno do botão esquerdo do modal
  function returnButtonLeft() {
    if (showCountries) {
      return (

        <ButtonBackSkip
          color={Colors.white}
          size={20}
          onPress={() => {
            setShowCountries(false);
          }}
        />

      );
    }
    else {
      return (

        <ButtonBack
          close
          color={Colors.white}
          onPress={() => {
            onCloseModal();
          }}
        />

      );
    }
  }


  // Função para título dinâmico
  function returnTitle() {
    if (showCountries) {
      return (

        <Title>
          {translate(TranslateConfig.COUNTRIES)}
        </Title>

      );
    }
    else {
      return (

        <Body>
          <Title>
            {translate(TranslateConfig.CITIES)}
          </Title>

          <LocationSubtitle>
            {translate(countriesOption.find((country) => country?.code === filterLangCountry?.code)?.label) || '-'}
          </LocationSubtitle>
        </Body>

      );
    }
  }


  // Função para quando uma cidade for pressionada
  function onCityPress(value: any) {
    if (props.action === 'RETURN') {
      if (props.onPress) {
        props.onPress(value);
      }
    }
    else {
      onCloseModal();
    }

    const newUrl = `${location.pathname}?gender=${genderChoose}&city=${value?.city}&state=${value?.state}&country=${value?.country}`;
    history.replace(newUrl);
  }



  useEffect(() => {
    if (appCountryUser && data[0]) {
      returnCountryListUser(data);
    }
  }, [appCountryUser]);


  useEffect(() => {
    getApiLocalities();
  }, []);



  return (

    <Overlay
      visible={visibleModal}
      showBackground={true}
      overlayColor={Colors.menuOverlay}
      onOverlayPress={props.onClose}>

      <CategoryChooseContainer>

        <Header
          backgroundColor={Colors.toolbarBackground}
          subBackgroundColor={Colors.toolbarBackground}
          leftIcon={returnButtonLeft()}
          centerContent={returnTitle()}
          rightIcon={!showCountries && (
            <LocationCountryButton
              onClick={() => {
                setShowCountries(true);
              }}>
              <LocationCountryImage
                alt={appCountryUser}
                src={filterLangCountry?.image || Images.world}
              />
            </LocationCountryButton>
          )}>


          {!showCountries && (
            <FilteredInput>
              <InputText
                type={'TEXT'}
                iconRight={filterLocation === '' ? null : (
                  <LocationClearButton
                    onClick={() => {
                      setFilterLocation('');
                      returnCountryListUser(data);
                    }}>
                    <IconClose
                      color={Colors.white}
                      size={28}
                    />
                  </LocationClearButton>
                )}
                placeholderText={'Digite a cidade'}
                value={filterLocation}
                onChange={(rawText: string) => handleFilterChange(rawText)}
              />
            </FilteredInput>
          )}

        </Header>



        <Container>

          {!showCountries && (
            <LocationCitiesScreen
              data={locationsApp}
              action={props.action}
              currentCity={appActualCityName}
              countrySelected={appCountryUser}
              onCityPress={(value: any) => {
                onCityPress(value);
              }}
              onShowCountries={() => {
                setShowCountries(true);
              }}
            />
          )}


          {showCountries && (
            <LocationCountriesScreen
              data={countriesApp}
              countrySelected={appCountryUser}
              onCountryPress={(country: string | number) => {
                if (country) {
                  setAppCountryUser(String(country));
                  setShowCountries(false);
                }
              }}
            />
          )}

        </Container>



        <FooterChoose
          hideCancelButton

          okColor={ArrayColors.arrayCancel}
          okLabel={
            <ButtonText
              color={Colors.white}>
              {'Fechar'}
            </ButtonText>
          }
          okPress={() => {
            onCloseModal();
          }}
        />

      </CategoryChooseContainer>

    </Overlay>

  );
};



export default LocationsScreen;
