import {
  ILanguageCountry,
} from '../../../apis/interfaces';

import {
  Images,
} from '../../constants';

import {
  ELanguage,
} from '../../enums';

import {
  ELanguagesTranslations,
} from '../../translations/translates.keys';



export const languagesOption: Array<ILanguageCountry> = [
  {
    code: 'BRA',
    label: ELanguagesTranslations.PT_BR,
    languageIso: ELanguage.PT_BR,
    flagImage: Images.countryFlagBrazil,
  },
  // {
  //   code: 'USA',
  //   label: ELanguagesTranslations.EN_US,
  //   languageIso: ELanguage.EN_US,
  //   flagImage: Images.countryFlagUnitedStates,
  // },
  // {
  //   code: 'ESP',
  //   label: ELanguagesTranslations.ES_AR,
  //   languageIso: ELanguage.ES_AR,
  //   flagImage: Images.countryFlagSpain,
  // },
  // {
  //   code: 'ITA',
  //   label: ELanguagesTranslations.IT_IT,
  //   languageIso: ELanguage.IT_IT,
  //   flagImage: Images.countryFlagItaly,
  // },
  // {
  //   code: 'FRA',
  //   label: ELanguagesTranslations.FR_FR,
  //   languageIso: ELanguage.FR_FR,
  //   flagImage: Images.countryFlagFrance,
  // },
];
