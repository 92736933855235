import { EUploadType } from '../../shared/enums';
import { EUserFavoritesType } from '../../shared/enums/Users/user-favorites.enum';
import ApiWS from '../index';



export const GetFavoriteModeles = async (idUserSend: string) => {
  const url = '/app/favorites';

  const payloadParams = {
    idUser: idUserSend,
  };

  const response = await ApiWS.get(url, {
    params: payloadParams,
  });
  return response.data;
};



export const PostFavoriteModele = async (
  idUserSend: string,
  idModele: any,
  type: EUserFavoritesType,
) => {
  const url = '/app/favorites';

  const payloadParams = {
    idUser: idUserSend,
  };

  const payload = {
    id: idModele,
    type,
  };

  const response = await ApiWS.post(url, payload, {
    params: payloadParams,
  });
  return response.data;
};
