import styled from 'styled-components';

import {
  Colors,
} from '../../../../shared/constants';



export const VideoContentRightHome = styled.div`
  display: flex;
  flex-direction: column;
`;



export const WarningApproveText = styled.p`
  margin-bottom: 10px;
  color: ${Colors.white};
  font-size: 16px;
`;



export const WrapperItemContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;
`;



export const ApproveSplitView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
  padding-top: 12px;
  padding-right: 12px;
`;

export const ApproveSplitIconView = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 23px;
  height: 23px;
  margin-right: 10px;
  background-color: ${Colors.primary};
  border-radius: 50%;
`;

export const ApproveSplitText = styled.p`
  color: ${Colors.white};
  font-size: 16px;
  font-weight: 500;
`;



export const ApproveItemInfoView = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ApproveItemSplitText = styled.p`
  position: relative;
  margin-bottom: 6px;
  margin-left: 5px;
  padding-left: 15px;
  color: ${Colors.grayLight};
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  
  :before{
    content: '';
    position: absolute;
    top: 8px;
    left: -2px;
    width: 5px;
    height: 5px;
    background-color: ${Colors.primary};
    border-radius: 9999px;
  }
`;


export const ApproveSubItemSplitText = styled.p`
  margin-bottom: 12px;
  padding-left: 20px;
  color: ${Colors.gray};
  font-size: 12px;
`;


export const ApproveSpanSplitText = styled.span`
  font-weight: bold;
  transition: all .15s ease;
  cursor: pointer;

  :hover {
    color: ${Colors.primary};
  }
`;
