import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';

import moment from 'moment';

import {
  TryRevalidateSession,
} from '../../apis/endpoints/app-global-auth.endpoints';

import AlertMessage from '../../components/Alerts';

import AdultContentModal from '../../modals/AdultContent';
import GenderModal from '../../modals/Gender';
import LocationsScreen from '../../modals/Locations';
import ModeleBadReportModal from '../../modals/ModeleBadReport';
import ModeleBrandPromoterModal from '../../modals/ModeleBrandPromoter';
import UserCoinsModal from '../../modals/UserCoins';
import UserSendGiftModal from '../../modals/UserSendGift';

import {
  RootState,
} from '../../redux';

import {
  MODAL_LOCATION_APP_ACTION,
  APP_DRAWER_ACTION,
  USER_GENDER_MODAL,
  USER_SEND_GIFT_MODAL,
  USER_BRAND_PROMOTER_MODAL,
  USER_COIN_MODAL,
  USER_BAD_REPORT_MODAL,
  APP_DRAWER_OFF_ACTION,
} from '../../redux/reducers/app-modals.store';

import {
  USER_LANGUAGE_MODAL,
} from '../../redux/reducers/localities.store';

import {
  USER_LOGGED,
  USER_LOGOUT_STATE,
  USER_SET,
} from '../../redux/reducers/user.store';

import LanguagesModal from '../../screens/Settings/Languages';

import {
  Colors,
} from '../../shared/constants';

import {
  ELocalStorageIds,
  ETokenAsync,
} from '../../shared/enums';

import {
  TranslateConfig,
} from '../../shared/translations';

import {
  getLocalStorageItem,
  setLocalStorageItem,
} from '../../shared/utils/local-storage.utils';

import DrawerUserOff from '../drawer/DrawerUserOff';
import DrawerUserOn from '../drawer/DrawerUserOn';

import NameRoutes from '../names';

import DrawerRoutes from './drawer.routes';
import ScreenRoutes from './screens.routes';

import 'moment/locale/pt-br';



function AppRoutes() {
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const { t: translate } = useTranslation();


  const loggedIn = useSelector((state: RootState) => state.user.loggedIn);
  const logout = useSelector((state: RootState) => state.user.logout);
  const drawerState = useSelector((state: RootState) => state.appModals.drawer);
  const drawerOffState = useSelector((state: RootState) => state.appModals.drawerOff);

  const showModalLanguage = useSelector((state: RootState) => state.localities.languageModal);

  const openLocationModal = useSelector((state: RootState) => state.appModals.modalLocationApp);
  const genderStateModal = useSelector((state: RootState) => state.appModals.genderModal);

  const openCoinsModal = useSelector((state: RootState) => state.appModals.coinsModal);
  const openSendGiftModal = useSelector((state: RootState) => state.appModals.sendGiftModal);
  const openVerifiedModal = useSelector((state: RootState) => state.appModals.brandPromoterModal);
  const openBadReportModal = useSelector((state: RootState) => state.appModals.badReportModal);

  const user = useSelector((state: RootState) => state.user.actualUser);
  const availableUser = user?.id;

  const projectParams = useSelector((state: RootState) => state.appConfigs.modules);


  const [showAgeWarningModal, setShowAgeWarningModal] = useState(false);


  const ScreensApp = [
    ...DrawerRoutes,
    ...ScreenRoutes,
  ];


  function setValues(idUser: string, newToken: string, clientNumber: string): void {
    try {
      setLocalStorageItem(ETokenAsync.USER_ID, idUser);
      setLocalStorageItem(ETokenAsync.USER_TOKEN, newToken);
      setLocalStorageItem(ETokenAsync.USER_CLIENT_NUMBER, clientNumber);
      // setLocalStorageItem(ETokenAsync.USER_DATA, JSON.stringify(data)); // FIXME

      dispatch({ type: USER_LOGGED, payload: true });
    }
    catch (error: any) {
      console.error(error);
    }
  }


  function clearValues(): void {
    try {
      dispatch({ type: USER_LOGGED, payload: false });
      dispatch({ type: USER_LOGOUT_STATE, payload: false });
      dispatch({ type: USER_SET, payload: {} });

      dispatch({ type: APP_DRAWER_ACTION, payload: false });
      dispatch({ type: USER_SEND_GIFT_MODAL, payload: false });
      dispatch({ type: USER_BRAND_PROMOTER_MODAL, payload: false });
      dispatch({ type: MODAL_LOCATION_APP_ACTION, payload: false });
      dispatch({ type: USER_GENDER_MODAL, payload: false });
    }
    catch (error: any) {
      console.error(error);
    }
  }


  function logoffAccount() {
    clearValues();
    // navigate(NameRoutes.GridEventsScreen); // Use navigate hook for routing
    console.log('Logoff');
  }


  // Try to validate user and logged in
  async function tryValidateAndLogin() {
    const userId = getLocalStorageItem(ETokenAsync.USER_ID);
    const token = getLocalStorageItem(ETokenAsync.USER_TOKEN);

    const cssText = `color: ${Colors.danger}`;

    try {
      if (loggedIn && !availableUser) {
        clearValues();
        return;
      }

      if (!loggedIn && userId && token) {
        const data = await TryRevalidateSession(token);
        const newToken = data?.token;
        const idUser = data?.user?.id;
        const clientNumberUser = data?.user?.clientNumber;

        if (newToken) {
          setValues(idUser, newToken, clientNumberUser);
        }
        else {
          clearValues();
          console.log(`%c${'NOT_AUTHORIZED'}`, cssText);
        }
      }
      else if (!loggedIn && (!userId || !token)) {
        clearValues();
        console.log(`%c${'NOT_AUTHORIZED'}`, cssText);
      }
    }
    catch (error: any) {
      console.error(error);

      clearValues();
      console.log(`%c${'NOT_AUTHORIZED'}`, cssText);
    }
  }


  function setModalWarningBool() {
    const hasAggree = getLocalStorageItem(ELocalStorageIds.WARNING_ASK_DONE);

    if (hasAggree === true) {
      return;
    }

    setShowAgeWarningModal(true);
  }


  function onPressModalWarning() {
    setLocalStorageItem(ELocalStorageIds.WARNING_ASK_DONE, true);
    setShowAgeWarningModal(false);
  }



  useEffect(() => {
    moment().locale('pt-br');

    setModalWarningBool();

    tryValidateAndLogin();
  }, [projectParams]);



  return (

    <>

      <Switch>

        {ScreensApp.map(
          ({ path, page: Page, exact, isPublic }, index) => {
            return isPublic
              ? (
                <Route
                  key={index}
                  path={path}
                  exact={exact}>

                  <Page />

                </Route>
              )
              : (
                <Route
                  key={index}
                  path={path}
                  exact={exact}
                  render={({ location }) => {
                    if (loggedIn) {
                      return <Page />;
                    }

                    // Redirect to Login Page
                    return (
                      <Redirect
                        to={{
                          pathname: NameRoutes.AccessScreen,
                          state: { from: location },
                        }}
                      />
                    );
                  }}
                />
              );
          },
        )}


        <Route>
          <Redirect to={NameRoutes.Error404Screen} />
        </Route>

      </Switch>



      <AdultContentModal
        visible={showAgeWarningModal}
        onClose={() => {
          onPressModalWarning();
        }}
      />



      <DrawerUserOff
        open={drawerOffState}
        onOpen={() => {
          dispatch({ type: APP_DRAWER_OFF_ACTION, payload: true });
        }}
        onClose={() => {
          dispatch({ type: APP_DRAWER_OFF_ACTION, payload: false });
        }}
      />



      <DrawerUserOn
        open={drawerState}
        onOpen={() => {
          dispatch({ type: APP_DRAWER_ACTION, payload: true });
        }}
        onClose={() => {
          dispatch({ type: APP_DRAWER_ACTION, payload: false });
        }}
      />



      <LanguagesModal
        visible={showModalLanguage}
        onOverlayPress={() => {
          dispatch({ type: USER_LANGUAGE_MODAL, payload: false });
        }}
      />



      <LocationsScreen
        visible={openLocationModal}
        action={'DISPATCH'}
        onClose={() => {
          dispatch({ type: MODAL_LOCATION_APP_ACTION, payload: false });
        }}
      />



      <GenderModal
        visible={genderStateModal}
        onClose={() => {
          dispatch({ type: USER_GENDER_MODAL, payload: false });
        }}
      />



      <UserCoinsModal
        visible={openCoinsModal?.visible}
        disabled={openCoinsModal?.disabled}
        onClose={() => {
          dispatch({
            type: USER_COIN_MODAL, payload: {
              visible: false,
              disabled: false,
            },
          });
        }}
      />



      <UserSendGiftModal
        visible={openSendGiftModal}
        onClose={() => {
          dispatch({ type: USER_SEND_GIFT_MODAL, payload: false });
        }}
      />



      <ModeleBrandPromoterModal
        visible={openVerifiedModal}
        onClose={() => {
          dispatch({ type: USER_BRAND_PROMOTER_MODAL, payload: false });
        }}
      />



      <ModeleBadReportModal
        visible={openBadReportModal}
        onClose={() => {
          dispatch({ type: USER_BAD_REPORT_MODAL, payload: false });
        }}
      />



      <AlertMessage
        visible={logout}
        title={translate(TranslateConfig.QUESTION_EXIT)}
        cancelText={translate(TranslateConfig.NO)}
        cancelPress={() => {
          dispatch({ type: USER_LOGOUT_STATE, payload: false });
        }}
        okText={translate(TranslateConfig.YES)}
        okPress={() => {
          logoffAccount();
        }}
      />

    </>

  );
}



export default AppRoutes;
