import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import {
  IGenderHighlight,
} from '../../apis/interfaces/Genders/gender-highlight.interface';

import Overlay from '../../components/Composh/web/Overlay';

import {
  IconEyeOn,
} from '../../components/Icons';

import {
  SetGenreColor,
  SetLightGenreText,
} from '../../config/genre.config';

import NameRoutes from '../../navigation/names';

import {
  RootState,
} from '../../redux';

import {
  USER_GENDER_CHOOSE_ACTION,
} from '../../redux/reducers/choose.store';

import {
  Colors,
} from '../../shared/constants';

import {
  setLocalStorageItem,
} from '../../shared/utils/local-storage.utils';

import {
  PlatformSizes,
} from '../../shared/utils/platforms.utils';

import {
  ChooseGenderContainer,
  ChooseGenderItem,
  ChooseGenderLongImage,
  ChooseGenderInfoContent,
  ChooseGenderFlexButton,
  ChooseGenderInfoButton,
  ChooseGenderTitle,
  ChooseGenderName,
  ChooseGenderViewUsername,
  ChooseGenderUsername,
  ChooseGenderUsernameIcon,
} from './styled';



export interface IProps {
  visible?: boolean;
  onClose?: any;
}



const GenderModal: React.FC<IProps> = (props: IProps) => {
  const visibleModal = props.visible;
  if (!visibleModal) {
    return null;
  }


  const dispatch = useDispatch();
  const history = useHistory();
  const { t: translate } = useTranslation();


  const localities = useSelector((state: RootState) => state.localities.selected);
  const modelesStore = useSelector((state: RootState) => state.modeles.modelesHighlights);


  const [windowWidth, setWindowWidth] = useState(window.innerWidth);



  function onPressGender(gender: string) {
    dispatch({ type: USER_GENDER_CHOOSE_ACTION, payload: gender });
    setLocalStorageItem(USER_GENDER_CHOOSE_ACTION, gender);

    const newUrl = `${location.pathname}?gender=${gender}&city=${localities?.city}&state=${localities?.state}&country=${localities?.country}`;
    history.replace(newUrl);
  }


  function renderUserImageItem(itemGender: IGenderHighlight, index: number) {
    const getWidth = Number(PlatformSizes.tabletM) || 0;


    const determineImagePath = (itemImageGender: IGenderHighlight) => {
      if (windowWidth < Number(getWidth)) {
        return itemImageGender?.imageHorizontal;
      }
      else {
        return itemImageGender?.imageVertical;
      }
    };


    const determinePositionImage = () => {
      if (windowWidth < Number(getWidth)) {
        return 'center right';
      }
      else {
        return 'center center';
      }
    };



    useEffect(() => {
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);



    return (

      <ChooseGenderItem
        key={index}>

        <ChooseGenderLongImage
          style={{
            objectPosition: determinePositionImage(),
          }}
          src={determineImagePath(itemGender)}
        />


        <ChooseGenderInfoContent>

          <ChooseGenderFlexButton
            onClick={() => {
              onPressGender(itemGender?.gender);
              if (props.onClose) {
                props.onClose();
              }
            }}
          />


          <ChooseGenderInfoButton
            onClick={() => {
              onPressGender(itemGender?.gender);

              if (itemGender?.alias && itemGender?.useralias) {
                history.push(NameRoutes.ModeleDetailsScreen.replace(
                  ':id',
                  itemGender?.useralias,
                ));
              }

              if (props.onClose) {
                props.onClose();
              }
            }}>

            <ChooseGenderTitle
              color={SetGenreColor(itemGender?.gender)}>
              {translate(SetLightGenreText(itemGender?.gender))}
            </ChooseGenderTitle>


            {itemGender?.alias && itemGender?.useralias && (
              <ChooseGenderName>
                {itemGender?.alias}
              </ChooseGenderName>
            )}


            {itemGender?.alias && itemGender?.useralias && (
              <ChooseGenderViewUsername>

                <ChooseGenderUsername>
                  @{itemGender?.useralias}
                </ChooseGenderUsername>


                <ChooseGenderUsernameIcon>
                  <IconEyeOn
                    color={Colors.white}
                    size={20}
                  />
                </ChooseGenderUsernameIcon>

              </ChooseGenderViewUsername>
            )}

          </ChooseGenderInfoButton>

        </ChooseGenderInfoContent>
      </ChooseGenderItem>

    );
  }



  return (

    <Overlay
      visible={props.visible}
      showBackground={true}
      overlayColor={Colors.alertOverlay}
      onOverlayPress={props.onClose}>

      <ChooseGenderContainer>

        {modelesStore.map((modeleGender: IGenderHighlight, index: number) => (
          renderUserImageItem(modeleGender, index)
        ))}

      </ChooseGenderContainer>

    </Overlay>

  );
};



export default GenderModal;
