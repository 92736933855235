import React from 'react';

import {
  useSelector,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import NameRoutes from '../../../../navigation/names';

import {
  RootState,
} from '../../../../redux';

import {
  Colors,
} from '../../../../shared/constants';

import {
  IconLocked,
} from '../../../Icons';

import {
  PhotoGridOverlay,
  PhotoGridSpecialText,
  PhotoGridSpecialCoinContent,
  PhotoGridSpecialCoinImage,
  PhotoGridSpecialBadgeText,
} from './styled';



const MediaPrivate: React.FC = () => {
  const history = useHistory();


  const genderColorChoose = useSelector((state: RootState) => state.choose.genderColorApp);

  const user = useSelector((state: RootState) => state.user.actualUser);
  const availableUser = user?.id;

  const projectParams = useSelector((state: RootState) => state.appConfigs.modules);



  return (

    <PhotoGridOverlay>

      <IconLocked
        color={Colors.white}
        size={50}
      />


      <PhotoGridSpecialText>
        Privada
      </PhotoGridSpecialText>


      {projectParams?.canAccess && (
        <PhotoGridSpecialCoinContent
          disabled={!availableUser}
          backgroundColor={genderColorChoose}
          onClick={() => {
            if (!availableUser) {
              history.push(NameRoutes.AccessScreen);
              return;
            }

            console.log('I WANT ACCESS');
          }}>

          <PhotoGridSpecialBadgeText>
            Peça acesso
          </PhotoGridSpecialBadgeText>

        </PhotoGridSpecialCoinContent>
      )}

    </PhotoGridOverlay>

  );
};



export default MediaPrivate;
