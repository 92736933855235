import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import {
  PostFavoriteModele,
} from '../../apis/endpoints/users-favorites.endpoints';

import {
  DoMediaLikeUser,
} from '../../apis/endpoints/users-medias-liked.endpoints';

import UserHeaderInfo from '../../components/Details/UserHeaderInfo';

import {
  IconArrowLightBack,
  IconArrowLightForward,
  IconClose,
  IconFavoriteOff,
  IconFavoriteOn,
  IconLike,
  IconLiked,
  IconMediaPhoto,
  IconPause,
  IconPlay,
  IconScreenFull,
  IconZoomMinus,
  IconZoomPlus,
} from '../../components/Icons';

import NameRoutes from '../../navigation/names';

import {
  RootState,
} from '../../redux';

import {
  APP_MODAL_LOADING_ACTION,
  APP_SNACKBAR_ACTION,
} from '../../redux/reducers/app-modals.store';

import {
  FAVORITES_SET,
} from '../../redux/reducers/favorites.store';

import { LIKES_SET } from '../../redux/reducers/likes.store';
import {
  Colors,
  Values,
} from '../../shared/constants';

import {
  EUserFavoritesType,
} from '../../shared/enums/Users/user-favorites.enum';

import {
  TranslateConfig,
} from '../../shared/translations';

import {
  treatErrorAndReturnColor,
  treatErrorAndReturnMessage,
} from '../../shared/utils/errors.utils';

import {
  ViewMediaOverlay,
  ViewMediaContainer,
  ViewProgressBarContent,
  ViewBarProgress,
  ViewToolbarContent,
  ViewToolbarCountText,
  ViewToolbarItemsContent,
  ViewToolbarItemButtom,
  ViewImageContainer,
  ViewToSeeImage,
  PhotoGridVideo,
  ViewImageControlsLeftButton,
  ViewImageControlsRightButton,
  ViewImageShowAllView,
  ViewImageFooterBarView,
  ViewImageFooterView,
  ViewImageFooterTextView,
  ViewFooterText,
  ViewFooterGridView,
  ViewFooterGridImage,
  ViewFooterImage,
  ViewFooterVideo,
} from './styled';



export interface IProps {
  visible?: boolean;
  showModele?: boolean;
  modele?: any;
  indexSelected: number;
  data: Array<any>;
  onClose?: any;
}



const ViewMediaModal: React.FC<IProps> = (props: IProps) => {
  const visibleModal = props.visible;
  if (!visibleModal) {
    return null;
  }


  const dispatch = useDispatch();
  const history = useHistory();
  const { t: translate } = useTranslation();


  const user = useSelector((state: RootState) => state.user.actualUser);
  const availableUser = user?.id;

  const projectParams = useSelector((state: RootState) => state.appConfigs.modules);

  const likedMediaUser: Array<any> = useSelector((state: RootState) => state.likes.actualLikes);
  const favoritesUser: Array<any> = useSelector((state: RootState) => state.favorites.actualFavorites);


  const transitionMs = 5600;

  const arrayMediaReceived = props.data?.length > 0 ? props.data : [];

  // Obtain the current media item based on the indexSelected
  const [currentIndex, setCurrentIndex] = useState(props.indexSelected);
  const [showGrid, setShowGrid] = useState(false);

  const [playing, setPlaying] = useState(false);
  const [progressWidth, setProgressWidth] = useState(0);

  const [loading, setLoading] = useState(false);



  function returnMediaCurrentItem(indexReceived) {
    return arrayMediaReceived[indexReceived];
  }

  const verifyLikeMedia = likedMediaUser?.length > 0
    ? likedMediaUser?.some((valueItem) => valueItem?.relationId === returnMediaCurrentItem(currentIndex)?.id)
    : false;

  const verifyFavoriteItem = favoritesUser?.length > 0
    ? favoritesUser?.some((valueItem) => valueItem?.relationId === returnMediaCurrentItem(currentIndex)?.id)
    : false;



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION,
      payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  // Função para navegar para a mídia anterior
  const handlePreviousMedia = () => {
    setCurrentIndex((prevIndex) => {
      if (prevIndex > 0) {
        return prevIndex - 1;
      }
      else {
        return arrayMediaReceived.length - 1; // Navegar do início para o final
      }
    });

    // Reiniciar o progresso ao mudar de mídia
    setProgressWidth(0);
  };


  // Função para navegar para a próxima mídia
  const handleNextMedia = () => {
    setCurrentIndex((prevIndex) => {
      if (prevIndex < arrayMediaReceived.length - 1) {
        return prevIndex + 1;
      }
      else {
        return 0; // Navegar do final para o início
      }
    });
    // Reiniciar o progresso ao mudar de mídia
    setProgressWidth(0);
  };


  // Função para lidar com o clique nas imagens da grade
  const handleClickGridImage = (index: number) => {
    setCurrentIndex(index);
    setShowGrid(false);
    // Reiniciar o progresso ao mudar de mídia
    setProgressWidth(0);
  };


  function returnQuantityGallery(): string {
    if (!arrayMediaReceived || arrayMediaReceived?.length <= 0) {
      return '0 / 0';
    }

    if (arrayMediaReceived?.length === 1) {
      return '1 / 1';
    }

    if (arrayMediaReceived?.length > 0) {
      return `${currentIndex + 1} / ${arrayMediaReceived.length}`;
    }

    return '-';
  }


  async function onLikePress(index: any) {
    try {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

      const response = await DoMediaLikeUser(
        availableUser,
        returnMediaCurrentItem(index)?.id,
      );

      if (!response?.data) {
        showSnackBarProps(
          Colors.accept,
          verifyLikeMedia
            ? translate('Erro ao descurtir')
            : translate('Erro ao curtir'),
        );
        return;
      }

      dispatch({ type: LIKES_SET, payload: response?.data || [] });

      showSnackBarProps(
        Colors.accept,
        verifyLikeMedia
          ? translate('Curtida removida')
          : translate('Curtido com sucesso'),
      );
    }
    catch (error: any) {
      console.error(error);

      const getMessageError = treatErrorAndReturnMessage(error);
      const messageError = translate(getMessageError);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  }


  async function onFavoritePress(index: any) {
    try {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

      const type = String(returnMediaCurrentItem(index)?.type).includes('video')
        ? EUserFavoritesType.VIDEOS
        : EUserFavoritesType.PHOTOS;

      const response = await PostFavoriteModele(
        availableUser,
        returnMediaCurrentItem(index)?.id,
        type,
      );

      if (!response?.data) {
        showSnackBarProps(
          Colors.accept,
          verifyFavoriteItem
            ? translate(TranslateConfig.ERROR_FAVORITE_REMOVE)
            : translate(TranslateConfig.ERROR_FAVORITE_ADD),
        );
        return;
      }

      dispatch({ type: FAVORITES_SET, payload: response?.data || [] });

      showSnackBarProps(
        Colors.accept,
        verifyFavoriteItem
          ? translate(TranslateConfig.SUCCESS_FAVORITE_REMOVE)
          : translate(TranslateConfig.SUCCESS_FAVORITE_ADD),
      );
    }
    catch (error: any) {
      console.error(error);

      const getMessageError = treatErrorAndReturnMessage(error);
      const messageError = translate(getMessageError);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  }


  function renderInfoModele() {
    const findUserMedia = returnMediaCurrentItem(currentIndex)?.user;

    if (!findUserMedia) {
      return;
    }


    return (

      <UserHeaderInfo
        modeleName={findUserMedia?.alias}
        modeleUsername={findUserMedia?.useralias}
        modelePlan={findUserMedia?.plan?.planCurrent}
        modeleImage={findUserMedia?.picture}
        verified={findUserMedia?.verified}
        brandPromoter={findUserMedia?.brandPromoter}
        onUserPress={() => {
          if (findUserMedia?.useralias) {
            history.push(NameRoutes.ModeleDetailsScreen.replace(
              ':id',
              findUserMedia?.useralias,
            ));
          }
        }}
      />

    );
  }



  useEffect(() => {
    let interval: ReturnType<typeof setInterval>;

    // Atualize a cada 100 ms para uma aparência mais suave
    const intervalDuration = 10;

    // Porcentagem de progresso por 100 ms
    const increment = (100 * intervalDuration) / transitionMs;

    if (playing) {
      // Se 'playing' estiver 'true', inicialize o intervalo para atualizar 'progressWidth'
      interval = setInterval(() => {
        setProgressWidth((prevProgress) => {
          const newProgress = prevProgress + increment;
          if (newProgress >= 100) {
            // Quando 'progressWidth' atingir 100%, mude para a próxima mídia e reinicie o progresso
            handleNextMedia();
            return 0;
          }
          return newProgress;
        });
      }, intervalDuration);
    }

    // Limpe o intervalo ao desativar 'playing' ou ao desmontar o componente
    return () => {
      clearInterval(interval);
    };
  }, [playing, transitionMs, handleNextMedia]);


  // Efeito para lidar com mudanças no índice atual
  useEffect(() => {
    if (String(returnMediaCurrentItem(currentIndex)?.type).includes('video')) {
      setLoading(true);
      // Usar setTimeout para atrasar o carregamento do vídeo
      const timeoutId = setTimeout(() => {
        setLoading(false);
      }, Values.reRenderTime);

      // Limpa o timeout ao mudar de mídia ou desmontar o componente
      return () => clearTimeout(timeoutId);
    }
  }, [currentIndex]);


  // Use um efeito separado para observar mudanças em 'playing'
  useEffect(() => {
    if (!playing) {
      // Quando 'playing' mudar para 'false', reinicie 'progressWidth' para zero
      setProgressWidth(0);
    }
  }, [playing]);



  return (

    <ViewMediaOverlay>

      <ViewMediaContainer>

        <ViewImageShowAllView>

          {playing && (
            <ViewProgressBarContent>
              <ViewBarProgress
                widthPercent={progressWidth}
              />
            </ViewProgressBarContent>
          )}


          <ViewToolbarContent>
            <ViewToolbarCountText>
              {returnQuantityGallery()}
            </ViewToolbarCountText>


            <ViewToolbarItemsContent>
              <ViewToolbarItemButtom
                onClick={() => {
                  //
                }}>
                <IconZoomMinus
                  color={false ? Colors.white : Colors.disabled}
                  size={22}
                />
              </ViewToolbarItemButtom>


              <ViewToolbarItemButtom
                onClick={() => {
                  //
                }}>
                <IconZoomPlus
                  color={false ? Colors.white : Colors.disabled}
                  size={22}
                />
              </ViewToolbarItemButtom>


              <ViewToolbarItemButtom
                onClick={() => {
                  //
                }}>
                <IconScreenFull
                  color={false ? Colors.white : Colors.disabled}
                  size={22}
                />
              </ViewToolbarItemButtom>


              {arrayMediaReceived?.length > 1 && (
                <ViewToolbarItemButtom
                  onClick={() => {
                    setPlaying(!playing);
                  }}>
                  {playing ? (
                    <IconPause
                      color={Colors.white}
                      size={22}
                    />
                  ) : (
                    <IconPlay
                      color={Colors.white}
                      size={22}
                    />
                  )}
                </ViewToolbarItemButtom>
              )}


              <ViewToolbarItemButtom
                onClick={() => {
                  if (props.onClose) {
                    props.onClose();
                  }
                }}>
                <IconClose
                  color={Colors.white}
                  size={22}
                />
              </ViewToolbarItemButtom>

            </ViewToolbarItemsContent>

          </ViewToolbarContent>



          <ViewImageContainer>
            {String(returnMediaCurrentItem(currentIndex)?.type).includes('image') && (
              <ViewToSeeImage
                alt={'Current Media'}
                src={returnMediaCurrentItem(currentIndex)?.picture}
              />
            )}



            {!loading &&
              String(returnMediaCurrentItem(currentIndex)?.type).includes('video') && (
                <PhotoGridVideo
                  controls>
                  <source src={returnMediaCurrentItem(currentIndex)?.picture} />
                  Seu navegador não suporta a reprodução de vídeos HTML5.
                </PhotoGridVideo>
              )}



            {arrayMediaReceived?.length > 1 && (
              <ViewImageControlsLeftButton
                onClick={() => {
                  handlePreviousMedia();
                }}>
                <IconArrowLightBack
                  color={Colors.white}
                  size={28}
                />
              </ViewImageControlsLeftButton>
            )}



            {arrayMediaReceived?.length > 1 && (
              <ViewImageControlsRightButton
                onClick={() => {
                  handleNextMedia();
                }}>
                <IconArrowLightForward
                  color={Colors.white}
                  size={28}
                />
              </ViewImageControlsRightButton>
            )}
          </ViewImageContainer>



          <ViewImageFooterBarView>

            {props.showModele && (
              renderInfoModele()
            )}



            <ViewImageFooterView>

              <ViewImageFooterTextView>
                <ViewFooterText>
                  {returnMediaCurrentItem(currentIndex)?.comment || '-'}
                </ViewFooterText>
              </ViewImageFooterTextView>



              {projectParams?.canAccess && (
                <ViewToolbarItemButtom
                  disabled={!availableUser}
                  onClick={() => {
                    if (!availableUser) {
                      history.push(NameRoutes.AccessScreen);
                      return;
                    }

                    onLikePress(currentIndex);
                  }}>
                  {verifyLikeMedia ? (
                    <IconLiked
                      color={Colors.primary}
                      size={22}
                    />
                  ) : (
                    <IconLike
                      color={Colors.white}
                      size={22}
                    />
                  )}
                </ViewToolbarItemButtom>
              )}


              {projectParams?.canAccess && (
                <ViewToolbarItemButtom
                  disabled={!availableUser}
                  onClick={() => {
                    if (!availableUser) {
                      history.push(NameRoutes.AccessScreen);
                      return;
                    }

                    onFavoritePress(currentIndex);
                  }}>
                  {verifyFavoriteItem ? (
                    <IconFavoriteOn
                      color={Colors.primary}
                      size={22}
                    />
                  ) : (
                    <IconFavoriteOff
                      color={Colors.white}
                      size={22}
                    />
                  )}
                </ViewToolbarItemButtom>
              )}



              {arrayMediaReceived?.length > 1 && (
                <ViewToolbarItemButtom
                  onClick={() => {
                    setShowGrid(!showGrid);
                  }}>
                  <IconMediaPhoto
                    color={Colors.white}
                    size={22}
                  />
                </ViewToolbarItemButtom>
              )}

            </ViewImageFooterView>

          </ViewImageFooterBarView>
        </ViewImageShowAllView>



        {showGrid && (
          <ViewFooterGridView>
            {arrayMediaReceived?.map((item: any, index: number) => (
              <ViewFooterGridImage
                key={index}
                active={index === currentIndex}
                onClick={() => {
                  handleClickGridImage(index);
                }}>

                {String(item?.type).includes('image') && (
                  <ViewFooterImage src={item?.picture} />
                )}


                {String(item?.type).includes('video') && (
                  <ViewFooterVideo muted controls={false}>
                    <source src={item?.picture} />
                    Seu navegador não suporta a reprodução de vídeos HTML5.
                  </ViewFooterVideo>
                )}

              </ViewFooterGridImage>
            ))}
          </ViewFooterGridView>
        )}

      </ViewMediaContainer>

    </ViewMediaOverlay>

  );
};



export default ViewMediaModal;
