import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useHistory,
} from 'react-router-dom';

import {
  IconModele,
  IconSponsor,
} from '../../../components/Icons';

import NameRoutes from '../../../navigation/names';

import {
  Colors,
  Images,
} from '../../../shared/constants';

import {
  TranslateConfig,
} from '../../../shared/translations';

import {
  HeroDetailsContainer,
  HeroDetailsButtonsView,
  HeroDetailsButton,
  HeroDetailsTextButton,
  HeroDetailsInfoContainer,
  HeroDetailsHeader,
  HeroDetailsTitle,
  HeroDetailsDescription,
  HeroDetailsItemsContainer,
  HeroDetailsItemsView,
  HeroDetailsItemsIcon,
  HeroDetailsItemsText,
  HeroDetailsFooterDescription,
  HeroDetailsFooterKnowMore,
} from './styled';



const HomeDetailsSection: React.FC = () => {
  const history = useHistory();
  const { t: translate } = useTranslation();


  const itemsApp = [
    {
      title: 'Modelos com documentos verificados e perfis atualizados',
      image: Images.userGalleryVerified,
    },
    {
      title: 'Fotos e vídeos verificados diariamente',
      image: Images.userVerified,
    },
    {
      title: 'Filtros avançados que te ajudam a encontrar exatamente o que deseja',
      image: Images.userFilters,
    },
  ];



  function renderButtonsOptions(icon: any, title: string, onPress: any) {
    return (

      <HeroDetailsButton
        onClick={onPress}>

        {icon}


        <HeroDetailsTextButton>
          {title}
        </HeroDetailsTextButton>

      </HeroDetailsButton>

    );
  }



  return (

    <HeroDetailsContainer>

      <HeroDetailsButtonsView>

        {renderButtonsOptions(
          <IconModele
            color={Colors.white}
            size={24}
          />,
          translate(TranslateConfig.ACTION_SEE_ALL_MODELES),
          () => {
            history.push(NameRoutes.AllModeleScreen);
          },
        )}


        {renderButtonsOptions(
          <IconSponsor
            color={Colors.white}
            size={24}
          />,
          translate(TranslateConfig.ACTION_SEE_ALL_SPONSORS),
          () => {
            history.push(NameRoutes.AllSponsorsScreen);
          },
        )}

      </HeroDetailsButtonsView>



      <HeroDetailsInfoContainer>

        <HeroDetailsHeader>

          <HeroDetailsTitle>
            {translate(TranslateConfig.LANDING_SECTION_TITLE_1)}
          </HeroDetailsTitle>

          <HeroDetailsDescription>
            {translate(TranslateConfig.LANDING_SECTION_DESCRIPTION_1)}
          </HeroDetailsDescription>

        </HeroDetailsHeader>



        <HeroDetailsItemsContainer>

          {itemsApp.map((item, index: number) => (
            <HeroDetailsItemsView
              key={index}>

              <HeroDetailsItemsIcon
                src={item?.image}
              />


              <HeroDetailsItemsText>
                {item?.title || '-'}
              </HeroDetailsItemsText>

            </HeroDetailsItemsView>
          ))}

        </HeroDetailsItemsContainer>



        <HeroDetailsFooterDescription>
          {translate(TranslateConfig.LANDING_ADULT_JOB)}
          {' '}
          {false && (
            // TODO Italo: Fazer o link no secretio site
            <HeroDetailsFooterKnowMore
              onClick={() => {
                //
              }}>
              {translate(TranslateConfig.ACTION_LEARN_MORE)}
            </HeroDetailsFooterKnowMore>
          )}
          {/* . */}
        </HeroDetailsFooterDescription>

      </HeroDetailsInfoContainer>

    </HeroDetailsContainer>

  );
};



export default HomeDetailsSection;
