import ApiWS from '../index';



export const GetAllModeles = async (genderSearch: string) => {
  const url = '/app/modele';

  const payload = {
    gender: genderSearch,
  };

  const response = await ApiWS.get(url, {
    params: payload,
  });
  return response.data;
};



export const GetModelesSponsors = async (genderSearch: string) => {
  const url = '/app/modele/sponsors-notfound';

  const payload = {
    gender: genderSearch,
  };

  const response = await ApiWS.get(url, {
    params: payload,
  });
  return response.data;
};



export const GetModeleDetails = async (idModele: string) => {
  const url = `/app/modele/${idModele}`;

  const response = await ApiWS.get(url);
  return response.data;
};



export const GetModeleProfissionalPanel = async (idModele: string) => {
  const url = `/app/modele/panel/${idModele}`;

  const response = await ApiWS.get(url);
  return response.data;
};



export const EditParamsModele = async (useralias: string, payload: any) => {
  const url = `/app/modele/params/${useralias}`;

  const response = await ApiWS.put(url, payload);
  return response.data;
};



export const GetPrivacityModele = async (idUser: string) => {
  const url = `/app/modele/privacity/${idUser}`;

  const response = await ApiWS.get(url);
  return response.data;
};



export const EditPrivacityModele = async (idUser: string, payload: any) => {
  const url = `/app/modele/privacity/${idUser}`;

  const response = await ApiWS.put(url, payload);
  return response.data;
};



export const EditRentItemModele = async (useralias: string, payload: any) => {
  const url = `/app/modele/rent/${useralias}`;

  const response = await ApiWS.put(url, payload);
  return response.data;
};



export const GetRentAllServicesModele = async (useralias: string) => {
  const url = `/app/modele/services/${useralias}`;

  const response = await ApiWS.get(url);
  return response.data;
};



export const EditRentServicesModele = async (idUser: string, payload: any) => {
  const url = `/app/modele/services/${idUser}`;

  const response = await ApiWS.put(url, payload);
  return response.data;
};



export const EditRentContactsModele = async (useralias: string, payload: any) => {
  const url = `/app/modele/contacts/${useralias}`;

  const response = await ApiWS.put(url, payload);
  return response.data;
};



export const EditRentPaymentTypeModele = async (useralias: string, payload: any) => {
  const url = `/app/modele/prices/${useralias}`;

  const response = await ApiWS.put(url, payload);
  return response.data;
};



export const ShowHideProfileModele = async (useralias: string) => {
  const url = `/app/modele/show-hide/${useralias}`;

  const response = await ApiWS.put(url);
  return response.data;
};
