import React,
{
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import {
  PostFavoriteModele,
} from '../../../../apis/endpoints/users-favorites.endpoints';

import {
  DoMediaLikeUser,
} from '../../../../apis/endpoints/users-medias-liked.endpoints';

import NameRoutes from '../../../../navigation/names';

import {
  RootState,
} from '../../../../redux';

import {
  APP_MODAL_LOADING_ACTION,
  APP_SNACKBAR_ACTION,
  USER_BRAND_PROMOTER_MODAL,
  USER_SEND_GIFT_MODAL,
  USER_VERIFIED_MODAL,
} from '../../../../redux/reducers/app-modals.store';

import {
  FAVORITES_SET,
} from '../../../../redux/reducers/favorites.store';

import {
  LIKES_SET,
} from '../../../../redux/reducers/likes.store';

import {
  Colors,
  Images,
} from '../../../../shared/constants';

import {
  EPlansUser,
} from '../../../../shared/enums';

import {
  EUserFavoritesType,
} from '../../../../shared/enums/Users/user-favorites.enum';

import {
  TranslateConfig,
} from '../../../../shared/translations';

import {
  FormatExtenseDate,
  FormatIsoDateWithPatternDayjs,
} from '../../../../shared/utils/date.utils';

import {
  treatErrorAndReturnColor,
  treatErrorAndReturnMessage,
} from '../../../../shared/utils/errors.utils';

import {
  validateString,
} from '../../../../shared/utils/string.utils';

import UserAvatar from '../../../Contents/UserAvatar';

import {
  IconFavoriteOff,
  IconFavoriteOn,
  IconSoundOff,
  IconLike,
  IconLiked,
  IconPlay,
  IconSoundOn,
  IconTipGifts,
  IconVerified,
  IconVerifiedPromoter,
  IconResize,
} from '../../../Icons';

import {
  CardPostContainer,
  CardPostHeader,
  CardPostUserButton,
  CardPostInfoView,
  CardPostNameView,
  CardPostNameBadge,
  CardPostNameText,
  CardPostUsernameView,
  CardPostUsernameText,
  CardPostVerifiedIcon,
  CardPostCommentContainer,
  CardPostDatePostedText,
  CardPostHourPostedText,
  CardPostImageTypeCont,
  CardPostImageContainer,
  CardPostImageTypeImage,
  CardPostBackgroundTypeImage,
  CardPostVideoContainer,
  CardPostImageTypeVideo,
  CardPostVideoPlayButton,
  CardPostVideoControlsView,
  CardPostVideoMuteButton,
  CardPostFooterActionContainer,
  CardPostImageTypeView,
  CardPostCaptionView,
  CardPostImageTypeCaptionText,
  CardPostLikeView,
  CardPostLikeIcon,
  CardPostLikeText,
  CardPostFooterActionsContent,
  CardPostFooterLikeButton,
  CardPostFooterButton,
  CardPostFooterIcon,
  CardPostFooterText,
  CardPostActionHeaderButton,
} from './styled';



export interface IProps {
  idMedia: string;
  type: string;
  feedType: 'DETAILS' | 'TIMELINE';
  imageUser?: any;
  plan?: EPlansUser;
  name?: string;
  username?: string;
  verified?: boolean;
  promoter?: boolean;
  createdAt?: string;
  mediaLink?: string;
  like?: number;
  caption?: string;
  onPress?: any;
  onModelePress?: any;
}



const CardPost: React.FC<IProps> = (props: IProps) => {
  const isMediaValid = String(props.type)
    && (String(props.type).includes('image') || String(props.type).includes('video'));


  if (!isMediaValid) {
    return null;
  }


  const dispatch = useDispatch();
  const history = useHistory();
  const { t: translate } = useTranslation();


  const usernameModele = props.username;
  const genderColorChoose = Colors.primary;


  const user = useSelector((state: RootState) => state.user.actualUser);
  const availableUser = user?.id;

  const projectParams = useSelector((state: RootState) => state.appConfigs.modules);

  const likedMediaUser: Array<any> = useSelector((state: RootState) => state.likes.actualLikes);
  const favoritesUser: Array<any> = useSelector((state: RootState) => state.favorites.actualFavorites);


  const userImage = validateString(props.imageUser) || Images.avatarUserImage;
  const userMedia = validateString(props.mediaLink) || Images.bannerDetails;

  const videoPlayerRef = useRef(null);
  const mainBoxRef = useRef(null);

  const [isPlayed, setIsPlayed] = useState(false);
  const [isMuted, setIsMuted] = useState(false);

  const verifyLikeMedia = likedMediaUser?.length > 0
    ? likedMediaUser?.some((valueItem) => valueItem?.relationId === props.idMedia)
    : false;

  const verifyFavoriteItem = favoritesUser?.length > 0
    ? favoritesUser?.some((valueItem) => valueItem?.relationId === props.idMedia)
    : false;



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION,
      payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  async function onLikePress() {
    try {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

      const response = await DoMediaLikeUser(
        availableUser,
        props.idMedia,
      );

      if (!response?.data) {
        showSnackBarProps(
          Colors.accept,
          verifyLikeMedia
            ? translate(TranslateConfig.ERROR_LIKE_REMOVE)
            : translate(TranslateConfig.ERROR_LIKE_ADD),
        );
        return;
      }

      dispatch({ type: LIKES_SET, payload: response?.data || [] });

      showSnackBarProps(
        Colors.accept,
        verifyLikeMedia
          ? translate(TranslateConfig.SUCCESS_LIKE_REMOVE)
          : translate(TranslateConfig.SUCCESS_LIKE_ADD),
      );
    }
    catch (error: any) {
      console.error(error);

      const getMessageError = treatErrorAndReturnMessage(error);
      const messageError = translate(getMessageError);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  }


  async function onFavoritePress() {
    try {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

      const type = String(props.type).includes('video')
        ? EUserFavoritesType.VIDEOS
        : EUserFavoritesType.PHOTOS;

      const response = await PostFavoriteModele(
        availableUser,
        props.idMedia,
        type,
      );

      if (!response?.data) {
        showSnackBarProps(
          Colors.accept,
          verifyFavoriteItem
            ? translate(TranslateConfig.ERROR_FAVORITE_REMOVE)
            : translate(TranslateConfig.ERROR_FAVORITE_ADD),
        );
        return;
      }

      dispatch({ type: FAVORITES_SET, payload: response?.data || [] });

      showSnackBarProps(
        Colors.accept,
        verifyFavoriteItem
          ? translate(TranslateConfig.SUCCESS_FAVORITE_REMOVE)
          : translate(TranslateConfig.SUCCESS_FAVORITE_ADD),
      );
    }
    catch (error: any) {
      console.error(error);

      const getMessageError = treatErrorAndReturnMessage(error);
      const messageError = translate(getMessageError);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  }


  function openModeleDetails() {
    if (props.onModelePress) {
      props.onModelePress();
    };
  }


  const handleVideoClick = () => {
    const video = videoPlayerRef.current;
    if (isPlayed) {
      video.pause();
      setIsPlayed(false);
    }
    else {
      video.play();
      setIsPlayed(true);
    }
  };


  const handleMute = (isMuted) => {
    videoPlayerRef.current.muted = isMuted;
    setIsMuted(isMuted);
  };


  const handleVideoEnded = () => {
    setIsPlayed(false);
  };


  function renderLikeTitle(): string {
    if (props.like <= 0) {
      return translate(TranslateConfig.NO_LIKED_MEDIA_COUNT);
    }

    if (props.like === 1) {
      return `1 ${translate(TranslateConfig.LIKED_MEDIA_COUNT)}`;
    }

    if (props.like > 1) {
      return `${props.like} ${translate(TranslateConfig.LIKED_MEDIA_COUNTS)}`;
    }

    return '';
  }



  useEffect(() => {
    const handleScroll = () => {
      const containerRect = mainBoxRef.current.getBoundingClientRect();
      if (containerRect.top < 0 && isPlayed) {
        setIsPlayed(false);
        videoPlayerRef.current.pause();
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isPlayed]);



  return (

    <CardPostContainer
      ref={mainBoxRef}>

      {props.feedType === 'TIMELINE' && (
        <CardPostHeader>

          <CardPostUserButton
            clickable={Boolean(props.onModelePress)}
            onClick={() => {
              openModeleDetails();
            }}>

            <UserAvatar
              plan={props.plan}
              image={userImage}
              width={50}
            />
          </CardPostUserButton>



          <CardPostInfoView>

            <CardPostNameView
              clickable={Boolean(props.onModelePress)}
              onClick={() => {
                openModeleDetails();
              }}>
              {projectParams?.canBeOnline && (
                <CardPostNameBadge />
              )}

              <CardPostNameText>
                {props.name || '-'}
              </CardPostNameText>
            </CardPostNameView>



            <CardPostUsernameView>
              <CardPostUsernameText
                clickable={Boolean(props.onModelePress)}
                onClick={() => {
                  openModeleDetails();
                }}>
                @{usernameModele || '-'}
              </CardPostUsernameText>



              {props.verified && (
                <CardPostVerifiedIcon
                  onClick={() => {
                    dispatch({ type: USER_VERIFIED_MODAL, payload: true });
                  }}>
                  <IconVerified
                    color={Colors.accent}
                    size={15}
                  />
                </CardPostVerifiedIcon>
              )}



              {props.promoter && (
                <CardPostVerifiedIcon
                  onClick={() => {
                    dispatch({ type: USER_BRAND_PROMOTER_MODAL, payload: true });
                  }}>
                  <IconVerifiedPromoter
                    size={15}
                  />
                </CardPostVerifiedIcon>
              )}

            </CardPostUsernameView>

          </CardPostInfoView>



          <CardPostCommentContainer>

            <CardPostDatePostedText>
              {FormatExtenseDate(props.createdAt)}
            </CardPostDatePostedText>


            <CardPostHourPostedText>
              {FormatIsoDateWithPatternDayjs(props.createdAt, 'HH:mm')}
            </CardPostHourPostedText>

          </CardPostCommentContainer>

        </CardPostHeader>
      )}



      <CardPostImageTypeCont>

        {String(props.type).includes('image') && (
          <CardPostImageContainer
            onClick={() => {
              if (props.onPress) {
                props.onPress();
              }
            }}>

            <CardPostBackgroundTypeImage
              alt={'postimage'}
              src={userMedia}
            />


            <CardPostImageTypeImage
              alt={'postimage'}
              src={userMedia}
            />

          </CardPostImageContainer>
        )}


        {String(props.type).includes('video') && (
          <CardPostVideoContainer>

            <CardPostImageTypeVideo
              ref={videoPlayerRef}
              onClick={() => {
                handleVideoClick();
              }}
              onTouchStart={() => {
                handleVideoClick();
              }}
              onEnded={() => {
                handleVideoEnded();
              }}>
              <source src={userMedia} />
              {translate(TranslateConfig.ERROR_VIDEO_PLAY)}
            </CardPostImageTypeVideo>


            {!isPlayed && (
              <CardPostVideoPlayButton
                onClick={handleVideoClick}>
                <IconPlay
                  color={Colors.white}
                  size={32}
                />
              </CardPostVideoPlayButton>
            )}


            <CardPostVideoControlsView>

              <CardPostVideoMuteButton
                onClick={() => {
                  if (props.onPress) {
                    props.onPress();
                  }
                }}>
                <IconResize
                  color={Colors.white}
                  size={21}
                />
              </CardPostVideoMuteButton>


              <CardPostVideoMuteButton
                onClick={() => {
                  if (isMuted) {
                    handleMute(false);
                    return;
                  }

                  handleMute(true);
                }}>
                {isMuted
                  ? (
                    <IconSoundOff
                      color={Colors.white}
                      size={21}
                    />
                  )
                  : (
                    <IconSoundOn
                      color={Colors.white}
                      size={21}
                    />
                  )
                }
              </CardPostVideoMuteButton>

            </CardPostVideoControlsView>

          </CardPostVideoContainer>

        )}
      </CardPostImageTypeCont>



      {projectParams?.canAccess && (
        <CardPostFooterActionContainer>

          <CardPostImageTypeView>

            <CardPostCaptionView>
              <CardPostImageTypeCaptionText>
                {props.caption || '-'}
              </CardPostImageTypeCaptionText>
            </CardPostCaptionView>


            {projectParams?.canAccess && (
              <CardPostLikeView>
                <CardPostLikeIcon>
                  <IconLiked
                    color={Colors.subtextApp}
                    size={15}
                  />
                </CardPostLikeIcon>

                <CardPostLikeText>
                  {renderLikeTitle()}
                </CardPostLikeText>
              </CardPostLikeView>
            )}

          </CardPostImageTypeView>



          {projectParams?.canAccess && (
            <CardPostFooterActionsContent>

              <CardPostFooterLikeButton
                disabled={!availableUser}
                onClick={() => {
                  if (!availableUser) {
                    history.push(NameRoutes.AccessScreen);
                    return;
                  }

                  onLikePress();
                }}>
                <CardPostFooterIcon>
                  {verifyLikeMedia
                    ? (
                      <IconLiked
                        color={genderColorChoose}
                        size={17}
                      />
                    )
                    : (
                      <IconLike
                        color={Colors.white}
                        size={17}
                      />
                    )
                  }
                </CardPostFooterIcon>


                <CardPostFooterText
                  color={verifyLikeMedia ? genderColorChoose : Colors.white}>
                  {verifyLikeMedia
                    ? translate(TranslateConfig.LIKED)
                    : translate(TranslateConfig.LIKE)
                  }
                </CardPostFooterText>
              </CardPostFooterLikeButton>



              {projectParams?.showFinancial && (
                <CardPostFooterButton
                  disabled={!availableUser}
                  onClick={() => {
                    if (!availableUser) {
                      history.push(NameRoutes.AccessScreen);
                      return;
                    }

                    dispatch({ type: USER_SEND_GIFT_MODAL, payload: true });
                  }}>
                  <CardPostFooterIcon>
                    <IconTipGifts
                      color={Colors.white}
                      size={20}
                    />
                  </CardPostFooterIcon>

                  <CardPostFooterText
                    color={Colors.white}>
                    {translate(TranslateConfig.ACTION_SEND_GIFT)}
                  </CardPostFooterText>
                </CardPostFooterButton>
              )}



              <CardPostActionHeaderButton
                disabled={!availableUser}
                onClick={() => {
                  if (!availableUser) {
                    history.push(NameRoutes.AccessScreen);
                    return;
                  }

                  onFavoritePress();
                }}>
                {verifyFavoriteItem ? (
                  <IconFavoriteOn
                    color={Colors.primary}
                    size={22}
                  />
                ) : (
                  <IconFavoriteOff
                    color={Colors.white}
                    size={22}
                  />
                )}
              </CardPostActionHeaderButton>

            </CardPostFooterActionsContent>
          )}

        </CardPostFooterActionContainer>
      )}

    </CardPostContainer>

  );
};



export default CardPost;
