import React from 'react';

import {
  KnowUsContainer,
} from './styled';



const HomeKnowUsSection: React.FC = () => {
  return (

    <KnowUsContainer>
      {/* TODO */}
    </KnowUsContainer>

  );
};



export default HomeKnowUsSection;
