import React from 'react';

import {
  Helmet,
} from 'react-helmet-async';

import {
  useTranslation,
} from 'react-i18next';

import {
  Colors,
  Images,
} from '../../../shared/constants';

import {
  TranslateConfig,
} from '../../../shared/translations';

import {
  Screen,
} from '../../Composh/web';

import FooterApp from '../../Footers/FooterApp';
import HeaderFixed from '../../Headers/HeaderFixed';

import {
  ErrorViewContainer,
  ErrorInfoViewPadder,
  ErrorInfoViewContainer,
  ErroLogoAppImage,
  ErrorInfoViewTitle,
  ErrorInfoViewDescription,
  ErrorInfoViewButtonAction,
  ErrorInfoViewBackground,
  ErrorInfoViewFooter,
} from './styled';



export interface IProps {
  disableNavInside: boolean;
  title: string;
  description?: string;
  image?: string;
  buttonText?: string;
  buttonPress?: any;
}



const ErrorContainer: React.FC<IProps> = (props: IProps) => {
  const { t: translate } = useTranslation();



  return (

    <Screen
      backgroundColor={Colors.appBackground}>


      <Helmet prioritizeSeoTags>
        <title>
          {`${translate(TranslateConfig.ERROR)} | ${translate(TranslateConfig.NAME_APP)}`}
        </title>

        <meta name="robots" content="noindex,nofollow" />
        <meta name="googlebot" content="noindex,nofollow" />
      </Helmet>



      <ErrorViewContainer>

        <ErrorInfoViewFooter>
          <HeaderFixed
            onlyLanguage={props.disableNavInside}
            backgroundColor={Colors.transparent}
          />
        </ErrorInfoViewFooter>



        <ErrorInfoViewPadder>

          <ErrorInfoViewContainer>

            <ErroLogoAppImage
              alt={''}
              src={Images.logoAppFullWhite}
            />


            <ErrorInfoViewTitle>
              {props.title || '-'}
            </ErrorInfoViewTitle>


            {props.description && (
              <ErrorInfoViewDescription>
                {props.description}
              </ErrorInfoViewDescription>
            )}


            {props.buttonText && (
              <ErrorInfoViewButtonAction
                title={props.buttonText}
                onPress={() => {
                  if (props.buttonPress) {
                    props.buttonPress();
                  }
                }}
              />
            )}
          </ErrorInfoViewContainer>

        </ErrorInfoViewPadder>



        {props.image && (
          <ErrorInfoViewBackground
            src={props.image}
          />
        )}



        <ErrorInfoViewFooter>
          <FooterApp
            onlyWebView={props.disableNavInside}
          />
        </ErrorInfoViewFooter>

      </ErrorViewContainer>

    </Screen>

  );
};



export default ErrorContainer;
