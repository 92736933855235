import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useSelector,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import {
  GetModelesSponsors,
} from '../../../apis/endpoints/modeles.endpoints';

import ButtonAction from '../../../components/Buttons/ButtonAction';
import SponsorsModele from '../../../components/Cards/SponsorsModele';

import {
  Body,
  Content,
  Screen,
  SubTitle,
  Title,
} from '../../../components/Composh/web';

import {
  ContentPadder,
} from '../../../components/Contents/ContentPadder';

import FooterApp from '../../../components/Footers/FooterApp';
import HeaderScreen from '../../../components/Headers/HeaderScreen';

import {
  IconBack,
  IconEyeOn,
  IconHome,
  IconLive,
  IconRentOn,
  IconSuggestions,
} from '../../../components/Icons';

import SectionTitle from '../../../components/Titles/SectionTitle';

import {
  SetLightGenreText,
} from '../../../config/genre.config';

import NameRoutes from '../../../navigation/names';

import {
  RootState,
} from '../../../redux';

import {
  Colors,
  Images,
} from '../../../shared/constants';

import { TranslateConfig } from '../../../shared/translations';

import {
  Erro404LogoAppImage,
  Error404BoxTitle,
  Error404ButtonsContainer,
  Error404ChoosesText,
  Error404ChoosesTypesView,
  Error404ChoosesWrapper,
  Error404ColunsContent,
  Error404ColunsOptionsView,
  Error404Container,
  Error404Description,
  Error404OrText,
  Error404SponsorsAllButon,
  Error404SponsorsAllText,
  Error404SponsorsHeader,
  Error404SponsorsView,
  Error404SponsorSwiperContainer,
  Error404SponsorSwiperView,
  Error404Title,
} from './styled';



const Error404Screen: React.FC = () => {
  const history = useHistory();
  const { t: translate } = useTranslation();


  const projectParams = useSelector((state: RootState) => state.appConfigs.modules);
  const genderChoose = useSelector((state: RootState) => state.choose.genderChoose);

  const user = useSelector((state: RootState) => state.user.actualUser);
  const availableUser = user?.id;


  const [sponsors404, setSponsors404] = useState<Array<any>>([]);



  const getAllModeles = async () => {
    try {
      const response = await GetModelesSponsors(genderChoose);

      if (!response?.data || !Array.isArray(response?.data)) {
        setSponsors404([]);
        return;
      }

      setSponsors404(response?.data);
    }
    catch (error: any) {
      console.error(error);
      setSponsors404([]);
    }
  };


  function onPressBackError() {
    history.goBack();
  }


  function onPressHomeError() {
    history.replace(NameRoutes.HomeScreen);
  }


  function onPressRent() {
    history.replace(NameRoutes.RentHomeScreen);
  }


  function onPressLive() {
    history.replace(NameRoutes.LiveHomeScreen);
  }


  function onPressSuggestions() {
    history.replace(NameRoutes.SuggestionScreen);
  }


  function onPressAllSponsors() {
    history.replace(NameRoutes.AllSponsorsScreen);
  }



  useEffect(() => {
    getAllModeles();
  }, []);



  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <HeaderScreen
        disableGender
        showBalance
        backgroundColor={Colors.toolbarBackground}
        subBackgroundColor={Colors.toolbarBackground}
        centerContent={
          <Body>
            <Title>
              {translate(SetLightGenreText(genderChoose))}
            </Title>

            <SubTitle>
              {translate(TranslateConfig.NAME_APP)}
            </SubTitle>
          </Body>
        }
      />



      <Content>
        <ContentPadder>

          <Error404Container>

            <Error404ColunsOptionsView>

              <Error404ColunsContent>

                <Error404Title>
                  {translate(TranslateConfig.ERROR_OOPS)}
                  <Error404BoxTitle>
                    404
                  </Error404BoxTitle>
                </Error404Title>


                <Error404Description>
                  {translate(TranslateConfig.ERROR_404)}
                </Error404Description>


                <Error404ButtonsContainer>

                  <ButtonAction
                    bold
                    title={translate(TranslateConfig.ACTION_BACK)}
                    backgroundColor={Colors.transparent}
                    color={Colors.primary}
                    borderColor={Colors.primary}
                    iconLeft={
                      <IconBack
                        color={Colors.primary}
                        size={20}
                      />
                    }
                    onPress={() => {
                      onPressBackError();
                    }}
                  />


                  <Error404OrText>
                    {translate(TranslateConfig.OR).toUpperCase()}
                  </Error404OrText>


                  <ButtonAction
                    bold
                    title={translate(TranslateConfig.ACTION_GO_TO_HOME)}
                    backgroundColor={Colors.primary}
                    color={Colors.black}
                    iconLeft={
                      <IconHome
                        color={Colors.black}
                        size={20}
                      />
                    }
                    onPress={() => {
                      onPressHomeError();
                    }}
                  />

                </Error404ButtonsContainer>

              </Error404ColunsContent>



              <Error404ColunsContent>

                <Error404ChoosesWrapper>
                  <Erro404LogoAppImage
                    alt={''}
                    src={Images.logoIconApp}
                  />


                  <Error404ChoosesText>
                    {translate(TranslateConfig.TRY_OTHER_MORE)}
                  </Error404ChoosesText>
                </Error404ChoosesWrapper>


                <Error404ChoosesTypesView>

                  {projectParams?.showRent && (
                    <ButtonAction
                      bold
                      title={translate(TranslateConfig.ESCORTS)}
                      backgroundColor={Colors.primary}
                      color={Colors.black}
                      iconLeft={
                        <IconRentOn
                          color={Colors.black}
                          size={20}
                        />
                      }
                      onPress={() => {
                        onPressRent();
                      }}
                    />
                  )}


                  {projectParams?.showLive && (
                    <ButtonAction
                      bold
                      title={translate(TranslateConfig.LIVE_ROOMS_ONDEMAND)}
                      backgroundColor={Colors.primary}
                      color={Colors.black}
                      iconLeft={
                        <IconLive
                          color={Colors.black}
                          size={20}
                        />
                      }
                      onPress={() => {
                        onPressLive();
                      }}
                    />
                  )}


                  {projectParams?.canAccess && availableUser && (
                    <ButtonAction
                      bold
                      title={translate(TranslateConfig.SUGGESTIONS)}
                      backgroundColor={Colors.primary}
                      color={Colors.black}
                      iconLeft={
                        <IconSuggestions
                          color={Colors.black}
                          size={20}
                        />
                      }
                      onPress={() => {
                        onPressSuggestions();
                      }}
                    />
                  )}

                </Error404ChoosesTypesView>

              </Error404ColunsContent>

            </Error404ColunsOptionsView>



            {sponsors404?.length > 0 && (
              <Error404SponsorsView>

                <Error404SponsorsHeader>

                  <SectionTitle
                    title={translate(TranslateConfig.NAME_APP)}
                    subtitle={translate(TranslateConfig.PROFILE_BROWSE)}
                  />


                  <Error404SponsorsAllButon
                    onClick={() => {
                      onPressAllSponsors();
                    }}>
                    <IconEyeOn
                      color={Colors.white}
                      size={18}
                    />

                    <Error404SponsorsAllText>
                      {translate(TranslateConfig.ACTION_SEE_ALL)}
                    </Error404SponsorsAllText>
                  </Error404SponsorsAllButon>

                </Error404SponsorsHeader>



                <Error404SponsorSwiperView>
                  <Error404SponsorSwiperContainer>

                    {sponsors404?.map((item: any, index: number) => (
                      <SponsorsModele
                        key={index}
                        image={item?.picture}
                        name={item?.alias}
                        username={item?.useralias}
                      />
                    ))}

                  </Error404SponsorSwiperContainer>
                </Error404SponsorSwiperView>

              </Error404SponsorsView>
            )}

          </Error404Container>

        </ContentPadder>



        <FooterApp />

      </Content>

    </Screen>

  );
};



export default Error404Screen;
