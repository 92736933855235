import React, { useEffect, useRef, useState } from 'react';

import { Helmet } from 'react-helmet-async';

import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';

import { Formik, FormikProps } from 'formik';

import {
  CreateTravelsModele,
  DeleteTravelsModele,
  GetAllTravelsModele,
} from '../../../apis/endpoints/modeles-travels.endpoints';

import { IInputSelect } from '../../../apis/interfaces/App/input-select.interface';

import AlertMessage from '../../../components/Alerts';
import ButtonAction from '../../../components/Buttons/ButtonAction';

import {
  AppBar,
  Container,
  Content,
  Screen,
  Switch,
  Title,
} from '../../../components/Composh/web';

import { ButtonBack, ButtonHelp } from '../../../components/Controls';

import FooterApp from '../../../components/Footers/FooterApp';
import HeaderScreen from '../../../components/Headers/HeaderScreen';

import {
  IconCalendarPast,
  IconCalendarUpcoming,
} from '../../../components/Icons';

import TabBarStyled from '../../../components/TabBar';
import SectionTitle from '../../../components/Titles/SectionTitle';

import DataSelectModal from '../../../modals/DataSelect';

import { RootState } from '../../../redux';

import {
  APP_MODAL_LOADING_ACTION,
  APP_SNACKBAR_ACTION,
} from '../../../redux/reducers/app-modals.store';

import { Colors, Values } from '../../../shared/constants';

import EResponseStatus from '../../../shared/enums/Response/response-status.enum';

import { TranslateConfig } from '../../../shared/translations';

import {
  returnCityArray,
  returnCountryArray,
  returnStateArray,
} from '../../../shared/utils/address.utils';

import { DayJStoIso } from '../../../shared/utils/date.utils';

import {
  treatErrorAndReturnColor,
  treatErrorAndReturnMessage,
} from '../../../shared/utils/errors.utils';

import CreateModal from './Modals/CreateModal';
import HelpTravelModal from './Modals/HelpModal';

import {
  TravelWrapperFull,
  TravelHeaderSection,
  TravelSectionTitle,
  TravelHeaderButtonsSection,
  TravelHeaderButtonAdd,
  ContentRowWrapper,
  TravelContentTabs,
  TravelInformationsContainer,
  TravelInformationsForm,
  InputTextStyled,
  InputSelectStyled,
  InputObsTextStyled,
  TravelLocationContainer,
  TravelColumnPlaceView,
  TravelRowDateView,
  TravelSwitchButton,
  TravelSwitchView,
  TravelSwitchText,
  TravelAboutSwitchText,
  TravelButtonsContainer,
} from './styled';

import TabTravelPast from './Tabs/TabPast';
import TabTravelUpcoming from './Tabs/TabUpcoming';

const ModeleTravelSeeScreen: React.FC = () => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();

  const user = useSelector((state: RootState) => state.user.actualUser);

  const accentGender = Colors.primary;

  const formik = useRef<FormikProps<any>>(null);

  const [stateIndex, setStateIndex] = useState(0);
  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);
  const [openHelpModal, setOpenHelpModal] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<string | null>(null);

  const [upcomingTravels, setUpcomingTravels] = useState<boolean>(false);
  const [pastTravels, setPastTravels] = useState<boolean>(false);

  const [selectedCountry, setSelectedCountry] = useState<IInputSelect | null>(
    null,
  );
  const [modalCountry, setModalCountry] = useState<boolean>(false);

  const [selectedState, setSelectedState] = useState<IInputSelect | null>(null);
  const [modalState, setModalState] = useState<boolean>(false);

  const [selectedCity, setSelectedCity] = useState<IInputSelect | null>(null);
  const [modalCity, setModalCity] = useState<boolean>(false);

  const [showProfileMyLocation, setShowProfileMyLocation] =
    useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);

  const screenTabs = [
    {
      visible: true,
      name: 'TabTravelUpcoming',
      title: translate(TranslateConfig.NEXT),
      icon: (
        <IconCalendarUpcoming
          color={stateIndex === 0 ? accentGender : Colors.inputCount}
          size={20}
        />
      ),
      component: (
        <TabTravelUpcoming
          loading={loading}
          travels={upcomingTravels}
          onDeleteTabPress={(id: string) => {
            setOpenDeleteModal(id);
          }}
        />
      ),
    },
    {
      visible: true,
      name: 'TabTravelPast',
      title: translate(TranslateConfig.PAST),
      icon: (
        <IconCalendarPast
          color={stateIndex === 1 ? accentGender : Colors.inputCount}
          size={20}
        />
      ),
      component: <TabTravelPast loading={loading} travels={pastTravels} />,
    },
  ];

  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION,
      payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }

  async function getActualModele() {
    setLoading(true);
    dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

    try {
      const response = await GetAllTravelsModele(user?.useralias);

      const responseModele = response?.data;

      if (!responseModele) {
        console.error('Error', response);
        showSnackBarProps(Colors.danger, 'Erro ao fazer o cadastro');
        return;
      }

      setUpcomingTravels(response?.data?.futureTravels);
      setPastTravels(response?.data?.pastTravels);
    }
    catch (error: any) {
      console.error(error);

      const getMessageError = treatErrorAndReturnMessage(error);
      const messageError = translate(getMessageError);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      setLoading(false);
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  }

  function mountPayload() {
    const values = formik?.current?.values;

    const payload: any = {
      country: values.country,
      state: values.state,
      city: values.city,
      startAt: DayJStoIso(values.startAt, 'DD/MM/YYYY'),
      endAt: DayJStoIso(values.endAt, 'DD/MM/YYYY'),
      observation: values.observation,
      showAdMyPlace: showProfileMyLocation,
    };

    return payload;
  }

  function resetForm() {
    setSelectedCountry(null);
    setSelectedState(null);
    setSelectedCity(null);
    setShowProfileMyLocation(false);
    formik.current?.resetForm();
  }

  async function deleteTravel(idComment: number | string) {
    setOpenDeleteModal(null);

    try {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

      const response = await DeleteTravelsModele(idComment, user?.id);

      if (response?.status === EResponseStatus.ERROR) {
        console.error('Error', response);
        showSnackBarProps(Colors.danger, 'Erro ao deletar comentário');
        return;
      }

      showSnackBarProps(Colors.accept, 'Comentário deletado com sucesso');
      getActualModele();
    }
    catch (error: any) {
      console.error(error);

      const getMessageError = treatErrorAndReturnMessage(error);
      const messageError = translate(getMessageError);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  }

  async function saveItemGroup() {
    dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

    const payload = mountPayload();
    const locationUser = user?.location;

    if (
      locationUser?.country === payload?.country &&
      locationUser?.city === payload?.city &&
      locationUser?.state === payload?.state &&
      payload?.city
    ) {
      showSnackBarProps(
        Colors.danger,
        'Não é possivel fazer viagem para cidade padrão',
      );
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
      return;
    }


    try {
      const response = await CreateTravelsModele(user?.id, payload);

      if (!response?.data) {
        console.error('Error', response);
        showSnackBarProps(Colors.danger, 'Erro ao fazer o cadastro');
        return;
      }

      showSnackBarProps(Colors.accept, 'Cadastro realizado com sucesso');
      resetForm();

      await getActualModele();
    }
    catch (error: any) {
      console.error(error);

      const getMessageError = treatErrorAndReturnMessage(error);
      const messageError = translate(getMessageError);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  }

  function renderCreateForm(
    errors: any,
    values: any,
    setFieldValue: any,
    handleChange: any,
    handleBlur: any,
    handleSubmit: any,
  ) {
    const hasLocation =
      user?.location &&
      user?.location?.country &&
      user?.location?.state &&
      user?.location?.city;

    const getUserCountry = user?.location?.country;
    const findCountry = returnCountryArray()?.find((item: IInputSelect) => item.value === getUserCountry);
    const countryUser = findCountry?.label;

    const getStateArray = returnStateArray(getUserCountry);
    const findState = getStateArray?.find((item: IInputSelect) => item.value === user?.location?.state);
    const stateUser = findState?.label;

    const getCityArray = returnCityArray(getUserCountry, findState?.value);
    const findCity = getCityArray?.find((item: IInputSelect) => item.value === user?.location?.city);
    const cityUser = findCity?.label;

    return (
      <TravelInformationsForm>
        <TravelLocationContainer>
          <SectionTitle
            title={'Selecione a localização'}
            subtitle="Você pode compartilhar sua localização de viajem inserindo os locais abaixo."
            color={Colors.primary}
          />

          <TravelColumnPlaceView>
            <InputSelectStyled
              labelText={translate(TranslateConfig.COUNTRY)}
              placeholderText={selectedCountry?.label}
              onPress={() => {
                setModalCountry(true);
              }}
            />

            <InputSelectStyled
              disabled={!selectedCountry}
              labelText={translate(TranslateConfig.STATE_REGION)}
              placeholderText={selectedState?.label}
              onPress={() => {
                setModalState(true);
              }}
            />

            <InputSelectStyled
              disabled={!selectedState}
              labelText={translate(TranslateConfig.CITY)}
              placeholderText={selectedCity?.label}
              onPress={() => {
                setModalCity(true);
              }}
            />
          </TravelColumnPlaceView>
        </TravelLocationContainer>

        <TravelLocationContainer>
          <SectionTitle
            title={'Escolha as datas da viagem'}
            subtitle={
              'Cadastre com antecedência suas viagens para que o Secretio mostre seus anuncios com mais facilidade!'
            }
            color={Colors.primary}
          />

          <TravelRowDateView>
            <InputTextStyled
              type={'DATETIME'}
              options={{
                format: 'DD/MM/YYYY',
              }}
              labelText={'Data de partida'}
              placeholderText={'Data'}
              errorText={errors.startAt}
              countLimit={Values.dateCount}
              value={values.startAt}
              onChange={(_rawText: string, maskedText: string) => {
                setFieldValue('startAt', maskedText);
              }}
              onBlur={handleBlur('startAt')}
            />

            <InputTextStyled
              type={'DATETIME'}
              options={{
                format: 'DD/MM/YYYY',
              }}
              labelText={'Data de chegada'}
              placeholderText={'Data'}
              errorText={errors.endAt}
              countLimit={Values.dateCount}
              value={values.endAt}
              onChange={(_rawText: string, maskedText: string) => {
                setFieldValue('endAt', maskedText);
              }}
              onBlur={handleBlur('endAt')}
            />
          </TravelRowDateView>

          <InputObsTextStyled
            type={'TEXT'}
            labelText={'Anotações'}
            placeholderText={'Anotações'}
            errorText={errors.observation}
            countLimit={Values.notationCount}
            value={values.observation}
            onChange={handleChange('observation')}
            onBlur={handleBlur('observation')}
          />

          {hasLocation && (
            <TravelSwitchButton
              onClick={() => {
                setShowProfileMyLocation(!showProfileMyLocation);
              }}
            >
              <TravelSwitchView>
                <TravelSwitchText>
                  Exibir meu anúncio na minha localização base durante essa
                  viagem
                </TravelSwitchText>

                <TravelAboutSwitchText>
                  Ao marcar essa opção seu perfil também será mostrado em{' '}
                  {cityUser}, {stateUser} - {countryUser} enquando você estiver
                  viajando
                </TravelAboutSwitchText>
              </TravelSwitchView>

              <Switch
                hideText
                selected={showProfileMyLocation}
                size={28}
                color={Colors.white}
                selectedColor={Colors.primary}
              />
            </TravelSwitchButton>
          )}
        </TravelLocationContainer>

        <TravelButtonsContainer>
          <ButtonAction
            title={translate(TranslateConfig.ACTION_SAVE)}
            width={'100%'}
            onPress={() => {
              handleSubmit();
            }}
          />

          {/* {props.showClose && (
            <TravelButtonsCloeAction
              title={'Fechar'}
              width={'100%'}
              onPress={() => {
                if (props.onCLosePress) {
                  props.onCLosePress();
                }
              }}
            />
          )} */}
        </TravelButtonsContainer>
      </TravelInformationsForm>
    );
  }

  useEffect(() => {
    getActualModele();
  }, []);

  return (
    <Formik
      enableReinitialize
      validateOnMount={false}
      innerRef={formik}
      initialValues={{}}
      // validationSchema={CharactersProfileValidationSchema}
      onSubmit={() => {
        // KeyboardModal.dismiss();
        saveItemGroup();
      }}
    >
      {({
        // dirty,
        // isValid,
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        setFieldValue,
      }) => (
        <Screen backgroundColor={Colors.appBackground}>

          <Helmet prioritizeSeoTags>
            <title>
              {`${translate('Viagens')} | ${translate(
                TranslateConfig.NAME_APP,
              )}`}
            </title>

            <meta name="robots" content="noindex,nofollow" />
            <meta name="googlebot" content="noindex,nofollow" />
          </Helmet>


          <Container>
            <HeaderScreen
              disableGender
              backgroundColor={Colors.toolbarBackground}
              subBackgroundColor={Colors.toolbarBackground}
              leftIcon={<ButtonBack color={Colors.white} size={27} />}
              centerContent={<Title color={Colors.white}>Viagens</Title>}
            />

            <Content>
              <TravelWrapperFull>
                <TravelHeaderSection>
                  <TravelSectionTitle
                    title={'Viagens'}
                    subtitle={'Defina suas próximas viagens'}
                    color={accentGender}
                  />

                  <TravelHeaderButtonsSection>
                    <TravelHeaderButtonAdd
                      color={Colors.white}
                      size={30}
                      onPress={() => {
                        setOpenCreateModal(true);
                      }}
                    />

                    <ButtonHelp
                      color={Colors.white}
                      size={24}
                      onPress={() => {
                        setOpenHelpModal(true);
                      }}
                    />
                  </TravelHeaderButtonsSection>
                </TravelHeaderSection>

                <ContentRowWrapper>
                  <TravelContentTabs>
                    <AppBar backgroundColor={Colors.tabBackground}>
                      <TabBarStyled
                        tabs={screenTabs}
                        selected={stateIndex}
                        height={50}
                        color={accentGender}
                        backgroundColor={Colors.tabBackground}
                        onPress={(tab) => {
                          setStateIndex(tab?.index);
                        }}
                      />
                    </AppBar>

                    {stateIndex === 0 && screenTabs[0].component}

                    {stateIndex === 1 && screenTabs[1].component}
                  </TravelContentTabs>

                  <TravelInformationsContainer>
                    {renderCreateForm(
                      errors,
                      values,
                      setFieldValue,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                    )}
                  </TravelInformationsContainer>
                </ContentRowWrapper>
              </TravelWrapperFull>

              <FooterApp />
            </Content>
          </Container>

          <CreateModal
            visible={openCreateModal}
            onClose={() => {
              setOpenCreateModal(false);
            }}
          >
            {renderCreateForm(
              errors,
              values,
              setFieldValue,
              handleChange,
              handleBlur,
              handleSubmit,
            )}
          </CreateModal>

          <HelpTravelModal
            visible={openHelpModal}
            onCancelPress={() => {
              setOpenHelpModal(false);
            }}
          />

          <AlertMessage
            visible={Boolean(openDeleteModal)}
            title={'Deseja deletar essa viagem?'}
            cancelText={'Não'}
            cancelPress={() => {
              setOpenDeleteModal(null);
            }}
            okText={'Sim'}
            okPress={() => {
              deleteTravel(openDeleteModal);
            }}
          />

          <DataSelectModal
            title={'País'}
            visible={modalCountry}
            data={returnCountryArray()}
            selectedValue={selectedCountry?.value}
            setData={(typeReturn: IInputSelect) => {
              setSelectedCountry(typeReturn);
              setFieldValue('country', typeReturn?.value);

              setSelectedState(null);
              setFieldValue('state', '');

              setSelectedCity(null);
              setFieldValue('city', '');
            }}
            onClose={() => {
              setModalCountry(false);
            }}
          />

          <DataSelectModal
            title={'Estado'}
            visible={modalState}
            data={returnStateArray(selectedCountry?.value)}
            selectedValue={selectedState?.value}
            setData={(typeReturn: IInputSelect) => {
              setSelectedState(typeReturn);
              setFieldValue('state', typeReturn?.value);

              setSelectedCity(null);
              setFieldValue('city', '');
            }}
            onClose={() => {
              setModalState(false);
            }}
          />

          <DataSelectModal
            title={'Cidade'}
            visible={modalCity}
            data={returnCityArray(selectedCountry?.value, selectedState?.value)}
            selectedValue={selectedCity?.value}
            setData={(typeReturn: IInputSelect) => {
              setSelectedCity(typeReturn);
              setFieldValue('city', typeReturn?.value);
            }}
            onClose={() => {
              setModalCity(false);
            }}
          />
        </Screen>
      )}
    </Formik>
  );
};

export default ModeleTravelSeeScreen;
