import {
  ILanguageCountry,
} from '../apis/interfaces';

import {
  languagesOption,
} from '../shared/arrays/App/lang-country.array';

import {
  ELanguage,
  EStorage,
} from '../shared/enums';

import {
  defaultLanguage,
  resourcesLanguage,
} from '../shared/translations';

import {
  getLocalStorageItem,
  setLocalStorageItem,
} from '../shared/utils/local-storage.utils';



export function setCurrentLanguage(language: string): void {
  setLocalStorageItem(EStorage.APP_LANGUAGE, `${language}`);
}



export function getCurrentLanguage(): string {
  const localLang = getLocalStorageItem(EStorage.APP_LANGUAGE) || languagesOption[0].languageIso;
  setCurrentLanguage(localLang);

  const findLang = languagesOption.find((item: ILanguageCountry) => item.languageIso === localLang);
  return findLang?.languageIso || '';
}



export function getSystemCurrentLanguage(): ELanguage {
  const currentLang = getCurrentLanguage();
  const hasCurrLang = Object.keys(resourcesLanguage).includes(currentLang);

  // https://gist.github.com/wpsmith/7604842
  const browserNavigator = navigator as any;
  const systemLang: string = browserNavigator?.language || browserNavigator?.userLanguage;
  const treatedLang: string = systemLang ? systemLang.replace('-', '_').toLowerCase() : '';

  const hasLanguage = Object.keys(resourcesLanguage).includes(treatedLang);

  // console.log(`CurrentLang: ${currentLang || '-'}, SystemLang: ${systemLang}, TreatedLang: ${treatedLang}, HasTreatedLangInArray: ${hasLanguage}`);


  if (currentLang && hasCurrLang) {
    return currentLang as ELanguage;
  }

  if ((!systemLang && !treatedLang) || !hasLanguage) {
    return defaultLanguage;
  }

  return treatedLang as ELanguage;
}
