import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useHistory,
} from 'react-router-dom';

import CardAdsSuggestion from '../../../../components/Ads';
import CardModeleSuggestion from '../../../../components/Cards/CardModeleSuggestion';

import Content from '../../../../components/Composh/web/Content';

import FooterApp from '../../../../components/Footers/FooterApp';
import SectionTitle from '../../../../components/Titles/SectionTitle';

import {
  ContentCenterFlex,
  ContentWrapperRowFull,
  WrapperItemContent,
} from '../../../../styles/styled.layout';

import {
  SuggestionModeleItemGrid,
  SuggestionRoomOnlineList,
} from '../../styled';



const TabGeneralRanking: React.FC = () => {
  const history = useHistory();


  const [loading, setLoading] = useState(false);

  const [modeles, setModeles] = useState(null);



  async function loadHome() {
    // const url = '/modeles';
    // const url = 'https://api.npoint.io/4a91d567c122eb0be824';
    // const url = FAKE_LIST;
    // const url = [
    //   {
    //     'id': 3,
    //     'plan': 'GOLD',
    //     'alias': 'Alex Santorinni',
    //     'cover': ProfileScreen2,
    //     'gender': 'MALE',
    //     'verified': true,
    //     'available': false,
    //     'useralias': 'alex_santorinni',
    //   },
    //   {
    //     'id': 4,
    //     'plan': 'GOLD',
    //     'alias': 'Alex Santorinni',
    //     'cover': ProfileScreen2,
    //     'gender': 'MALE',
    //     'verified': false,
    //     'available': false,
    //     'useralias': 'alex_santorinni',
    //   },
    //   {
    //     'id': 5,
    //     'plan': 'GOLD',
    //     'alias': 'Alex Santorinni',
    //     'cover': ProfileScreen2,
    //     'gender': 'MALE',
    //     'verified': true,
    //     'available': false,
    //     'useralias': 'alex_santorinni',
    //   },
    //   {
    //     'id': 6,
    //     'plan': 'GOLD',
    //     'alias': 'Alex Santorinni',
    //     'cover': ProfileScreen2,
    //     'gender': 'MALE',
    //     'verified': false,
    //     'available': false,
    //     'useralias': 'alex_santorinni',
    //   },
    //   {
    //     'id': 7,
    //     'plan': 'GOLD',
    //     'alias': 'Alex Santorinni',
    //     'cover': ProfileScreen2,
    //     'gender': 'MALE',
    //     'verified': false,
    //     'available': false,
    //     'useralias': 'alex_santorinni',
    //   },
    //   {
    //     'id': 8,
    //     'plan': 'GOLD',
    //     'alias': 'Alex Santorinni',
    //     'cover': ProfileScreen2,
    //     'gender': 'MALE',
    //     'verified': false,
    //     'available': false,
    //     'useralias': 'alex_santorinni',
    //   },
    //   {
    //     'id': 9,
    //     'plan': 'GOLD',
    //     'alias': 'Alex Santorinni',
    //     'cover': ProfileScreen2,
    //     'gender': 'MALE',
    //     'verified': false,
    //     'available': false,
    //     'useralias': 'alex_santorinni',
    //   },
    // ];

    try {
      setLoading(true);

      // const response = await ApiWS.get(url, {
      //   cancelToken: source.token,
      // });

      // setModeles(response.data);

      // const response = url;

      // setModeles(response);
    }
    catch (err) {
      console.error(err);
    }
    finally {
      setLoading(false);
    }
  };



  function renderBigLongItem(item, index) {
    return (

      <CardModeleSuggestion
      />

    );
  }



  useEffect(() => {
    loadHome();
  }, []);



  return (

    <Content>

      <ContentWrapperRowFull>

        <ContentCenterFlex>
          <WrapperItemContent>
            <SectionTitle
              title={'Ranking'}
            />


          </WrapperItemContent>
        </ContentCenterFlex>



        <CardAdsSuggestion />

      </ContentWrapperRowFull>



      <FooterApp />

    </Content>

  );
};



export default TabGeneralRanking;
