import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';



export interface IProps {
  backgroundColor?: string;
  color?: string;
  size?: number;
}



export const EditWarningView = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  padding-top: 8px;
  padding-left: 13px;
  padding-right: 13px;
  padding-bottom: 8px;
  width: 100%;
  background-color: ${(props: IProps) => props.backgroundColor};

  border-color: ${(props: IProps) => props.color};
  border-style: solid;
  border-width: 1px;
  
  border-radius: ${Sizes.cardRadius}px;
`;

export const InfoIcon = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10px;
`;

export const WarningInfoView = styled.div`
  display: flex;
  flex-direction: column;
`;

export const EditWarningTitle = styled.p`
  margin-bottom: 3px;
  color: ${(props: IProps) => props.color};
  font-size: ${(props: IProps) => props.size ? props.size * 1.3 : 13}px;
  font-weight: bold;
`;

export const EditWarningDescription = styled.p`
  color: ${Colors.black};
  font-size: ${(props: IProps) => props.size ? props.size * 1.1 : 11}px;
`;
