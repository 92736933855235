import ApiWS from '../index';



export const getBasicInfo = async (data) => {
  const url = '/getinfo';

  const response = await ApiWS.post(url, data);
  return response.data;
};



export const linkedUsers = async () => {
  const result = [];
  // const userData = await getUserProfile();

  // await Promise.all(
  //   userData.messagingUsers.map(async (user) => {
  //     const basicData = await getBasicInfo({ userName: user });
  //     result.push(basicData);
  //   }),
  // );

  return result;
};



export const getMessages = async (userName) => {
  const url = '/message/getmessages';
  const data = { otherUserName: userName };

  const response = await ApiWS.post(url, data);
  return response.data;
};



export const sendMessage = async (userName, text) => {
  const url = '/message/sendmessage';
  const data = { otherUserName: userName, text };

  const response = await ApiWS.post(url, data);
  return response.data;
};
