import React,
{
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  Helmet,
} from 'react-helmet-async';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  Formik,
  FormikProps,
} from 'formik';

import {
  EditRentContactsModele,
} from '../../../apis/endpoints/modeles.endpoints';

import {
  IInputSelect,
} from '../../../apis/interfaces/App/input-select.interface';

import ButtonAction from '../../../components/Buttons/ButtonAction';

import {
  Container,
  Content,
  Screen,
  Title,
} from '../../../components/Composh/web';

import {
  ContentButtonForm,
  ContentPadder,
} from '../../../components/Contents/ContentPadder';

import {
  ButtonBack,
} from '../../../components/Controls';

import FooterApp from '../../../components/Footers/FooterApp';
import HeaderScreen from '../../../components/Headers/HeaderScreen';

import {
  IconPhone,
  IconSocialFacebook,
  IconSocialInstagram,
  IconSocialOnlyFans,
  IconSocialPrivacy,
  IconSocialTelegram,
  IconSocialTiktok,
  IconSocialTwitter,
  IconSocialWebsite,
} from '../../../components/Icons';

import LoadingScreen from '../../../components/Loadings/LoadingScreen';
import SectionTitle from '../../../components/Titles/SectionTitle';
import RequiredText from '../../../components/Titles/TextRequired';

import {
  RootState,
} from '../../../redux';

import {
  APP_MODAL_LOADING_ACTION,
  APP_SNACKBAR_ACTION,
} from '../../../redux/reducers/app-modals.store';

import {
  USER_SET,
} from '../../../redux/reducers/user.store';

import { ddiArray } from '../../../shared/arrays/App/ddi.array';

import {
  Colors,
  Sizes,
  Values,
} from '../../../shared/constants';

import {
  TranslateConfig,
} from '../../../shared/translations';

import {
  treatErrorAndReturnColor,
  treatErrorAndReturnMessage,
} from '../../../shared/utils/errors.utils';

import DdiModal from './DdiModal';

import {
  DetailsTopViewItems,
  DetailsBottomViewItems,
  ContactsInputsContent,
  InputTextStyled,
  InputDdiTextStyled,
  InputPhoneStyled,
  InputButtonCheckboxStyled,
  InputSwitchStyled,
  CardContactsForm,
  ContactsAttendanceOptionsContent,
} from './styled';
import { IPhoneDDI } from '../../../apis/interfaces/Phones/phone-ddi.interface';



const ContactsModeleScreen: React.FC = () => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();


  const user = useSelector((state: RootState) => state.user.actualUser);


  const formik = useRef<FormikProps<any>>(null);
  const charIconsSize = 20;

  const [selectedDdi, setSelectedDdi] = useState<IInputSelect | null>(null);
  const [modalDdi, setModalDdi] = useState<boolean>(false);

  const [primaryNumberWhatsApp, setPrimaryNumberWhatsApp] = useState<boolean>(false);
  const [showNumberPhone, setShowNumberPhone] = useState<boolean>(false);
  const [showSocials, setShowSocials] = useState<boolean>(false);
  const [phone24h, setPhone24h] = useState<boolean>(false);

  const [loading, setLoading] = useState(false);
  const [mounted, setMounted] = useState(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION, payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  function updateForm() {
    if (formik?.current) {
      const canCheck24h = user?.params?.rent
        || user?.params?.fanClub
        || user?.params?.live
        || user?.params?.massage;

      setPhone24h(canCheck24h && user?.contacts?.phoneNumber24h ? user?.contacts?.phoneNumber24h : false);
      formik.current?.setFieldValue('phoneNumber24h', canCheck24h && user?.contacts?.phoneNumber24h);

      setShowNumberPhone(user?.contacts?.showPhoneNumber ? user?.contacts?.showPhoneNumber : false);
      formik.current?.setFieldValue('showPhoneNumber', user?.contacts?.showPhoneNumber);

      setShowSocials(user?.contacts?.showSocials ? user?.contacts?.showSocials : false);
      formik.current?.setFieldValue('showSocials', user?.contacts?.showSocials);

      const findDdi = ddiArray?.find((item: IPhoneDDI) => item?.value === Number(user?.contacts?.phoneDdi));
      formik.current?.setFieldValue('phoneDdi', findDdi?.value);
      setSelectedDdi(findDdi || null);

      formik.current?.setFieldValue('phoneNumber', user?.contacts?.phoneNumber);
      formik.current?.setFieldValue('phoneNumberWhatsapp', user?.contacts?.phoneNumberWhatsapp);
      setPrimaryNumberWhatsApp(user?.contacts?.phoneNumberWhatsapp ? user?.contacts?.phoneNumberWhatsapp : false);

      formik.current?.setFieldValue('instagram', user?.contacts?.instagram);
      formik.current?.setFieldValue('twitter', user?.contacts?.twitter);
      formik.current?.setFieldValue('facebook', user?.contacts?.facebook);
      formik.current?.setFieldValue('tiktok', user?.contacts?.tiktok);
      formik.current?.setFieldValue('telegram', user?.contacts?.telegram);

      formik.current?.setFieldValue('onlyfans', user?.contacts?.onlyfans);
      formik.current?.setFieldValue('privacy', user?.contacts?.privacy);
      formik.current?.setFieldValue('website', user?.contacts?.website);

      formik.current?.validateForm();
    }

    setTimeout(() => {
      setMounted(true);
    }, Values.mountTime);
  }


  function mountPayload() {
    const values = formik?.current?.values;

    const payload: any = {
      phoneNumber24h: values.phoneNumber24h,

      showPhoneNumber: values.showPhoneNumber,
      showSocials: values.showSocials,

      phoneDdi: values.phoneDdi,
      phoneNumber: values.phoneNumber,
      phoneNumberWhatsapp: values.phoneNumberWhatsapp,

      instagram: values.instagram,
      twitter: values.twitter,
      facebook: values.facebook,
      tiktok: values.tiktok,
      telegram: values.telegram,

      onlyfans: values.onlyfans,
      privacy: values.privacy,
      website: values.website,
    };

    return payload;
  }


  async function saveItemGroup() {
    setLoading(true);
    dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

    const payload = mountPayload();

    try {
      const response = await EditRentContactsModele(user?.useralias, payload);

      if (!response?.data) {
        console.error('Error', response);
        showSnackBarProps(Colors.danger, 'Erro ao fazer o cadastro');
        return;
      }

      showSnackBarProps(Colors.accept, 'Cadastro realizado com sucesso');
      dispatch({ type: USER_SET, payload: response?.data });
    }
    catch (error: any) {
      console.error(error);

      const getMessageError = treatErrorAndReturnMessage(error);
      const messageError = translate(getMessageError);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      setLoading(false);
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  }


  function renderLoading() {
    return (

      <LoadingScreen
        type={'SCREEN'}
        color={Colors.white}
      />

    );
  }


  function returnMaskPhoneType() {
    if (Number(selectedDdi?.value) === 55) {
      return {
        maskType: 'BRL',
        withDDD: true,
        dddMask: '(99) ',
      };
    }

    return {
      maskType: 'INTERNATIONAL',
      withDDD: false,
    };
  }



  useEffect(() => {
    updateForm();
  }, [formik]);



  return (

    <Formik
      enableReinitialize
      validateOnMount={false}
      innerRef={formik}
      initialValues={{}}
      // validationSchema={CharactersProfileValidationSchema}
      onSubmit={() => {
        // KeyboardModal.dismiss();
        saveItemGroup();
      }}>
      {({
        // dirty,
        // isValid,
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        setFieldValue,
      }) => (

        <Screen
          backgroundColor={Colors.appBackground}>


          <Helmet prioritizeSeoTags>
            <title>
              {`${translate('Contatos / Disponibilidade')} | ${translate(TranslateConfig.NAME_APP)}`}
            </title>

            <meta name="robots" content="noindex,nofollow" />
            <meta name="googlebot" content="noindex,nofollow" />
          </Helmet>



          <Container>

            <HeaderScreen
              disableGender
              backgroundColor={Colors.toolbarBackground}
              subBackgroundColor={Colors.toolbarBackground}
              leftIcon={
                <ButtonBack
                  color={Colors.white}
                  size={27}
                />
              }
              centerContent={
                <Title
                  color={Colors.white}>
                  Contatos / Disponibilidade
                </Title>
              }
            />



            <Content>

              {!mounted && (
                renderLoading()
              )}



              {mounted && (
                <ContentPadder>

                  <CardContactsForm
                    backgroundColor={Colors.formBackground}
                    borderRadius={Sizes.cardRadius}>

                    <RequiredText>
                      * {'Campos obrigatórios'}
                    </RequiredText>



                    <DetailsTopViewItems>

                      <SectionTitle
                        title={'Contato profissional'}
                        subtitle={'Esse é o número que aparecerá no seu perfil para que entrem em contato com você'}
                        color={Colors.primary}
                      />


                      <ContactsInputsContent>

                        <InputDdiTextStyled
                          disabled={loading || !showNumberPhone}
                          labelText={`${'Código do país'}`}
                          placeholderText={selectedDdi?.value && selectedDdi?.label
                            ? `+${selectedDdi?.value} - ${selectedDdi?.label}`
                            : ''
                          }
                          onPress={() => {
                            setModalDdi(true);
                          }}
                        />


                        <InputPhoneStyled
                          disabled={loading || !showNumberPhone || !selectedDdi}
                          type={'PHONE'}
                          iconLeft={
                            <IconPhone
                              color={Colors.white}
                              size={charIconsSize}
                            />
                          }
                          options={returnMaskPhoneType()}
                          labelText={`${'Número de telefone'}`}
                          placeholderText={'Apenas números'}
                          errorText={errors.phoneNumber}
                          countLimit={Values.phoneMaxCount}
                          value={values.phoneNumber}
                          onChange={(rawText: string) => {
                            setFieldValue('phoneNumber', rawText);

                            if (rawText?.length === 0) {
                              setPrimaryNumberWhatsApp(false);
                              setFieldValue('phoneNumberWhatsapp', false);
                            }
                          }}
                          onBlur={handleBlur('phoneNumber')}
                        />


                        <InputButtonCheckboxStyled
                          disabled={values.phoneNumber?.length <= 0 || !showNumberPhone}
                          title={'Este número é WhatsApp'}
                          checked={primaryNumberWhatsApp}
                          color={Colors.primaryDark}
                          onPress={() => {
                            setPrimaryNumberWhatsApp(!primaryNumberWhatsApp);
                            setFieldValue('phoneNumberWhatsapp', !primaryNumberWhatsApp);
                          }}
                        />

                      </ContactsInputsContent>



                      {/* TODO: Fazer o verificar telefone */}

                    </DetailsTopViewItems>



                    <DetailsBottomViewItems>

                      <SectionTitle
                        title={'Disponibilidade'}
                        color={Colors.primary}
                      />


                      <ContactsAttendanceOptionsContent>
                        <InputButtonCheckboxStyled
                          disabled={!user?.params?.rent
                            && !user?.params?.fanClub
                            && !user?.params?.live
                            && !user?.params?.massage
                          }
                          checked={phone24h}
                          title={'Disponibilidade 24h'}
                          helpText={!user?.params?.rent
                            && !user?.params?.fanClub
                            && !user?.params?.live
                            && !user?.params?.massage
                            ? 'É necessario um serviço ativo'
                            : ''
                          }
                          color={Colors.primaryDark}
                          onPress={() => {
                            setPhone24h(!phone24h);
                            setFieldValue('phoneNumber24h', !phone24h);
                          }}
                        />
                      </ContactsAttendanceOptionsContent>

                    </DetailsBottomViewItems>



                    <DetailsBottomViewItems>

                      <SectionTitle
                        title={'Visibilidade dos contatos'}
                        subtitle={'Gerencie quais contatos serão mostrados no seu perfil.'}
                        color={Colors.primary}
                      />


                      <ContactsInputsContent>

                        <InputSwitchStyled
                          disabled={loading}
                          selected={showNumberPhone}
                          title={'Telefone'}
                          description={'Mostrar telefone'}
                          onPress={() => {
                            setShowNumberPhone(!showNumberPhone);
                            setFieldValue('showPhoneNumber', !showNumberPhone);
                          }}
                        />



                        <InputSwitchStyled
                          disabled={loading}
                          selected={showSocials}
                          title={'Redes sociais'}
                          description={'Mostrar redes sociais'}
                          onPress={() => {
                            setShowSocials(!showSocials);
                            setFieldValue('showSocials', !showSocials);
                          }}
                        />

                      </ContactsInputsContent>

                    </DetailsBottomViewItems>



                    <DetailsBottomViewItems>

                      <SectionTitle
                        title={'Redes sociais'}
                      />


                      <ContactsInputsContent>

                        <InputTextStyled
                          disabled={loading}
                          autoCorrect
                          autoCapitalize={'words'}
                          type={'TEXT'}
                          iconLeft={
                            <IconSocialInstagram
                              color={Colors.white}
                              size={charIconsSize}
                            />
                          }
                          labelText={'Instagram'}
                          placeholderText={'Instagram'}
                          helpText={'Coloque apenas o nome de usuário'}
                          errorText={errors.instagram}
                          helpColor={errors.instagram ? Colors.danger : Colors.inputCount}
                          countLimit={Values.linkCount}
                          value={values.instagram}
                          onChange={handleChange('instagram')}
                          onBlur={handleBlur('instagram')}
                        />


                        <InputTextStyled
                          disabled={loading}
                          autoCorrect
                          autoCapitalize={'words'}
                          type={'TEXT'}
                          iconLeft={
                            <IconSocialTwitter
                              color={Colors.white}
                              size={charIconsSize}
                            />
                          }
                          labelText={'X (Twitter)'}
                          placeholderText={'X (Twitter)'}
                          helpText={'Coloque apenas o nome de usuário'}
                          errorText={errors.twitter}
                          helpColor={errors.twitter ? Colors.danger : Colors.inputCount}
                          countLimit={Values.linkCount}
                          value={values.twitter}
                          onChange={handleChange('twitter')}
                          onBlur={handleBlur('twitter')}
                        />


                        <InputTextStyled
                          disabled={loading}
                          autoCorrect
                          autoCapitalize={'words'}
                          type={'TEXT'}
                          iconLeft={
                            <IconSocialFacebook
                              color={Colors.white}
                              size={charIconsSize}
                            />
                          }
                          labelText={'Facebook'}
                          placeholderText={'Facebook'}
                          helpText={'Coloque apenas o nome de usuário'}
                          errorText={errors.facebook}
                          helpColor={errors.facebook ? Colors.danger : Colors.inputCount}
                          countLimit={Values.linkCount}
                          value={values.facebook}
                          onChange={handleChange('facebook')}
                          onBlur={handleBlur('facebook')}
                        />


                        <InputTextStyled
                          disabled={loading}
                          autoCorrect
                          autoCapitalize={'words'}
                          type={'TEXT'}
                          iconLeft={
                            <IconSocialTiktok
                              color={Colors.white}
                              size={charIconsSize}
                            />
                          }
                          labelText={'Tiktok'}
                          placeholderText={'Tiktok'}
                          helpText={'Coloque apenas o nome de usuário'}
                          errorText={errors.tiktok}
                          helpColor={errors.tiktok ? Colors.danger : Colors.inputCount}
                          countLimit={Values.linkCount}
                          value={values.tiktok}
                          onChange={handleChange('tiktok')}
                          onBlur={handleBlur('tiktok')}
                        />


                        <InputTextStyled
                          disabled={loading}
                          autoCorrect
                          autoCapitalize={'words'}
                          type={'TEXT'}
                          iconLeft={
                            <IconSocialTelegram
                              color={Colors.white}
                              size={charIconsSize}
                            />
                          }
                          labelText={'Telegram'}
                          placeholderText={'Telegram'}
                          helpText={'Coloque apenas o nome de usuário'}
                          errorText={errors.telegram}
                          helpColor={errors.telegram ? Colors.danger : Colors.inputCount}
                          countLimit={Values.linkCount}
                          value={values.telegram}
                          onChange={handleChange('telegram')}
                          onBlur={handleBlur('telegram')}
                        />

                      </ContactsInputsContent>
                    </DetailsBottomViewItems>



                    <DetailsBottomViewItems>

                      <SectionTitle
                        title={'Plataformas'}
                      />


                      <ContactsInputsContent>

                        <InputTextStyled
                          disabled={loading}
                          autoCorrect
                          autoCapitalize={'words'}
                          type={'TEXT'}
                          iconLeft={
                            <IconSocialOnlyFans
                              color={Colors.white}
                              size={charIconsSize}
                            />
                          }
                          labelText={'Onlyfans'}
                          placeholderText={'Onlyfans'}
                          helpText={'Coloque apenas o nome de usuário'}
                          errorText={errors.onlyfans}
                          helpColor={errors.onlyfans ? Colors.danger : Colors.inputCount}
                          countLimit={Values.linkCount}
                          value={values.onlyfans}
                          onChange={handleChange('onlyfans')}
                          onBlur={handleBlur('onlyfans')}
                        />


                        <InputTextStyled
                          disabled={loading}
                          autoCorrect
                          autoCapitalize={'words'}
                          type={'TEXT'}
                          iconLeft={
                            <IconSocialPrivacy
                              color={Colors.white}
                              size={charIconsSize}
                            />
                          }
                          labelText={'Privacy'}
                          placeholderText={'Privacy'}
                          helpText={'Coloque apenas o nome de usuário'}
                          errorText={errors.privacy}
                          helpColor={errors.privacy ? Colors.danger : Colors.inputCount}
                          countLimit={Values.linkCount}
                          value={values.privacy}
                          onChange={handleChange('privacy')}
                          onBlur={handleBlur('privacy')}
                        />


                        <InputTextStyled
                          disabled={loading}
                          autoCorrect
                          autoCapitalize={'words'}
                          type={'TEXT'}
                          iconLeft={
                            <IconSocialWebsite
                              color={Colors.white}
                              size={charIconsSize}
                            />
                          }
                          labelText={'Website'}
                          placeholderText={'Link'}
                          helpText={'Coloque o link completo'}
                          errorText={errors.website}
                          helpColor={errors.website ? Colors.danger : Colors.inputCount}
                          countLimit={Values.linkCount}
                          value={values.website}
                          onChange={handleChange('website')}
                          onBlur={handleBlur('website')}
                        />

                      </ContactsInputsContent>
                    </DetailsBottomViewItems>



                    <ContentButtonForm>
                      <ButtonAction
                        width={Sizes.buttonMinWidth}
                        title={'Salvar'}
                        onPress={() => {
                          handleSubmit();
                        }}
                      />
                    </ContentButtonForm>

                  </CardContactsForm>

                </ContentPadder>
              )}



              <FooterApp />

            </Content>

          </Container>



          <DdiModal
            visible={modalDdi}
            setData={(typeReturn: IInputSelect) => {
              setSelectedDdi(typeReturn);
              setFieldValue('phoneDdi', typeReturn?.value);
            }}
            onClose={() => {
              setModalDdi(false);
            }}
          />

        </Screen>

      )}
    </Formik>

  );
};



export default ContactsModeleScreen;
