import React,
{
  useEffect,
  useState,
} from 'react';

import {
  Helmet,
} from 'react-helmet-async';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  ChangeVisibleReview,
  GetModeleReviews,
  UpdateAutoVisibleComments,
} from '../../../apis/endpoints/reviews-modele.endpoints';

import AlertMessage from '../../../components/Alerts';
import CardComment from '../../../components/Cards/CardComment';

import {
  Container,
  Content,
  Screen,
  Title,
} from '../../../components/Composh/web';

import {
  ContentPadder,
} from '../../../components/Contents/ContentPadder';

import {
  ButtonBack,
} from '../../../components/Controls';

import EmptyContent from '../../../components/Empty';
import FooterApp from '../../../components/Footers/FooterApp';
import HeaderScreen from '../../../components/Headers/HeaderScreen';
import LoadingScreen from '../../../components/Loadings/LoadingScreen';

import {
  RootState,
} from '../../../redux';

import {
  APP_MODAL_LOADING_ACTION,
  APP_SNACKBAR_ACTION,
} from '../../../redux/reducers/app-modals.store';

import {
  USER_SET,
} from '../../../redux/reducers/user.store';

import {
  Colors,
  Images,
} from '../../../shared/constants';

import EStatusVisible from '../../../shared/enums/Status/status-visibility.enum';

import {
  TranslateConfig,
} from '../../../shared/translations';

import {
  treatErrorAndReturnColor,
  treatErrorAndReturnMessage,
} from '../../../shared/utils/errors.utils';

import {
  TitleModeleReviewContainer,
  TitleModeleReviewLengthView,
  TitleModeleReviewLengthText,
  TitleModeleReviewLengthHelpText,
  TitleModeleReviewAverageText,
  ButtonAutomaticPublishStyled,
  ModeleReviewScrollFlatList,
} from './styled';



const ReviewModeleScreen: React.FC = () => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();


  const user = useSelector((state: RootState) => state.user.actualUser);
  const userIdSearch = user?.useralias;


  const [reviewsModele, setReviewsModele] = useState<Array<any>>([]);

  const [autoVisibleModule, setautoVisibleModule] = useState<boolean>(user?.params?.autoVisibleComments);
  const [invisibleCommentModal, setInvisibleCommentModal] = useState<number | null>(null);

  const [loading, setLoading] = useState(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION, payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  async function getModeleComments() {
    setLoading(true);

    try {
      const response = await GetModeleReviews(userIdSearch);

      const responseModele = response?.data;

      if (!responseModele) {
        console.error('Error', response);
        setReviewsModele([]);
        showSnackBarProps(Colors.danger, translate(TranslateConfig.ERROR_GET_DATA));
        return;
      }

      setReviewsModele(responseModele);
    }
    catch (error: any) {
      console.error(error);
      setReviewsModele([]);

      const getMessageError = treatErrorAndReturnMessage(error);
      const messageError = translate(getMessageError);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      setLoading(false);
    }
  }


  async function saveItemGroup(payload: any) {
    try {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

      const response = await UpdateAutoVisibleComments(user?.useralias, payload);

      if (!response?.data) {
        console.error('Error', response);
        showSnackBarProps(Colors.danger, translate(TranslateConfig.ERROR_SAVE));
        return;
      }

      setautoVisibleModule(response?.data?.params?.autoVisibleComments);
      dispatch({ type: USER_SET, payload: response?.data });

      showSnackBarProps(Colors.accept, translate(TranslateConfig.SUCCESS_SAVE));
    }
    catch (error: any) {
      console.error(error);

      const getMessageError = treatErrorAndReturnMessage(error);
      const messageError = translate(getMessageError);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  }


  async function changeVisibleComment(idComment: number) {
    setInvisibleCommentModal(null);

    try {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

      const response = await ChangeVisibleReview(idComment);

      if (!response?.data) {
        console.error('Error', response);
        showSnackBarProps(Colors.danger, translate(TranslateConfig.ERROR_SAVE));
        return;
      }

      showSnackBarProps(Colors.accept, translate(TranslateConfig.SUCCESS_SAVE));
      getModeleComments();
    }
    catch (error: any) {
      console.error(error);

      const getMessageError = treatErrorAndReturnMessage(error);
      const messageError = translate(getMessageError);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  }


  function renderItem(item: any, index: number) {
    return (

      <CardComment
        key={index}
        edit
        type={'MODELE'}
        visible={item?.visible}
        alias={item?.userMake?.alias}
        plan={item?.userMake?.plan?.planCurrent}
        image={item?.userMake?.picture}
        createdAt={item?.createdAt}
        rating={item?.rating}
        dateMeet={item?.date}
        comment={item?.comment}
        meet={item?.meet}
        ambience={item?.ambience}
        medias={item?.medias}
        recommend={item?.recommend}

        makeAlias={item?.userMake?.alias}
        makePlan={item?.userMake?.plan?.planCurrent}
        makeImage={item?.userMake?.picture}

        onVisibleOrRevisdPress={() => {
          if (item?.visible === EStatusVisible.VISIBLE) {
            setInvisibleCommentModal(item?.id);
          }
          else {
            changeVisibleComment(item?.id);
          }
        }}
      />

    );
  }


  function renderCommentNumberTitle(): string {
    const numberToCount = reviewsModele?.length;

    if (loading) {
      return '';
    }

    if (numberToCount <= 0) {
      return translate(TranslateConfig.EMPTY_DASH_COMMENT);
    }

    if (numberToCount === 1) {
      return `1 ${translate(TranslateConfig.COMMENT)}`;
    }

    if (numberToCount > 1) {
      return `${numberToCount} ${translate(TranslateConfig.COMMENTS)}`;
    }

    return '';
  }


  function listHeaderComponent() {
    return (

      <TitleModeleReviewContainer>

        <TitleModeleReviewLengthView>

          <ButtonAutomaticPublishStyled
            disabled={loading}
            selected={autoVisibleModule}
            textOn={translate(TranslateConfig.ACTION_PUSBLISH_AUTO_ON)}
            textOff={translate(TranslateConfig.ACTION_PUSBLISH_AUTO_OFF)}
            color={Colors.primary}
            onPress={() => {
              if (!loading) {
                saveItemGroup({ autoVisibleComments: !autoVisibleModule });
              }
            }}
          />


          {renderCommentNumberTitle() && (
            <TitleModeleReviewLengthText>
              {renderCommentNumberTitle()}
            </TitleModeleReviewLengthText>
          )}

        </TitleModeleReviewLengthView>


        <TitleModeleReviewLengthHelpText>
          {translate(TranslateConfig.COMMENT_AUTO_TEXT_1)}
        </TitleModeleReviewLengthHelpText>


        <TitleModeleReviewAverageText>
          {translate(TranslateConfig.COMMENT_AUTO_TEXT_2)}
        </TitleModeleReviewAverageText>

      </TitleModeleReviewContainer>

    );
  }


  function renderLoading() {
    return (

      <LoadingScreen
        type={'SCREEN'}
        color={Colors.white}
      />

    );
  }


  function listEmptyComponent() {
    return (

      <EmptyContent
        image={Images.noComments}
        title={translate(TranslateConfig.EMPTY_COMMENTS)}
      />

    );
  }



  useEffect(() => {
    getModeleComments();
  }, []);



  return (

    <Screen
      backgroundColor={Colors.appBackground}>


      <Helmet prioritizeSeoTags>
        <title>
          {`${translate(TranslateConfig.COMMENTS)} | ${translate(TranslateConfig.NAME_APP)}`}
        </title>

        <meta name="robots" content="noindex,nofollow" />
        <meta name="googlebot" content="noindex,nofollow" />
      </Helmet>



      <Container>

        <HeaderScreen
          disableGender
          backgroundColor={Colors.toolbarBackground}
          subBackgroundColor={Colors.toolbarBackground}
          leftIcon={
            <ButtonBack
              color={Colors.white}
              size={27}
            />
          }
          centerContent={
            <Title
              color={Colors.white}>
              {translate(TranslateConfig.COMMENTS)}
            </Title>
          }
        />



        <Content>

          <ContentPadder>

            {listHeaderComponent()}


            <ModeleReviewScrollFlatList
              noScroll
              loading={loading}
              data={reviewsModele}
              renderItem={renderItem}
              listLoadingComponent={renderLoading()}
              listEmptyComponent={listEmptyComponent()}
            />

          </ContentPadder>



          <FooterApp />

        </Content>

      </Container>



      <AlertMessage
        visible={Boolean(invisibleCommentModal)}
        title={translate(TranslateConfig.QUESTION_COMMENT_HIDE)}
        cancelText={translate(TranslateConfig.NO)}
        cancelPress={() => {
          setInvisibleCommentModal(null);
        }}
        okText={translate(TranslateConfig.YES)}
        okPress={() => {
          changeVisibleComment(invisibleCommentModal);
        }}
      />

    </Screen>

  );
};



export default ReviewModeleScreen;
