import React,
{
  useEffect,
  useState,
} from 'react';

import {
  Helmet,
} from 'react-helmet-async';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import {
  EditParamsModele,
  GetModeleProfissionalPanel,
  ShowHideProfileModele,
} from '../../../apis/endpoints/modeles.endpoints';

import {
  IInputSelect,
} from '../../../apis/interfaces/App/input-select.interface';

import AlertMessage from '../../../components/Alerts';
import CardPostCreate from '../../../components/Cards/Posts/CardPostCreate';

import {
  Container,
  Content,
  Screen,
  Title,
} from '../../../components/Composh/web';

import {
  ContentRowToColumnPadder,
} from '../../../components/Contents/ContentPadder';

import UserAvatar from '../../../components/Contents/UserAvatar';

import {
  ButtonDrawer,
} from '../../../components/Controls';

import ButtonPanelLocation from '../../../components/Details/ButtonPanelLocation';
import ButtonPanelTravels from '../../../components/Details/ButtonPanelTravels';
import ButtonPanelUser from '../../../components/Details/ButtonPanelUser';
import FooterApp from '../../../components/Footers/FooterApp';
import HeaderScreen from '../../../components/Headers/HeaderScreen';

import {
  IconAdsPersonalBanner,
  IconAdsModeleBoost,
  IconAdsRentOfDay,
  IconAdsRentSponsor,
  IconAntiFake,
  IconFaClub,
  IconLive,
  IconLocked,
  IconMediaComplete,
  IconPhone,
  IconUpgrade,
  IconUser,
  IconVisibility,
  IconRentOn,
  IconMassageOn,
  IconCircleCheck,
  IconCircleRemove,
  IconVerified,
  IconVerifiedPromoter,
  IconProfile,
  IconStar,
  IconCircleAttention,
  IconEyeOff,
  IconEyeOn,
  IconAdsRentAttention,
  IconAdsRentLevel,
  IconPlane,
  IconLinkBio,
  IconWarning,
} from '../../../components/Icons';

import LoadingScreen from '../../../components/Loadings/LoadingScreen';
import SectionTitle from '../../../components/Titles/SectionTitle';

import {
  SetGenreSingularText,
  SetSuitsGenderImage,
} from '../../../config/genre.config';

import NameRoutes from '../../../navigation/names';

import {
  RootState,
} from '../../../redux';

import {
  PROJECT_CONFIGS_MODULES_SET,
} from '../../../redux/reducers/app-configs.store';

import {
  APP_MENU_SELECTED_ACTION,
  APP_SNACKBAR_ACTION,
  APP_MODAL_LOADING_ACTION,
  USER_VERIFIED_MODAL,
  USER_BRAND_PROMOTER_MODAL,
} from '../../../redux/reducers/app-modals.store';

import {
  USER_SET,
} from '../../../redux/reducers/user.store';

import { countriesOption } from '../../../shared/arrays/App/countries.array';
import { plansFinancialArray } from '../../../shared/arrays/Financial/plans.array';
import { userStatusArray } from '../../../shared/arrays/User/user-status.array';

import {
  Colors,
  Images,
  Sizes,
} from '../../../shared/constants';

import {
  EEnvsName,
  EPlansUser,
} from '../../../shared/enums';

import EDrawerMenu from '../../../shared/enums/App/drawer-menu.enum';
import EAppMediaVisibility from '../../../shared/enums/Status/app-media-visibility.enum';
import EStatusVisible from '../../../shared/enums/Status/status-visibility.enum';
import EStatusProfile from '../../../shared/enums/Users/status-profile.enum';
import EUserType from '../../../shared/enums/Users/user-type.enum';

import Vars from '../../../shared/environments/variables';

import Seo from '../../../shared/seo';

import {
  TranslateConfig,
} from '../../../shared/translations';

import {
  returnCityArray,
  returnCountryArray,
  returnStateArray,
} from '../../../shared/utils/address.utils';

import {
  FormatExtenseDate,
} from '../../../shared/utils/date.utils';

import {
  treatErrorAndReturnColor,
  treatErrorAndReturnMessage,
} from '../../../shared/utils/errors.utils';

import {
  stringRawDataText,
  validateNumber,
  validateString,
} from '../../../shared/utils/string.utils';

import {
  PanelContentCenterFlex,
  WarningTextsContainer,
  WarningItemStyled,
  DetailsApproveViewBackground,
  HeaderUserBackgroundOverlay,
  DetailsApproveBackground,
  DetailsApproveViewImage,
  PanelPadderContent,
  PanelAvatarItemInfo,
  PanelAvatarName,
  PanelUsernameValuesView,
  PanelAvatarUsername,
  HomeIdentiyIcon,
  PanelWrapperItemContent,
  PanelWrapperLastItemContent,
  PanelActiveItemContent,
  ButtonPublishStyled,
  PanelResumeContent,
  PanelResumeTitle,
  PanelLocationsFlag,
  PanelResumeText,
  ModeleTravelBadgeView,
  PanelResumeLocationContainer,
  PanelResumeLevelContainer,
  PanelResumeLevelView,
  PanelResumeLevelImage,
  PanelValuesContainer,
  PanelValuesContent,
  PanelValuesLabel,
  PanelValuesText,
  PanelValuesPublishView,
  PanelValuesPublishIcon,
  PanelValuesPublishText,
  PanelOptionsContainer,
  PanelUpgradeContainer,
  PanelItemsButton,
  PanelHighlightButton,
  PanelItemsIcon,
  PanelItemsText,
  PanelInputHelpText,
  CommentsCountContainer,
  CommentsCountIcon,
  CommentsCountText,
  FinancialRightSuggestion,
  SuggestionViewButton,
  SuggestionTitle,
  SuggestionText,
  FinancialAdsImageButton,
  FinancialAdsAlButton,
  FinancialTitle,
  FinancialAdsTextBold,
  FinancialAdsBold,
  FinancialAdsNormalText,
} from './styled';



const PanelModeleScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t: translate } = useTranslation();


  const projectParams = useSelector((state: RootState) => state.appConfigs.modules);

  const user = useSelector((state: RootState) => state.user.actualUser);
  const userIdSearch = user?.useralias;


  const [configModulesParams, setConfigModulesParams] = useState<any>(null);
  const [messagesReceived, setMessagesReceived] = useState<Array<any>>([]);

  const [rentModule, setRentModule] = useState<boolean>(false);
  const [fanClubModule, setFanClubModule] = useState<boolean>(false);
  const [liveModule, setLiveModule] = useState<boolean>(false);
  const [massageModule, setMassageModule] = useState<boolean>(false);
  const [streamModule, setStreamModule] = useState<boolean>(false);

  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION,
      payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  function setStatesParams(paramsReceived: any) {
    setRentModule(paramsReceived?.rent);
    setLiveModule(paramsReceived?.live);
    setFanClubModule(paramsReceived?.fanClub);
    setMassageModule(paramsReceived?.massage);
    setStreamModule(paramsReceived?.stream);
  }


  function appProjectParamsValues(dataParams: any) {
    setConfigModulesParams(dataParams);
    dispatch({ type: PROJECT_CONFIGS_MODULES_SET, payload: dataParams });
  }


  async function getActualModele() {
    try {
      setLoading(true);

      const response = await GetModeleProfissionalPanel(userIdSearch);
      const responseModele = response?.data;

      if (!responseModele) {
        console.error('Error', response);
        showSnackBarProps(Colors.danger, translate(TranslateConfig.ERROR_GET_DATA));
        return;
      }

      appProjectParamsValues(response?.data?.configs?.modules);

      setMessagesReceived(responseModele?.configs?.messages);

      setStatesParams(responseModele?.modele?.params);
      dispatch({ type: USER_SET, payload: responseModele?.modele });
    }
    catch (error: any) {
      console.error(error);

      const getMessageError = treatErrorAndReturnMessage(error);
      const messageError = translate(getMessageError);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      setLoading(false);
    }
  }


  async function saveItemGroup(payload: any) {
    try {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

      const response = await EditParamsModele(userIdSearch, payload);

      if (!response?.data) {
        console.error('Error', response);
        showSnackBarProps(Colors.danger, translate(TranslateConfig.ERROR_SAVE));
        return;
      }

      setStatesParams(response?.data?.params);
      dispatch({ type: USER_SET, payload: response?.data });

      showSnackBarProps(Colors.accept, translate(TranslateConfig.SUCCESS_SAVE));
    }
    catch (error: any) {
      console.error(error);

      const getMessageError = treatErrorAndReturnMessage(error);
      const messageError = translate(getMessageError);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  }


  function renderPanelUser() {
    return (
      <ButtonPanelUser />
    );
  }


  function renderPanelButton(title: string, icon: any, onPress: any, accent = false, disabled = false, information = '') {
    return (

      <PanelItemsButton
        accent={accent}
        disabled={disabled}
        onPress={onPress}>

        <PanelItemsIcon>
          {icon}
        </PanelItemsIcon>


        <PanelItemsText>
          {title}
        </PanelItemsText>


        {information && (
          <PanelInputHelpText>
            {information}
          </PanelInputHelpText>
        )}

      </PanelItemsButton>

    );
  }


  function renderLocation() {
    const hasLocation = user?.location
      && user?.location?.country
      && user?.location?.state
      && user?.location?.city;

    const countryUser = user?.location?.country;

    const getStateArray = returnStateArray(countryUser);
    const findState = getStateArray?.find((item: IInputSelect) => item.value === user?.location?.state);
    const stateUser = findState?.label;

    const getCityArray = returnCityArray(countryUser, findState?.value);
    const findCity = getCityArray?.find((item: IInputSelect) => item.value === user?.location?.city);
    const cityUser = findCity?.label;


    return (

      <ButtonPanelLocation
        title={translate(TranslateConfig.LOCATION)}
        country={hasLocation ? countryUser : ''}
        place={hasLocation
          ? `${cityUser}, ${stateUser}`
          : translate(TranslateConfig.EMPTY_LOCATION)
        }
        onPress={() => {
          history.push(NameRoutes.ModeleLocationFormSection);
        }}
      />

    );
  }


  function renderAtualLocation() {
    // Obtenha a data atual
    const currentDate = new Date();

    // Verifique se o usuário está em uma viagem atualmente
    const currentTravel = user?.appTravels?.find((travel) => {
      const startDate = new Date(travel.startAt);
      const endDate = new Date(travel.endAt);
      return currentDate >= startDate && currentDate <= endDate;
    });

    // Se o usuário está em uma viagem atualmente, retorne a localização da viagem atual
    if (currentTravel) {
      const getUserCountry = currentTravel.country;
      const findCountry = returnCountryArray()?.find((item: IInputSelect) => item.value === getUserCountry);
      const countryUser = translate(findCountry?.label);

      const getStateArray = returnStateArray(getUserCountry);
      const findState = getStateArray?.find((itemLocation: IInputSelect) => itemLocation.value === currentTravel.state);
      const stateUser = translate(findState?.label);

      const getCityArray = returnCityArray(getUserCountry, findState?.value);
      const findCity = getCityArray?.find((itemLocation: IInputSelect) => itemLocation.value === currentTravel.city);
      const cityUser = translate(findCity?.label);

      const filterLangCountry = countriesOption.find((country) => stringRawDataText(country?.code) === stringRawDataText(getUserCountry));


      return (

        <PanelResumeLocationContainer>
          <PanelLocationsFlag
            src={filterLangCountry?.image}
          />


          <PanelResumeText>
            {`${cityUser}, ${stateUser} - ${countryUser}`}
          </PanelResumeText>


          <ModeleTravelBadgeView>
            <IconPlane
              color={Colors.white}
              size={13}
            />
          </ModeleTravelBadgeView>
        </PanelResumeLocationContainer>

      );
    }

    // Caso contrário, verifique se o usuário tem uma localização definida
    const hasLocation = user?.location &&
      user?.location?.country &&
      user?.location?.state &&
      user?.location?.city;

    const countryUser = user?.location?.country;
    const findCountry = returnCountryArray()?.find((item: IInputSelect) => item.value === countryUser);
    const getUserCountry = findCountry?.label;

    const getStateArray = returnStateArray(countryUser);
    const findState = getStateArray?.find((item: IInputSelect) => item.value === user?.location?.state);
    const stateUser = findState?.label;

    const getCityArray = returnCityArray(countryUser, findState?.value);
    const findCity = getCityArray?.find((item: IInputSelect) => item.value === user?.location?.city);
    const cityUser = findCity?.label;

    // Retorne a localização definida pelo usuário, se houver
    if (hasLocation) {
      const filterLangCountry = countriesOption.find((country) => stringRawDataText(country?.code) === stringRawDataText(countryUser));

      return (

        <PanelResumeLocationContainer>
          <PanelLocationsFlag
            src={filterLangCountry?.image}
          />

          <PanelResumeText>
            {`${cityUser}, ${stateUser} - ${getUserCountry}`}
          </PanelResumeText>
        </PanelResumeLocationContainer>

      );
    }


    // Se o usuário não tiver localização definida
    return (

      <PanelResumeLocationContainer>
        <PanelResumeText>
          {translate(TranslateConfig.EMPTY_LOCATION)}
        </PanelResumeText>
      </PanelResumeLocationContainer>

    );
  }


  function renderHeaderRent(value: number) {
    const loopValue = value;
    const rows = [];

    for (let index = 0; index < loopValue; index++) {
      rows.push(
        <PanelResumeLevelView>
          <PanelResumeLevelImage
            key={index}
            src={SetSuitsGenderImage(user?.gender)}
          />
        </PanelResumeLevelView>,
      );
    }



    return (

      <PanelResumeContent>

        <PanelResumeTitle>
          {translate(TranslateConfig.PROFILE_LEVEL_MODELE)}:
        </PanelResumeTitle>


        <PanelResumeLevelContainer>
          {rows}
        </PanelResumeLevelContainer>

      </PanelResumeContent>

    );
  }


  function renderTravels() {
    return (

      <ButtonPanelTravels
        title={translate(TranslateConfig.TRAVELS)}
        travelCount={validateNumber(user?.appTravels?.length) || 0}
        onPress={() => {
          history.push(NameRoutes.ModeleTravelSeeScreen);
        }}
      />

    );
  }


  function renderRentLevel() {
    const information = user?.plan?.planCurrent === EPlansUser.DIAMOND
      ? translate(TranslateConfig.VALIDATE_BLOCKED_PLAN)
      : !rentModule && translate(TranslateConfig.VALIDATE_BLOCKED_PLAN);


    return (

      <PanelItemsButton
        accent={false}
        disabled={!rentModule || user?.plan?.planCurrent === EPlansUser.DIAMOND}
        onPress={() => {
          history.push(NameRoutes.RentChangeLevelScreen);
        }}>

        <PanelItemsIcon>
          <IconAdsRentLevel
            color={Colors.white}
            size={26}
          />
        </PanelItemsIcon>


        <PanelItemsText>
          {`${translate(TranslateConfig.ESCORT)}: ${translate(TranslateConfig.ACTION_LEVEL_CHANGE)}`}
        </PanelItemsText>


        {information && (
          <PanelInputHelpText>
            {information}
          </PanelInputHelpText>
        )}

      </PanelItemsButton>

    );
  }


  function renderRentHights() {
    const information = user?.plan?.planCurrent === EPlansUser.DIAMOND
      ? translate(TranslateConfig.VALIDATE_BLOCKED_PLAN)
      : !rentModule && translate(TranslateConfig.VALIDATE_BLOCKED_PLAN);


    return (

      <PanelHighlightButton
        accent={false}
        comeNow={user?.appRentHighlight?.length > 0}
        disabled={!rentModule || user?.plan?.planCurrent === EPlansUser.DIAMOND}
        onPress={() => {
          history.push(NameRoutes.RentPayAttentionScreen);
        }}>

        <PanelItemsIcon>
          <IconAdsRentAttention
            color={Colors.white}
            size={26}
          />
        </PanelItemsIcon>


        <PanelItemsText>
          {`${translate(TranslateConfig.ESCORT)}: ${translate(TranslateConfig.ACTION_LEVEL_SHAKE_ATTENTION)}`}
        </PanelItemsText>


        {information && (
          <PanelInputHelpText>
            {information}
          </PanelInputHelpText>
        )}

      </PanelHighlightButton>

    );
  }


  function verifyAllModulesActive(): boolean {
    const allModules = configModulesParams;
    const moduleValues = allModules ? Object.values(allModules) : [];

    return moduleValues.some((value) => {
      return value === true;
    });
  }


  async function onShowHideProfile() {
    setOpenDeleteModal(false);

    try {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

      const response = await ShowHideProfileModele(userIdSearch);
      const responseModele = response?.data;

      if (!responseModele) {
        console.error('Error', response);
        showSnackBarProps(Colors.danger, translate(TranslateConfig.ERROR_SAVE));
        return;
      }

      appProjectParamsValues(response?.data?.configs?.modules);

      setMessagesReceived(responseModele?.configs?.messages);

      setStatesParams(responseModele?.modele?.params);
      dispatch({ type: USER_SET, payload: responseModele?.modele });

      showSnackBarProps(Colors.accept, translate(TranslateConfig.SUCCESS_SAVE));
    }
    catch (error: any) {
      console.error(error);

      const getMessageError = treatErrorAndReturnMessage(error);
      const messageError = translate(getMessageError);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  }



  function verifyAntiFakeReceived() {
    if (!user?.antiFake || !Array.isArray(user.antiFake)) {
      return false;
    }

    // Verifica se há algum item em 'antiFake' que atende às condições especificadas
    const hasAntiFake = user?.antiFake?.some((item: any) => {
      return (
        item.revised === false &&
        item.visible === EStatusVisible.INVISIBLE &&
        item.visibility === EAppMediaVisibility.PUBLIC
      );
    });

    return hasAntiFake;
  }


  function renderPendeciesTitle(): string {
    const numberToCount = validateNumber(user?.pendencies);

    if (numberToCount === 1) {
      return `1 ${translate(TranslateConfig.UPDATE_PENDENT)}`;
    }

    if (numberToCount > 1) {
      return `${numberToCount} ${translate(TranslateConfig.UPDATES_PENDENTS)}`;
    }

    return translate(TranslateConfig.EMPTY_DASH_UPDATES);
  }



  useEffect(() => {
    dispatch({ type: APP_MENU_SELECTED_ACTION, payload: EDrawerMenu.ADM_MODEL });
    getActualModele();
  }, []);


  const urlJsonLd = `${Vars(EEnvsName.PROD).secretioApp}${NameRoutes.PanelModeleScreen}`;

  const jsonLd = {
    '@context': 'http://schema.org',
    '@type': 'WebSite',
    '@id': urlJsonLd,
    'url': urlJsonLd,
    'name': `${translate('Painel profissional')} | ${translate(TranslateConfig.NAME_APP)}`,
    'image': Images.bannerAppLink,
    'description': Seo.descriptionPanelModele,
    'isPartOf': {
      '@type': 'WebSite',
      'url': Vars(EEnvsName.PROD).secretioApp,
      'name': translate(TranslateConfig.NAME_APP),
    },
  };



  return (

    <Screen
      backgroundColor={Colors.appBackground}>


      <Helmet prioritizeSeoTags>
        <title>
          {`${translate(TranslateConfig.PANEL_PROFISSIONAL)} | ${translate(TranslateConfig.NAME_APP)}`}
        </title>

        <meta name="robots" content="index,follow" />
        <meta name="googlebot" content="index,follow" />
        <meta name="description" content={Seo.descriptionPanelModele} />

        <meta name="keywords" content={Seo.keywords} />

        <meta property="og:site_name" content={translate(TranslateConfig.NAME_APP)} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={Images.logo512} />
        <meta property="og:url" content={urlJsonLd} />
        <meta property="og:title" content={`${translate(TranslateConfig.PANEL_PROFISSIONAL)} | ${translate(TranslateConfig.NAME_APP)}`} />
        <meta property="og:description" content={Seo.descriptionPanelModele} />

        <link rel="canonical" href={urlJsonLd} />

        <script type="application/ld+json">
          {JSON.stringify(jsonLd)}
        </script>
      </Helmet>



      <Container>

        <HeaderScreen
          disableGender
          backgroundColor={Colors.toolbarBackground}
          subBackgroundColor={Colors.toolbarBackground}
          leftIcon={
            <ButtonDrawer
              color={Colors.white}
              size={27}
            />
          }
          centerContent={
            <Title
              color={Colors.white}>
              {translate(TranslateConfig.PANEL_PROFISSIONAL)}
            </Title>
          }
        />



        <Content>

          {loading && (
            <LoadingScreen
              type={'SCREEN'}
              color={Colors.white}
            />
          )}



          {!loading && (
            <ContentRowToColumnPadder>

              <PanelContentCenterFlex>

                {messagesReceived?.length > 0 && (
                  <WarningTextsContainer>
                    {messagesReceived.map((message: any, index: number) => {
                      if (validateString(message?.description)) {
                        return (

                          <WarningItemStyled
                            key={index}
                            status={message?.status}
                            title={message?.title}
                            description={message?.description}
                          />

                        );
                      }
                    })}
                  </WarningTextsContainer>
                )}



                <DetailsApproveViewBackground>
                  <HeaderUserBackgroundOverlay />

                  <DetailsApproveBackground
                    src={validateString(user?.pictureCover) || Images.bannerDetails}
                  />
                </DetailsApproveViewBackground>



                <DetailsApproveViewImage>
                  <UserAvatar
                    plan={user?.plan?.planCurrent}
                    image={user?.picture}
                    width={190}
                    height={250}
                  />
                </DetailsApproveViewImage>



                <PanelPadderContent>

                  <PanelAvatarItemInfo>

                    <PanelAvatarName>
                      {user?.alias || '-'}
                    </PanelAvatarName>



                    <PanelUsernameValuesView>

                      <PanelAvatarUsername>
                        @{user?.useralias || '-'}
                      </PanelAvatarUsername>


                      {user?.verified && (
                        <HomeIdentiyIcon
                          onClick={() => {
                            dispatch({ type: USER_VERIFIED_MODAL, payload: true });
                          }}>
                          <IconVerified
                            size={16}
                            color={Colors.accent}
                          />
                        </HomeIdentiyIcon>
                      )}


                      {user?.brandPromoter && (
                        <HomeIdentiyIcon
                          onClick={() => {
                            dispatch({ type: USER_BRAND_PROMOTER_MODAL, payload: true });
                          }}>
                          <IconVerifiedPromoter
                            size={16}
                            color={Colors.primaryDark}
                          />
                        </HomeIdentiyIcon>
                      )}

                    </PanelUsernameValuesView>

                  </PanelAvatarItemInfo>



                  <PanelWrapperItemContent>

                    <SectionTitle
                      title={translate(TranslateConfig.RESUME)}
                      color={Colors.primary}
                    />


                    <PanelResumeContent>
                      <PanelResumeTitle>
                        {translate(TranslateConfig.LOCATION_MY)}:
                      </PanelResumeTitle>


                      {renderAtualLocation()}
                    </PanelResumeContent>


                    {user?.appRentLevel && user?.appRentLevel[0] && renderHeaderRent(user?.appRentLevel[0]?.level)}

                  </PanelWrapperItemContent>



                  <PanelWrapperItemContent>

                    <SectionTitle
                      title={translate(TranslateConfig.GENERAL_INFO)}
                      color={Colors.primary}
                    />



                    <PanelValuesContainer>

                      <PanelValuesContent>

                        <PanelValuesLabel>
                          {translate(TranslateConfig.ID_MEMBER)}:
                          {' '}
                          <PanelValuesText>
                            {user?.clientNumber || '-'}
                          </PanelValuesText>
                        </PanelValuesLabel>


                        <PanelValuesLabel>
                          {translate(TranslateConfig.EMAIL_REGISTERED)}:
                          {' '}
                          <PanelValuesText>
                            {user?.email || '-'}
                          </PanelValuesText>
                        </PanelValuesLabel>


                        <PanelValuesLabel>
                          {translate(TranslateConfig.GENRE)}:
                          {' '}
                          <PanelValuesText>
                            {translate(SetGenreSingularText(user?.gender)) || '-'}
                          </PanelValuesText>
                        </PanelValuesLabel>


                        <PanelValuesLabel>
                          {translate(TranslateConfig.PROFILE_TYPE)}:
                          {' '}
                          <PanelValuesText>
                            {user?.type === EUserType.MODELE
                              ? translate(TranslateConfig.MODELE)
                              : translate(TranslateConfig.USER)
                            }
                          </PanelValuesText>
                        </PanelValuesLabel>


                        <PanelValuesLabel>
                          {translate(TranslateConfig.EXCLUSIVE)}:
                          {' '}
                          <PanelValuesText
                            color={user?.exclusive ? Colors.primary : Colors.white}>
                            {user?.exclusive
                              ? translate(TranslateConfig.YES)
                              : translate(TranslateConfig.NO)
                            }
                          </PanelValuesText>
                        </PanelValuesLabel>


                        <PanelValuesLabel>
                          {translate(TranslateConfig.PORNSTAR)}:
                          {' '}
                          <PanelValuesText
                            color={user?.pornstar ? Colors.primary : Colors.white}>
                            {user?.pornstar
                              ? translate(TranslateConfig.YES)
                              : translate(TranslateConfig.NO)
                            }
                          </PanelValuesText>
                        </PanelValuesLabel>

                      </PanelValuesContent>



                      <PanelValuesContent>

                        <PanelValuesLabel>
                          {translate(TranslateConfig.MEMBER_SINCE)}:
                          {' '}
                          <PanelValuesText>
                            {user?.reports?.createdAt ? FormatExtenseDate(user?.reports?.createdAt) : '-'}
                          </PanelValuesText>
                        </PanelValuesLabel>


                        <PanelValuesLabel>
                          {translate(TranslateConfig.STATUS)}:
                          {' '}
                          <PanelValuesText>
                            {translate(userStatusArray?.find((item: IInputSelect) => item.value === user?.status)?.label) || '-'}
                          </PanelValuesText>
                        </PanelValuesLabel>


                        <PanelValuesLabel>
                          {translate(TranslateConfig.PLAN)}:
                          {' '}
                          <PanelValuesText>
                            {translate(plansFinancialArray?.find((item: IInputSelect) => item.value === user?.plan?.planCurrent)?.label) || '-'}
                          </PanelValuesText>
                        </PanelValuesLabel>


                        {!user?.plan?.isToPay && (
                          <PanelValuesLabel>
                            <PanelValuesPublishText
                              color={Colors.primary}>
                              {translate(TranslateConfig.PLAN_PAY_EXEPTION)}
                            </PanelValuesPublishText>
                          </PanelValuesLabel>
                        )}



                        <PanelValuesPublishView>
                          <PanelValuesPublishIcon>
                            {user?.visible
                              ? (
                                <IconCircleCheck
                                  color={Colors.accept}
                                  size={18}
                                />
                              )
                              : (
                                <IconCircleRemove
                                  color={Colors.attention}
                                  size={18}
                                />
                              )
                            }
                          </PanelValuesPublishIcon>

                          <PanelValuesPublishText
                            color={user?.visible ? Colors.accept : Colors.attention}>
                            {user?.visible
                              ? translate(TranslateConfig.PROFILE_VISIBLE)
                              : translate(TranslateConfig.PROFILE_INVISIBLE)
                            }
                          </PanelValuesPublishText>
                        </PanelValuesPublishView>



                        <PanelValuesPublishView>
                          <PanelValuesPublishIcon>
                            {validateNumber(user?.pendencies) && user?.pendencies > 0
                              ? (
                                <IconCircleAttention
                                  color={Colors.attention}
                                  size={18}
                                />
                              )
                              : (
                                <IconCircleCheck
                                  color={Colors.accept}
                                  size={18}
                                />
                              )
                            }
                          </PanelValuesPublishIcon>

                          <PanelValuesPublishText
                            color={validateNumber(user?.pendencies) && user?.pendencies > 0 ? Colors.attention : Colors.accept}>
                            {renderPendeciesTitle()}
                          </PanelValuesPublishText>
                        </PanelValuesPublishView>
                      </PanelValuesContent>

                    </PanelValuesContainer>

                  </PanelWrapperItemContent>



                  {verifyAllModulesActive() && (
                    <PanelWrapperItemContent>

                      <SectionTitle
                        title={translate(TranslateConfig.ACTIVATE_SERVICES)}
                        color={Colors.primary}
                      />


                      <PanelActiveItemContent>

                        {configModulesParams?.showRent && (
                          <ButtonPublishStyled
                            disabled={loading}
                            selected={rentModule}
                            textOn={translate(TranslateConfig.RENT_ON)}
                            textOff={translate(TranslateConfig.RENT_OFF)}
                            color={Colors.primary}
                            onPress={() => {
                              if (!loading) {
                                saveItemGroup({ rent: !rentModule });
                              }
                            }}
                          />
                        )}


                        {configModulesParams?.showFanClub && (
                          <ButtonPublishStyled
                            disabled={loading || fanClubModule}
                            selected={fanClubModule}
                            textOn={translate(TranslateConfig.FAN_CLUB_ON)}
                            textOff={translate(TranslateConfig.FAN_CLUB_OFF)}
                            color={Colors.primary}
                            onPress={() => {
                              if (fanClubModule) {
                                return;
                              }

                              if (!loading) {
                                saveItemGroup({ fanClub: !fanClubModule });
                              }
                            }}
                          />
                        )}


                        {configModulesParams?.showLive && (
                          <ButtonPublishStyled
                            disabled={loading}
                            selected={liveModule}
                            textOn={translate(TranslateConfig.LIVE_ON)}
                            textOff={translate(TranslateConfig.LIVE_OFF)}
                            color={Colors.primary}
                            onPress={() => {
                              if (!loading) {
                                saveItemGroup({ live: !liveModule });
                              }
                            }}
                          />
                        )}


                        {configModulesParams?.showMassage && (
                          <ButtonPublishStyled
                            disabled={loading}
                            selected={massageModule}
                            textOn={translate(TranslateConfig.MASSAGE_ON)}
                            textOff={translate(TranslateConfig.MASSAGE_OFF)}
                            color={Colors.primary}
                            onPress={() => {
                              if (!loading) {
                                saveItemGroup({ massage: !massageModule });
                              }
                            }}
                          />
                        )}


                        {configModulesParams?.showStream && (
                          <ButtonPublishStyled
                            disabled={loading}
                            selected={streamModule}
                            textOn={translate(TranslateConfig.STREAM_ON)}
                            textOff={translate(TranslateConfig.STREAM_OFF)}
                            color={Colors.primary}
                            onPress={() => {
                              if (!loading) {
                                saveItemGroup({ stream: !streamModule });
                              }
                            }}
                          />
                        )}

                      </PanelActiveItemContent>

                    </PanelWrapperItemContent>
                  )}



                  <PanelWrapperItemContent>

                    <SectionTitle
                      title={translate(TranslateConfig.PROFILE)}
                      color={Colors.primary}
                    />


                    <PanelOptionsContainer>

                      {renderPanelButton(
                        translate(TranslateConfig.DATA_PRINCIPAL),
                        <IconProfile
                          color={Colors.white}
                          size={26}
                        />,
                        () => {
                          history.push(NameRoutes.CreateModeleFormScreen);
                        },
                      )}


                      {renderPanelUser()}


                      {/* TODO: Colocar uma porcentagem ate atingir 100% dos serviços ativos e ai poder publicar */}
                      {/* Para ser visível em buscas e listagens, você precisa completar todos os campos do seu perfil. */}


                    </PanelOptionsContainer>

                  </PanelWrapperItemContent>



                  {(
                    configModulesParams?.showSignatures ||
                    configModulesParams?.showRent ||
                    configModulesParams?.showFanClub ||
                    configModulesParams?.showLive ||
                    configModulesParams?.showMassage
                  ) && (
                      <PanelWrapperItemContent>

                        <SectionTitle
                          title={translate(TranslateConfig.SERVICES)}
                          color={Colors.primary}
                        />


                        <PanelOptionsContainer>

                          <PanelUpgradeContainer>

                            {/* TODO */}
                            {false && (user?.plan?.planCurrent !== EPlansUser.DIAMOND &&
                              configModulesParams?.showSignatures && user?.visible
                            ) && renderPanelButton(
                              translate(TranslateConfig.PROFILE_PLAN_UPGRADE),
                              <IconUpgrade
                                color={Colors.white}
                                size={26}
                              />,
                              () => {
                                history.push(NameRoutes.UpgradeModeleShowScreen);
                              },
                              true,
                            )}


                            {/* TODO */}
                            {false && (user?.plan?.planCurrent !== EPlansUser.NONE && user?.plan?.planCurrent !== EPlansUser.DIAMOND) &&
                              configModulesParams?.showSignatures && user?.visible &&
                              renderPanelButton(
                                translate(TranslateConfig.PLAN_MANAGE),
                                <IconUpgrade
                                  color={Colors.white}
                                  size={26}
                                />,
                                () => {
                                  history.push(NameRoutes.ModelePlanManageScreen);
                                },
                                true,
                              )
                            }

                          </PanelUpgradeContainer>


                          {configModulesParams?.showRent && renderPanelButton(
                            translate(TranslateConfig.RENT_PROFILE),
                            <IconRentOn
                              color={Colors.white}
                              size={26}
                            />,
                            () => {
                              history.push(NameRoutes.RentProfileModeleScreen);
                            },
                            true,
                            !rentModule,
                            !rentModule && translate(TranslateConfig.VALIDATE_MODULE_DISABLED),
                          )}


                          {configModulesParams?.showFanClub && renderPanelButton(
                            translate(TranslateConfig.FAN_CLUB),
                            <IconFaClub
                              color={Colors.white}
                              size={26}
                            />,
                            () => {
                              history.push(NameRoutes.PeopleClubListScreen);
                            },
                            true,
                            !fanClubModule,
                            !fanClubModule && translate(TranslateConfig.VALIDATE_MODULE_DISABLED),
                          )}


                          {configModulesParams?.showLive && renderPanelButton(
                            translate(TranslateConfig.LIVE),
                            <IconLive
                              color={Colors.white}
                              size={26}
                            />,
                            () => {
                              history.push(NameRoutes.ModeleLiveOptionsScreen);
                            },
                            true,
                            !liveModule,
                            !liveModule && translate(TranslateConfig.VALIDATE_MODULE_DISABLED),
                          )}


                          {configModulesParams?.showMassage && renderPanelButton(
                            translate(TranslateConfig.MASSAGE),
                            <IconMassageOn
                              color={Colors.white}
                              size={26}
                            />,
                            () => {
                              // history.push(NameRoutes.ProfileRentModeleScreen);
                            },
                            true,
                            !massageModule,
                            !massageModule && translate(TranslateConfig.VALIDATE_MODULE_DISABLED),
                          )}

                        </PanelOptionsContainer>

                      </PanelWrapperItemContent>
                    )
                  }



                  {user?.visible && (
                    (configModulesParams?.showSignatures && configModulesParams?.showRent) ||
                    configModulesParams?.showAds
                  ) && (
                      <PanelWrapperItemContent>

                        <SectionTitle
                          title={translate(TranslateConfig.ADS)}
                          color={Colors.primary}
                        />


                        <PanelOptionsContainer>

                          {configModulesParams?.showSignatures && configModulesParams?.showRent &&
                            renderRentLevel()
                          }


                          {configModulesParams?.showSignatures && configModulesParams?.showRent
                            && user?.appRentLevel && user?.appRentLevel[0]?.level > 0 &&
                            renderRentHights()
                          }


                          {configModulesParams?.showAds && configModulesParams?.showRent && renderPanelButton(
                            'Acompanhante do dia',
                            <IconAdsRentOfDay
                              color={Colors.white}
                              size={26}
                            />,
                            () => {
                              history.push(NameRoutes.ModeleAdsScreen);
                            },
                            false,
                            !rentModule,
                            !rentModule && translate(TranslateConfig.VALIDATE_MODULE_DISABLED),
                          )}


                          {configModulesParams?.showAds && renderPanelButton(
                            'Secretio Boost',
                            <IconAdsModeleBoost
                              color={Colors.white}
                              size={26}
                            />,
                            () => {
                              history.push(NameRoutes.ModeleAdsScreen);
                            },
                          )}


                          {configModulesParams?.showAds && renderPanelButton(
                            'Personal Banner',
                            <IconAdsPersonalBanner
                              color={Colors.white}
                              size={26}
                            />,
                            () => {
                              history.push(NameRoutes.ModeleAdsScreen);
                            },
                          )}


                          {configModulesParams?.showAds && renderPanelButton(
                            'Secretio Sponsor',
                            <IconAdsRentSponsor
                              color={Colors.white}
                              size={26}
                            />,
                            () => {
                              history.push(NameRoutes.ModeleAdsScreen);
                            },
                          )}


                          {configModulesParams?.showAds && renderPanelButton(
                            'Direct E-mail',
                            <IconAdsRentSponsor
                              color={Colors.white}
                              size={26}
                            />,
                            () => {
                              // history.push(NameRoutes.ModeleAdsScreen);
                            },
                            false,
                            true,
                            true && translate(TranslateConfig.VALIDATE_MODULE_DISABLED),
                          )}


                          {configModulesParams?.showAds && renderPanelButton(
                            'Banner sugestão',
                            <IconAdsRentSponsor
                              color={Colors.white}
                              size={26}
                            />,
                            () => {
                              // history.push(NameRoutes.ModeleAdsScreen);
                            },
                            false,
                            true,
                            true && translate(TranslateConfig.VALIDATE_MODULE_DISABLED),
                          )}

                        </PanelOptionsContainer>

                      </PanelWrapperItemContent>
                    )
                  }



                  <PanelWrapperItemContent>

                    <SectionTitle
                      title={translate(TranslateConfig.MEDIAS)}
                      color={Colors.primary}
                    />


                    <PanelOptionsContainer>

                      <CardPostCreate />


                      {renderPanelButton(
                        translate(TranslateConfig.MEDIAS),
                        <IconMediaComplete
                          color={Colors.white}
                          size={26}
                        />,
                        () => {
                          history.push(NameRoutes.ModeleMediaAddScreen);
                        },
                      )}


                      {renderPanelButton(
                        translate(TranslateConfig.ANTI_FAKE_VERIFY),
                        <IconAntiFake
                          color={Colors.white}
                          size={26}
                        />,
                        () => {
                          history.push(NameRoutes.AntiFakeVerifiedScreen);
                        },
                        false,
                        false,
                        verifyAntiFakeReceived() && translate(TranslateConfig.VALIDATE_ANTI_FAKE_MEDIA),
                      )}


                      {configModulesParams?.showFanClub && renderPanelButton(
                        translate(TranslateConfig.GALLERY_PRIVATE),
                        <IconLocked
                          color={Colors.white}
                          size={26}
                        />,
                        () => {
                          history.push(NameRoutes.ModelePrivateGalleryScreen);
                        },
                      )}

                    </PanelOptionsContainer>

                  </PanelWrapperItemContent>



                  <PanelWrapperItemContent>

                    <SectionTitle
                      title={translate(TranslateConfig.INFORMATIONS)}
                      color={Colors.primary}
                    />


                    <PanelOptionsContainer>

                      {renderLocation()}


                      {renderTravels()}


                      {renderPanelButton(
                        translate(TranslateConfig.CONTACTS_AVAILABILITY),
                        <IconPhone
                          color={Colors.white}
                          size={26}
                        />,
                        () => {
                          history.push(NameRoutes.ContactsModeleScreen);
                        },
                      )}


                      {configModulesParams?.showLinkbio && renderPanelButton(
                        translate(TranslateConfig.LINKBIO),
                        <IconLinkBio
                          color={Colors.white}
                          size={26}
                        />,
                        () => {
                          history.push(NameRoutes.LinkBioDashboardScreen);
                        },
                      )}


                      {renderPanelButton(
                        translate(TranslateConfig.COMMENTS),
                        <CommentsCountContainer>
                          <CommentsCountIcon>
                            <IconStar
                              color={Colors.primary}
                              size={26}
                            />
                          </CommentsCountIcon>


                          <CommentsCountText>
                            {user?.appReviewsAverage}
                          </CommentsCountText>
                        </CommentsCountContainer>,
                        () => {
                          history.push(NameRoutes.ReviewModeleScreen);
                        },
                      )}

                    </PanelOptionsContainer>

                  </PanelWrapperItemContent>



                  <PanelWrapperLastItemContent>

                    <SectionTitle
                      title={translate(TranslateConfig.ACCOUNT)}
                      color={Colors.primary}
                    />


                    <PanelOptionsContainer>

                      {renderPanelButton(
                        translate(TranslateConfig.VISIBILITY_PRIVACY),
                        <IconVisibility
                          color={Colors.white}
                          size={26}
                        />,
                        () => {
                          history.push(NameRoutes.VisibilityModeleScreen);
                        },
                      )}


                      {renderPanelButton(
                        translate(TranslateConfig.PROFILE_PROFISSIONAL_DATA),
                        <IconUser
                          color={Colors.white}
                          size={26}
                        />,
                        () => {
                          history.push(NameRoutes.ModeleDataProfileScreen);
                        },
                      )}


                      {user?.visible && !user?.fanClub && renderPanelButton(
                        user?.status === EStatusProfile.HIDE_BY_USER
                          ? translate(TranslateConfig.PROFILE_SHOW)
                          : translate(TranslateConfig.PROFILE_HIDE),
                        user?.status === EStatusProfile.HIDE_BY_USER
                          ? (
                            <IconEyeOn
                              color={Colors.white}
                              size={26}
                            />
                          )
                          : (
                            <IconEyeOff
                              color={Colors.white}
                              size={26}
                            />
                          ),
                        () => {
                          if (user?.status === EStatusProfile.ACTIVE) {
                            setOpenDeleteModal(true);
                            return;
                          }

                          onShowHideProfile();
                        },
                      )}

                    </PanelOptionsContainer>

                  </PanelWrapperLastItemContent>

                </PanelPadderContent>

              </PanelContentCenterFlex>



              <FinancialRightSuggestion>

                {projectParams?.showRanking && (
                  <SuggestionViewButton
                    onClick={() => {
                      history.push(NameRoutes.RankingsScreen);
                    }}>
                    <SuggestionTitle>
                      {translate(TranslateConfig.RANKINGS)}
                    </SuggestionTitle>

                    <SuggestionText>
                      {translate(TranslateConfig.ACTION_OPEN_MORE)}
                    </SuggestionText>
                  </SuggestionViewButton>
                )}



                <FinancialAdsImageButton
                  backgroundColor={Colors.cardBackground}
                  borderRadius={Sizes.cardRadius}>

                  <FinancialAdsTextBold>
                    <IconWarning
                      color={Colors.attention}
                      size={20}
                    />

                    <FinancialTitle>
                      {translate(TranslateConfig.ATTENTION).toUpperCase()}
                    </FinancialTitle>
                  </FinancialAdsTextBold>


                  <FinancialAdsNormalText>
                    {translate(TranslateConfig.ATTENTION_TEXT_1)}
                  </FinancialAdsNormalText>

                  <FinancialAdsNormalText>
                    {translate(TranslateConfig.ATTENTION_TEXT_2)}
                  </FinancialAdsNormalText>


                  <FinancialAdsBold
                    onClick={() => {
                      history.push({
                        pathname: NameRoutes.WebViewScreen,
                        state: {
                          title: translate(TranslateConfig.CONTACT),
                          link: Vars().secretioContact,
                        },
                      });
                    }}>
                    {translate(TranslateConfig.ATTENTION_CONTACT)}
                  </FinancialAdsBold>

                </FinancialAdsImageButton>



                <FinancialAdsAlButton
                  backgroundColor={Colors.cardBackground}
                  borderRadius={Sizes.cardRadius}>

                  <FinancialAdsTextBold>
                    <IconWarning
                      color={Colors.attention}
                      size={20}
                    />

                    <FinancialTitle>
                      {translate(TranslateConfig.ATTENTION_NOTICE).toUpperCase()}
                    </FinancialTitle>
                  </FinancialAdsTextBold>

                  <FinancialAdsNormalText>
                    {translate(TranslateConfig.ATTENTION_TEXT_3)}
                  </FinancialAdsNormalText>

                  <FinancialAdsNormalText>
                    {translate(TranslateConfig.ATTENTION_TEXT_4)}
                  </FinancialAdsNormalText>


                  <FinancialAdsBold
                    onClick={() => {
                      history.push({
                        pathname: NameRoutes.WebViewScreen,
                        state: {
                          title: translate(TranslateConfig.CONTACT),
                          link: Vars().secretioContact,
                        },
                      });
                    }}>
                    {translate(TranslateConfig.ATTENTION_CONTACT)}
                  </FinancialAdsBold>

                </FinancialAdsAlButton>

              </FinancialRightSuggestion>

            </ContentRowToColumnPadder>
          )}



          <FooterApp />

        </Content>

      </Container>



      <AlertMessage
        visible={openDeleteModal}
        title={translate(TranslateConfig.QUESTION_PROFILE_HIDE)}
        cancelText={translate(TranslateConfig.NO)}
        cancelPress={() => {
          setOpenDeleteModal(false);
        }}
        okText={translate(TranslateConfig.YES)}
        okPress={() => {
          onShowHideProfile();
        }}
      />

    </Screen>

  );
};



export default PanelModeleScreen;
