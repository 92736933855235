import React,
{
  useEffect,
  useState,
} from 'react';

import {
  Helmet,
} from 'react-helmet-async';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import {
  IInputSelect,
} from '../../../apis/interfaces/App/input-select.interface';

import {
  Content,
  Screen,
  Title,
} from '../../../components/Composh/web';

import {
  ContentRowToColumnPadder,
} from '../../../components/Contents/ContentPadder';

import {
  ButtonDrawer,
} from '../../../components/Controls';

import ButtonPaneUser from '../../../components/Details/ButtonPanelUser';
import ItemUser from '../../../components/Details/ItemUser';
import CreditsBuy from '../../../components/Financial/CreditsBuy';
import InfoCreditCard from '../../../components/Financial/InfoCreditCard';
import InfoPlan from '../../../components/Financial/InfoPlan';
import FooterApp from '../../../components/Footers/FooterApp';
import HeaderScreen from '../../../components/Headers/HeaderScreen';

import {
  IconAvaliations,
  IconBlock,
  IconCreditCard,
  IconFaClub,
  IconLiked,
  IconMediaComplete,
  IconModele,
  IconProfile,
} from '../../../components/Icons';

import LoadingScreen from '../../../components/Loadings/LoadingScreen';
import SectionTitle from '../../../components/Titles/SectionTitle';

import NameRoutes from '../../../navigation/names';

import {
  RootState,
} from '../../../redux';

import {
  APP_MENU_SELECTED_ACTION,
  APP_SNACKBAR_ACTION,
} from '../../../redux/reducers/app-modals.store';

import PricesArray from '../../../shared/arrays/Financial/prices.array';

import {
  Colors,
  Images,
} from '../../../shared/constants';

import { EEnvsName } from '../../../shared/enums';
import EDrawerMenu from '../../../shared/enums/App/drawer-menu.enum';
import EUserType from '../../../shared/enums/Users/user-type.enum';

import Vars from '../../../shared/environments/variables';

import Seo from '../../../shared/seo';

import {
  TranslateConfig,
} from '../../../shared/translations';

import {
  returnCityArray,
  returnStateArray,
} from '../../../shared/utils/address.utils';

import {
  treatErrorAndReturnColor,
  treatErrorAndReturnMessage,
} from '../../../shared/utils/errors.utils';

import {
  ContentLeftMessage,
  ContentUserItem,
  DashboardItemButton,
  DashboardClientIcon,
  DashboardClientInfo,
  DashboardItemText,
  DashboardItemValueText,
  ContentCenterFlex,
  ContentUserEditItem,
  ContentUserMediaItem,
  PanelItemsButton,
  PanelItemsIcon,
  PanelItemsText,
  WrapperItemContent,
  DashboardBillingContainer,
  WrapperLastContent,
  DashboardItemTitle,
  DashboardItemGrid,
} from './styled';



const DashboardModeleScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t: translate } = useTranslation();


  const projectParams = useSelector((state: RootState) => state.appConfigs.modules);

  const user = useSelector((state: RootState) => state.user.actualUser);


  const [loading, setLoading] = useState<boolean>(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION,
      payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  async function getActualUser() {
    try {
      setLoading(true);

      // FIXME- Criar endpoint para trazer os dados do dashboard
      // const response = await GetModeleProfissionalPanel(userIdSearch);
      // const responseModele = response?.data;

      // if (!responseModele) {
      //   console.error('Error', response);
      //   showSnackBarProps(Colors.danger, 'Erro ao obter perfil');
      //   return;
      // }

      // appProjectParamsValues(response?.data?.configs?.modules);

      // setMessagesReceived(responseModele?.configs?.messages);

      // setStatesParams(responseModele?.modele?.params);
      // dispatch({ type: USER_SET, payload: responseModele?.modele });
    }
    catch (error: any) {
      console.error(error);

      const getMessageError = treatErrorAndReturnMessage(error);
      const messageError = translate(getMessageError);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      setLoading(false);
    }
  }


  function renderItemHeader() {
    const hasLocation = user?.location
      && user?.location?.country
      && user?.location?.state
      && user?.location?.city;

    const countryUser = user?.location?.country;

    const getStateArray = returnStateArray(countryUser);
    const findState = getStateArray?.find((itemFind: IInputSelect) => itemFind.value === user?.location?.state);
    const stateUser = findState?.label;

    const getCityArray = returnCityArray(countryUser, findState?.value);
    const findCity = getCityArray?.find((itemFind: IInputSelect) => itemFind.value === user?.location?.city);
    const cityUser = findCity?.label;


    return (

      <ContentUserItem>
        <ItemUser
          name={user?.alias}
          username={user?.useralias}
          image={user?.picture}
          country={hasLocation ? countryUser : ''}
          place={hasLocation
            ? `${cityUser}, ${stateUser}`
            : translate(TranslateConfig.EMPTY_LOCATION)
          }
        />
      </ContentUserItem>

    );
  }


  function renderItemBoard(title: string, subtitle: string, icon: any, onPress: any) {
    return (

      <DashboardItemButton
        onClick={onPress}>

        <DashboardClientIcon>
          {icon}
        </DashboardClientIcon>


        <DashboardClientInfo>

          <DashboardItemText>
            {title}
          </DashboardItemText>

          <DashboardItemValueText>
            {subtitle}
          </DashboardItemValueText>

        </DashboardClientInfo>

      </DashboardItemButton>

    );
  }


  function renderCreditBuy(item: any, index: any) {
    return (

      <CreditsBuy
        key={index}
        packageCoin={item?.credit}
        price={item?.price}
        onPress={() => {
          history.push(
            NameRoutes.AddMoneyScreen,
            // FIXME
            // search: `?package=${item?.package}`,
          );
        }}
      />

    );
  }


  function renderBlockCountText(): string {
    const numberToCount = 0 as any; // FIXME

    if (numberToCount === 1) {
      return `1 ${translate(TranslateConfig.BLOCK)}`;
    }

    if (numberToCount > 1) {
      return `${numberToCount} ${translate(TranslateConfig.BLOCKS)}`;
    }

    return translate(TranslateConfig.EMPTY_DASH_BLOCK);
  }


  function renderMediaCountText(): string {
    const numberToCount = 0 as any; // FIXME

    if (numberToCount === 1) {
      return `1 ${translate(TranslateConfig.MEDIA)}`;
    }

    if (numberToCount > 1) {
      return `${numberToCount} ${translate(TranslateConfig.MEDIAS)}`;
    }

    return translate(TranslateConfig.EMPTY_DASH_MEDIA_BUYED);
  }


  function renderLikedMediaCountText(): string {
    const numberToCount = 0 as any; // FIXME

    if (numberToCount === 1) {
      return `1 ${translate(TranslateConfig.MEDIAS)}`;
    }

    if (numberToCount > 1) {
      return `${numberToCount} ${translate(TranslateConfig.MEDIAS)}`;
    }

    return translate(TranslateConfig.EMPTY_DASH_MEDIA_LIKED);
  }


  function renderCommentsCountText(): string {
    const numberToCount = 0 as any; // FIXME

    if (numberToCount === 1) {
      return `1 ${translate(TranslateConfig.COMMENT)}`;
    }

    if (numberToCount > 1) {
      return `${numberToCount} ${translate(TranslateConfig.COMMENTS)}`;
    }

    return translate(TranslateConfig.EMPTY_DASH_COMMENT);
  }


  function renderFanClubCountText(): string {
    const numberToCount = 0 as any; // FIXME

    if (numberToCount === 1) {
      return `1 ${translate(TranslateConfig.FAN_CLUB)}`;
    }

    if (numberToCount > 1) {
      return `${numberToCount} ${translate(TranslateConfig.FAN_CLUBS)}`;
    }

    return translate(TranslateConfig.EMPTY_DASH_FAN_CLUB);
  }



  useEffect(() => {
    dispatch({ type: APP_MENU_SELECTED_ACTION, payload: EDrawerMenu.DASHBOARD });
    getActualUser();
  }, []);


  const urlJsonLd = `${Vars(EEnvsName.PROD).secretioApp}${NameRoutes.DashboardModeleScreen}`;

  const jsonLd = {
    '@context': 'http://schema.org',
    '@type': 'WebSite',
    '@id': urlJsonLd,
    'url': urlJsonLd,
    'name': `${translate(TranslateConfig.DASHBOARD)} | ${translate(TranslateConfig.NAME_APP)}`,
    'image': Images.bannerAppLink,
    'description': Seo.descriptionPanelModele,
    'isPartOf': {
      '@type': 'WebSite',
      'url': Vars(EEnvsName.PROD).secretioApp,
      'name': translate(TranslateConfig.NAME_APP),
    },
  };



  return (

    <Screen
      backgroundColor={Colors.appBackground}>


      <Helmet prioritizeSeoTags>
        <title>
          {`${translate(TranslateConfig.DASHBOARD)} | ${translate(TranslateConfig.NAME_APP)}`}
        </title>

        <meta name="robots" content="index,follow" />
        <meta name="googlebot" content="index,follow" />
        <meta name="description" content={Seo.descriptionPanelModele} />

        <meta name="keywords" content={Seo.keywords} />

        <meta property="og:site_name" content={translate(TranslateConfig.NAME_APP)} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={Images.logo512} />
        <meta property="og:url" content={urlJsonLd} />
        <meta property="og:title" content={`${translate(TranslateConfig.DASHBOARD)} | ${translate(TranslateConfig.NAME_APP)}`} />
        <meta property="og:description" content={Seo.descriptionPanelModele} />

        <link rel="canonical" href={urlJsonLd} />

        <script type="application/ld+json">
          {JSON.stringify(jsonLd)}
        </script>
      </Helmet>



      <HeaderScreen
        disableGender
        showBalance
        backgroundColor={Colors.toolbarBackground}
        subBackgroundColor={Colors.toolbarBackground}
        leftIcon={
          <ButtonDrawer
            color={Colors.white}
            size={27}
          />
        }
        centerContent={
          <Title
            color={Colors.white}>
            {translate(TranslateConfig.DASHBOARD)}
          </Title>
        }
      />



      <Content>

        {loading && (
          <LoadingScreen
            type={'SCREEN'}
            color={Colors.white}
          />
        )}



        {!loading && (
          <ContentRowToColumnPadder>

            <ContentLeftMessage>

              {renderItemHeader()}


              {projectParams?.canAccess && projectParams?.showChatMessages && renderItemBoard(
                translate(TranslateConfig.BLOCK_USER),
                renderBlockCountText(),
                <IconBlock
                  size={28}
                  color={Colors.white}
                />,
                () => {
                  history.push(NameRoutes.BlockedScreen);
                },
              )}


              {projectParams?.showFinancial && renderItemBoard(
                translate(TranslateConfig.BUYED_ITEMS),
                renderMediaCountText(),
                <IconMediaComplete
                  size={24}
                  color={Colors.white}
                />,
                () => {
                  history.push(NameRoutes.BuyMediasScreen);
                },
              )}


              {renderItemBoard(
                translate(TranslateConfig.MEDIAS_LIKED),
                renderLikedMediaCountText(),
                <IconLiked
                  size={24}
                  color={Colors.white}
                />,
                () => {
                  history.push(NameRoutes.MediaLikedScreen);
                },
              )}


              {renderItemBoard(
                translate(TranslateConfig.COMMENTS_DONE),
                renderCommentsCountText(),
                <IconAvaliations
                  size={24}
                  color={Colors.white}
                />,
                () => {
                  history.push(NameRoutes.CommentDoneScreen);
                },
              )}


              {projectParams?.showFanClub && projectParams?.showFinancial && renderItemBoard(
                translate(TranslateConfig.FAN_CLUB_DONE),
                renderFanClubCountText(),
                <IconFaClub
                  size={24}
                  color={Colors.white}
                />,
                () => {
                  history.push(NameRoutes.FanClubPayedScreen);
                },
              )}


              {projectParams?.showFinancial && renderItemBoard(
                translate(TranslateConfig.PAYMENT_HISTORY),
                translate(TranslateConfig.ACTION_SEE_HISTORY),
                <IconCreditCard
                  size={24}
                  color={Colors.white}
                />,
                () => {
                  history.push(NameRoutes.MyPaymentsScreen);
                },
              )}


              {user?.type !== EUserType.MODELE && renderItemBoard(
                translate(TranslateConfig.BE_MODELE),
                translate(TranslateConfig.ACTION_MORE_DETAILS),
                <IconModele
                  size={24}
                  color={Colors.white}
                />,
                () => {
                  history.push(NameRoutes.BeModeleScreen);
                },
              )}

            </ContentLeftMessage>



            <ContentCenterFlex>

              <ContentUserEditItem>

                <PanelItemsButton
                  onPress={() => {
                    history.push(NameRoutes.CreateModeleFormScreen);
                  }}>

                  <PanelItemsIcon>
                    <IconProfile
                      color={Colors.white}
                      size={26}
                    />
                  </PanelItemsIcon>


                  <PanelItemsText>
                    {translate(TranslateConfig.DATA_PRINCIPAL)}
                  </PanelItemsText>

                </PanelItemsButton>



                <ButtonPaneUser />

              </ContentUserEditItem>



              {user?.type === EUserType.USER && (
                <ContentUserMediaItem>

                  <PanelItemsButton
                    onPress={() => {
                      history.push(NameRoutes.UserImageProfileScreen);
                    }}>

                    <PanelItemsIcon>
                      <IconMediaComplete
                        color={Colors.white}
                        size={26}
                      />
                    </PanelItemsIcon>


                    <PanelItemsText>
                      {translate(TranslateConfig.PROFILE_IMAGE)}
                    </PanelItemsText>

                  </PanelItemsButton>

                </ContentUserMediaItem>
              )}


              {projectParams?.showFinancial && (
                <WrapperItemContent>
                  <SectionTitle
                    title={translate(TranslateConfig.PLAN_CHARGING)}
                    color={Colors.primary}
                  />


                  <DashboardBillingContainer>

                    <InfoPlan />

                    <InfoCreditCard />

                  </DashboardBillingContainer>
                </WrapperItemContent>
              )}



              {projectParams?.showFinancial && (
                <WrapperLastContent>
                  <SectionTitle
                    title={translate(TranslateConfig.CREDITS_AVAILABLE)}
                    color={Colors.primary}
                  />


                  <DashboardItemTitle>
                    {translate(TranslateConfig.CREDITS_BUY_AVAILABLE)}
                  </DashboardItemTitle>


                  <DashboardItemGrid>
                    {PricesArray.map((itemPrice: any, index: number) => (
                      renderCreditBuy(itemPrice, index)
                    ))}
                  </DashboardItemGrid>
                </WrapperLastContent>
              )}

            </ContentCenterFlex>

          </ContentRowToColumnPadder>
        )}



        <FooterApp />

      </Content>

    </Screen>

  );
};



export default DashboardModeleScreen;
