import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useDispatch,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import {
  Content,
  Screen,
  Title,
} from '../../../components/Composh/web';

import {
  ContentPadder,
} from '../../../components/Contents/ContentPadder';

import {
  ButtonDrawer,
} from '../../../components/Controls';

import InfoCreditCard from '../../../components/Financial/InfoCreditCard';
import InfoPlan from '../../../components/Financial/InfoPlan';
import FooterApp from '../../../components/Footers/FooterApp';
import HeaderScreen from '../../../components/Headers/HeaderScreen';

import {
  IconEyeOff,
  IconEyeOn,
} from '../../../components/Icons';

import LoadingScreen from '../../../components/Loadings/LoadingScreen';
import SectionTitle from '../../../components/Titles/SectionTitle';

import NameRoutes from '../../../navigation/names';
import {
  APP_MENU_SELECTED_ACTION,
} from '../../../redux/reducers/app-modals.store';

import {
  Colors,
  Images,
} from '../../../shared/constants';

import EDrawerMenu from '../../../shared/enums/App/drawer-menu.enum';

import {
  WrapperItemContent,
  FinancialValuesContainer,
  CreditValueContainer,
  CreditValueContent,
  CreditValueHeader,
  CreditValueLabel,
  ShowValueButton,
  CreditValuePriceView,
  CreditValueIcon,
  CreditCoinImage,
  CreditValueText,
  PayFinancialContainer,
} from './styled';



const FinancialScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();


  const [showPrice, setShowPrice] = useState(false);

  const [loading, setLoading] = useState(false);



  function renderLoading() {
    if (!loading) {
      return null;
    }

    return (

      <LoadingScreen
        type={'SCREEN'}
        color={Colors.white}
      />

    );
  }



  useEffect(() => {
    dispatch({ type: APP_MENU_SELECTED_ACTION, payload: EDrawerMenu.ADM_FINANCIAL });
  }, []);



  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <HeaderScreen
        disableGender
        backgroundColor={Colors.toolbarBackground}
        subBackgroundColor={Colors.toolbarBackground}
        leftIcon={
          <ButtonDrawer
            color={Colors.white}
            size={27}
          />
        }
        centerContent={
          <Title>
            Financeiro
          </Title>
        }
      />



      <Content>

        {loading && (
          renderLoading()
        )}



        {!loading && (
          <ContentPadder>

            <WrapperItemContent>

              <SectionTitle
                title={'Informações financeiras'}
                color={Colors.primary}
              />


              <FinancialValuesContainer>

                <CreditValueContainer>
                  <CreditValueContent>

                    <CreditValueHeader>
                      <CreditValueLabel>
                        Daily Sales
                      </CreditValueLabel>

                      <ShowValueButton
                        onClick={() => {
                          setShowPrice(!showPrice);
                        }}>
                        {showPrice
                          ? <IconEyeOn
                            color={Colors.white}
                            size={28}
                          />
                          : <IconEyeOff
                            color={Colors.white}
                            size={28}
                          />
                        }
                      </ShowValueButton>
                    </CreditValueHeader>


                    <CreditValuePriceView>
                      <CreditValueIcon>
                        <CreditCoinImage
                          alt={'Coin'}
                          src={Images.gencoin}
                        />
                      </CreditValueIcon>

                      <CreditValueText>
                        {showPrice ? '$ 249.95' : '• • • • •'}
                      </CreditValueText>
                    </CreditValuePriceView>

                  </CreditValueContent>
                </CreditValueContainer>



                <CreditValueContainer>
                  <CreditValueContent>

                    <CreditValueLabel>
                      Code
                    </CreditValueLabel>

                    <p
                      onClick={() => {
                        history.push(NameRoutes.FinancialAffiliatesScreen);
                      }}>
                      Saiba mais
                    </p>

                    <CreditValuePriceView>
                      <CreditValueLabel style={{ margin: 0, color: '#ffffff' }}>
                        Share code
                      </CreditValueLabel>

                      <p style={{ margin: 0, color: '#ffffff', fontSize: 12 }}>
                        3 persons
                      </p>
                    </CreditValuePriceView>

                  </CreditValueContent>
                </CreditValueContainer>



                <CreditValueContainer>
                  <CreditValueContent>

                    <CreditValueLabel>
                      Transfer Credits
                    </CreditValueLabel>

                    <p style={{ color: '#ffffff' }}>
                      Credits:
                      0

                      Move to Bank
                    </p>

                  </CreditValueContent>
                </CreditValueContainer>



                <CreditValueContainer>
                  <CreditValueContent>

                    <CreditValueLabel>
                      Todas as movimentações
                    </CreditValueLabel>


                    <button
                      onClick={() => {
                        history.push(NameRoutes.FinancialMovimentsScreen);
                      }}>
                      Ver
                    </button>


                  </CreditValueContent>
                </CreditValueContainer>

              </FinancialValuesContainer>

            </WrapperItemContent>



            <WrapperItemContent>

              <SectionTitle
                title={'Informações de cobrança'}
                color={Colors.primary}
              />


              <PayFinancialContainer>

                <InfoPlan />

                <InfoCreditCard />

              </PayFinancialContainer>

            </WrapperItemContent>

          </ContentPadder>
        )}


        <FooterApp />

      </Content>

    </Screen>

  );
};



export default FinancialScreen;
