import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import ChipApp from '../../../../../components/Chip';
import ItemDescription from '../../../../../components/Details/ItemDescription';

import {
  IconView,
  IconBed,
  IconBody,
  IconBristle,
  IconBust,
  IconEthnicity,
  IconEye,
  IconFoot,
  IconGender,
  IconHairColor,
  IconHairSize,
  IconHeight,
  IconHip,
  IconHiv,
  IconItemMan,
  IconMannequin,
  IconPenis,
  IconReport,
  IconSocialFacebook,
  IconSocialInstagram,
  IconSocialTelegram,
  IconSocialTiktok,
  IconSocialTwitter,
  IconWaist,
  IconWeight,
  IconAccessLogin,
  IconMember,
  IconProfileEdit,
} from '../../../../../components/Icons';

import SectionTitle from '../../../../../components/Titles/SectionTitle';

import {
  returnIconBreasts,
  returnIconDrink,
  returnIconPiercing,
  returnIconSmoke,
  returnIconTattoo,
} from '../../../../../config/app-icons-bool.config';

import {
  returnIconZodiac,
} from '../../../../../config/app-icons-zodiac.config';

import {
  openSite,
} from '../../../../../config/linking.config';

import NameRoutes from '../../../../../navigation/names';

import {
  RootState,
} from '../../../../../redux';

import {
  USER_BAD_REPORT_MODAL,
} from '../../../../../redux/reducers/app-modals.store';

import { bodyHairArray } from '../../../../../shared/arrays/User/user-body-hair.array';
import { bodyTypeArray } from '../../../../../shared/arrays/User/user-body-type.array';
import { bustArray } from '../../../../../shared/arrays/User/user-bust.array';
import { cockSizesArray } from '../../../../../shared/arrays/User/user-cock-size.array';
import { ethnicityArray } from '../../../../../shared/arrays/User/user-ethnicity.array';
import { eyeColorArray } from '../../../../../shared/arrays/User/user-eye-color.array';
import { feetArray } from '../../../../../shared/arrays/User/user-feet.array';
import { hairColorArray } from '../../../../../shared/arrays/User/user-hair-color.array';
import { hairSizeArray } from '../../../../../shared/arrays/User/user-hair-size.array';
import { hivArray } from '../../../../../shared/arrays/User/user-health.array';
import { heightsArray } from '../../../../../shared/arrays/User/user-height.array';
import { hipArray } from '../../../../../shared/arrays/User/user-hip.array';
import { interestArray } from '../../../../../shared/arrays/User/user-interest.array';
import { languagesUserArray } from '../../../../../shared/arrays/User/user-languages.array';
import { mannequinArray } from '../../../../../shared/arrays/User/user-mannequin.array';
import { orientationsArray } from '../../../../../shared/arrays/User/user-orientation.array';
import { positionsArray } from '../../../../../shared/arrays/User/user-positions.array';
import { tribeArray } from '../../../../../shared/arrays/User/user-tribe.array';
import { waistArray } from '../../../../../shared/arrays/User/user-waist.array';
import { weightsArray } from '../../../../../shared/arrays/User/user-weight.array';
import { zodiacArray } from '../../../../../shared/arrays/User/user-zodiac.array';

import {
  Colors,
  Images,
} from '../../../../../shared/constants';

import {
  TranslateConfig,
} from '../../../../../shared/translations';

import {
  CalculateAgeByBirthdayString,
  FormatExtenseDate,
} from '../../../../../shared/utils/date.utils';

import RightContentUser from '../../Right';

import {
  CharContentCenterRowFlex,
  CharContentLeft,
  CharWrapperFirstContent,
  CharWrapperSecondContent,
  CharPornExclusiveView,
  CharPornExclusiveButton,
  CharImagePornExclusive,
  CharCenterFlex,
  CharServiceContainer,
  FeedTipsHeader,
  FeedTipsIconHeader,
  CharUserDescription,
  CharItemContainer,
} from './styled';



export interface IProps {
  showActions?: boolean;
  modele?: any;
  suggestions?: Array<any>;
}



const TabCharacteristicsScreen: React.FC<IProps> = (props: IProps) => {
  const modeleReceived = props.modele;
  const suggestionsArray = props.suggestions;
  const showActions = props.showActions;


  const dispatch = useDispatch();
  const history = useHistory();
  const { t: translate } = useTranslation();


  const user = useSelector((state: RootState) => state.user.actualUser);
  const availableUser = user?.id;

  const projectParams = useSelector((state: RootState) => state.appConfigs.modules);


  const charIconsSize = 26;

  const [languagesUser, setLanguagesUser] = useState<Array<any>>([]);
  const [tribesUser, setTribesUser] = useState<Array<any>>([]);
  const [imOpen, setImOpen] = useState<Array<any>>([]);



  function setServicesForms() {
    if (modeleReceived?.appAbout?.languages) {
      const splitItem: Array<string> = modeleReceived?.appAbout?.languages?.split(', ');
      const filteredItem = splitItem?.map((value) => {
        return languagesUserArray.find((item) => item?.value === value);
      });

      setLanguagesUser(filteredItem);
    }


    if (modeleReceived?.appAbout?.tribes) {
      const splitItem: Array<string> = modeleReceived?.appAbout?.tribes?.split(', ');
      const filteredItem = splitItem?.map((value) => {
        return tribeArray.find((item) => item?.value === value);
      });

      setTribesUser(filteredItem);
    }


    if (modeleReceived?.appAbout?.imOpen) {
      const splitItem: Array<string> = modeleReceived?.appAbout?.imOpen?.split(', ');
      const filteredItem = splitItem?.map((value) => {
        return interestArray.find((item) => item?.value === value);
      });

      setImOpen(filteredItem);
    }
  }


  function renderPersonalItem(icon: any, title: string, text: string, description?: string, onPress?: any) {
    if (text) {
      return (

        <ItemDescription
          disabled
          icon={icon}
          title={title}
          text={text}
          description={description}
          onPress={onPress}
        />

      );
    }
  }


  function renderChipLanguages(item: any, index: number) {
    return (

      <ChipApp
        hideIcon
        key={index}
        title={item?.label}
        backgroundColor={Colors.cardBackground}
        color={Colors.white}
      />

    );
  }



  useEffect(() => {
    setServicesForms();
  }, []);



  return (

    <CharContentCenterRowFlex>

      {(modeleReceived?.exclusive || modeleReceived?.pornstar) && (
        <CharContentLeft>

          <CharWrapperSecondContent>
            <SectionTitle
              title={translate(TranslateConfig.MORE)}
            />


            <CharPornExclusiveView>

              {modeleReceived?.exclusive && (
                <CharPornExclusiveButton
                  onClick={() => {
                    // TODO: Link
                  }}>
                  <CharImagePornExclusive
                    src={Images.memberExclusive}
                  />
                </CharPornExclusiveButton>
              )}


              {modeleReceived?.pornstar && (
                <CharPornExclusiveButton
                  onClick={() => {
                    // TODO: Link para o Secretio Stream
                  }}>
                  <CharImagePornExclusive
                    src={Images.memberPornstar}
                  />
                </CharPornExclusiveButton>
              )}

            </CharPornExclusiveView>

          </CharWrapperSecondContent>

        </CharContentLeft>
      )}



      <CharCenterFlex>

        {modeleReceived?.appAbout?.aboutMe && (
          <CharWrapperFirstContent>

            <FeedTipsHeader>

              <SectionTitle
                title={translate(TranslateConfig.DESCRIPTION)}
              />


              {/* FIXME */}
              {false && projectParams?.canAccess && !showActions && (
                <FeedTipsIconHeader
                  disabled={!modeleReceived?.params?.rent}
                  onClick={() => {
                    if (!availableUser) {
                      history.push(NameRoutes.AccessScreen);
                      return;
                    }

                    dispatch({ type: USER_BAD_REPORT_MODAL, payload: true });
                  }}>
                  <IconReport
                    color={Colors.white}
                    size={18}
                  />
                </FeedTipsIconHeader>
              )}

            </FeedTipsHeader>



            <CharUserDescription>
              {modeleReceived?.appAbout?.aboutMe}
            </CharUserDescription>

          </CharWrapperFirstContent>
        )}



        {(modeleReceived?.characters?.ageShow ||
          modeleReceived?.characters?.height ||
          modeleReceived?.characters?.weight ||
          modeleReceived?.characters?.ethnicity ||
          modeleReceived?.characters?.hairSize ||
          modeleReceived?.characters?.hairColor ||
          modeleReceived?.characters?.eyes ||
          modeleReceived?.characters?.mannequin ||
          modeleReceived?.characters?.bust ||
          modeleReceived?.characters?.waist ||
          modeleReceived?.characters?.hip ||
          modeleReceived?.characters?.feet ||
          modeleReceived?.characters?.cockSize ||
          modeleReceived?.characters?.position) && (
            <CharWrapperFirstContent>
              <SectionTitle
                title={translate(TranslateConfig.CHARACTERISTICS)}
              />


              <CharItemContainer>

                {modeleReceived?.characters?.ageShow && renderPersonalItem(
                  <IconItemMan
                    color={Colors.white}
                    size={charIconsSize}
                  />,
                  translate(TranslateConfig.AGE),
                  CalculateAgeByBirthdayString(modeleReceived?.birthday),
                )}


                {renderPersonalItem(
                  <IconHeight
                    color={Colors.white}
                    size={charIconsSize}
                  />,
                  translate(TranslateConfig.HEIGHT),
                  heightsArray.find((item) => item?.value === modeleReceived?.characters?.height)?.label,
                )}


                {renderPersonalItem(
                  <IconWeight
                    color={Colors.white}
                    size={charIconsSize}
                  />,
                  translate(TranslateConfig.WEIGHT),
                  weightsArray.find((item) => item?.value === modeleReceived?.characters?.weight)?.label,
                )}


                {renderPersonalItem(
                  <IconEthnicity
                    color={Colors.white}
                    size={charIconsSize}
                  />,
                  translate(TranslateConfig.ETHNICITY),
                  ethnicityArray.find((item) => item?.value === modeleReceived?.characters?.ethnicity)?.label,
                )}


                {renderPersonalItem(
                  <IconHairSize
                    color={Colors.white}
                    size={charIconsSize}
                  />,
                  translate(TranslateConfig.HAIR_SIZE),
                  hairSizeArray.find((item) => item?.value === modeleReceived?.characters?.hairSize)?.label,
                )}


                {renderPersonalItem(
                  <IconHairColor
                    color={Colors.white}
                    size={charIconsSize}
                  />,
                  translate(TranslateConfig.HAIR_COLOR),
                  hairColorArray.find((item) => item?.value === modeleReceived?.characters?.hairColor)?.label,
                )}


                {renderPersonalItem(
                  <IconEye
                    color={Colors.white}
                    size={charIconsSize}
                  />,
                  translate(TranslateConfig.EYE_COLOR),
                  eyeColorArray.find((item) => item?.value === modeleReceived?.characters?.eyes)?.label,
                )}


                {renderPersonalItem(
                  <IconMannequin
                    color={Colors.white}
                    size={charIconsSize}
                  />,
                  translate(TranslateConfig.MANNEQUIN),
                  mannequinArray.find((item) => item?.value === modeleReceived?.characters?.mannequin)?.label,
                )}


                {renderPersonalItem(
                  <IconBust
                    color={Colors.white}
                    size={charIconsSize}
                  />,
                  translate(TranslateConfig.BUST),
                  bustArray.find((item) => item?.value === modeleReceived?.characters?.bust)?.label,
                )}


                {renderPersonalItem(
                  <IconWaist
                    color={Colors.white}
                    size={charIconsSize}
                  />,
                  translate(TranslateConfig.WAIST),
                  waistArray.find((item) => item?.value === modeleReceived?.characters?.waist)?.label,
                )}


                {renderPersonalItem(
                  <IconHip
                    color={Colors.white}
                    size={charIconsSize}
                  />,
                  translate(TranslateConfig.HIP),
                  hipArray.find((item) => item?.value === modeleReceived?.characters?.hip)?.label,
                )}


                {renderPersonalItem(
                  <IconFoot
                    color={Colors.white}
                    size={charIconsSize}
                  />,
                  translate(TranslateConfig.FEET),
                  feetArray.find((item) => item?.value === modeleReceived?.characters?.feet)?.label,
                )}


                {renderPersonalItem(
                  <IconPenis
                    color={Colors.white}
                    size={charIconsSize}
                  />,
                  translate(TranslateConfig.PENIS),
                  cockSizesArray.find((item) => item?.value === modeleReceived?.characters?.cockSize)?.label,
                )}


                {renderPersonalItem(
                  <IconBed
                    color={Colors.white}
                    size={charIconsSize}
                  />,
                  translate(TranslateConfig.POSITION),
                  positionsArray.find((item) => item?.value === modeleReceived?.characters?.position)?.label,
                )}

              </CharItemContainer>

            </CharWrapperFirstContent>
          )
        }



        {(modeleReceived?.style?.body ||
          modeleReceived?.style?.bodyHair ||
          modeleReceived?.style?.orientation ||
          modeleReceived?.style?.zodiac ||
          modeleReceived?.style?.smoke ||
          modeleReceived?.style?.drink ||
          modeleReceived?.style?.tattoo ||
          modeleReceived?.style?.piercing ||
          modeleReceived?.style?.breasts) && (
            <CharWrapperFirstContent>
              <SectionTitle
                title={translate(TranslateConfig.STYLE)}
              />


              <CharItemContainer>

                {renderPersonalItem(
                  <IconBody
                    color={Colors.white}
                    size={charIconsSize}
                  />,
                  translate(TranslateConfig.BODY),
                  bodyTypeArray.find((item) => item?.value === modeleReceived?.style?.body)?.label,
                )}


                {renderPersonalItem(
                  <IconBristle
                    color={Colors.white}
                    size={charIconsSize}
                  />,
                  translate(TranslateConfig.BODY_HAIR),
                  bodyHairArray.find((item) => item?.value === modeleReceived?.style?.bodyHair)?.label,
                )}


                {renderPersonalItem(
                  <IconGender
                    color={Colors.white}
                    size={charIconsSize}
                  />,
                  translate(TranslateConfig.ORIENTATION_SEXUAL),
                  orientationsArray.find((item) => item?.value === modeleReceived?.style?.orientation)?.label,
                )}


                {renderPersonalItem(
                  returnIconZodiac(
                    modeleReceived?.style?.zodiac,
                    Colors.white,
                    charIconsSize,
                  ),
                  translate(TranslateConfig.ZODIAC),
                  zodiacArray.find((item) => item?.value === modeleReceived?.style?.zodiac)?.label,
                )}


                {renderPersonalItem(
                  returnIconSmoke(
                    modeleReceived?.style?.smoke,
                    Colors.white,
                    charIconsSize,
                  ),
                  translate(TranslateConfig.SMOKE),
                  modeleReceived?.style?.smoke
                    ? translate(TranslateConfig.YES)
                    : translate(TranslateConfig.NO),
                )}


                {renderPersonalItem(
                  returnIconDrink(
                    modeleReceived?.style?.drink,
                    Colors.white,
                    charIconsSize,
                  ),
                  translate(TranslateConfig.DRINK),
                  modeleReceived?.style?.drink
                    ? translate(TranslateConfig.YES)
                    : translate(TranslateConfig.NO),
                )}


                {renderPersonalItem(
                  returnIconTattoo(
                    modeleReceived?.style?.drink,
                    Colors.white,
                    charIconsSize,
                  ),
                  translate(TranslateConfig.TATTOOS),
                  modeleReceived?.style?.tattoo
                    ? translate(TranslateConfig.YES)
                    : translate(TranslateConfig.NO),
                )}


                {renderPersonalItem(
                  returnIconPiercing(
                    modeleReceived?.style?.piercing,
                    Colors.white,
                    charIconsSize,
                  ),
                  translate(TranslateConfig.PIERCING),
                  modeleReceived?.style?.piercing
                    ? translate(TranslateConfig.YES)
                    : translate(TranslateConfig.NO),
                )}


                {renderPersonalItem(
                  returnIconBreasts(
                    modeleReceived?.style?.breasts,
                    Colors.white,
                    charIconsSize,
                  ),
                  translate(TranslateConfig.BREASTS),
                  modeleReceived?.style?.breasts
                    ? translate(TranslateConfig.YES)
                    : translate(TranslateConfig.NO),
                )}

              </CharItemContainer>
            </CharWrapperFirstContent>
          )
        }



        {(modeleReceived?.appAbout?.hiv && modeleReceived?.appAbout?.hiv !== 'NO_SHOW') && (
          <CharWrapperFirstContent>
            <SectionTitle
              title={translate(TranslateConfig.HEALTH)}
            />


            <CharServiceContainer>

              {modeleReceived?.appAbout?.hiv !== 'NO_SHOW' && renderPersonalItem(
                <IconHiv
                  color={Colors.white}
                  size={charIconsSize}
                />,
                translate(TranslateConfig.HIV),
                hivArray.find((item) => item?.value === modeleReceived?.appAbout?.hiv)?.label,
              )}

            </CharServiceContainer>
          </CharWrapperFirstContent>
        )}



        {languagesUser?.length > 0 && (
          <CharWrapperFirstContent>
            <SectionTitle
              title={translate(TranslateConfig.SPOKEN_LANGS)}
            />


            <CharServiceContainer>
              {languagesUser.map((item: any, index: number) => (
                renderChipLanguages(item, index)
              ))}
            </CharServiceContainer>
          </CharWrapperFirstContent>
        )}



        {tribesUser?.length > 0 && (
          <CharWrapperFirstContent>
            <SectionTitle
              title={translate(TranslateConfig.TRIBES)}
            />


            <CharServiceContainer>
              {tribesUser.map((item: any, index: number) => (
                renderChipLanguages(item, index)
              ))}
            </CharServiceContainer>
          </CharWrapperFirstContent>
        )}



        {imOpen?.length > 0 && (
          <CharWrapperFirstContent>
            <SectionTitle
              title={translate(TranslateConfig.IM_IN)}
            />


            <CharServiceContainer>
              {imOpen.map((item: any, index: number) => (
                renderChipLanguages(item, index)
              ))}
            </CharServiceContainer>
          </CharWrapperFirstContent>
        )}



        {modeleReceived?.contacts?.showSocials && (
          modeleReceived?.contacts?.instagram ||
          modeleReceived?.contacts?.twitter ||
          modeleReceived?.contacts?.facebook ||
          modeleReceived?.contacts?.tiktok ||
          modeleReceived?.contacts?.telegram
        ) && (
            <CharWrapperFirstContent>
              <SectionTitle
                title={translate(TranslateConfig.SOCIAL_MEDIA)}
              />


              <CharItemContainer>

                {modeleReceived?.contacts?.instagram && renderPersonalItem(
                  <IconSocialInstagram
                    color={Colors.white}
                    size={charIconsSize}
                  />,
                  translate(TranslateConfig.INSTAGRAM),
                  modeleReceived?.contacts?.instagram,
                  translate(TranslateConfig.ACTION_CLICK_OPEN),
                  () => {
                    openSite(`http://instagram.com/_u/${modeleReceived?.contacts?.instagram}`);
                  },
                )}


                {modeleReceived?.contacts?.twitter && renderPersonalItem(
                  <IconSocialTwitter
                    color={Colors.white}
                    size={charIconsSize}
                  />,
                  translate(TranslateConfig.TWITTER),
                  modeleReceived?.contacts?.twitter,
                  translate(TranslateConfig.ACTION_CLICK_OPEN),
                  () => {
                    openSite(`http://twitter.com/${modeleReceived?.contacts?.twitter}`);
                  },
                )}


                {modeleReceived?.contacts?.facebook && renderPersonalItem(
                  <IconSocialFacebook
                    color={Colors.white}
                    size={charIconsSize}
                  />,
                  translate(TranslateConfig.FACEBOOK),
                  modeleReceived?.contacts?.facebook,
                  translate(TranslateConfig.ACTION_CLICK_OPEN),
                  () => {
                    openSite(`http://facebook.com/${modeleReceived?.contacts?.facebook}`);
                  },
                )}


                {modeleReceived?.contacts?.tiktok && renderPersonalItem(
                  <IconSocialTiktok
                    color={Colors.white}
                    size={charIconsSize}
                  />,
                  translate(TranslateConfig.TIKTOK),
                  modeleReceived?.contacts?.tiktok,
                  translate(TranslateConfig.ACTION_CLICK_OPEN),
                  () => {
                    openSite(`http://tiktok.com/${modeleReceived?.contacts?.tiktok}`);
                  },
                )}


                {modeleReceived?.contacts?.telegram && renderPersonalItem(
                  <IconSocialTelegram
                    color={Colors.white}
                    size={charIconsSize}
                  />,
                  translate(TranslateConfig.TELEGRAM),
                  modeleReceived?.contacts?.telegram,
                  translate(TranslateConfig.ACTION_CLICK_OPEN),
                  () => {
                    openSite(`http://t.me/${modeleReceived?.contacts?.telegram}`);
                  },
                )}

              </CharItemContainer>
            </CharWrapperFirstContent>
          )
        }



        <CharWrapperSecondContent>
          <SectionTitle
            title={translate(TranslateConfig.STATISTICS)}
          />


          <CharItemContainer>

            {modeleReceived?.reports?.appViews > 0 && renderPersonalItem(
              <IconView
                color={Colors.white}
                size={charIconsSize}
              />,
              translate(TranslateConfig.PROFILE_VISITS),
              modeleReceived?.reports?.appViews,
            )}


            {modeleReceived?.appPrivacity?.showLastLogin && renderPersonalItem(
              <IconAccessLogin
                color={Colors.white}
                size={charIconsSize}
              />,
              translate(TranslateConfig.ACCESS_LAST),
              modeleReceived?.reports?.lastLogin ? FormatExtenseDate(modeleReceived?.reports?.lastLogin) : '',
            )}


            {renderPersonalItem(
              <IconMember
                color={Colors.white}
                size={charIconsSize}
              />,
              translate(TranslateConfig.MEMBER_SINCE),
              modeleReceived?.reports?.createdAt ? FormatExtenseDate(modeleReceived?.reports?.createdAt) : '',
            )}


            {renderPersonalItem(
              <IconProfileEdit
                color={Colors.white}
                size={charIconsSize}
              />,
              translate(TranslateConfig.PROFILE_CHANGED_IN),
              modeleReceived?.reports?.updateAt ? FormatExtenseDate(modeleReceived?.reports?.updateAt) : '',
            )}

          </CharItemContainer>
        </CharWrapperSecondContent>

      </CharCenterFlex>



      <RightContentUser
        showActions={showActions}
        modele={modeleReceived}
        suggestions={suggestionsArray}
      />

    </CharContentCenterRowFlex>

  );
};



export default TabCharacteristicsScreen;
