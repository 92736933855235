import React,
{
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useSelector,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import {
  IInputSelect,
} from '../../../apis/interfaces/App/input-select.interface';

import CardHomeInfoUser from '../../../components/Cards/CardHomeInfoUser';
import CardSuggestionBig from '../../../components/Cards/CardSuggestions/CardSuggestionBig';

import ItemUser from '../../../components/Details/ItemUser';

import {
  IconLiveNow,
} from '../../../components/Icons';

import SectionTitle from '../../../components/Titles/SectionTitle';

import NameRoutes from '../../../navigation/names';

import {
  RootState,
} from '../../../redux';

import {
  Images,
} from '../../../shared/constants';

import {
  EUserType,
} from '../../../shared/enums';

import {
  TranslateConfig,
} from '../../../shared/translations';

import {
  returnCityArray,
  returnStateArray,
} from '../../../shared/utils/address.utils';

import {
  HomeLeftContent,
  HomeStartLeftContent,
  HomeLiveNowLeftContent,
  HomeOptionsLeftContent,
  SuggestionLeftContent,
  SuggestionViewLeftButton,
  LeftBoostLogoImage,
  SuggestionLeftTitle,
  SuggestionLeftText,
  HomeLiveNowView,
  BadgeLiveNowButton,
  BadgeLiveNowImage,
} from './styled';



const LeftSidebarHome: React.FC = () => {
  const history = useHistory();
  const { t: translate } = useTranslation();


  const projectParams = useSelector((state: RootState) => state.appConfigs.modules);

  const user = useSelector((state: RootState) => state.user.actualUser);


  const [modeleBoost] = useState(null);



  function renderItemHeader() {
    const hasLocation = user?.location
      && user?.location?.country
      && user?.location?.state
      && user?.location?.city;

    const countryUser = user?.location?.country;

    const getStateArray = returnStateArray(countryUser);
    const findState = getStateArray?.find((itemFind: IInputSelect) => itemFind.value === user?.location?.state);
    const stateUser = findState?.label;

    const getCityArray = returnCityArray(countryUser, findState?.value);
    const findCity = getCityArray?.find((itemFind: IInputSelect) => itemFind.value === user?.location?.city);
    const cityUser = findCity?.label;


    return (

      <ItemUser
        name={user?.alias}
        username={user?.useralias}
        image={user?.picture}
        country={hasLocation ? countryUser : ''}
        place={hasLocation
          ? `${cityUser}, ${stateUser}`
          : translate(TranslateConfig.EMPTY_LOCATION)
        }
      />

    );
  }



  return (

    <HomeLeftContent>

      <HomeStartLeftContent>

        {user?.type === EUserType.USER && renderItemHeader()}


        {user?.type === EUserType.MODELE && (
          <CardHomeInfoUser />
        )}

      </HomeStartLeftContent>



      <HomeOptionsLeftContent>

        {projectParams?.showRanking && (
          <SuggestionLeftContent>
            <SuggestionViewLeftButton
              onClick={() => {
                history.push(NameRoutes.RankingsScreen);
              }}>
              <SuggestionLeftTitle>
                {translate(TranslateConfig.RANKINGS)}
              </SuggestionLeftTitle>

              <SuggestionLeftText>
                {translate(TranslateConfig.ACTION_OPEN_MORE)}
              </SuggestionLeftText>
            </SuggestionViewLeftButton>
          </SuggestionLeftContent>
        )}



        <SuggestionViewLeftButton
          onClick={() => {
            history.push(NameRoutes.SuggestionScreen);
          }}>
          <SuggestionLeftTitle>
            {translate(TranslateConfig.SUGGESTIONS)}
          </SuggestionLeftTitle>

          <SuggestionLeftText>
            {translate(TranslateConfig.ACTION_OPEN_MORE)}
          </SuggestionLeftText>
        </SuggestionViewLeftButton>

      </HomeOptionsLeftContent>



      {projectParams?.showAds && (
        <HomeOptionsLeftContent>

          <LeftBoostLogoImage
            alt={'boost'}
            src={Images.boostLogo}
          />


          <CardSuggestionBig
            key={modeleBoost?.aliasname}
            online={modeleBoost?.online}
            exclusive={modeleBoost?.exclusive}
            plan={modeleBoost?.plan?.planCurrent}
            image={modeleBoost?.image}
            backgroundImage={modeleBoost?.backgroundImage}
            username={modeleBoost?.aliasname}
            name={modeleBoost?.name}
            verified={modeleBoost?.verified}
            promoter={modeleBoost?.promoter}
            onPress={() => {
              history.push(NameRoutes.ModeleDetailsScreen.replace(
                ':id',
                'useralias',
              ));
            }}
          />

        </HomeOptionsLeftContent>
      )}



      {projectParams?.showLive && (
        <HomeLiveNowLeftContent>
          <HomeLiveNowView>
            <SectionTitle
              title={translate(TranslateConfig.NOW)}
            />

            <IconLiveNow />
          </HomeLiveNowView>


          <BadgeLiveNowButton
            onClick={() => {
              history.push({
                pathname: NameRoutes.LiveRoomCamScreen.replace(
                  ':id',
                  'username',
                ),
                state: {
                  status: null,
                  promo: true,
                },
              });
            }}>
            <BadgeLiveNowImage
              alt={''}
              src={Images.bannerDetails}
            />
          </BadgeLiveNowButton>
        </HomeLiveNowLeftContent>
      )}

    </HomeLeftContent>

  );
};



export default LeftSidebarHome;
