import ApiWS from '../index';



export const GetAllSuggestions = async (genderSearch: string) => {
  const url = '/app/suggestions';

  const payload = {
    gender: genderSearch,
  };

  const response = await ApiWS.get(url, {
    params: payload,
  });
  return response.data;
};



export const GetHomeSuggestions = async (genderSearch: string) => {
  const url = '/app/suggestions/home-suggestions';

  const payload = {
    gender: genderSearch,
  };

  const response = await ApiWS.get(url, {
    params: payload,
  });
  return response.data;
};
