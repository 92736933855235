import React from 'react';

import {
  Helmet,
} from 'react-helmet-async';

import {
  useTranslation,
} from 'react-i18next';

import {
  useSelector,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import ButtonAction from '../../../components/Buttons/ButtonAction';

import {
  Body,
  Content,
  Epigraph,
  Screen,
  SubTitle,
  Title,
} from '../../../components/Composh/web';

import {
  ButtonBack,
} from '../../../components/Controls';

import FooterApp from '../../../components/Footers/FooterApp';
import HeaderScreen from '../../../components/Headers/HeaderScreen';

import {
  openBeModeleWhatsApp,
} from '../../../config/linking.config';

import {
  FormatMoney,
} from '../../../config/mask.config';

import NameRoutes from '../../../navigation/names';

import {
  RootState,
} from '../../../redux';

import {
  Colors,
  Images,
} from '../../../shared/constants';

import {
  EEnvsName,
} from '../../../shared/enums';
import EUserType from '../../../shared/enums/Users/user-type.enum';

import Vars from '../../../shared/environments/variables';

import Seo from '../../../shared/seo';

import {
  TranslateConfig,
} from '../../../shared/translations';

import {
  RentHowWorkContainer,
  RentAdsArticleContainer,
  RentAdsArticleView,
  RentAdsContView,
  RentAdsArticleSubText,
  RentAdsArticleText,
  RentBoldText,
  RentAdsArticleBoldText,
  SuitItemImage,
  RentAdsArticlePrice,
  RentAdsArticleSuit,
  RentAdsArticleButtonView,
  BeModelButtonOrText,
} from './styled';



const RentHowAdsScreen: React.FC = () => {
  const history = useHistory();
  const { t: translate } = useTranslation();


  const genderColorChoose = useSelector((state: RootState) => state.choose.genderColorApp);
  const suitImageChoose = useSelector((state: RootState) => state.choose.suitImageApp);

  const user = useSelector((state: RootState) => state.user.actualUser);
  const availableUser = user?.id;
  const userModele = user?.type;
  const hasRentLevel = false; // FIXME

  const projectParams = useSelector((state: RootState) => state.appConfigs.modules);


  const valueFirstLevel = 2000; // R$ 20,00



  function openRegisterScreen() {
    history.push({
      pathname: NameRoutes.WebViewScreen,
      state: {
        title: translate(TranslateConfig.REGISTER),
        link: Vars().globalAccountRegister,
      },
    });
  }


  function renderRenderAdsTitle(title: string) {
    return (

      <Epigraph.Section
        bold
        text={title}
        textColor={Colors.primary}
        borderBottomWidth={2}
        borderBottomColor={Colors.primary}
        textSize={22}
      />

    );
  }


  function renderHeaderRent() {
    return (

      <SuitItemImage
        src={suitImageChoose}
      />

    );
  }


  function renderTitleRentHowAdsHelmet(): string {
    return `${translate(`${translate(TranslateConfig.ESCORTS)} - ${translate(TranslateConfig.UNDERSTAND_ADS)}`)} | ${translate(TranslateConfig.NAME_APP)}`;
  }

  const urlJsonLd = `${Vars(EEnvsName.PROD).secretioApp}${NameRoutes.RentHowAdsScreen}`;

  const jsonLd = {
    '@context': 'http://schema.org',
    '@type': 'WebSite',
    '@id': urlJsonLd,
    'url': urlJsonLd,
    'name': renderTitleRentHowAdsHelmet(),
    'image': Images.bannerAppLink,
    'description': Seo.descriptionRentHowAds,
    'isPartOf': {
      '@type': 'WebSite',
      'url': Vars(EEnvsName.PROD).secretioApp,
      'name': translate(TranslateConfig.NAME_APP),
    },
  };



  return (

    <Screen
      backgroundColor={Colors.appBackground}>


      <Helmet prioritizeSeoTags>
        <title>
          {renderTitleRentHowAdsHelmet()}
        </title>

        <meta name="robots" content="index,follow" />
        <meta name="googlebot" content="index,follow" />
        <meta name="description" content={Seo.descriptionRentHowAds} />

        <meta name="keywords" content={Seo.keywords} />

        <meta property="og:site_name" content={translate(TranslateConfig.NAME_APP)} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={Images.logo512} />
        <meta property="og:url" content={urlJsonLd} />
        <meta property="og:title" content={renderTitleRentHowAdsHelmet()} />
        <meta property="og:description" content={Seo.descriptionRentHowAds} />

        <link rel="canonical" href={urlJsonLd} />

        <script type="application/ld+json">
          {JSON.stringify(jsonLd)}
        </script>
      </Helmet>



      <HeaderScreen
        showBalance
        showPlace
        backgroundColor={Colors.toolbarBackground}
        subBackgroundColor={Colors.toolbarBackground}
        leftIcon={
          <ButtonBack
            color={Colors.white}
            size={27}
          />
        }
        centerContent={
          <Body>
            <Title
              color={Colors.white}>
              {translate(TranslateConfig.UNDERSTAND_ADS)}
            </Title>

            <SubTitle>
              {translate(TranslateConfig.ESCORTS)}
            </SubTitle>
          </Body>
        }
      />



      <Content>

        <RentHowWorkContainer>

          <RentAdsArticleContainer>

            <RentAdsArticleView>
              {renderRenderAdsTitle(translate(TranslateConfig.ADS_FREE))}


              <RentAdsArticleText>
                {translate(TranslateConfig.ADS_RENT_FREE_1)}
              </RentAdsArticleText>
            </RentAdsArticleView>



            <RentAdsContView>
              {renderRenderAdsTitle(translate(TranslateConfig.ADS_HIGHLIGHT))}


              <RentAdsArticleSubText>
                {translate(TranslateConfig.ADS_BENEFITS)}:
              </RentAdsArticleSubText>

              <RentAdsArticleText>
                {translate(TranslateConfig.ADS_RENT_HIGHLIGHT_TEXT_1)}
              </RentAdsArticleText>

              <RentAdsArticleText>
                <RentAdsArticleBoldText>
                  {translate(TranslateConfig.ADS_RENT_EVIDENCE).toUpperCase()}
                </RentAdsArticleBoldText>
                {' '}
                {translate(TranslateConfig.ADS_RENT_EVIDENCE_1)}
              </RentAdsArticleText>



              <RentAdsArticleSubText>
                {translate(TranslateConfig.ADS_TYPES_HIGHLIGHT)}:
              </RentAdsArticleSubText>

              <RentAdsArticleText>
                {translate(TranslateConfig.ADS_TYPES_HIGHLIGHT_1)} [{renderHeaderRent()}].
              </RentAdsArticleText>

              <RentAdsArticleText>
                {translate(TranslateConfig.ADS_TYPES_HIGHLIGHT_2)} [{renderHeaderRent()}] {translate(TranslateConfig.ADS_TYPES_HIGHLIGHT_3)} [{renderHeaderRent()}{renderHeaderRent()}] {translate(TranslateConfig.ADS_TYPES_HIGHLIGHT_4)}
              </RentAdsArticleText>

              <RentAdsArticleText>
                {translate(TranslateConfig.ADS_LOCATION_HIGHLIGHT_1)} [{renderHeaderRent()}], {translate(TranslateConfig.ADS_LOCATION_HIGHLIGHT_2)} <RentAdsArticleBoldText>{translate(TranslateConfig.ADS_LOCATION_HIGHLIGHT_3)}</RentAdsArticleBoldText> [{renderHeaderRent()}{renderHeaderRent()}] {translate(TranslateConfig.ADS_LOCATION_HIGHLIGHT_4)}
              </RentAdsArticleText>



              <RentAdsArticleSubText>
                {translate(TranslateConfig.ADS_RENT_TIME)}:
              </RentAdsArticleSubText>


              <RentAdsArticleSuit>
                {translate(TranslateConfig.ADS_RENT_HIGH_NUMBER_1)}: {renderHeaderRent()}
              </RentAdsArticleSuit>

              <RentAdsArticlePrice>
                {FormatMoney(valueFirstLevel)}
              </RentAdsArticlePrice>



              <RentAdsArticleSuit>
                {translate(TranslateConfig.ADS_RENT_HIGH_NUMBER_1)}: {renderHeaderRent()}{renderHeaderRent()}
              </RentAdsArticleSuit>

              <RentAdsArticlePrice>
                {FormatMoney(valueFirstLevel * 2)}
              </RentAdsArticlePrice>



              <RentAdsArticleSuit>
                {translate(TranslateConfig.ADS_RENT_HIGH_NUMBER_3)}: {renderHeaderRent()}{renderHeaderRent()}{renderHeaderRent()}
              </RentAdsArticleSuit>

              <RentAdsArticlePrice>
                {FormatMoney(valueFirstLevel * 3)}
              </RentAdsArticlePrice>



              <RentAdsArticleSuit>
                {translate(TranslateConfig.ADS_RENT_HIGH_NUMBER_4)}: {renderHeaderRent()}{renderHeaderRent()}{renderHeaderRent()}{renderHeaderRent()}
              </RentAdsArticleSuit>

              <RentAdsArticlePrice>
                {FormatMoney(valueFirstLevel * 4)}
              </RentAdsArticlePrice>



              <RentAdsArticleSuit>
                {translate(TranslateConfig.ADS_RENT_HIGH_NUMBER_5)}: {renderHeaderRent()}{renderHeaderRent()}{renderHeaderRent()}{renderHeaderRent()}{renderHeaderRent()}
              </RentAdsArticleSuit>

              <RentAdsArticlePrice>
                {FormatMoney(valueFirstLevel * 5)}
              </RentAdsArticlePrice>



              <RentAdsArticleText>
                {translate(TranslateConfig.ADS_RENT_HIGH_NUMBER_MORE)}
              </RentAdsArticleText>



              <RentAdsArticleSubText>
                {translate(TranslateConfig.ADS_RENT_VALIDITY)}:
              </RentAdsArticleSubText>

              <RentAdsArticleText>
                {translate(TranslateConfig.ADS_RENT_VALIDITY_1)} <RentBoldText>{translate(TranslateConfig.ADS_RENT_VALIDITY_DAYS_1)}</RentBoldText> {translate(TranslateConfig.ADS_RENT_VALIDITY_2)}
              </RentAdsArticleText>

              <RentAdsArticleText>
                {translate(TranslateConfig.ADS_RENT_VALIDITY_3)}
              </RentAdsArticleText>



              <RentAdsArticleSubText>
                {translate(TranslateConfig.ADS_RENT_HOW_PROCEED)}
              </RentAdsArticleSubText>

              <RentAdsArticleText>
                {translate(TranslateConfig.ADS_RENT_HOW_PROCEED_1)}
              </RentAdsArticleText>

            </RentAdsContView>



            <RentAdsArticleButtonView>

              {(!availableUser || (availableUser && userModele === EUserType.USER && !hasRentLevel)) && (
                <ButtonAction
                  backgroundColor={genderColorChoose}
                  title={translate(TranslateConfig.ACTION_BE_MODEL)}
                  onPress={() => {
                    if (!availableUser) {
                      openRegisterScreen();
                      return;
                    }

                    history.push(NameRoutes.BeModeleScreen);
                  }}
                />
              )}



              {projectParams?.canAccess && availableUser && userModele === EUserType.MODELE && (
                <ButtonAction
                  backgroundColor={genderColorChoose}
                  title={hasRentLevel
                    ? translate(TranslateConfig.ACTION_LEVEL_CHANGE_HIGHLIGHT)
                    : translate(TranslateConfig.ACTION_LEVEL_UP_HIGHLIGHT)
                  }
                  onPress={() => {
                    history.push(NameRoutes.RentChangeLevelScreen);
                  }}
                />
              )}


              <BeModelButtonOrText>
                {translate(TranslateConfig.OR).toUpperCase()}
              </BeModelButtonOrText>


              <ButtonAction
                title={translate(TranslateConfig.CONTACT_US)}
                backgroundColor={Colors.primaryDark}
                onPress={() => {
                  openBeModeleWhatsApp();
                }}
              />

            </RentAdsArticleButtonView>

          </RentAdsArticleContainer>

        </RentHowWorkContainer>



        <FooterApp />

      </Content>

    </Screen>

  );
};



export default RentHowAdsScreen;
