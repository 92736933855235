import React,
{
  useEffect,
  useState,
} from 'react';

import {
  Helmet,
} from 'react-helmet-async';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import {
  GetUserReviews,
  UserDeleteReview,
} from '../../../apis/endpoints/reviews-user.endpoints';

import CardAdsSuggestion from '../../../components/Ads';
import AlertMessage from '../../../components/Alerts';
import CardComment from '../../../components/Cards/CardComment';

import {
  Container,
  Content,
  Screen,
  Title,
} from '../../../components/Composh/web';

import {
  ButtonBack,
} from '../../../components/Controls';

import EmptyContent from '../../../components/Empty';
import FooterApp from '../../../components/Footers/FooterApp';
import HeaderScreen from '../../../components/Headers/HeaderScreen';
import LoadingScreen from '../../../components/Loadings/LoadingScreen';

import NameRoutes from '../../../navigation/names';

import {
  RootState,
} from '../../../redux';

import {
  APP_MODAL_LOADING_ACTION,
  APP_SNACKBAR_ACTION,
} from '../../../redux/reducers/app-modals.store';

import {
  Colors,
  Images,
} from '../../../shared/constants';

import EResponseStatus from '../../../shared/enums/Response/response-status.enum';

import {
  TranslateConfig,
} from '../../../shared/translations';

import {
  treatErrorAndReturnColor,
  treatErrorAndReturnMessage,
} from '../../../shared/utils/errors.utils';

import {
  TitleUserReviewContainer,
  TitleUserReviewLengthText,
  ContentWrapperRowFull,
  HighlightEventScrollFlatList,
} from './styled';



const CommentDoneScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t: translate } = useTranslation();


  const user = useSelector((state: RootState) => state.user.actualUser);


  const [reviewsUser, setReviewsUser] = useState<Array<any>>([]);

  const [deleteReviewModal, setDeleteReviewModal] = useState<number | null>(null);

  const [loading, setLoading] = useState(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION, payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  async function getModeleComments() {
    setLoading(true);

    try {
      const response = await GetUserReviews(user?.useralias);

      const responseModele = response?.data;

      if (!responseModele) {
        console.error('Error', response);
        setReviewsUser([]);
        showSnackBarProps(Colors.danger, translate(TranslateConfig.EMPTY_HOME));
        return;
      }

      setReviewsUser(responseModele);
    }
    catch (error: any) {
      console.error(error);
      setReviewsUser([]);

      const getMessageError = treatErrorAndReturnMessage(error);
      const messageError = translate(getMessageError);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      setLoading(false);
    }
  }


  async function deleteReview(idComment: number) {
    setDeleteReviewModal(null);

    try {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

      const response = await UserDeleteReview(idComment, user?.id);

      if (response?.status === EResponseStatus.ERROR) {
        console.error('Error', response);
        showSnackBarProps(Colors.danger, translate(TranslateConfig.ERROR_COMMENT_DELETED));
        return;
      }

      showSnackBarProps(Colors.accept, translate(TranslateConfig.SUCCESS_COMMENT_DELETED));
      getModeleComments();
    }
    catch (error: any) {
      console.error(error);

      const getMessageError = treatErrorAndReturnMessage(error);
      const messageError = translate(getMessageError);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  }


  function renderItem(item: any, index: number) {
    return (

      <CardComment
        key={index}
        edit
        type={'USER'}
        visible={item?.visible}
        alias={item?.userReceived?.alias}
        plan={item?.userReceived?.plan?.planCurrent}
        image={item?.userReceived?.picture}
        createdAt={item?.createdAt}
        rating={item?.rating}
        dateMeet={item?.date}
        comment={item?.comment}
        meet={item?.meet}
        ambience={item?.ambience}
        medias={item?.medias}
        recommend={item?.recommend}

        onImagePress={() => {
          history.push(NameRoutes.ModeleDetailsScreen.replace(
            ':id',
            item?.userReceived?.useralias,
          ));
        }}

        onEditPress={() => {
          history.push({
            pathname: NameRoutes.DoCommentModeleScreen.replace(
              ':id',
              item?.userReceived?.useralias,
            ),
            state: {
              review: item,
            },
          });
        }}
        onDeletePress={() => {
          setDeleteReviewModal(item?.id);
        }}
      />

    );
  }


  function renderCommentNumberTitle(): string {
    const numberToCount = reviewsUser?.length;

    if (loading) {
      return '';
    }

    if (numberToCount <= 0) {
      return translate(TranslateConfig.EMPTY_DASH_COMMENT);
    }

    if (numberToCount === 1) {
      return `1 ${translate(TranslateConfig.COMMENT)}`;
    }

    if (numberToCount > 1) {
      return `${numberToCount} ${translate(TranslateConfig.COMMENTS)}`;
    }

    return '';
  }


  function listHeaderComponent() {
    if (!renderCommentNumberTitle()) {
      return null;
    }

    return (

      <TitleUserReviewContainer>

        <TitleUserReviewLengthText>
          {renderCommentNumberTitle()}
        </TitleUserReviewLengthText>

      </TitleUserReviewContainer>

    );
  }


  function renderLoading() {
    return (

      <LoadingScreen
        type={'SCREEN'}
        color={Colors.white}
      />

    );
  }


  function listEmptyComponent() {
    return (

      <EmptyContent
        image={Images.noCommentsDone}
        title={translate(TranslateConfig.EMPTY_DO_COMMENTS)}
        description={translate(TranslateConfig.EMPTY_DO_COMMENTS_TEXT)}
      />

    );
  }



  useEffect(() => {
    getModeleComments();
  }, []);



  return (

    <Screen
      backgroundColor={Colors.appBackground}>


      <Helmet prioritizeSeoTags>
        <title>
          {`${translate(TranslateConfig.COMMENTS_DONE)} | ${translate(TranslateConfig.NAME_APP)}`}
        </title>

        <meta name="robots" content="noindex,nofollow" />
        <meta name="googlebot" content="noindex,nofollow" />
      </Helmet>



      <Container>

        <HeaderScreen
          disableGender
          backgroundColor={Colors.toolbarBackground}
          subBackgroundColor={Colors.toolbarBackground}
          leftIcon={
            <ButtonBack
              color={Colors.white}
              size={27}
            />
          }
          centerContent={
            <Title>
              {translate(TranslateConfig.COMMENTS_DONE)}
            </Title>
          }
        />



        <Content>

          <ContentWrapperRowFull>

            <HighlightEventScrollFlatList
              noScroll
              loading={loading}
              data={reviewsUser}
              renderItem={renderItem}
              listHeaderComponent={listHeaderComponent()}
              listLoadingComponent={renderLoading()}
              listEmptyComponent={listEmptyComponent()}
            />



            <CardAdsSuggestion />

          </ContentWrapperRowFull>



          <FooterApp />

        </Content>

      </Container>



      <AlertMessage
        visible={Boolean(deleteReviewModal)}
        title={translate(TranslateConfig.QUESTION_COMMENT_DELETED)}
        cancelText={translate(TranslateConfig.NO)}
        cancelPress={() => {
          setDeleteReviewModal(null);
        }}
        okText={translate(TranslateConfig.YES)}
        okPress={() => {
          deleteReview(deleteReviewModal);
        }}
      />

    </Screen>

  );
};



export default CommentDoneScreen;
