import ApiWS from '../index';



export const GetHelpFaq = async (useralias: string) => {
  const url = `/help/${useralias}`;

  const response = await ApiWS.get(url);
  return response.data;
};
