import React,
{
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  Helmet,
} from 'react-helmet-async';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import {
  GetAllRents,
} from '../../../apis/endpoints/rent.endpoint';

import {
  IInputSelect,
} from '../../../apis/interfaces/App/input-select.interface';

import CardAdsSuggestion from '../../../components/Ads';
import CardModeles from '../../../components/Cards/CardModeles';

import {
  Body,
  Container,
  Content,
  Screen,
  SubTitle,
  Title,
} from '../../../components/Composh/web';

import {
  ButtonDrawer,
} from '../../../components/Controls';

import EmptyContent from '../../../components/Empty';
import FooterApp from '../../../components/Footers/FooterApp';
import HeaderScreen from '../../../components/Headers/HeaderScreen';

import {
  IconBed,
  IconFilter,
  IconFlagHome,
  IconInformation,
  IconPenis,
} from '../../../components/Icons';

import LoadingScreen from '../../../components/Loadings/LoadingScreen';
import CardModeleBoost from '../../../components/Sections/CardModeleBoost';
import CardModeleDay from '../../../components/Sections/CardModeleDay';

import {
  SetEmptyGenderImage,
  SetGenrePluralText,
  SetLightGenreText,
} from '../../../config/genre.config';

import NameRoutes from '../../../navigation/names';

import {
  RootState,
} from '../../../redux';

import {
  APP_MENU_SELECTED_ACTION,
  APP_SNACKBAR_ACTION,
  MODAL_LOCATION_APP_ACTION,
} from '../../../redux/reducers/app-modals.store';

import {
  countriesOption,
} from '../../../shared/arrays/App/countries.array';

import {
  Colors,
  Images,
} from '../../../shared/constants';

import {
  EEnvsName,
  EGenderUser,
  EPlansUser,
} from '../../../shared/enums';

import EDrawerMenu from '../../../shared/enums/App/drawer-menu.enum';

import Vars from '../../../shared/environments/variables';

import Seo from '../../../shared/seo';

import {
  TranslateConfig,
} from '../../../shared/translations';

import {
  returnCityArray,
  returnStateArray,
} from '../../../shared/utils/address.utils';

import {
  treatErrorAndReturnColor,
  treatErrorAndReturnMessage,
} from '../../../shared/utils/errors.utils';

import {
  stringRawDataText,
} from '../../../shared/utils/string.utils';

import {
  RentContentPadder,
  RentViewContent,
  RentScreenHeader,
  SectionTitleStyled,
  MessageRentHeader,
  RentWrapperActionsView,
  RentWrapSortableButton,
  HowRentButtonAction,
  HowRentButtonText,
  SectionLevelTitleStyled,
  RentItemView,
  RentItemTitle,
  RentImageInfiniteContainer,
  RentInfiniteImage,
  RentImageLevelContainer,
  RentItemImage,
  HomeHighlightsContainer,
  ModeleItemGrid,
  RentItemContent,
} from './styled';



const RentHomeScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t: translate } = useTranslation();


  const genderColorChoose = useSelector((state: RootState) => state.choose.genderColorApp);
  const accentGender = genderColorChoose;

  const genderChoose = useSelector((state: RootState) => state.choose.genderChoose);
  const localities = useSelector((state: RootState) => state.localities.selected);

  const suitImageChoose = useSelector((state: RootState) => state.choose.suitImageApp);

  const projectParams = useSelector((state: RootState) => state.appConfigs.modules);


  const colorSelected = Colors.primary;
  const colorUnselected = Colors.unselected;

  const [cityScreen, setCityScreen] = useState<string>('');
  const [stateScreen, setStateScreen] = useState<string>('');
  const [countryScreen, setCountryScreen] = useState<string>('');

  const [showCock, setShowCock] = useState<boolean>(false);
  const [showPosition, setShowPosition] = useState<boolean>(false);
  const [showFlagsHome, setShowFlagsHome] = useState<boolean>(true);

  const [modeleDay, setModeleDay] = useState(null);
  const [boostModele, setBoostModele] = useState(null);

  const [diamondModeles, setDiamondModeles] = useState<Array<any>>([]);
  const [modeleHighlights, setModeleHighlights] = useState<Array<any>>([]);
  const [showFaceModeles, setShowFaceModeles] = useState<Array<any>>([]);
  const [hideFaceModeles, setHideFaceModeles] = useState<Array<any>>([]);

  const [totalModeles, setTotalModeles] = useState<number>(0);

  const [loading, setLoading] = useState(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION,
      payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  function getLocalitiesLabel() {
    const countryUser = localities?.country;
    const filterCountry = countriesOption.find((country) => stringRawDataText(country?.code) === stringRawDataText(countryUser));
    setCountryScreen(translate(filterCountry?.label));

    const getStateArray = returnStateArray(countryUser);
    const findState = getStateArray?.find((item: IInputSelect) => item.value === localities?.state);
    const stateUser = findState?.label;
    setStateScreen(stateUser);

    const getCityArray = returnCityArray(countryUser, findState?.value);
    const findCity = getCityArray?.find((item: IInputSelect) => item.value === localities?.city);
    const cityUser = findCity?.label;
    setCityScreen(cityUser);
  }


  function treatRentList(dataResponse: any) {
    const modeleDayResponse = dataResponse?.modeleDay;
    setModeleDay(modeleDayResponse);

    const boostResponse = dataResponse?.modeleBoost;
    setBoostModele(boostResponse);


    // Filtra usuários com plano DIAMOND
    const diamond = dataResponse?.highlights?.filter((modele) => modele?.plan?.planCurrent === 'DIAMOND');
    setDiamondModeles(diamond);

    // Filtra outros usuários, removendo aqueles com plano DIAMOND
    const nonDiamond = dataResponse?.highlights?.filter((modele) => modele?.plan?.planCurrent !== 'DIAMOND');
    setModeleHighlights(nonDiamond);

    const showFaceDiamond = dataResponse?.showFace?.filter((modele) => modele?.plan?.planCurrent === 'DIAMOND');
    setDiamondModeles([
      ...diamond,
      ...showFaceDiamond,
    ]);

    // Adiciona usuários com plano DIAMOND à lista showFaceModeles
    const showFaceNonDiamond = dataResponse?.showFace?.filter((modele) => modele?.plan?.planCurrent !== 'DIAMOND');
    setShowFaceModeles(showFaceNonDiamond);

    setHideFaceModeles(dataResponse?.hideFace);


    const numberHighlights: number = dataResponse?.highlights?.length;
    const numberShowFace: number = dataResponse?.showFace?.length;
    const numberHideFace: number = dataResponse?.hideFace?.length;

    setTotalModeles(
      numberHighlights +
      numberShowFace +
      numberHideFace +
      (modeleDayResponse ? 1 : 0) +
      (boostResponse ? 1 : 0),
    );
  }


  async function loadAllRents() {
    setLoading(true);

    try {
      const response = await GetAllRents(genderChoose, localities);
      const responseModele = response?.data;

      if (!responseModele) {
        console.error('Error', response);
        showSnackBarProps(Colors.danger, translate(TranslateConfig.EMPTY_HOME));
        return;
      }

      treatRentList(responseModele);
    }
    catch (error: any) {
      console.error(error);
      setModeleDay(null);
      setBoostModele(null);

      setModeleHighlights([]);
      setShowFaceModeles([]);
      setHideFaceModeles([]);

      setTotalModeles(0);

      const getMessageError = treatErrorAndReturnMessage(error);
      const messageError = translate(getMessageError);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      setLoading(false);
    }
  };


  function openDetailsModele(useralias: string) {
    history.push(NameRoutes.ModeleDetailsScreen.replace(
      ':id',
      useralias,
    ));
  }


  function renderItem(item: any, index: number) {
    const hasLocation = item?.location
      && item?.location?.country
      && item?.location?.state
      && item?.location?.city;

    const countryUser = item?.location?.country;

    const getStateArray = returnStateArray(countryUser);
    const findState = getStateArray?.find((itemFind: IInputSelect) => itemFind.value === item?.location?.state);
    const stateUser = findState?.label;

    const getCityArray = returnCityArray(countryUser, findState?.value);
    const findCity = getCityArray?.find((itemFind: IInputSelect) => itemFind.value === item?.location?.city);
    const cityUser = findCity?.label;


    return (

      <CardModeles
        key={index}
        disabled={loading}
        comeNow={item?.plan?.planCurrent !== EPlansUser.DIAMOND
          ? item?.appRentHighlight?.length > 0
          : false
        }
        isOnTravel={item?.isTravel}
        showFlag={showFlagsHome}
        showCock={showCock}
        cock={item?.characters?.cockSize}
        showPosition={showPosition}
        position={item?.characters?.position}
        image={item?.picture}
        plan={item?.plan?.planCurrent}
        name={item?.alias}
        username={item?.useralias}
        online={item?.online}
        verified={item?.verified}
        brandPromoter={item?.brandPromoter}
        all24h={item?.contacts?.phoneNumber24h}
        labelPlace={translate(TranslateConfig.FROM)}
        country={hasLocation ? countryUser : ''}
        place={hasLocation
          ? `${cityUser}, ${stateUser}`
          : translate(TranslateConfig.EMPTY_LOCATION)
        }
        onPress={() => {
          openDetailsModele(item?.useralias);
        }}
      />

    );
  }


  function listHeaderComponent() {
    return (

      <RentScreenHeader>

        <SectionTitleStyled
          title={totalModeles > 0
            ? `${translate(TranslateConfig.ADS)}: ${totalModeles}`
            : translate(TranslateConfig.EMPTY_ADS)
          }
          subtitle={cityScreen && stateScreen ? `${cityScreen}, ${stateScreen}` : '-'}
        />



        <MessageRentHeader>

          <RentWrapperActionsView>

            {(genderChoose === EGenderUser.MAN || genderChoose === EGenderUser.TRANS_WOMAN) && (
              <RentWrapSortableButton
                title={translate(TranslateConfig.FILTER_COCK)}
                onClick={() => {
                  setShowCock(!showCock);
                }}>
                <IconPenis
                  color={showCock ? colorSelected : colorUnselected}
                  size={26}
                />
              </RentWrapSortableButton>
            )}



            {(genderChoose === EGenderUser.MAN || genderChoose === EGenderUser.TRANS_WOMAN) && (
              <RentWrapSortableButton
                title={translate(TranslateConfig.FILTER_POSITION)}
                onClick={() => {
                  setShowPosition(!showPosition);
                }}>
                <IconBed
                  color={showPosition ? colorSelected : colorUnselected}
                  size={26}
                />
              </RentWrapSortableButton>
            )}



            <RentWrapSortableButton
              title={translate(TranslateConfig.FILTER_WHERE_FROM)}
              onClick={() => {
                setShowFlagsHome(!showFlagsHome);
              }}>
              <IconFlagHome
                color={showFlagsHome ? colorSelected : colorUnselected}
                size={26}
              />
            </RentWrapSortableButton>

          </RentWrapperActionsView>



          {/*
          <FilterRentButtonAction
            iconLeft={
              <IconFilter
                color={Colors.white}
                size={20}
              />
            }
            title={'Filtros'}
            backgroundColor={Colors.cardBackground}
            onPress={() => {
              // 
            }}
          />
          */}


          <HowRentButtonAction
            onClick={() => {
              history.push(NameRoutes.RentHowAdsScreen);
            }}>
            <IconInformation
              color={Colors.white}
              size={20}
            />

            <HowRentButtonText>
              {translate(TranslateConfig.UNDERSTAND_ADS)}
            </HowRentButtonText>
          </HowRentButtonAction>

        </MessageRentHeader>

      </RentScreenHeader>

    );
  }



  function renderDiamondHeader() {
    return (

      <SectionLevelTitleStyled
        title={
          <RentItemView>

            <RentItemTitle>
              {translate(TranslateConfig.LEVEL)}:
            </RentItemTitle>


            <RentImageInfiniteContainer>
              <RentInfiniteImage
                src={Images.infinite}
              />
            </RentImageInfiniteContainer>

          </RentItemView>
        }
      />

    );
  }


  function renderHeaderRent(value: number) {
    const loopValue = value;
    const rows = [];

    for (let index = 0; index < loopValue; index++) {
      rows.push(
        <RentImageLevelContainer>
          <RentItemImage
            key={index}
            src={suitImageChoose}
          />
        </RentImageLevelContainer>,
      );
    }



    return (

      <SectionLevelTitleStyled
        title={
          <RentItemView>

            <RentItemTitle>
              {translate(TranslateConfig.LEVEL)}:
            </RentItemTitle>

            {rows}

          </RentItemView>
        }
      />

    );
  }


  function renderLoading() {
    return (

      <LoadingScreen
        type={'SCREEN'}
        color={Colors.white}
      />

    );
  }


  function listEmptyComponent() {
    return (

      <EmptyContent
        center={false}
        image={SetEmptyGenderImage(genderChoose)}
        title={translate(TranslateConfig.EMPTY_MODELES)}
        description={translate(TranslateConfig.EMPTY_MODELES_TEXT)}
        buttonText={translate(TranslateConfig.ACTION_CHOOSE_LOCATION)}
        buttonColor={accentGender}
        onPress={() => {
          dispatch({ type: MODAL_LOCATION_APP_ACTION, payload: true });
        }}
      />

    );
  }



  // Agrupar modeleHighlights por appRentLevel.level e ordenar em ordem decrescente
  const groupedHighlights = useMemo(() => {
    const grouped: { [level: number]: any[] } = {};

    modeleHighlights.forEach((highlight) => {
      const level = highlight?.appRentLevel[0].level;
      if (!grouped[level]) {
        grouped[level] = [];
      }
      grouped[level].push(highlight);
    });

    return grouped;
  }, [modeleHighlights]);



  useEffect(() => {
    if (genderChoose === EGenderUser.MAN || genderChoose === EGenderUser.TRANS_WOMAN) {
      setShowCock(false);
      setShowPosition(false);
    }

    getLocalitiesLabel();

    loadAllRents();
  }, [genderChoose, localities]);


  useEffect(() => {
    dispatch({ type: APP_MENU_SELECTED_ACTION, payload: EDrawerMenu.RENT });
  }, []);



  // Função para renderizar a seção DIAMOND
  function renderDiamondRent() {
    return (
      <RentItemContent>

        <ModeleItemGrid
          noScroll
          listHeaderComponent={renderDiamondHeader()}
          data={diamondModeles}
          renderItem={renderItem}
        />

      </RentItemContent>

    );
  }


  // Renderizar cada grupo de modeleHighlights em ordem decrescente
  function renderHighlightGroups() {
    return Object.entries(groupedHighlights)
      .sort(([levelA], [levelB]) => Number(levelB) - Number(levelA)) // Ordenar os grupos em ordem decrescente
      .map(([level, highlights], index) => {
        // Dividir os modelos em duas listas: com appRentHighlight e sem appRentHighlight
        const withHighlight = highlights.filter((highlight) => highlight.appRentHighlight?.length > 0);
        const withoutHighlight = highlights.filter((highlight) => highlight.appRentHighlight?.length === 0);

        // Ordenar a lista com appRentHighlight pelo createdAt
        withHighlight.sort((a, b) => {
          const dateA = new Date(a.appRentHighlight[0]?.createdAt).getTime();
          const dateB = new Date(b.appRentHighlight[0]?.createdAt).getTime();
          return dateA - dateB; // Ordena em ordem crescente pelo createdAt
        });

        // Combina as listas: com appRentHighlight (ordenada) seguida por sem appRentHighlight
        const combinedHighlights = [...withHighlight, ...withoutHighlight];

        return (

          <RentItemContent
            key={index}>

            <ModeleItemGrid
              noScroll
              listHeaderComponent={renderHeaderRent(Number(level))}
              data={combinedHighlights}
              renderItem={renderItem}
            />

          </RentItemContent>

        );
      });
  }


  function renderTitleRentHelmet(): string {
    let titleRender = `${translate(TranslateConfig.ESCORTS)} ${translate(SetGenrePluralText(genderChoose))}`;

    if (cityScreen && stateScreen && countryScreen) {
      titleRender = `${translate(TranslateConfig.ESCORTS)} ${translate(SetGenrePluralText(genderChoose))} - ${cityScreen}, ${stateScreen}, ${countryScreen}`;
    }

    return `${titleRender} | ${translate(TranslateConfig.NAME_APP)}`;
  }


  function renderDescriptionRentHelmet(): string {
    let descriptionRender = `${translate(TranslateConfig.NAME_APP)}. ${Seo.descriptionRent}`;

    if (cityScreen && stateScreen && countryScreen) {
      descriptionRender = `${translate(TranslateConfig.NAME_APP)}: ${translate(TranslateConfig.ESCORTS)} ${translate(SetGenrePluralText(genderChoose))} - ${cityScreen}, ${stateScreen}, ${countryScreen}. ${Seo.descriptionRent}`;
    }

    return descriptionRender;
  }


  function renderUrlRentHelmet(): string {
    const currentUrl = window.location.href;
    if (currentUrl) {
      return currentUrl;
    }

    return `${Vars(EEnvsName.PROD).secretioApp}${NameRoutes.RentHomeScreen}`;
  }


  const urlJsonLd = `${Vars(EEnvsName.PROD).secretioApp}${NameRoutes.RentHomeScreen}`;

  const jsonLd = {
    '@context': 'http://schema.org',
    '@type': 'WebSite',
    '@id': urlJsonLd,
    'url': urlJsonLd,
    'name': renderTitleRentHelmet(),
    'image': Images.bannerAppLink,
    'description': renderDescriptionRentHelmet(),
    'potentialAction': {
      '@type': 'SearchAction',
      'target': `${Vars().secretioApp}/search?query={search_term_string}`,
      'query-input': 'required name=search_term_string',
    },
    'isPartOf': {
      '@type': 'WebSite',
      'url': Vars(EEnvsName.PROD).secretioApp,
      'name': translate(TranslateConfig.NAME_APP),
    },
    'sameAs': [
      'https://www.facebook.com/secretio',
      'https://twitter.com/secretio',
      'https://www.linkedin.com/company/secretio',
    ],
  };



  return (

    <Screen
      backgroundColor={Colors.appBackground}>


      <Helmet prioritizeSeoTags>
        <title>
          {renderTitleRentHelmet()}
        </title>

        <meta name="robots" content="index,follow" />
        <meta name="googlebot" content="index,follow" />
        <meta name="description" content={renderDescriptionRentHelmet()} />

        <meta name="keywords" content={Seo.keywords} />

        <meta property="og:site_name" content={translate(TranslateConfig.NAME_APP)} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={Images.bannerAppLink} />
        <meta property="og:url" content={renderUrlRentHelmet()} />
        <meta property="og:title" content={renderTitleRentHelmet()} />
        <meta property="og:description" content={renderDescriptionRentHelmet()} />

        <link rel="canonical" href={`${Vars(EEnvsName.PROD).secretioApp}${NameRoutes.RentHomeScreen}`} />

        <link rel="alternate" href={`${Vars(EEnvsName.PROD).secretioApp}${NameRoutes.RentHomeScreen}`} hrefLang="x-default" />
        <link rel="alternate" href={`${Vars(EEnvsName.PROD).secretioApp}${NameRoutes.RentHomeScreen}?hl=en`} hrefLang="en" />

        <script type="application/ld+json">
          {JSON.stringify(jsonLd)}
        </script>
      </Helmet>



      <HeaderScreen
        screenKey={EDrawerMenu.RENT}
        showBalance
        showPlace
        backgroundColor={Colors.toolbarBackground}
        subBackgroundColor={Colors.toolbarBackground}
        leftIcon={projectParams?.canAccess
          ? (
            <ButtonDrawer
              color={Colors.white}
              size={27}
            />
          )
          : null
        }
        centerContent={
          <Body>
            <Title
              color={Colors.white}>
              {translate(TranslateConfig.ESCORTS)}
            </Title>

            <SubTitle>
              {translate(SetLightGenreText(genderChoose))}
            </SubTitle>
          </Body>
        }
      />



      <Content>

        {loading && (
          renderLoading()
        )}



        {!loading && (
          <RentContentPadder>

            <RentViewContent>

              {listHeaderComponent()}



              {totalModeles === 0 && (
                listEmptyComponent()
              )}



              {totalModeles > 0 && (
                <Container>

                  {(modeleDay || boostModele) && (
                    <HomeHighlightsContainer>

                      {modeleDay && (
                        <CardModeleDay
                          modele={modeleDay}
                          onPress={() => {
                            // TODO
                            // openDetailsModele('name34');
                          }}
                        />
                      )}



                      {boostModele && (
                        <CardModeleBoost
                          modele={boostModele}
                          onPress={() => {
                            // TODO
                            // openDetailsModele('name34');
                          }}
                        />
                      )}

                    </HomeHighlightsContainer>
                  )}



                  {diamondModeles.length > 0 && renderDiamondRent()}



                  {renderHighlightGroups()}



                  {showFaceModeles && (
                    <RentItemContent
                      noMarginBottom={!hideFaceModeles || hideFaceModeles?.length <= 0}>
                      <ModeleItemGrid
                        noScroll
                        listHeaderComponent={
                          <SectionLevelTitleStyled
                            title={translate(TranslateConfig.HIGHLIGHTS)} // Modelos Gratuitas e que mostram o rosto
                          />
                        }
                        data={showFaceModeles}
                        renderItem={renderItem}
                      />
                    </RentItemContent>
                  )}



                  {hideFaceModeles && (
                    <RentItemContent
                      noMarginBottom>
                      <ModeleItemGrid
                        noScroll
                        listHeaderComponent={
                          <SectionLevelTitleStyled
                            title={translate(TranslateConfig.MORE_PROFILES)} // Todo o restante de Modelos Gratuitas 
                          />
                        }
                        data={hideFaceModeles}
                        renderItem={renderItem}
                      />
                    </RentItemContent>
                  )}



                  {/* TODO: Fazer uma seção que mostre as cidades próximas */}

                </Container>
              )}

            </RentViewContent>



            <CardAdsSuggestion />

          </RentContentPadder>
        )}



        <FooterApp />

      </Content>

    </Screen>

  );
};



export default RentHomeScreen;
