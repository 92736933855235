import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import Color from 'color';

import {
  PostFavoriteModele,
} from '../../../../apis/endpoints/users-favorites.endpoints';

import {
  IInputSelect,
} from '../../../../apis/interfaces/App/input-select.interface';

import ButtonDoModules from '../../../../components/Buttons/ButtonDoModules';
import ButtonSendMessage from '../../../../components/Buttons/ButtonSendMessage';
import UserAvatar from '../../../../components/Contents/UserAvatar';

import {
  IconFavoriteOff,
  IconUserFollow,
  IconStar,
  IconRoomOpen,
  IconRoomClose,
  IconRentOn,
  IconRentOff,
  IconBlock,
  IconReport,
  IconVerifiedPromoter,
  IconFavoriteOn,
  IconVerified,
  IconTipGifts,
  IconShare,
} from '../../../../components/Icons';

import NameRoutes from '../../../../navigation/names';

import {
  RootState,
} from '../../../../redux';

import {
  APP_MODAL_LOADING_ACTION,
  APP_SNACKBAR_ACTION,
  USER_FACLUB_MODAL,
  USER_BRAND_PROMOTER_MODAL,
  USER_VERIFIED_MODAL,
  USER_SEND_GIFT_MODAL,
  USER_BAD_REPORT_MODAL,
} from '../../../../redux/reducers/app-modals.store';

import {
  FAVORITES_SET,
} from '../../../../redux/reducers/favorites.store';

import {
  Colors,
  Images,
} from '../../../../shared/constants';

import {
  EUserFavoritesType,
} from '../../../../shared/enums/Users/user-favorites.enum';

import {
  TranslateConfig,
} from '../../../../shared/translations';

import {
  returnCityArray,
  returnStateArray,
} from '../../../../shared/utils/address.utils';

import {
  treatErrorAndReturnColor,
  treatErrorAndReturnMessage,
} from '../../../../shared/utils/errors.utils';

import {
  validateString,
} from '../../../../shared/utils/string.utils';

import DetailsSectionTravel from './SectionTravel';

import {
  HeaderUserHeaderView,
  HeaderUserBackgroundView,
  HeaderUserBackgroundOverlay,
  HeaderUserBackgroundImage,
  HeaderUserInfoView,
  HeaderUserInfoContent,
  HeaderUserAvatarImage,
  HeaderUserStatusView,
  HeaderStatusUsersBadge,
  HeaderUserLastAccessText,
  HeaderInfoContentView,
  HeaderUserExclusiveText,
  HeaderNameText,
  SubtitleLongView,
  HeaderUserNameText,
  IconVerifiedButton,
  HeaderUserFollowItemView,
  HeaderUserFollowView,
  HeaderUserAvaliatinsView,
  HeaderUserFollowIconText,
  HeaderUserFollowText,
  HeaderUserFollowContent,
  HeaderUserActionsBadgeView,
  HeaderUserTravelView,
  HeaderUserColumnActionView,
  HeaderUserRowActionView,
  HeaderUserActionButton,
} from './styled';



export interface IProps {
  showActions?: boolean;
  modele?: any;
  openShareModal?: any;
}



const ModeleDetailHeader: React.FC<IProps> = (props: IProps) => {
  const modeleReceived = props.modele;
  const showActions = props.showActions;


  const dispatch = useDispatch();
  const history = useHistory();
  const { t: translate } = useTranslation();


  const user = useSelector((state: RootState) => state.user.actualUser);
  const availableUser = user?.id;

  const genderColorChoose = useSelector((state: RootState) => state.choose.genderColorApp);
  const accentGender = Color(genderColorChoose).darken(0.5).toString();

  const favoritesUser: Array<any> = useSelector((state: RootState) => state.favorites.actualFavorites);
  const verifyItem = favoritesUser?.length > 0 ? favoritesUser?.some((valueItem) => valueItem?.relationId === modeleReceived?.id) : false;

  const projectParams = useSelector((state: RootState) => state.appConfigs.modules);


  const iconRating = 16;
  const iconActions = 17;

  const userPicture = validateString(modeleReceived?.picture) || Images.avatarUserImage;
  const userCover = validateString(modeleReceived?.pictureCover) || Images.bannerDetails;



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION, payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  async function onFavoritePress() {
    try {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

      const response = await PostFavoriteModele(availableUser, modeleReceived?.id, EUserFavoritesType.MODELE);

      if (!response?.data || response?.hasError) {
        showSnackBarProps(Colors.accept,
          verifyItem
            ? translate(TranslateConfig.ERROR_FAVORITE_REMOVE)
            : translate(TranslateConfig.ERROR_FAVORITE_ADD),
        );
        return;
      }

      dispatch({ type: FAVORITES_SET, payload: response?.data || [] });

      showSnackBarProps(Colors.accept,
        verifyItem
          ? translate(TranslateConfig.SUCCESS_FAVORITE_REMOVE)
          : translate(TranslateConfig.SUCCESS_FAVORITE_ADD),
      );
    }
    catch (error: any) {
      console.error(error);

      const getMessageError = treatErrorAndReturnMessage(error);
      const messageError = translate(getMessageError);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  }


  function renderFollowTitle(): string {
    const numberToCount = modeleReceived?.totalFollow;

    if (numberToCount === 1) {
      return `1 ${translate(TranslateConfig.FOLLOWER)}`;
    }

    if (numberToCount > 1) {
      return `${numberToCount} ${translate(TranslateConfig.FOLLOWERS)}`;
    }

    return translate(TranslateConfig.FOLLOWERS_NONE);
  }


  function renderCommentNumberTitle(): string {
    const numberToCount = modeleReceived?.appReviewsReceived?.length;

    if (numberToCount === 1) {
      return `1 ${translate(TranslateConfig.COMMENT)}`;
    }

    if (numberToCount > 1) {
      return `${numberToCount} ${translate(TranslateConfig.COMMENTS)}`;
    }

    return translate(TranslateConfig.EMPTY_DASH_COMMENT);
  }


  function renderUserTravel() {
    const hasLocation = modeleReceived?.location
      && modeleReceived?.location?.country
      && modeleReceived?.location?.state
      && modeleReceived?.location?.city;

    const countryUser = modeleReceived?.location?.country;

    const getStateArray = returnStateArray(countryUser);
    const findState = getStateArray?.find((item: IInputSelect) => item.value === modeleReceived?.location?.state);
    const stateUser = findState?.label;

    const getCityArray = returnCityArray(countryUser, findState?.value);
    const findCity = getCityArray?.find((item: IInputSelect) => item.value === modeleReceived?.location?.city);
    const cityUser = findCity?.label;


    return (

      <DetailsSectionTravel
        name={modeleReceived?.alias}
        from={hasLocation
          ? `${cityUser}, ${stateUser}`
          : translate(TranslateConfig.EMPTY_LOCATION)
        }
        fromCountry={countryUser}
        travels={modeleReceived?.appTravels}
      />

    );
  }


  function renderStatusColor(): string {
    return Colors.grayDark;
  }



  return (

    <HeaderUserHeaderView>

      <HeaderUserBackgroundView>

        <HeaderUserBackgroundOverlay />


        <HeaderUserBackgroundImage
          alt={''}
          src={userCover}
        />

      </HeaderUserBackgroundView>



      <HeaderUserInfoView>
        <HeaderUserInfoContent>

          <HeaderUserAvatarImage>
            <UserAvatar
              width={200}
              height={275}
              plan={modeleReceived?.plan?.planCurrent}
              image={userPicture}
            />


            {projectParams?.canBeOnline && (
              <HeaderUserStatusView>

                <HeaderStatusUsersBadge
                  backgroundColor={renderStatusColor()}>
                  {modeleReceived?.online
                    ? translate(TranslateConfig.ONLINE)
                    : translate(TranslateConfig.DISCONNECTED)
                  }
                </HeaderStatusUsersBadge>


                {/* TODO */}
                <HeaderUserLastAccessText>
                  {translate(TranslateConfig.ACCESS_LAST)}: {translate(TranslateConfig.NOT_AVAILABLE)}
                </HeaderUserLastAccessText>

              </HeaderUserStatusView>
            )}

          </HeaderUserAvatarImage>



          <HeaderInfoContentView>

            {modeleReceived?.exclusive && (
              <HeaderUserExclusiveText>
                {translate(TranslateConfig.EXCLUSIVE)}
              </HeaderUserExclusiveText>
            )}



            <HeaderNameText>
              {modeleReceived?.alias || '-'}
            </HeaderNameText>



            <SubtitleLongView>

              <HeaderUserNameText>
                @{modeleReceived?.useralias || '-'}
              </HeaderUserNameText>


              {modeleReceived?.verified && (
                <IconVerifiedButton
                  onClick={() => {
                    dispatch({ type: USER_VERIFIED_MODAL, payload: true });
                  }}>
                  <IconVerified
                    color={Colors.accent}
                    size={16}
                  />
                </IconVerifiedButton>
              )}


              {modeleReceived?.brandPromoter && (
                <IconVerifiedButton
                  onClick={() => {
                    dispatch({ type: USER_BRAND_PROMOTER_MODAL, payload: true });
                  }}>
                  <IconVerifiedPromoter
                    size={16}
                  />
                </IconVerifiedButton>
              )}

            </SubtitleLongView>



            {projectParams?.canAccess && (
              <HeaderUserFollowItemView>

                <HeaderUserFollowView>

                  <HeaderUserFollowIconText>
                    <IconUserFollow
                      // [Quem favoritou]
                      color={Colors.white}
                      size={iconRating}
                    />
                  </HeaderUserFollowIconText>

                  <HeaderUserFollowText>
                    {renderFollowTitle()}
                  </HeaderUserFollowText>

                </HeaderUserFollowView>



                <HeaderUserAvaliatinsView>

                  <HeaderUserFollowIconText>
                    <IconStar
                      color={Colors.white}
                      size={iconRating}
                    />
                  </HeaderUserFollowIconText>

                  <HeaderUserFollowText>
                    {renderCommentNumberTitle()}
                  </HeaderUserFollowText>

                </HeaderUserAvaliatinsView>

              </HeaderUserFollowItemView>
            )}



            <HeaderUserTravelView>
              {renderUserTravel()}
            </HeaderUserTravelView>

          </HeaderInfoContentView>



          <HeaderUserFollowContent>

            <HeaderUserActionsBadgeView>

              {projectParams?.canAccess && projectParams?.showRent && projectParams?.showCalendar && !showActions && (
                <ButtonDoModules
                  type={'ACTION'}
                  disabled={!modeleReceived?.params?.rent}
                  icon={modeleReceived?.params?.rent
                    ? <IconRentOn
                      color={Colors.white}
                      size={iconActions}
                    />
                    : <IconRentOff
                      color={Colors.white}
                      size={iconActions}
                    />
                  }
                  title={modeleReceived?.params?.rent
                    ? translate(TranslateConfig.ACTION_SCHEDULE_TIME)
                    : translate(TranslateConfig.EMPTY_SCHEDULE_TIME)
                  }
                  onPress={() => {
                    // if (false) {
                    //   history.push('/rent/username');
                    // }
                  }}
                />
              )}


              {projectParams?.canAccess && projectParams?.showLive && !showActions && (
                <ButtonDoModules
                  type={'ACTION'}
                  disabled={!modeleReceived?.params?.live}
                  icon={modeleReceived?.params?.live
                    ? <IconRoomOpen
                      color={Colors.white}
                      size={iconActions}
                    />
                    : <IconRoomClose
                      color={Colors.white}
                      size={iconActions}
                    />
                  }
                  title={modeleReceived?.params?.live
                    ? translate(TranslateConfig.ACTION_ROOM_TIME)
                    : translate(TranslateConfig.EMPTY_ROOM_TIME)
                  }
                  onPress={() => {
                    // if (false) {
                    //   history.push('/room/username');
                    // }
                  }}
                />
              )}


              {projectParams?.canAccess && projectParams?.showFanClub && !showActions && (
                <ButtonDoModules
                  type={'FAN_CLUB'}
                  disabled={!modeleReceived?.params?.fanClub}
                  onPress={() => {
                    dispatch({ type: USER_FACLUB_MODAL, payload: true });
                  }}
                />
              )}

            </HeaderUserActionsBadgeView>



            <HeaderUserColumnActionView>

              <HeaderUserRowActionView>

                {projectParams?.canAccess && !showActions && (
                  <HeaderUserActionButton
                    disabled={!availableUser}
                    backgroundColor={verifyItem ? accentGender : Colors.shadow}
                    onClick={() => {
                      if (!availableUser) {
                        history.push(NameRoutes.AccessScreen);
                        return;
                      }

                      onFavoritePress();
                    }}>

                    {verifyItem
                      ? (
                        <IconFavoriteOn
                          color={Colors.white}
                          size={iconActions}
                        />
                      )
                      : (
                        <IconFavoriteOff
                          color={Colors.white}
                          size={iconActions}
                        />
                      )
                    }

                  </HeaderUserActionButton>
                )}


                {projectParams?.canAccess && projectParams?.showFinancial && !showActions && (
                  <HeaderUserActionButton
                    disabled={!availableUser}
                    onClick={() => {
                      if (!availableUser) {
                        history.push(NameRoutes.AccessScreen);
                        return;
                      }

                      dispatch({ type: USER_SEND_GIFT_MODAL, payload: true });
                    }}>

                    <IconTipGifts
                      color={Colors.white}
                      size={iconActions}
                    />

                  </HeaderUserActionButton>
                )}


                {projectParams?.canAccess && projectParams?.showChatMessages && !showActions && (
                  <HeaderUserActionButton
                    disabled={!availableUser}
                    onClick={() => {
                      if (!availableUser) {
                        history.push(NameRoutes.AccessScreen);
                        return;
                      }

                      console.log('BLOCK');
                    }}>
                    <IconBlock
                      color={Colors.white}
                      size={iconActions}
                    />
                  </HeaderUserActionButton>
                )}


                {/* FIXME */}
                {false && projectParams?.canAccess && !showActions && (
                  <HeaderUserActionButton
                    disabled={!availableUser}
                    onClick={() => {
                      if (!availableUser) {
                        history.push(NameRoutes.AccessScreen);
                        return;
                      }

                      dispatch({ type: USER_BAD_REPORT_MODAL, payload: true });
                    }}>
                    <IconReport
                      color={Colors.white}
                      size={iconActions}
                    />
                  </HeaderUserActionButton>
                )}


                <HeaderUserActionButton
                  onClick={() => {
                    if (props.openShareModal) {
                      props.openShareModal();
                    }
                  }}>
                  <IconShare
                    color={Colors.white}
                    size={iconActions}
                  />
                </HeaderUserActionButton>

              </HeaderUserRowActionView>



              {projectParams?.canAccess && projectParams?.showChatMessages && !showActions && (
                <ButtonSendMessage
                  size={iconActions}
                  onPress={() => {
                    // TODO
                  }}
                />
              )}

            </HeaderUserColumnActionView>

          </HeaderUserFollowContent>

        </HeaderUserInfoContent>
      </HeaderUserInfoView>

    </HeaderUserHeaderView>

  );
};



export default ModeleDetailHeader;
